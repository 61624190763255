import { memoize } from 'lodash';

/**
 * The max distance tollerance between color channel to discriminate
 * an image as multicolored or not.
 *
 * For example 10 means that if the differences between all max and min values
 * of all RGB channels is more than 10 than we consider the image multicolored
 *
 * @const {Number} TINT_COLOR_THRESHOLD
 * @private
 */
const TINT_COLOR_THRESHOLD = 10;

const tint = (ctx, w, h, color) => {
    const imageData = ctx.getImageData(0, 0, w, h);
    const data = imageData.data;
    for (let i = 0, size = data.length; i < size; i += 4) {
        let opacity = data[i + 3];
        if (opacity !== 0) {
            data[i] = color[0];
            data[i + 1] = color[1];
            data[i + 2] = color[2];
        }
    }
    ctx.putImageData(imageData, 0, 0);
};

/**
 * Given an image this method checks if it's multicolored
 *
 * @param {HTMLImageElement} img the image element to count the color for
 *
 * @returns {Boolean} true if the image is multicolor
 */
const isMulticolor = memoize(
    (img) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, img.naturalWidth, img.naturalHeight);
        let minR, minG, minB, maxR, maxG, maxB;

        minR = minG = minB = 255;
        maxR = maxG = maxB = 0;

        // Iterate through every pixel
        for (let i = 0; i < imageData.data.length; i += 4) {
            const r = imageData.data[i + 0];
            const g = imageData.data[i + 1];
            const b = imageData.data[i + 2];
            const a = imageData.data[i + 3];

            if (a > 127) { // 50% transparent
                if (r < minR) minR = r;
                if (g < minG) minG = g;
                if (b < minB) minB = b;

                if (r > maxR) maxR = r;
                if (g > maxG) maxG = g;
                if (b > maxB) maxB = b;
            }
        }

        const distance = (((maxR - minR) ^ 2) + ((maxG - minG) ^ 2) + ((maxB - minB) ^ 2));
        const multicolor = distance > TINT_COLOR_THRESHOLD;

        return multicolor;
    },
    (img) => img.src
);

/**
 * Recolorize provided image speaker: 212  chat: 726
 *
 * @param {String} url image to recolor
 * @param {String} color hexa color
 * @param {Object} $q promise API from angular
 * @returns {Promise} promise resolving with a data: url
 */
const recolorize = memoize(
    (url, color, $q) => {
        // cannot do real promise (IE ...)
        const deferred = $q.defer();
        const img = new Image();
        img.onload = () => {
            // If the image contains is multicolored
            // do not apply the tint.
            if (isMulticolor(img)) {
                console.log('Multicolor detected', img.src);
                deferred.resolve(img.src);
                return;
            }

            const canvas = document.createElement('canvas');
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            tint(
                ctx,
                img.naturalWidth,
                img.naturalHeight,
                hexToRgbArray(color) || [ 0, 0, 0 ]
            );
            deferred.resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = deferred.reject;
        img.src = url;
        return deferred.promise;
    },
    (url, color) => `${url}${color}`
);
module.exports.recolorize = recolorize;

/**
 * Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
 *
 * @param {string} shorthand the hex shorthand
 * @returns {string} full hex color code
 */
const hexShorthandToFull = shorthand => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    return shorthand.replace(shorthandRegex, function(m, r, g, b) {
        return '#' + r + r + g + g + b + b;
    });
};
module.exports.hexShorthandToFull = hexShorthandToFull;

/**
 * Transforms an hex color to its rgb array representation
 * @param {string} hex the hex code number
 * @returns {string[]} the RGB array
 */
const hexToRgbArray = hex => {
    hex = hexShorthandToFull(hex);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ]
        : null;
};
module.exports.hexToRgbArray = hexToRgbArray;

/**
 * Given an hexadecimal string this method
 * returns a valid web format.
 *
 * @param color the hex color string.
 *
 * @return a valid hexadecimal value.
 */
const sanitizeHex = color => {
    if (color.length <= 7) return color;
    let ahex = color.split('#')[1];
    let hexa = ahex.substring(2) + ahex.substring(0, 2);
    return '#' + hexa;
};
module.exports.sanitizeHex = sanitizeHex;

/**
 * Gets the level of light in a color
 * @param {string} hex the hex color code
 * @returns {Number} the quantity of light in the color ( 0 <= dark, light => 1)
 */
const getLightFromHex = hex => {
    let rgb = hexToRgbArray(hex);
    let r = rgb[0] / 255;
    let g = rgb[1] / 255;
    let b = rgb[2] / 255;
    // see http://alienryderflex.com/hsp.html
    return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
};
module.exports.getLightFromHex = getLightFromHex;
