class FrontstageLoaderService {
    /* @ngInject */
    constructor(
        EVENT,
        $http,
        $injector,
        $q,
        handlerService,
        progressBarService,
        $ocLazyLoad
    ) {
        this.event = EVENT;
        this.$http = $http;
        this.$injector = $injector;
        this.$q = $q;
        this.progressBarService = progressBarService;
        this.handlerService = handlerService;
        this.loadingPromise = false;
        this.$ocLazyLoad = $ocLazyLoad;
    }

    loadFstg() {
        const deferred = this.$q.defer();
        const backendUrl = this.handlerService.generateBackendUrl;

        if (this.$injector.has('pageService')) {
            return this.$q.resolve(true);
        }

        if (this.loadingPromise) {
            return this.loadingPromise;
        }

        this.loadingPromise = deferred.promise;
        this.progressBarService.startTask();

        return this.$http.get(backendUrl('webapp/fstg-all'))
            .then(response => {
                const compiledFstgAssets = response.data;
                const blocks = compiledFstgAssets.frontstageBlocks?.blocks;
                delete compiledFstgAssets.frontstageBlocks;

                return angular
                    .module('frontstage.config', [])
                    .constant('FSTG_LOADER', 'maestro')
                    .constant('COMPILED_FSTG_ASSETS', compiledFstgAssets)
                    .constant('BLOCKS', blocks);
            })
            .then(() => {
                const fstgSrcUrl = backendUrl('webapp/fstg-source');

                return this.$ocLazyLoad
                    .load('js!' + fstgSrcUrl)
                    .then(() => {
                        deferred.resolve(true);
                    });
            })
            .finally(() => {
                this.progressBarService.finishTask();
            });
    }
}

angular
    .module('maestro.services')
    .service('frontstageLoaderService', FrontstageLoaderService);
