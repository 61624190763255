import { pick, get } from 'lodash';
import moment from 'moment-timezone';
import { googleCalendarUrl } from '../../utils/external-calendar-services';
import i18nWithFallback from '../../utils/i18n-with-fallback';

const VCARD_HANDLER = 'webapp/export-vcard';
const ALL_VCARDS_HANDLER = 'webapp/export-all-vcards';
const ICAL_HANDLER = 'webapp/export-ical';
const NOTES_EXPORT_HANDLER = 'webapp/export-notes';

const choiceSheet = ({ $sheet, $device, navTemplates, title, actions }) => {
    const sheetConfig = {
        title,
        scrollable: false,
        dismissOnOutsideClick: true
    };

    if ($device('isPhone')) {
        sheetConfig.menu = true;
        sheetConfig.actions = actions;
    } else {
        sheetConfig.services = actions;
        sheetConfig.templateUri = navTemplates.calendar_services_sheet;
    }

    return $sheet(sheetConfig);
};

export default class NavIntegrations {
    /* @ngInject */
    constructor(
        NAV_TEMPLATES,
        EVENT,
        SETTINGS,
        $window,
        $i18n,
        $device,
        handlerService,
        $filter
    ) {
        this.NAV_TEMPLATES = NAV_TEMPLATES;
        this.EVENT = EVENT;
        this.SETTINGS = SETTINGS;
        this.$window = $window;
        this.$i18n = $i18n;
        this.$device = $device;
        this.handlerService = handlerService;
        this.stripHTML = $filter('stripHTML');
    }

    addToAddressBook(data) {
        const attrs = pick(
            data,
            '_id',
            'fname',
            'lname',
            'position',
            'company',
            'email',
            'phone',
            'mobile',
            'linkedin_url',
            'notes'
        );
        this.$window.open(
            this.handlerService.generateHandlerUrl(VCARD_HANDLER, attrs),
            '_blank'
        );
    }

    addAllToAddressBook() {
        const { $window, $sheet, $device, $i18n, handlerService } = this;

        const download = format =>
            $window.open(
                handlerService.generateHandlerUrl(ALL_VCARDS_HANDLER, {
                    format
                }),
                '_blank'
            );

        const supportsMultipleFormats =
            $i18n('general.download_csv_cards') !==
            'general.download_csv_cards';
        if (!supportsMultipleFormats) {
            return download('vcf');
        }

        choiceSheet({
            $sheet,
            $device,
            navTemplates: this.NAV_TEMPLATES,

            title: $i18n('general.select_format'),
            actions: [
                {
                    id: 'vcf',
                    label: $i18n('general.download_vcf_cards'),
                    action: () => download('vcf') && true
                },
                {
                    id: 'csv',
                    label: $i18n('general.download_csv_cards'),
                    action: () => download('csv') && true
                }
            ]
        });
    }

    addToCalendar(data) {
        const { $window, $sheet, $device, $i18n, handlerService } = this;

        const openICalFile = () => {
            const attrs = pick(data, '_id', 'name', 'start', 'end', 'location');

            if (data.content) {
                attrs.notes = this.stripHTML(data.content);
                if (attrs.notes.length > 1500) {
                    attrs.notes = attrs.notes.substring(0, 1494) + ' [...]';
                }
            }

            const url = handlerService.generateHandlerUrl(ICAL_HANDLER, attrs);
            $window.open(url, '_top');
        };
        // &&true => angular complains about actions returning $window
        const openGoogleCalendar = () =>
            $window.open(
                googleCalendarUrl(data,
                    get(this, 'SETTINGS.date.timezone') === 'device' ?
                        moment.tz.guess() :
                        this.EVENT.timezone
                ),
                '_blank'
            ) && true;

        choiceSheet({
            $sheet,
            $device,
            navTemplates: this.NAV_TEMPLATES,

            title: $i18n('general.select_service'),
            actions: [
                {
                    id: 'native',
                    label: this.$i18n('general.native_calendar_apps'),
                    action: openICalFile
                },
                {
                    id: 'gcal',
                    label: i18nWithFallback(this.$i18n, 'general.google_calendar', 'Google Calendar'),
                    action: openGoogleCalendar
                }
            ]
        });
    }

    exportNotes() {
        this.$window.open(
            this.handlerService.generateHandlerUrl(NOTES_EXPORT_HANDLER),
            '_blank'
        );
    }
}
