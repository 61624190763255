import { map, cloneDeep, extend, isString } from 'lodash';

// helper to add the parent nav id to the action params
const getParentNavConfig = parentNavId =>
    parentNavId
        ? {
            _parent_nav_id: parentNavId
        }
        : null;

export default class NavActions {
    /* @ngInject */
    constructor($rootScope, $sheet, metaService, progressBarService, $q) {
        this.$rootScope = $rootScope;
        this.$sheet = $sheet;
        this.metaService = metaService;
        this.ongoingAction = false;
        this.progressBarService = progressBarService;
        this.$q = $q;
    }

    dispatch(action) {
        // for documents with type links we get back only the url
        if (isString(action)) {
            action = {
                service: 'navService',
                method: 'openExternalUri',
                params: { uri: action }
            };
        }

        this.$$performAction(action);
    }

    // Action helpers - used by navs
    $$performAction({ service, method, params = {}, extraParams }) {
        // wrap into promises
        return this.$q.resolve(
            this.metaService.callServiceMethod(service, method, [
                params,
                extraParams
            ])
        );
    }

    performActions(actions, parentNavId) {
        if (this.ongoingAction) {
            console.log(
                '[NavHelpers.performActions] Skip actions because of ongoing action.'
            );
            return;
        }
        this.ongoingAction = true;

        const progressBarService = this.progressBarService;
        progressBarService.startTask();

        if (!parentNavId && parentNavId !== null) {
            console.warn(
                '[NavHelpers.performActions] parentNavId should be provided for proper navigation.'
            );
        }
        this.$q
            .all(
                map(actions, action =>
                    this.$$performAction(
                        extend(action, {
                            extraParams: getParentNavConfig(parentNavId)
                        })
                    )
                )
            )
            .catch(() =>
                console.warn(
                    '[NavHelpers.performActions] could not perform actions.',
                    actions
                )
            )
            .then(() => {
                this.ongoingAction = false;
                progressBarService.finishTask();
            });
    }

    mergeAndPerformRowActions(actionsSpec, parentNavId) {
        if (!parentNavId && parentNavId !== null) {
            console.warn(
                '[NavHelpers.mergeAndPerformRowActions] parentNavId should be provided for proper navigation.'
            );
        }
        return row =>
            this.performActions(
                row.actions && !row.actionParam
                    ? row.actions
                    : map(cloneDeep(actionsSpec), (action, index) => {
                        action.params = row.actionParams[index] || {};
                        return action;
                    }),
                parentNavId
            );
    }

    showNavToolbarMenu(menu) {
        return $event =>
            this.$sheet({
                menu: true,
                positionMenuAround: $event.currentTarget,
                title: 'general.more_with_ellipsis',
                actions: map(menu, action => {
                    if (typeof action.action !== 'function') {
                        action.action = this.performActions.bind(
                            this,
                            cloneDeep([ action.action ])
                        );
                    }
                    return action;
                })
            });
    }
}
