/**
 * Image is an element that display in his basic usage just an image,
 * but it can draw a title and a subtitle over the image using a shadow
 * curtain.
 *
 * The properties that can be set on an Image element are the following
 *
 * ----------------------------------------------------------------------------------------------
 * Parameter        Type     Default     Min      Max      Encoding             Mandatory   Notes
 * ----------------------------------------------------------------------------------------------
 * url              String   -           -        -        -                    yes
 * title            String   -           -        -        -                    no          (1)
 * title_color      String   #ffffff     -        -        #RRGGBB or           no
 *                                                         #AARRGGBB
 * title_size       int      24pt        >= 1     -        -                    no
 * title_style      String   bold        -        -        normal, bold,        no
 *                                                         italic, bold-italic
 * title_max_lines  int      unlimited   >= 0     100      -                    no
 * subtitle         String   -           -        -        -                    no          (2)
 * subtitle_color   String   #ffffff     -        -        #RRGGBB or           no
 *                                                         #AARRGGBB
 * subtitle_size    int      14pt        >= 1     -        -                    no
 * subtitle_style   String   normal      -        -        normal, bold,        no
 *                                                         italic, bold-italic
 * width            int      -           >= 1     -        points/dp            no          (3)
 * height           int      -           >= 1     -        points/dp            no          (3)
 * margin           Json     -                                                  no          (4)
 * `- left          int      0           >= 0     -        points/dp            no
 * `- top           int      8           >= 0     -        points/dp            no
 * `- right         int      0           >= 0     -        points/dp            no
 * `- bottom        int      8           >= 0     -        points/dp            no
 * corner_radius    int      0           > 0      -        -                    no
 * ----------------------------------------------------------------------------------------------
 *
 * (1) title could span on at most 2 lines
 *
 * (2) subtitle could span over multiple lines
 *
 * (3) Android: when image width or height are not defined or have a not supported
 *     value the image is displayed with his own original size and put at the center
 *     in all the other case the image will be resize to cover the entire width of
 *     the screen
 *
 * (4) Contains margin properties (left,top, right and bottom), a margin value missing
 *     will be set to a default value.
 */

export const ImageComponent = {
    bindings: {
        row: '<'
    },
    template: require('./image.jade'),
    controller: class ImageComponent {
        /* @ngInject */
        constructor(navService, styleParser) {
            this.navService = navService;
            this.parser = styleParser;
        }

        $onInit() {
            this.config = this.row.config;
            // console.debug('[ImageComponent] init', this.config);

            const margins = this.parser.parseMargins(this.config);
            const boxing = this.parser.parseBoxing(this.config);

            this.style = angular.merge(margins, boxing);
        }
    }
};
