import { each } from 'lodash';

/* @ngInject */
function ComponentLoaderDirective($compile) {
    return {
        restrict: 'E',
        scope: {
            component: '<',
            attrs: '<'
        },
        link($scope, $element) {
            const componentName = $scope.component;
            const template = document.createElement(componentName);
            const attrs = $scope.attrs || {};
            each(attrs, (value, key) => template.setAttribute(key, value));
            $element.html($compile(template)($scope.$parent));
        }
    };
}

angular
    .module('maestro.directives')
    .directive('componentLoader', ComponentLoaderDirective);
