import { isFunction } from 'lodash';

export const JoinCallButtonComponent = {
    bindings: {
        sessionId: '<',
        nav: '<',
    },
    template: require('./join-call-button.pug'),
    controller: class JoinCallButtonComponent {
        /* @ngInject */
        constructor($scope, $eventBus, videoBreakoutService, $location) {
            this.$scope = $scope;
            this.$eventBus = $eventBus;
            this.$location = $location;
            this.videoBreakoutService = videoBreakoutService;
            this.showButton = true;
        }

        $onInit() {
            const showButton = () => this.showButton = true;
            const hideButton = () => this.showButton = false;

            const unsubVideoCreated = this.$eventBus.on('join-call-button:hide', hideButton);
            const unsubOngoing = this.$eventBus.on('video-call:ongoing', hideButton);

            const unsubOnClose = this.$eventBus.on('join-call-button:show', showButton);

            this.listeners = [
                unsubVideoCreated,
                unsubOngoing,
                unsubOnClose
            ];
        }

        $postLink() {
            this.$eventBus.emit('join-call-button:created', this.$location.url());
            this.videoBreakoutService.attemptAutoStart(this.nav, this.sessionId);
        }

        $onDestroy() {
            for (const unsub of this.listeners) {
                if (isFunction(unsub)) unsub();
            }
        }

        joinVideoCall() {
            this.videoBreakoutService.start(this.nav, this.sessionId);
        }
    }
};
