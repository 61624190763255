/**
 * MediaText is an element that may contain image, text and a side text, all
 * of this displayed in an horizontal row.
 *
 * His main purpose is to act as an header at the top of a card block, maybe
 * for this reason we should call it HeaderText ...
 *
 * The properties that can be set on a MediaText element are the following
 *
 * -----------------------------------------------------------------------------------------------------------
 * Parameter              Type       Default      Min       Max        Encoding              Mandatory   Notes
 * -----------------------------------------------------------------------------------------------------------
 * title                  String     -            -         -          -                     no
 * title_color            String     808080       -         -          #RRGGBB or
 *                                                                     #AARRGGBB             no
 * title_size             int        18pt         >= 1      -          -                     no
 * title_style            String     bold         -         -          normal, bold,         no
 *                                                                     italic, bold-italic
 * title_max_lines        int        unlimited    >= 0      100        -                     no
 * subtitle               String     -            -         -          -                     no
 * subtitle_color         String     808080       -         -          #RRGGBB or            no
 *                                                                     #AARRGGBB
 * subtitle_size          int        16pt         >= 1      -          -                     no
 * subtitle_style         String     normal       -         -          normal, bold,         no
 *                                                                     italic, bold-italic
 * sidetitle              String     -            -         -          -                     no
 * sidetitle_color        String     808080       -         -          #RRGGBB or            no
 *                                                                     #AARRGGBB
 * sidetitle_size         int        14pt         >= 1      -          -                     no
 * sidetitle_style        String     normal       -         -          normal, bold,         no
 *                                                                     italic, bold-italic
 * sidetitle_action       Json                                                               no           (1)
 * path                   String     -            -         -          -                     no
 * params                 Json                                                               no
 * image_url              String     -            -         -          -                     no
 * image_shape            String     rectangle    -         -          rectangle or          no
 *                                                                     circle
 * image_width            int        -            >= 1      -          points/dp             no
 * image_height           int        -            >= 1      -          points/dp             no
 * image_corner_radius    int        0            > 0       -          -                     no
 * margin                 Json                                                               no           (2)
 * `- left                int        8            >= 0      -          points/dp             no
 * `- top                 int        8            >= 0      -          points/dp             no
 * `- right               int        8            >= 0      -          points/dp             no
 * `- bottom              int        8            >= 0      -          points/dp             no
 * -----------------------------------------------------------------------------------------------------------
 *
 * (1) Json that contains path and params attributes related to the side title
 *     action
 *
 * (2) Contains margin properties (left,top, right and bottom), a margin value
 *     missing will be set to a default value.
 *
 */

export const MediaTextComponent = {
    bindings: {
        row: '<'
    },
    replace: true,
    template: require('./media-text.jade'),
    controller: class MediaTextComponent {
        /* @ngInject */
        constructor(navService, styleParser) {
            this.navService = navService;
            this.parser = styleParser;
        }

        $onInit() {
            this.config = this.row.config;

            const titleTexts = this.parser.parseTextsStyles(
                this.config,
                'title'
            );
            const titleBoxing = this.parser.parseBoxing(this.config, 'title');
            this.title_style = angular.merge(titleTexts, titleBoxing);

            this.style = this.parser.parseMargins(this.config);
            this.image_style = this.parser.parseImageStyles(this.config);
            if (this.image_style) {
                this.image_wrapper_style = { width: this.image_style.width, height: this.image_style.height };
            }
            this.subtitle_style = this.parser.parseTextsStyles(
                this.config,
                'subtitle'
            );
            this.sidetitle_style = this.parser.parseTextsStyles(
                this.config,
                'sidetitle'
            );
        }

        performSideTitleAction($event) {
            $event.stopPropagation();
            const act = this.config.sidetitle_action;
            const lastNav = this.navService.activeNav.nav || {};
            return this.navService.openDocument(act.params, {
                _parent_nav_id: lastNav.nuid
            });
        }
    }
};
