import { extend } from 'lodash';
import i18nWithFallback from '../../../../utils/i18n-with-fallback';

export const InlineBlockPaginatorComponent = {
    template: require('./inline-block-paginator.jade'),
    bindings: {
        blocks: '<',
        previousPage: '<',
        previousPageAction: '<',
        prependBlocks: '&',
        removeBlock: '&',
        updateInlineBlock: '&',
        threadAction: '<'
    },
    controller: class InlineBlockPaginatorComponent {
        /* @ngInject */ constructor($i18n, databaseService, navService, EventEmitter) {
            this.databaseService = databaseService;
            this.EventEmitter = EventEmitter;
            this.navService = navService;
            this.$i18n = $i18n;
            this.showThreadLabel = i18nWithFallback(
                $i18n,
                'feed.show_this_thread',
                'Show this thread'
            );

        }

        $onInit() {
            console.log('[InlineBlockPaginatorComponent] init', this.blocks);
            const hasLabel = this.$i18n('feed.show_this_thread') !== 'feed.show_this_thread';
            this.showThreadAction = hasLabel && this.threadAction;
        }

        $onChanges(changes) {
            if (changes.blocks) {
                this.blocks = extend({}, this.blocks);
            }
        }

        // paging
        getPageParams(action, pageParam, getBlock) {
            if (!action || !action.path) {
                return null;
            }

            if (!this.blocks || !this.blocks.length) {
                return (action.params || {}).page || {};
            }

            const block = getBlock();
            return block && block.data.content[pageParam];
        }

        hasMoreContent() {
            return this.hasPreviousPage();
        }

        // previous page
        previousPageParams() {
            return (
                this.previousPageAction &&
                this.previousPageAction.path &&
                this.previousPage
            );
        }

        hasPreviousPage() {
            return !!this.previousPageParams();
        }

        // loading content
        loadPage(action, pageParams) {
            const params = extend({}, action.params, { page: pageParams });
            return this.databaseService
                .runAppScript(action.path || action.target, params)
                .then(({ data: { response } }) => response);
        }

        loadPreviousPage() {
            const pageParams = this.previousPageParams();
            if (!pageParams) return;

            this.loadingPreviousPage = true;
            this.loadPage(this.previousPageAction, pageParams)
                .then(blocks =>
                    this.prependBlocks(this.EventEmitter({ blocks }))
                )
                .finally(() => {
                    this.clicked = true;
                    this.loadingPreviousPage = false;
                });
        }

        performBlockAction() {
            if (this.threadAction) {
                this.navService.runScript(this.threadAction);
            }
        }
    }
};
