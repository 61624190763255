class OfflineService {
    /* @ngInject */
    constructor($q, $eventBus, $timeout) {
        this.$q = $q;
        this.$timeout = $timeout;
        // turn the service into an event emitter
        $eventBus.spawn(this);
    }

    interceptor() {
        const $q = this.$q;
        const $timeout = this.$timeout;
        const eventEmitter = this;

        let offline = false;
        let pendingCall = false;
        let defer = $q.defer();
        defer.resolve();

        window.addEventListener('offline', () => {
            // create a new defer. Its promise will resolve when back online
            defer = $q.defer();
            offline = true;
            $timeout(() => eventEmitter.emit('offline'));
        });

        window.addEventListener('online', () => {
            // if it went offline before, waiting is a promise waiting for its resolve fn to be called
            offline = false;
            pendingCall = false;
            $timeout(() => eventEmitter.emit('online'));
            defer.resolve();
        });

        return {
            request(request) {
                if (offline && !pendingCall) {
                    pendingCall = true;
                    $timeout(() => eventEmitter.emit('offline:pending'));
                }
                return defer.promise.then(() => request);
            }
        };
    }
}

angular
    .module('maestro.services')
    .service('offlineService', OfflineService)
    .config([ '$httpProvider', $httpProvider => {
        $httpProvider.interceptors.push([
            'offlineService',
            offlineService => offlineService.interceptor()
        ]);
    } ]);
