import { ActionsComponent } from './actions/actions-fragment.component';
import { CarouselComponent } from './carousel/carousel-fragment.component';
import { DividerComponent } from './divider/divider-fragment.component';
import { ImageComponent } from './image/image-fragment.component';
import { MediaTextComponent } from './media-text/media-text-fragment.component';
import { TextComponent } from './text/text-fragment.component';
import { TagListComponent } from './tag-list/tag-list.component';

export const FragmentsModule = angular
    .module('maestro.components.blocks.cards.fragments', [])
    .component('actionsFragment', ActionsComponent)
    .component('carouselFragment', CarouselComponent)
    .component('dividerFragment', DividerComponent)
    .component('imageFragment', ImageComponent)
    .component('mediaTextFragment', MediaTextComponent)
    .component('textFragment', TextComponent)
    .component('tagListFragment', TagListComponent).name;
