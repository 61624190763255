// Utils
import { get, isFunction } from 'lodash';
import { MODERATOR_ACTIONS } from '../../../services/videoCallModeratorService';
import i18nWithFallback from '../../../utils/i18n-with-fallback';
import { addEventListener } from '../../../utils/dom';

/**
 * This component is used to render the subscriber video
 *
 */
export const ModeratorOptions = {
    bindings: {
        isPinned: '<',
        otObject: '=',
        showModeratorControls: '=',
        hasAudio: '<',
        pid: '<',
        isOwnPublisher: '=',
        name: '='
    },
    template: require('./moderator-options.pug'),
    controller: class SubscriberComponent {
        /* @ngInject */
        constructor($i18n, $scope, $element, $eventBus, $timeout, uiService, videoCallModeratorService, THEME) {
            this.$scope = $scope;
            $scope.eventTheme = THEME;
            this.$eventBus = $eventBus;
            this.uiService = uiService;
            this.videoCallModeratorService = videoCallModeratorService;
            this.listeners = [];
            this.showSubscriberOptions = false;
            this.$timeout = $timeout;

            this.labels = {
                mute: i18nWithFallback($i18n, 'video_calls.actions.mute', 'Mute'),
                ask_unmute: i18nWithFallback($i18n, 'video_calls.actions.ask_all_unmute', 'Ask to unmute'),
                kickout: i18nWithFallback($i18n, 'video_calls.actions.kickout', 'Kickout participant'),
                pin: i18nWithFallback($i18n, 'video_calls.actions.pin', 'Pin'),
                unpin: i18nWithFallback($i18n, 'video_calls.actions.unpin', 'Unpin'),
                actions: i18nWithFallback($i18n, 'video_calls.actions.actions', 'Actions'),
                all_participants: i18nWithFallback($i18n, 'video_calls.message.all_participants', 'All participants'),
                unmute_request_sent: i18nWithFallback($i18n, 'video_calls.message.unmute_request_sent', 'Unmute request sent')
            };
        }

        $postLink() {
            const hideOptions = () => {
                if (this.showSubscriberOptions) {
                    this.$timeout(() => {
                        this.showSubscriberOptions = false;
                    });
                }
            };
            this.listeners.push(
                addEventListener(document, 'mouseup', hideOptions)
            );
        }

        $onDestroy() {
            for (let listener of this.listeners) {
                if (isFunction(listener)) {
                    listener();
                }
            }
            this.listeners = [];
        }

        toggleSubscriberOptions() {
            this.showSubscriberOptions = !this.showSubscriberOptions;
        }

        moderatorAction(action) {
            const connection = get(this, 'otObject.stream.connection', {});

            if (!connection) {
                return;
            }

            if (action === 'KICKOUT') {
                return this.$scope.$emit('moderator:showKickoutModal', { connection });
            }

            this.videoCallModeratorService.sendAction(MODERATOR_ACTIONS[action], { connection });
            if (action === 'UNMUTE') {
                const message = this.uiService.buildNotificationMessage({
                    title: this.name || this.labels.all_participants,
                    message: {
                        body: this.labels.unmute_request_sent,
                    },
                    duration: 5,
                    backgroundColor: get(this.$scope, 'eventTheme.toolbar.background_color'),
                    contentColor: get(this.$scope, 'eventTheme.toolbar.font_color'),
                    iconColor: get(this.$scope, 'eventTheme.toolbar.font_color')
                });
                this.uiService.inAppNotification(message);
            }
        }

        pinSpeaker() {
            this.$scope.$emit('moderator:pin', this.pid);
        }
    }
};
