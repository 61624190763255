/* @ngInject */
function InterceptAppscriptLinksDirective(navService) {
    return {
        restrict: 'A',
        link(scope, elem) {
            console.log('[InterceptAppscriptLinksDirective] init', elem);

            let intercept = function(e) {
                e.preventDefault();

                let [ path, params ] = this.getAttribute('href')
                    .split('://')[1]
                    .split('?');
                params = decodeURIComponent(params);
                if (!params) return;

                try {
                    params = JSON.parse(params);
                } catch (e) {
                    console.error(
                        '[InterceptAppScriptLinks] invalid JSON',
                        params,
                        e
                    );
                    return;
                }

                console.log(
                    '[InterceptAppScriptLinks] intercepted',
                    path,
                    params
                );
                navService.runScript({ path, params });
            };

            elem.on('click', 'a.appscript-link', intercept);
            scope.$on('$destroy', () => elem.off('click', 'a.appscript-link'));
        }
    };
}

angular
    .module('maestro.directives')
    .directive('interceptAppscriptLinks', InterceptAppscriptLinksDirective);
