import { filter, isArray, isFunction, get, each } from 'lodash';
import { FilteringController } from '../../common/filtering';

const PAGE_SIZE = 52; // multiple of 4 for the grid layout

export const ListNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./list-nav.jade'),
    controller: class ListNavComponent extends FilteringController {
        /* @ngInject */
        constructor(
            NAV_TEMPLATES,
            PID,
            THEME,
            EID,

            $timeout,
            navService,
            metaService,
            metricsService,
            progressBarService,
            paginatorFactory,
            $scope,
            $eventBus,
            $q,
            dataSourceEvaluatorService,
            $i18n,
            $popover,
            $http
        ) {
            super(THEME, EID, $q, $popover, $i18n, $http, dataSourceEvaluatorService);
            this.navTemplates = NAV_TEMPLATES;
            this.pid = PID;
            this.theme = THEME;
            this.$timeout = $timeout;
            this.navService = navService;
            this.metaService = metaService;
            this.metricsService = metricsService;
            this.progressBarService = progressBarService;
            this.paginator = null;
            this.paginatorFactory = paginatorFactory;

            this.elementIds = {};
            this.loading = false;
            this.$eventBus = $eventBus;
            this.searchQuery = '';
            this.selectedFilters = [];

            $scope.$on('bottom-reached', () => this.nextPage());

            this.listeners = [];
        }

        $onInit() {
            const config = this.config;
            console.log('[ListNavComponent] init', config);

            if (config.remote_ds) {
                config.ds = {
                    type: 'remote',
                    source: {
                        path: config.remote_ds.path
                    },
                    paginate: true
                };
                this.searchEnabled = config.remote_ds_filters && config.remote_ds_filters.search;
                this.paginator = this.paginatorFactory({
                    pageSize: config.remote_ds.page_size || PAGE_SIZE,
                    buildQueryParams: (nextStartKey) => {
                        if (nextStartKey && nextStartKey.bookmark) {
                            return { bookmark: nextStartKey.bookmark };
                        }
                        return this.getInflateNavParams();
                    }
                });
            } else {
                this.paginator = this.paginatorFactory({
                    pageSize: config.ds.page_size || PAGE_SIZE
                });
            }

            config.ds.elements = isArray(config.ds.elements) ? config.ds.elements : [];

            this.paginator.reset();
            this.reloadNav();

            this.selectedFilters = this.filters;
            this.getFilters();

            this.performRowActions = this.navService.mergeAndPerformRowActions(
                config.actions,
                config.nuid
            );

            if (config.no_content) {
                this.performNoContentActions = () =>
                    this.navService.performActions(
                        config.no_content.actions,
                        config.nuid
                    );
            }

            const unsub = this.navService.subscribeToEvents(
                this.navService.flattenNotificationMap(this.config.ds.reload_on),
                this.reset.bind(this)
            );

            // Special case for chat nav
            const notificationUnsub = this.$eventBus.on('notification:in-app', (config) => {
                if (get(config, 'action.params.conversation_id')) {
                    this.reset();
                }
            });

            this.listeners.push(unsub, notificationUnsub);
        }

        $onDestroy() {
            for (const unsubscribe of this.listeners) {
                if (isFunction(unsubscribe)) {
                    unsubscribe();
                }
            }
        }

        reset(data) {
            console.log('[ListNavComponent] reload', data);
            this.paginator.reset();
            this.elementIds = {};
            this.config.ds.elements.splice(0, this.config.ds.elements.length);
            this.reloadNav();
        }

        reload() {
            this.reset();
        }

        filterAndAppendElements(elements) {
            if (!elements) return;
            elements = filter(elements, ({ _id }) =>
                this.elementIds[_id] ? false : (this.elementIds[_id] = true)
            );
            elements.forEach(e => {
                const type = get(e, 'render.row_type');
                if (type === 'person_row') {
                    const mapping = get(e, 'render.params.mapping');
                    mapping.mode = this.config.use_grid_layout ? 'large' : '';
                }
            });
            Array.prototype.push.apply(this.config.ds.elements, elements);
        }

        performAction(button) {
            console.log('Button: ', button);
            return this.navService.performActions(button, this.config.nuid);
        }

        reloadNav() {
            if (this.config.ds.paginate !== false) {
                return this.nextPage();
            }

            this.loading = true;
            this.progressBarService.startTask();

            this.navService
                .inflateNav(this.config, {})
                .then(resp => {
                    if (!resp) return;
                    this.elementIds = {};
                    this.config.ds.elements.splice(0, this.config.ds.elements.length);
                    this.$timeout(() => this.filterAndAppendElements(resp.elements));
                })
                .finally(() => this.finishLoading());
        }

        nextPage(optimalCount) {
            if (optimalCount) {
                this.paginator.setPageSize(optimalCount);
            }
            if (!this.paginator.hasMorePages()) {
                return this.finishLoading();
            }
            // makes sure we don't repeat same calls
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.progressBarService.startTask();

            this.navService
                .inflatePaginatedNav(this.config, {}, this.paginator)
                .then(resp => {
                    if (!resp) return;
                    return this.filterAndAppendElements(resp.elements);
                })
                .finally(() => this.finishLoading());
        }

        getInflateNavParams() {
            let params = {
                search: encodeURIComponent(this.searchQuery)
            };
            each(this.selectedFilters, (filter) => {
                params[filter.field] = filter.value.map(encodeURIComponent).join();
            });
            return params;
        }

        finishLoading() {
            this.progressBarService.finishTask();
            this.loading = false;
        }

        onApplyInlineFilters(filtersApplied) {
            if (filtersApplied === 'cleared') {
                this.searchQuery = '';
                this.clearFilters();
            } else {
                this.selectedFilters = isArray(filtersApplied)
                    ? filtersApplied
                    : [];
            }
            this.reset();
        }

        onSearchQueryChange() {
            console.log(
                '[ListNavComponent] onSearchQueryChange',
                this.searchQuery
            );

            this.reset();
        }
    }
};
