export default class NavMisc {
    /* @ngInject */
    constructor($q, authService) {
        this.$q = $q;
        this.authService = authService;
    }

    sync() {
        console.warn('[NavService/sync] is not implemented');
        return this.$q.resolve();
    }

    flushRepl() {
        console.warn('[NavService/flushRepl] is not implemented');
        return this.$q.resolve();
    }

    flushReplications() {
        console.warn('[NavService/flushReplications] is not implemented');
        return this.$q.resolve();
    }

    singlerepl() {
        console.warn('[NavService/singlerepl] is not implemented');
        return this.$q.resolve();
    }

    hideSpotman() {
        console.warn('[NavService/hideSpotman] is not implemented');
        return this.$q.resolve();
    }

    nativeCall() {
        console.warn('[NavService/nativeCall] is not implemented');
        return this.$q.resolve();
    }

    logOut(options = {}) {
        this.authService.logOut(options);
    }
}
