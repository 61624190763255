import { isFunction } from 'lodash';

/* @ngInject */
function FstgNavCtrl(
    EVENT,
    $scope,
    $injector,
    progressBarService,
    navService,
    frontstageLoaderService,
    liveStreamService,
    signalService,
    videoBreakoutService
) {
    console.log('[navFstgCtrl] init', $scope.nav.page_id);
    $scope.eventTitle = EVENT.description.title.en;
    $scope.page = {};

    let videoBreakoutId;
    let signalListeners = [];
    let pageService = {};

    const unsubscribeFromSignals = async () => {
        for (let remover of signalListeners) {
            if (isFunction(remover)) {
                await remover();
            }
        }
        signalListeners = [];
    };

    $scope.$on('$destroy', async function() {
        await unsubscribeFromSignals();
    });

    const loadPage = async function(nav) {
        if (!nav || !nav.page_id) return;

        console.info('[navFstgCtrl] loadPage', nav);

        videoBreakoutId = null;
        $scope.video_breakout_id = null;
        $scope.pageController = null;

        progressBarService.startTask();
        pageService
            .loadPage(nav.page_id, nav.page_params || {})
            .then(([ page, pageController ]) => {
                $scope.page = page;
                $scope.pageController = pageController;

                if (nav.live_stream_id) {
                    console.log('[FstgCtrl] Opening live stream with ID ', nav.live_stream_id);
                    return liveStreamService.start({ id: nav.live_stream_id });
                }

                if (nav.video_breakout) {
                    // pkg-lib-navigation-helpers has been updated
                    if (nav.video_breakout.id) {
                        videoBreakoutId = nav.video_breakout.id;
                        if (!nav.can_register) {
                            return videoBreakoutService.attemptAutoStart(nav, videoBreakoutId);
                        }
                    }
                } else if (nav.video_breakout_id) {
                    // legacy pkg-lib-navigation-helpers
                    videoBreakoutId = nav.video_breakout_id;
                    return videoBreakoutService.attemptAutoStart(nav, videoBreakoutId);
                }
            })
            .finally(() => {
                $scope.$emit('fstg:page:loaded');
                progressBarService.finishTask();
            });


        const onRegistrationStatusEvent = async (sessionId, is_registered) => {
            if (sessionId !== nav.page_params.model_id) {
                return;
            }

            nav.is_registered = is_registered;
            if (nav.is_registered) {
                $scope.video_breakout_id = videoBreakoutId;
                nav.video_breakout_id = videoBreakoutId;
            } else {
                stopCall(nav);
            }
        };

        await unsubscribeFromSignals();
        signalListeners.push(
            await signalService.addSignalListener(
                `videoCalls/schedule/${nav.page_params.model_id}`, ({ video_breakout_id }) => {
                    videoBreakoutId = video_breakout_id;
                    if (!nav.can_register || nav.is_registered) {
                        $scope.video_breakout_id = videoBreakoutId;
                    }
                }
            ),
            await signalService.addSignalListener(
                `videoCalls/cancel/${nav.page_params.model_id}`, () => {
                    stopCall(nav);
                    videoBreakoutId = null;
                }
            ),
            // for legacy pkg-agenda
            navService.on('webview:refresh', () => navService.reload()),
            // for updated pkg-agenda
            navService.on('session_registration:status',
                ({ is_registered, sessionId }) => onRegistrationStatusEvent(sessionId, is_registered)
            )
        );
    };

    const stopCall = (nav) => {
        if (videoBreakoutId) {
            videoBreakoutService.stop();
        }
        $scope.video_breakout_id = null;
        nav.video_breakout_id = null;
    };

    frontstageLoaderService.loadFstg().then(() => {
        pageService = $injector.get('pageService');
        $scope.$watch('nav', loadPage);
    });
}

angular.module('maestro.navs').controller('FstgNavCtrl', FstgNavCtrl);
