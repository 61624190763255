/**
 *
 * /!\ * Important: /!\
 *
 * =======================================================================
 * Please keep
 *
 * pkg-lib-frontstage/src/components/avatar/avatar.component.js
 * spotme-webapp/src/components/common/avatar/avatar.component.js
 *
 * aligned.
 *
 * If you change this make sure you change the counterpart too,
 * and viceversa.
 * =======================================================================
 */
import { get } from 'lodash';

const NO_PHOTO = 'icon.nophoto/file.png';

class AvatarController {
    /* @ngInject */
    constructor(THEME, $scope, $filter, SETTINGS) {
        this.theme = THEME;
        this.$scope = $scope;
        this.$filter = $filter;
        this.zoomed = false;
        this.settings = SETTINGS;
    }

    $onInit() {
        if (this.watch) {
            this.$scope.$watch('$ctrl.object', () => this.update(), true);
            this.$scope.$watch('$ctrl.hidePicture', () => this.update(), true);
        } else {
            this.update();
        }
    }

    toggleZoom() {
        if (this.zoomable) {
            this.zoomed = !this.zoomed;
        }
    }

    onImageError() {
        this.zoomable = false;
        // Extra test to behave the same way as the mobile app
        const placeHolderImage = this.getPlaceholderImage();
        if (placeHolderImage && this.getImage() !== placeHolderImage) {
            this.image = placeHolderImage;
        } else if (get(this.settings, 'person.show_initials', true)) {
            this.showInitials = true;
        } else if (this.image !== NO_PHOTO) {
            this.image = NO_PHOTO;
        }
        this.$scope.$applyAsync();
    }

    update() {
        if (this.hidePicture) {
            this.showInitials = true;
            if (!get(this.settings, 'person.show_initials', true)) {
                this.initials = ' ';
            }
        }
        this.initials = this.getInitials();
        this.image = this.getImage();
        this.mode = this.getMode();

        this.$scope.$applyAsync();
    }

    getInitials() {
        if (this.initials || this.object.initials) {
            return this.initials || this.object.initials;
        }

        const prop = [ '_representation', 'full_name', 'real_name', 'title' ].find(p => this.object.hasOwnProperty(p));
        const name = this.object[prop];

        if (!name || !name.length) {
            console.warn('[AvatarComponent] Could not find a name in the given object', this.object);
            return;
        }

        return this.$filter('takeInitials')(name);
    }

    getImage() {
        const prop = [ '_representation_image', 'image_url', 'image', 'img', 'photo', 'photoUrl', 'avatar_url' ].find(p => this.object.hasOwnProperty(p));
        const image = this.object[prop];

        if (image && image.length && image !== NO_PHOTO) {
            this.showInitials = false;
            return image;
        }
        if (get(this.settings, 'person.show_initials', true)) {
            this.showInitials = true;
        } else {
            return NO_PHOTO;
        }
    }

    getPlaceholderImage() {
        const image = this.object.placeholder_img;

        if (image && image.length && image !== NO_PHOTO) {
            return image;
        }

        return false;
    }

    getMode() {
        return this.object.mode || this.mode;
    }
}

export const AvatarComponent = {
    bindings: {
        object: '<',
        initials: '@',
        mode: '@',
        zoomable: '<',
        watch: '<',
        shape: '<',
        hidePicture: '<',
        immediate: '<'
    },
    template: require('./avatar.pug'),
    controller: AvatarController
};
