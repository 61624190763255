import { filter, isEqual } from 'lodash';

const UPDATE_INTERVAL = 25000;

export const ScanNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./scan-nav.jade'),
    controller: class ScanNavComponent {
        /* @ngInject */
        constructor(
            NAV_TEMPLATES,
            ACTIVATED_PERSON,
            THEME,
            $timeout,
            $i18n,
            $sheet,
            progressBarService,
            handlerService,
            navService,
            metricsService
        ) {
            this.navTemplates = NAV_TEMPLATES;
            this.activatedPerson = ACTIVATED_PERSON;

            this.activatedPersonFullName = `${ACTIVATED_PERSON.fname} ${ACTIVATED_PERSON.lname}`.trim();

            this.theme = THEME;
            this.$timeout = $timeout;
            this.$i18n = $i18n;
            this.$sheet = $sheet;
            this.progressBarService = progressBarService;
            this.handlerService = handlerService;
            this.navService = navService;
            this.metricsService = metricsService;

            this.newCardIsBeingPresented = false;

            // live updates aka push but not really
            this.updateTimeout;
            this.firstUpdateDone = false;
            this.updateInProgress = false;
        }

        $onInit() {
            const config = this.config;
            console.log('[ScanNavComponent] init', config);

            this.showToolbarMenu = this.navService.showNavToolbarMenu(
                config.toolbar_menu
            );

            this.loading = true;
            this.config.ds.elements = [];

            this.updateNav();
        }

        $onDestroy() {
            this.updateTimeout && this.$timeout.cancel(this.updateTimeout);
        }

        presentNewCard(card) {
            if (this.newCardIsBeingPresented || this.$sheet.hasOpenSheets())
                return;
            this.newCardIsBeingPresented = true;

            this.$sheet({
                title: this.$i18n('nav_scan.bcx.new-card'),
                templateUri: this.navTemplates.person_row,
                pin: false,
                scrollable: false,
                prompt: true,
                actions: [
                    {
                        id: 'view',
                        label: 'View contact details',
                        action: sheet => sheet.accept()
                    },
                    {
                        id: 'close',
                        label: this.$i18n('general.dismiss'),
                        action: sheet => sheet.reject()
                    }
                ],

                row: card
            })
                .then(() => this.performRowActions(card))
                .finally(() => (this.newCardIsBeingPresented = false));
        }

        filterNewCards(cards) {
            return filter(cards, card => {
                const currentCard = this.config.ds.elements.find(
                    ({ _id }) => _id === card._id
                );

                return !isEqual(currentCard, card);
            });
        }

        updateNav() {
            if (this.updateInProgress) return;
            if (this.updateTimeout) {
                this.$timeout.cancel(this.updateTimeout);
            }

            if (!this.firstUpdateDone) this.progressBarService.startTask();

            this.updateInProgress = true;
            return this.navService
                .inflateNav(this.config, {
                    cache: false
                })
                .then(({ elements }) => {
                    if (!elements || !elements.length) {
                        return;
                    }

                    this.config.ds.elements = elements.reverse();

                    const newCards = this.filterNewCards(elements);

                    if (this.firstUpdateDone && newCards.length === 1) {
                        this.presentNewCard(newCards[0]);
                    }
                })
                .finally(() => {
                    if (!this.firstUpdateDone)
                        this.progressBarService.finishTask();

                    this.updateInProgress = false;
                    this.firstUpdateDone = true;
                    this.updateTimeout = this.$timeout(
                        () => this.updateNav(),
                        UPDATE_INTERVAL + Math.round(Math.random() * 1000)
                    );
                    this.adding = false;
                });
        }

        performRowActions(row) {
            this.navService.openDocument(
                { id: row._id },
                { _parent_nav_id: this.config.nuid }
            );
        }
    }
};
