import { get, isEmpty } from 'lodash';
import { getStaticAsset } from '../../../../utils/assets';
import i18nWithFallback from '../../../../utils/i18n-with-fallback';

export const EmptyStateComponent = {
    template: require('./empty-state.pug'),

    transclude: true,

    bindings: {
        config: '<',
        title: '<',
        subtitle: '<',
        imageUrl: '<'
    },

    controller: class EmptyStateComponent {
        /* @ngInject */
        constructor(THEME, $i18n) {
            this.assetSuffix = THEME.get('appearance') === 'dark' ? 'dark' : 'light';
            this.$i18n = $i18n;
            this.IMAGES_MAP = {
                'nav_bookmarks': getStaticAsset(`graphics/no-bookmarks-${this.assetSuffix}.png 2x`),
                'nav_business_cards': getStaticAsset(`graphics/no-contacts-${this.assetSuffix}.png 2x`),
                'nav_chats': getStaticAsset(`graphics/no-chats-${this.assetSuffix}.png 2x`),
                'nav_meetings': getStaticAsset(`graphics/no-meetings-${this.assetSuffix}.png 2x`),
                'nav_messages_no_send': getStaticAsset(`graphics/no-chats-${this.assetSuffix}.png 2x`),
                'nav_noteslist': getStaticAsset(`graphics/no-notes-${this.assetSuffix}.png 2x`),
                'nav_notifications': getStaticAsset(`graphics/no-notifications-${this.assetSuffix}.png 2x`),
                'nav_questionnaire': getStaticAsset(`graphics/no-forms-${this.assetSuffix}.png 2x`),
                'nav_leads': getStaticAsset(`graphics/no-leads-${this.assetSuffix}.png 2x`),
                'no-comments': getStaticAsset(`graphics/no-posts-${this.assetSuffix}.png 2x`),
                'no-matches': getStaticAsset(`graphics/no-matches-${this.assetSuffix}.png 2x`)
            };
        }

        $onInit() {
            console.info('[EmptyState] init', this.config);
            const id = get(this.config, '_id', get(this.config, 'id'));

            if (this.config.data) {
                this.title = get(this, 'config.data.content.title') || '';
                this.subtitle = get(this, 'config.data.content.subtitle') || '';
                this.imageUrl = get(this, 'config.data.content.image_url') || '';
            }

            this.imageUrl = this.IMAGES_MAP[id] || this.imageUrl || '';

            const noContent = this.config.no_content || this.config.nocontent;
            if (!isEmpty(noContent)) {
                if (noContent.title_text) {
                    this.title = this.$i18n(noContent.title_text);
                }
                if (noContent.subtitle_text) {
                    this.subtitle = this.$i18n(noContent.subtitle_text);
                }

                if (noContent.is_activity) {
                    this.imageUrl = getStaticAsset(`graphics/no-activity-${this.assetSuffix}.png 2x`);
                }
            }

            if (id === 'nav_business_cards') {
                this.title = this.$i18n('nav_scan.bcx.nocontents.title');
                this.subtitle = this.$i18n('nav_scan.bcx.exchanged.instructions');
            }

            if (isEmpty(this.title)) {
                this.title = i18nWithFallback(this.$i18n, 'general.no_content', 'No content');
            }

            if (this.imageUrl.endsWith('2x')) {
                this.srcset = this.imageUrl;
                this.imageUrl = this.imageUrl.split(' 2x')[0];
            }
        }

        onImageError($event) {
            console.error('[EmptyState] Could not load image', $event.originalEvent);
            this.imageUrl = null;
        }
    }
};
