// Utils
import { isFunction, isString } from 'lodash';

export const MODERATOR_ACTIONS = {
    FORCE_MUTE: 'forceMute',
    UNMUTE: 'askUnmute',
    KICKOUT: 'kickout',
    PIN: 'pinSpeaker'
};

/**
 * This service is used to handle moderators' requests
 */
class VideoCallModeratorService {

    /* @ngInject */
    constructor($eventBus) {
        this.$eventBus = $eventBus;
        this.listeners = [];
    }

    init(session) {
        this.session = session;

        this.unregisterListeners();
        this.registerToSignalListeners();
    }

    unregisterListeners() {
        for (const unsubscribe of this.listeners) {
            if (isFunction(unsubscribe)) {
                unsubscribe();
            }
        }
        this.listeners = [];
    }

    registerToSignalListeners() {
        for (let key in MODERATOR_ACTIONS) {
            const action = MODERATOR_ACTIONS[key];
            const handler = (event) => {
                if (this.session.connection && event.from.connectionId !== this.session.connection.id) {
                    console.log('[ModeratorService] got action ' + action, event.data);
                    let data;
                    if (isString(event.data)) {
                        data = JSON.parse(event.data);
                    }
                    this.$eventBus.emit(`moderatorRequest:${action}`, data);
                }
            };
            this.session.on(`signal:${action}`, handler);
            this.listeners.push(
                () => { this.session.off(`signal:${action}`, handler); },
            );
        }
    }

    /**
     * @param {String} action
     * @param {Object} options
     * @param {Object} options.connection
     * @param {Object} options.data
     */
    sendAction(action, options) {
        console.log('[ModeratorService] send action', action, options);

        const payload = {
            type: action,
        };
        if (options) {
            if (options.connection) {
                payload.to = options.connection;
            }
            if (options.data) {
                payload.data = JSON.stringify(options.data);
            }
        }
        this.session.signal(payload);
    }
}

angular
    .module('maestro.services')
    .service('videoCallModeratorService', VideoCallModeratorService);
