// Utils
import { defaults, get, isFunction } from 'lodash';

export class Subscriber {

    /**
     * @param {Object} services
     * @param {Object} services.triggerService
     * @param {Object} params
     * @param {Object} params.session
     * @param {String} params.name
     * @param {String} params.pid
     * @param {Object} params.stream
     */
    constructor({ triggerService }, { session, name, pid, stream }) {
        console.log('[Subscriber] constructor', stream);

        this.triggerService = triggerService;

        this.session = session;
        this.name = name;
        this.pid = pid;
        this.stream = stream;
        this.hasAudio = stream.hasAudio;
        this.hasVideo = stream.hasVideo;

        this.otSubscriberObject = null;
        this.isPinned = false;

        this.listeners = [];
    }

    async init(element, eventEmitter, options = {}) {
        this.element = element;
        // TODO: when refactoring allows it, assign the event emitter in the constructor
        this.eventEmitter = eventEmitter;
        this.options = options;
        await this.subscribeToStream();
        // subscribeToAudio is only set to false while waiting for publisher to be published. for all re-subscriptions
        // to broken subscribers, we need to set this to true otherwise we might recreate soundless subscribers
        this.options.subscribeToAudio = true;
    }

    async subscribeToStream() {
        if (this.isSubscribing) {
            return;
        }
        this.isSubscribing = true;
        await this.unsubscribe();
        return new Promise((resolve, reject) => {
            const options = defaults(this.options, {
                insertMode: 'append',
                fitMode: 'contain',
                showControls: false,
                width: '100%',
                height: '100%',
                subscribeToAudio: true
            });

            const otSubscriberObject = this.session.subscribe(this.stream, this.element, options, err => {
                this.isSubscribing = false;
                if (err) {
                    return reject(err);
                } else {
                    // /!\ do not assign the otSubscriberObject before the subscribe callback /!\
                    // /!\ some methods calls can break the subscriber /!\
                    this.otSubscriberObject = otSubscriberObject;
                    this.videoElement = this.element.getElementsByTagName('video')[0];
                    this.mediaStream = this.videoElement.srcObject;

                    this.addListeners();
                    this.adaptResolution();

                    this.eventEmitter.emit('subscriber:subscribed');

                    return resolve();
                }
            });
        });
    }

    async unsubscribe() {
        console.log('[Subscriber] unsubscribe');

        for (const unsubscribe of this.listeners) {
            unsubscribe();
        }

        this.listeners = [];

        clearTimeout(this.resubscribeTimeout);
        this.resubscribeTimeout = null;

        if (this.otSubscriberObject) {
            this.otSubscriberObject.off();
            await this.session.unsubscribe(this.otSubscriberObject);
            this.otSubscriberObject = null;
            this.element.innerHTML = '';
        }
    }

    adaptResolution(layoutData = this.layoutData) {
        if (!layoutData || !isFunction(get(this, 'otSubscriberObject.setPreferredResolution'))) {
            return;
        }

        this.layoutData = layoutData;

        const widthPercentage = get(layoutData, 'fitValues[0]', 100);
        const heightPercentage = get(layoutData, 'fitValues[1]', 100);
        let percentage = Math.min(widthPercentage, heightPercentage);
        percentage = Math.max(percentage, 25);
        const reductionFactor = Math.floor(100 / percentage);
        const width = 1280 / reductionFactor;
        const height = 720 / reductionFactor;
        this.otSubscriberObject.setPreferredResolution({ width, height });
    }

    async destroy() {
        await this.unsubscribe();
    }

    addListeners() {

        this.listeners = [
            this.triggerService.addTrigger('destroySubscriber', async () => {
                await this.session.unsubscribe(this.otSubscriberObject);
            }),
            this.triggerService.addTrigger('stopSubscriberVideoMediaStreamTrack', () => {
                this.mediaStream.getVideoTracks()[0].stop();
            }),
            this.triggerService.addTrigger('stopSubscriberAudioMediaStreamTrack', () => {
                // for some reason this doesn't work on Chrome, and audio continues...
                this.mediaStream.getAudioTracks()[0].stop();
            }),
            this.triggerService.addTrigger('stopSubscriberMediaTracks', () => {
                for (const track of this.mediaStream.getTracks()) {
                    track.stop();
                }
            }),
            // listeners of 'inactive' on mediaStream, 'ended', and 'mute' on mediaStreamTracks don't seem very reliable
            // especially across browsers, their use was therefore put aside for now
        ];

        this.otSubscriberObject.on('disconnected', () => {
            console.log('[Subscriber] disconnected');
            // Display a notification about temporary issue ?
        });

        this.otSubscriberObject.on('connected', async () => {
            console.log('[Subscriber] connected');
            // Not called after session.subscribe, only called after recovery after a 'disconnected' event
            // OT seems to have some rare issues on reconnection, it is really hard to reproduce.
            // After a networks disruption, it is not much longer for the user to have a subscriber
            // resubscribe instead of the auto recover from vonage.
            this.scheduleResubscribe();
        });

        this.otSubscriberObject.on('destroyed', async (event) => {
            console.log('[Subscriber] destroyed', this.otSubscriberObject, event);
            // On some occasions, subscribers die, and start showing a message:
            // "The stream was unable to connect due to a network error. Ensure you have a stable connection and try again."
            // it seems to only be recoverable by resubscribing
            // when other pax leave, we might also get here, but in this case the subscribe to stream will fail,
            // video-call.component will remove this subscriber a bit later.
            this.scheduleResubscribe();
        });
    }

    scheduleResubscribe() {
        if (this.resubscribeTimeout) {
            return;
        }
        this.resubscribeTimeout = setTimeout(async () => {
            console.log('[Subscriber] resubscribing');
            this.resubscribeTimeout = null;
            await this.subscribeToStream(true);
        }, 1000);
    }

    setPinned(val) {
        this.isPinned = val;
    }

    setTalkingPosition(val) {
        this.talkingPosition = val;
    }
}
