import { isArray, findIndex, extend, get } from 'lodash';

import { CommonFeedBlockNotificationHandlers } from './common-feed-block-notifications-handler';
import mergeWithoutArrays from '../../../utils/merge-without-arrays';

export class CommonFeedBlock extends CommonFeedBlockNotificationHandlers {
    // following are expected to be injected
    // $timeout, databaseService, navService, EventEmitter
    constructor(NAV_TEMPLATE_API_PREFIX, $filter, $sheet, $i18n, THEME) {
        super(THEME);

        this.assetUrl = $filter('assetUrl');
        this.NAV_TEMPLATE_API_PREFIX = NAV_TEMPLATE_API_PREFIX;
        this.$sheet = $sheet;
        this.$i18n = $i18n;
    }

    $onInit() {
        super.$onInit();
        this.blockId = this.config.id;
        console.log(`[${this.constructor.name}] init`, this.config);

        const content = get(this, 'config.data.content');

        if (content.video_url) {
            this.media = {
                sources: [ {
                    type: 'video/mp4',
                    source: this.assetUrl(content.video_url)
                } ]
            };

            if (content.image_url) {
                this.media.thumbnail = this.assetUrl(content.image_url);
            }

            this.config.data.content.image_url = null;
        }
    }

    $onChanges(changes) {
        if (!changes.config) return;

        this.config = extend({}, this.config);
        this.content = this.config.data.content;
        this.actions = this.config.data.actions;
        this.inlineBlocks = this.content.inline_blocks || [];

        // console.log(`[${this.constructor.name}] config changed`, this.config);
    }

    // used by inline-block-paginator
    prependBlocks({ blocks }) {
        if (!isArray(blocks)) return;

        console.log(
            `[${this.constructor.name}] prepending blocks`,
            this.config.id,
            blocks
        );

        this.onUpdate(
            this.EventEmitter({
                id: this.config.id,
                updates: {
                    content: {
                        inline_blocks: blocks.concat(this.inlineBlocks)
                    }
                }
            })
        );
    }

    updateInlineBlock({ id, updates }) {
        if (!id || !updates) return;

        const blockIndex = findIndex(
            this.inlineBlocks,
            ({ id: _id }) => id === _id
        );
        if (blockIndex === -1) return;

        console.log(`[${this.constructor.name}] inline update`, id, updates);

        let block = extend({}, this.inlineBlocks[blockIndex]);
        // arrays will be replaced, not merged
        block.data = mergeWithoutArrays(block.data, updates);

        const newInlineBlocks = this.inlineBlocks
            .slice(0, blockIndex)
            .concat([ block ])
            .concat(this.inlineBlocks.slice(blockIndex + 1));

        this.onUpdate(
            this.EventEmitter({
                id: this.config.id,
                updates: {
                    content: {
                        inline_blocks: newInlineBlocks
                    }
                }
            })
        );
    }

    zoomMedia(image) {
        this.$sheet({
            templateUri: `${this.NAV_TEMPLATE_API_PREFIX}/zoom.html`,
            actions: [
                {
                    id: 'close',
                    label: this.$i18n('general.close'),
                    action: sheet => sheet.reject()
                }
            ],
            scrollable: true,
            image
        });
    }
}
