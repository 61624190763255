import { get } from 'lodash';
export const FeedNewPostBlockComponent = {
    template: require('./feed-new-post-block.jade'),
    bindings: {
        parentNavId: '<',
        config: '<'
    },
    controller: class FeedNewPostBlockComponent {
        /* @ngInject */ constructor(
            ACTIVATED_PERSON,
            THEME,
            databaseService,
            navFormService,
            $eventBus
        ) {
            this.person = ACTIVATED_PERSON;
            this.THEME = THEME;
            this.databaseService = databaseService;
            this.navFormService = navFormService;
            this.$eventBus = $eventBus;

            this.input_key = 'media';
            this.thumbnail_key = 'thumbnail';

            this.person.photo = `${ACTIVATED_PERSON._id}-photo/photo?_=1`;
            this.person.full_name = ACTIVATED_PERSON._representation || [ ACTIVATED_PERSON.fname, ACTIVATED_PERSON.lname ].join(' ');
        }

        $onInit() {
            this.disableUpload = get(this.config, 'data.action.params.disable_photo_upload');
            this.uploadBlockConfig = this.disableUpload ? null : {
                input_key: this.input_key,
                thumbnail_key: this.thumbnail_key
            };

            this.$eventBus.on('upload-block:file-selected', () => {
                this.onFileSelected();
            });
        }

        onFileSelected() {
            const uploadedFile = this.navFormService.get(this.parentNavId);
            if (!uploadedFile || !uploadedFile[this.input_key]) return;

            this.navFormService.setPreSelectedFile(this.input_key, uploadedFile[this.input_key]);
            this.openModal();
        }

        openModal() {
            const targetScript = get(this.config, 'data.action.target');
            const params = get(this.config, 'data.action.params');
            params.feedFormDataKey = this.parentNavId;
            this.databaseService
                .runAppScript(targetScript, params)
                .finally(() => {
                    this.navFormService.set(this.parentNavId, this.input_key, null);
                });
        }
    }
};
