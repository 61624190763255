import { DeviceManager } from './DeviceManager';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

const VIDEO_FRAME_WIDTH = 1280;
const VIDEO_FRAME_HEIGHT = 720;
const VOLUME_METER_WIDTH = 48;
const VOLUME_METER_HEIGHT = 48;
/**
 * This component is used to prompt the user which
 * source devices are going to be used for the video call,
 * namely microphone and camera.
 *
 * We assume that at this stage of the flow OpenTok runtime
 * is loaded.
 */
export const OnboardingComponent = {
    bindings: {
        actions: '=',
        conversation: '='
    },
    template: require('./onboarding.pug'),
    controller: class OnboardingComponent extends DeviceManager {
        /* @ngInject */
        constructor($scope, ACTIVATED_PERSON, mediaDevicesService, $element, $eventBus, $i18n) {
            super(mediaDevicesService, $eventBus, $scope);
            this.ACTIVATED_PERSON = ACTIVATED_PERSON;
            this.$i18n = $i18n;
            this.$element = $element;

            this.audioOn = true;
            this.videoOn = true;

            this.hasConnectedOneDevice = false;

            this.labels = {
                no_cam_found: i18nWithFallback(this.$i18n, 'video_calls.onboard.no_cam_found', 'No camera found'),
                no_mic_found: i18nWithFallback(this.$i18n, 'video_calls.onboard.no_mic_found', 'No microphone found'),
                at_least_one_device: i18nWithFallback(this.$i18n, 'video_calls.onboard.at_least_one_device', 'You need at least a microphone, or a camera, to enter the call.'),
                browser_device_request: i18nWithFallback(this.$i18n, 'video_calls.onboard.waiting_permission', 'When your browser requests access to your webcam and microphone, please click “allow”. You may need to click on the little lock, or camera, on the URL left hand side, and allow access again.')
            };
        }

        async $postLink() {
            super.init();
            this.userPermissions = await this.mediaDevicesService.requestPermissions();
            this.userPermissionAsked = true;
            this.$scope.$emit('hideSpinner');
            this.$scope.$applyAsync();
        }

        $onDestroy() {
            super.$onDestroy();
            this.releaseAudioContextOrVideoEl('all');
            this.mediaDevicesService.stopAllStreams();
        }

        releaseAudioContextOrVideoEl(kind) {
            if (kind === 'audio' || kind === 'all') {
                if (this.audioContext && this.audioContext.state !== 'closed') {
                    this.audioContext.close();
                }
            }

            if (kind === 'video' || kind === 'all') {
                this.mediaDevicesService.stopVideoElement(this.videoElement);
            }
        }

        async deviceUpdatedHandler({ error }) {
            if (error) this.handleError(error);
            this.mediaDevicesService.selectDefaultDevices();
            await this.initVideoInput();
            await this.initAudioInput();
            this.$scope.$applyAsync();
        }

        joinCall() {
            this.$scope.$emit('joinCall', {
                videoOn: this.videoOn,
                audioOn: this.audioOn
            });
        }

        async initVideoInput() {
            console.info('[Onboarding] Video source changed');

            if (!this.mediaDevicesService.videoDeviceSelected) {
                await this.setVideoOn(false);
                return;
            }

            // To prevent memory leaks we force-close the camera usage on previous streams
            this.mediaDevicesService.stopAllStreams('video');

            const params = {
                video: {
                    deviceId: { exact: this.mediaDevicesService.videoDeviceSelected.deviceId },
                    facingMode: 'user',
                    width: { ideal: VIDEO_FRAME_WIDTH },
                    height: { ideal: VIDEO_FRAME_HEIGHT },
                    aspectRatio: { exact: 16 / 9 }
                },
                audio: false
            };

            try {
                const stream = await this.mediaDevicesService.getUserMedia(params);
                this.videoElement = this.$element.find('video')[0];
                if (!this.videoElement) {
                    return;
                }
                this.videoElement.srcObject = stream;
                this.videoElement.onloadedmetadata = async () => {
                    const { videoWidth, videoHeight } = this.videoElement;
                    console.debug('[Onboarding] Camera resolution', videoWidth, videoHeight);
                    try {
                        await this.videoElement.play();
                        this.hasConnectedOneDevice = true;
                        this.$scope.$applyAsync();
                    } catch (error) {
                        console.error('[onboardingComponent] Failed to play', error);
                    }
                };
            } catch (error) {
                await this.setVideoOn(false);
                this.mediaDevicesService.selectDevice('video', null);
            }
        }

        async initAudioInput() {
            console.info('[Onboarding] Audio source changed');

            if (!this.mediaDevicesService.audioDeviceSelected) {
                await this.setAudioOn(false);
                return;
            }

            // To prevent memory leaks we force-close the mic usage on previous streams
            this.releaseAudioContextOrVideoEl('audio');

            const params = {
                audio: { deviceId: { exact: this.mediaDevicesService.audioDeviceSelected.deviceId } },
            };
            try {
                const stream = await this.mediaDevicesService.getUserMedia(params);
                /** @type {HTMLCanvasElement} */
                const volume = angular.element('.volume')[0];
                if (!volume) return;

                /* eslint-disable-next-line no-undef */
                this.audioContext = window.AudioContext ? new AudioContext() : new webkitAudioContext();
                const analyser = this.audioContext.createAnalyser();
                const microphone = this.audioContext.createMediaStreamSource(stream);
                const javascriptNode = this.audioContext.createScriptProcessor(2048, 1, 1);

                analyser.smoothingTimeConstant = 0.7;
                analyser.fftSize = 1024;

                microphone.connect(analyser);
                analyser.connect(javascriptNode);
                javascriptNode.connect(this.audioContext.destination);

                volume.width = VOLUME_METER_WIDTH;
                volume.height = VOLUME_METER_HEIGHT;

                const canvasContext = volume.getContext('2d');

                javascriptNode.onaudioprocess = function() {
                    const array = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(array);
                    let values = 0;

                    const length = array.length;
                    for (let i = 0; i < length; i++) {
                        values += (array[i]);
                    }

                    const average = values / length;

                    canvasContext.clearRect(0, 0, VOLUME_METER_WIDTH, VOLUME_METER_HEIGHT);

                    canvasContext.fillStyle = 'rgba(0, 0, 0, 0.5)';
                    canvasContext.fillRect(0, 0, VOLUME_METER_WIDTH, VOLUME_METER_HEIGHT);

                    canvasContext.fillStyle = '#2DD269';
                    canvasContext.fillRect(0, VOLUME_METER_HEIGHT - ((average / 100) * VOLUME_METER_HEIGHT), VOLUME_METER_WIDTH, VOLUME_METER_HEIGHT * 2);
                };
                this.hasConnectedOneDevice = true;
                this.$scope.$applyAsync();
            }
            catch (error) {
                await this.setAudioOn(false);
                this.mediaDevicesService.selectDevice('audio', null);
            }
        }

        async setVideoOn(value) {
            this.videoOn = value;
            if (!this.videoOn) {
                this.releaseAudioContextOrVideoEl('video');
            } else {
                await this.initVideoInput();
            }

            if (!this.initials && this.ACTIVATED_PERSON) {
                this.initials = this.ACTIVATED_PERSON.fname[0] + this.ACTIVATED_PERSON.lname[0];
            }
            this.$scope.$applyAsync();
        }

        async setAudioOn(value) {
            this.audioOn = value;
            if (!this.audioOn) {
                this.releaseAudioContextOrVideoEl('audio');
            } else {
                await this.initAudioInput();
            }
            this.$scope.$applyAsync();
        }

        async changeAudioSource(device) {
            super.changeAudioSource(device);
            await this.initAudioInput();
            this.$scope.$applyAsync();
        }

        async changeVideoSource(device) {
            super.changeVideoSource(device);
            await this.initVideoInput();
            this.$scope.$applyAsync();
        }

        hasAuthorizedOneDevice() {
            if (!this.userPermissions) {
                return false;
            }
            return this.userPermissions.audio || this.userPermissions.video;
        }

        async onTryAgainClicked() {
            if (!this.hasAuthorizedOneDevice()) {
                return location.reload();
            }
            this.userPermissions = await this.mediaDevicesService.requestPermissions();
            this.$eventBus.emit('restartCall');
            this.$scope.$applyAsync();
        }

        handleError(error, name) {
            this.releaseAudioContextOrVideoEl('all');
            if (error) console.error('[Onboarding] Error: ', error);
            if (name) this.errorMessage = `video_calls.errors.${name}`;
            this.$scope.$applyAsync();
        }
    }
};
