export default class NavExternals {
    /* @ngInject */
    constructor($window, $filter, $i18n, $q, $sheet) {
        this.$window = $window;
        this.$filter = $filter;
        this.$i18n = $i18n;
        this.$q = $q;
        this.$sheet = $sheet;
    }

    showLegalRequirements(action) {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (isIE11) {
            action.features = 'scrollbars=1,resizable=1';
        }

        return this.openExternalUri(action);
    }

    openExternalUri({ uri, features, url }) {
        let fullUri = url ? url : this.$filter('assetUrl')(uri);

        // Adding a link element to the DOM and clicking it can bypass popup blockers
        const linkElement = angular.element('<a id="_link"></a>');
        angular.element('body').append(linkElement);
        if (features) {
            fullUri += (fullUri.indexOf('?') === -1 ? '?' : '&') + features;
        }
        linkElement.attr('href', fullUri);
        linkElement.attr('target', '_blank');
        linkElement.attr('rel', 'noopener noreferrer');
        // Safari on iOS needs that timeout.
        setTimeout(() => {
            linkElement[0].click();
            linkElement.remove();
        }
        , 0);

        return this.$q.resolve();
    }

    reload() {
        this.$window.location.reload(true);
    }
}
