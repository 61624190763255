
export const PipControlsComponent = {
    template: require('./pip-controls.jade'),
    bindings: {
    },
    controller: class PipControls {
        /* @ngInject */ constructor(
            $eventBus,
            $scope
        ) {
            this.$eventBus = $eventBus;
            this.$scope = $scope;
        }

        reattach() {
            this.$eventBus.emit('video-pip:reopen-nav');
        }

        close() {
            this.$scope.$emit('pip:close');
        }
    }
};
