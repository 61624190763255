// Utils
import { defaults, get, isString } from 'lodash';

export default class UiService {
    /* @ngInject */
    constructor($eventBus, $i18n, ACTIVATED_PERSON) {
        this.$eventBus = $eventBus;
        this.$i18n = $i18n;
        this.pid = ACTIVATED_PERSON._id;

        $eventBus.spawn(this);
    }

    inAppNotification(config) {
        console.info('[UI Service] In App Notification:', config);

        // If the person that generated the notification is the current
        // user, we don't show the notification
        if (get(config, 'action.params.sender_pid') === this.pid) return;

        this.$eventBus.emit('notification:in-app', config);
    }

    buildNotificationMessage(message) {
        message = defaults(message, {
            message: { title: '', body: '' },
            icon: 'icon.spotman/file.png',
            // TODO: support v3 theme
            backgroundColor: '#FB4F15',
            contentColor: '#FFFFFF',
            iconColor: '#FFFFFF',
            duration: 10,
            action: {
                path: '',
                params: {}
            }
        });

        const titleTr = message.title && this.$i18n(message.title);
        let subtitleTr = message.body && this.$i18n(message.body);
        if (subtitleTr && message.senderName) {
            subtitleTr = subtitleTr.replace('{fname}', message.senderName);
        }

        return {
            title: titleTr || message.message.title,
            subtitle: subtitleTr || message.message.body,
            icon_url: buildImageUrl(message.icon),
            background_color: message.backgroundColor,
            icon_color: message.iconColor,
            content_color: message.contentColor,
            duration: message.duration,
            action: message.action,
            notificationId: message.notificationId,
            ctx: message.ctx
        };
    }
}

/**
 * Parses image url
 *
 * @param {string} asset
 * @returns {string}
 */
function buildImageUrl(asset) {
    if (!isString(asset)) {
        return '';
    }

    if (asset.includes('://')) {
        return asset;
    }

    return asset.replace(/\{{2,3}deviceorientation\}{2,3}/g, 'landscape');
}

angular.module('maestro.services').service('uiService', UiService);
