// Utils
import { difference, get, isArray, isString, isObject } from 'lodash';

// Constants
const EXT_PREFIX = '_ext.';
const PRESENCE_STRING_VALUE = '*has_field*';

/**
 * @typedef {object} TargetedObject
 *
 * @property {string|object[]} targets a list of fields the current user must match against
 * @property {string|object[]} exceptions a list of fields the current user must not match against
 */

export default class TargetingService {
    /* @ngInject */
    constructor(ACTIVATED_PERSON, PERSON_DYNAMIC_EXTENSIONS, EID, PID, $http) {
        this.user = ACTIVATED_PERSON;
        this.dynExt = PERSON_DYNAMIC_EXTENSIONS;
        this.$http = $http;
        this.eventId = EID;
        this.pid = PID;
    }

    /**
     * Use this method to check if a user matches against the given object
     *
     * @param {TargetedObject} item the item to tests
     *
     * @returns {boolean} true if the user can see the object false otherwise
     */
    canUserSeeItem(item) {
        if (!isObject(item)) {
            return false;
        }

        if (this.shouldAskTheServer(item)) {
            console.warn('[TargetingService] Request should be offloaded to the server');
            return false;
        }

        const { targets, exceptions } = this._extractRules(item);

        if (targets.length === 0 && exceptions.length === 0) {
            return true;
        }

        return this._testTargetsAndExceptions(targets, exceptions);
    }

    /**
     * Use this method to check if a user matches against the given object, if the targeting cannot be resolved locally,
     * the server will be asked to resolve it.
     *
     * @param {TargetedObject} item the item to tests
     *
     * @returns {Promise<boolean>} true if the user can see the object false otherwise
     */
    async isUserTargeted(item) {
        if (!isObject(item)) {
            return false;
        }

        if (this.shouldAskTheServer(item)) {
            console.warn('[TargetingService] Request should be offloaded to the server');
            return await this._askServer(item);
        }

        const { targets, exceptions } = this._extractRules(item);

        if (targets.length === 0 && exceptions.length === 0) {
            return true;
        }

        return this._testTargetsAndExceptions(targets, exceptions);
    }

    /**
     * Checks if the targeting rules contain a dynamic extensions,
     * in which case we should delegate to the server the targeting
     * computation.
     *
     * @param {TargetedObject} item the item to tests
     *
     * @returns {boolean} true if the request should be delegated, false otherwise
     */
    shouldAskTheServer(item) {
        if (!isObject(item)) {
            return false;
        }

        const { targets, exceptions } = this._extractRules(item);
        const rules = [ ...targets, ...exceptions ];

        for (const rule of rules) {
            for (const field of Object.keys(rule)) {
                if (this.dynExt.includes(field)) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Asks server if the user matches against the given targets and exceptions
     * @param item
     * @return {Promise<boolean>}
     * @private
     */
    async _askServer(item) {
        const { targets, exceptions } = this._extractRules(item);

        const url = `/api/v1/eid/${this.eventId}/targeting/is-targeted`;

        const result = await this.$http.post(url, { targets, exceptions });

        return get(result, 'data.targeted', false);
    }

    /**
     * Extracts and normalizes the targeting rules from the given object
     *
     * @param {TargetedObject} item the item from which rules are extracted
     *
     * @returns {object}
     * @private
     */
    _extractRules(item) {
        let { targets, exceptions } = item;
        targets = targets || [];
        exceptions = exceptions || [];

        if (isString(targets)) {
            try {
                targets = JSON.parse(targets);
            } catch (error) {
                console.warn('[TargetingService] Could not parse the target', error);
            }
        }

        if (isString(exceptions)) {
            try {
                exceptions = JSON.parse(exceptions);
            } catch (error) {
                console.warn('[TargetingService] Could not parse the exceptions', error);
            }
        }

        return { targets, exceptions };
    }

    /**
     * Runs `test` against every item in `targets` and `exceptions`,
     * deciding if the item is targeted or not
     *
     * @param {object[]} targets a list of matching targets
     * @param {object[]} exceptions a list of matching exceptions
     *
     * @returns {boolean} true if the test passes false otherwise
     * @private
     */
    _testTargetsAndExceptions(targets, exceptions) {
        // No targets but exceptions means address everyone except the exceptions
        let isTargeted = targets.length ? false : true;

        for (let i = 0; i < targets.length; i++) {
            if (this._checkMatch(targets[i])) {
                isTargeted = true;
                break;
            }
        }

        if (!isTargeted) { return false; }
        if (!exceptions) { return true; }

        for (let i = 0; i < exceptions.length; i++) {
            if (this._checkMatch(exceptions[i])) {
                isTargeted = false;
                break;
            }
        }

        return isTargeted;
    }


    /**
     * Performs a cross reference between the current user and the
     * given object to check.
     *
     * @returns {boolean} true if the user matches against the given object
     * @private
     */
    _checkMatch(atom) {
        try {
            const user = this.user;
            const keys = Object.keys(atom);

            for (const key of keys) {

                let fieldValue = user[key];

                if ((key.slice(0, EXT_PREFIX.length) === EXT_PREFIX) && user._ext) {
                    fieldValue = user._ext[key.slice(EXT_PREFIX.length)];
                }

                const testField = atom[key];

                if (!this._checkFieldMatch(fieldValue, testField, key)) {
                    return false;
                }
            }

            return true;

        } catch (error) {
            console.error('[TargetingService] Could not verify target for user', error);
            return false;
        }
    }

    /**
     * Checks if the person's field exists or matches the requested value
     *
     * @param {string} fieldValue the field's value for the current person
     * @param {string} searchedValue the field to search for
     * @param {string} fieldName the name of the field to check
     *
     * @returns {boolean} true if the field exists and matches with the search criteria
     * @private
     */
    _checkFieldMatch(fieldValue, searchedValue, fieldName) {
        if (searchedValue === PRESENCE_STRING_VALUE) {
            return this.user.hasOwnProperty(fieldName);
        }

        let fieldsMatch = fieldValue === searchedValue;

        if (!fieldsMatch && isArray(fieldValue)) {
            if (isArray(searchedValue)) {
                fieldsMatch = difference(fieldValue, searchedValue).length === 0;
            } else {
                fieldsMatch = fieldValue.includes(searchedValue);
            }
        }

        return fieldsMatch;
    }

}
