import 'frontloader-common/vendor/bootstrap/tooltip';
import 'frontloader-common/vendor/bootstrap/popover';
import { defaults } from 'lodash';

const DEFAULT_POPOVER_OPTIONS = {
    animated: true,
    html: true,
    placement: 'top auto',
    trigger: 'manual'
};

function PopoverService() {
    let currentOpenPopoverScope;
    let service;

    window.addEventListener('orientationchange', () => {
        if (service) {
            service.clearAll();
        }
    });

    this.$get = /* @ngInject */ function($q, $rootScope, $templateRequest, $compile, $timeout) {
        // dismiss open popover when clicking outside
        const closeOutsideHandler = e => {
            if (
                currentOpenPopoverScope &&
                $(e.target).parents('.popover.in').length === 0
            ) {
                currentOpenPopoverScope.reject('clickOutside');
            }
        };

        service = config => {
            // only one popover can be visible at a time
            service.dismissOpenPopover();

            const $target = angular.element(config.target);
            const deferred = $q.defer();
            const localScope = $rootScope.$new(/* isolated */ true);
            angular.extend(localScope, config);

            // deffered is wrapped so that angular won't digest it too much
            localScope.$$deferred = () => deferred;
            localScope.accept = value => deferred.resolve(value || localScope);
            localScope.reject = reason => deferred.reject(reason || localScope);

            const fetchTemplate = () =>
                config.template
                    ? $q.resolve(config.template)
                    : $templateRequest(config.templateUri);

            fetchTemplate().then(
                template => {
                    const options = defaults(
                        config.options || {},
                        DEFAULT_POPOVER_OPTIONS
                    );
                    options.content = $compile(template)(localScope);

                    $target.popover(options);
                    if (options.trigger === 'manual') {
                        $target.popover('show');
                        // the above can be synchronous, then the click handler for
                        // closing is called by the opening click
                        $timeout(() =>
                            $(document.body).on('click', closeOutsideHandler)
                        );
                    }

                    currentOpenPopoverScope = localScope;
                },
                // close popover if template cannot be loaded
                () => deferred.reject(localScope)
            );

            return deferred.promise.finally(() => {
                localScope.$destroy();
                $target.popover('destroy');
                currentOpenPopoverScope = null;
                $(document.body).off('click', closeOutsideHandler);
            });
        };

        service.hasOpenPopover = () => !!currentOpenPopoverScope;
        service.dismissOpenPopover = () => {
            if (currentOpenPopoverScope) {
                currentOpenPopoverScope.reject();
            }

            currentOpenPopoverScope = null;
        };

        // In some case popover target reference are destroyed before the
        // service can properly handle them. Thus, if we want to close all
        // popovers, we are forced to manually remove them from the DOM.
        service.clearAll = () => {
            service.dismissOpenPopover();
            angular.element('.popover.in').remove();
        };

        return service;
    };
}

angular.module('maestro.services').provider('$popover', PopoverService);
