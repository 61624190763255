import { groupBy, isString } from 'lodash';
import memoizeAsync from '../../../utils/memoizeAsync';

export const UniversalSearchRowComponent = {
    template: require('./universal-search-row.jade'),
    bindings: {
        config: '<',
        onFocus: '&',
        onBlur: '&',
        onActions: '&'
    },
    controller: class UniversalSearchRowComponent {
        /* @ngInject */
        constructor(NAV_TEMPLATES, $timeout, $q, databaseService) {
            this.navTemplates = NAV_TEMPLATES;
            this.$timeout = $timeout;
            this.$q = $q;
            this.databaseService = databaseService;

            this.reset();
        }

        $onInit() {
            console.log('[UniversalSearchRow] init', this.config);
            const ds = (this.config.dataSources || {}).searchResults;

            this.visible =
                ds && ds.type === 'javascript' && isString(ds.source.path);
            if (!this.visible)
                return console.warn(
                    '[UniversalSearchRow] malformed DS configuration',
                    ds
                );

            const render = this.config.render;
            this.noResultsLabel = render.no_results;
            this.placeholderLabel = render.placeholder;
            this.renderGroupLabels = render.groups;

            this.execSearch = memoizeAsync(
                term =>
                    this.databaseService
                        .runAppScript(ds.source.path, { term })
                        .then(({ data: { response } }) => response),
                this.$q
            );
        }

        performSearch() {
            const term = (this.searchTerm || '').trim();
            if (!term || term.length < 3 || !this.execSearch) {
                if (!term || !term.length) {
                    this.searchResults = [];
                }
                this.activity = false;
                return;
            }

            this.activity = true;
            this.execSearch(term)
                .then(response => this.applyResults(response))
                .finally(() => (this.activity = false));
        }

        applyResults(results) {
            this.searchResults = groupBy(results, 'key');
            this.noResults = !results.length;
            this.activity = false;
        }

        wakeup($event) {
            return (
                $event.which > 47 &&
                !$event.altKey &&
                !$event.ctrlKey &&
                !$event.shiftKey &&
                (this.activity = true)
            );
        }

        focus() {
            this.searchFocused = true;
            this.onFocus();
        }

        reset() {
            // reinitialize values
            this.searchFocused = false;
            this.searchTerm = '';
            this.searchResults = null;
            this.noResults = false;
        }

        close() {
            this.reset();
            this.onBlur();
        }

        performActions(actions) {
            this.onActions({ actions });
        }

        classNamesForBlock({ block_type: type, layout = {} }) {
            return [
                type,
                `block-width-${layout.width}`,
                `block-height-${layout.height}`,
                'animated'
            ].join(' ');
        }

        templateUrlForBlock({ block_type }) {
            return this.navTemplates[block_type];
        }
    }
};
