import { get, isFunction, omit } from 'lodash';

const PUBLISHER_RESOLUTION = { width: 1280, height: 720 };
import { addEventListener } from '../../../utils/dom';

/**
 * This component is used to render the publisher video
 *
 */
export const PublisherComponent = {
    bindings: {
        publisher: '=',
        audioOn: '=',
        conversationId: '<',
        videoOn: '=',
        session: '=',
        name: '<',
        pid: '<',
        screenShareActive: '<',
        showModeratorControls: '=',
        isPinned: '<',
        testOptions: '='
    },
    template: require('./publisher.pug'),
    controller: class PublisherComponent {
        /* @ngInject */
        constructor($scope, $element, mediaDevicesService, $eventBus, triggerService, crashReportingService, $timeout) {
            this.$scope = $scope;
            this.$eventBus = $eventBus;
            this.$element = $element;
            this.mediaDevicesService = mediaDevicesService;
            this.triggerService = triggerService;
            this.crashReportingService = crashReportingService;
            this.$timeout = $timeout;
            this.listeners = [];

            this.addErrorTriggers();

            this.addEventListeners();

            this.addMediaStreamTrackErrorHandlers();
        }

        $postLink() {
            this.publisherVideoEl = this.$element.find('.publisher-video')[0];
            this.initPublisher();
            this.listeners.push(
                addEventListener(this.publisherVideoEl, 'contextmenu', e => e.preventDefault())
            );
        }

        addEventListeners() {
            const updateVideoSourceListener = this.$scope.$on('publisher:updateVideoSource', this.updateVideoSource.bind(this));
            const updateAudioSourceListener = this.$scope.$on('publisher:updateAudioSource', this.updateAudioSource.bind(this));
            this.listeners.push(updateVideoSourceListener, updateAudioSourceListener);
        }

        $onDestroy() {
            for (const unsub of this.listeners) {
                if (isFunction(unsub)) {
                    unsub();
                }
            }
            this.publisher.off();
            this.publisher.destroy();
            this.publisher = null;
        }

        initPublisher() {
            this.options = {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                fitMode: 'contain',
                showControls: false,
                publishAudio: this.audioOn,
                publishVideo: this.videoOn,
                name: this.name,
                mirror: true,
                videoSource: this.mediaDevicesService.permissions.video ? this.mediaDevicesService.videoDeviceSelected : null,
                audioSource: this.mediaDevicesService.permissions.audio ? this.mediaDevicesService.audioDeviceSelected : null
            };

            if (get(this, 'testOptions.videoFilter')) {
                this.options.videoFilter = this.testOptions.videoFilter;
            }

            const resolutionReductionFactor = get(this, 'testOptions.reducePublisherResolutionByFactor', 1);
            this.options.resolution = PUBLISHER_RESOLUTION.width / resolutionReductionFactor
                + 'x' + PUBLISHER_RESOLUTION.height / resolutionReductionFactor;

            console.log('[Publisher component] initiating publisher with options', this.options);
            this.publisher = OT.initPublisher(this.publisherVideoEl, this.options, this.publishToSession.bind(this));

            this.addPublisherListeners();
        }

        addPublisherListeners() {
            this.publisher.on('streamCreated', () => {
                this.$scope.$emit('publisher:created', this.publisher);
                this.$eventBus.emit(`chat:updatecall:${this.conversationId}`);
                this.addAudioMediaTrackListeners();
                this.addVideoMediaTrackListeners();
            });

            this.publisher.on('accessDenied', (event) => {
                // We prevent access without devices during onboarding, so it may seem it can never happen
                // but if the user denies access to devices after the onboarding, we get here
                console.error('[Publisher component] user have prevented access to camera and microphone, or screen share on some machine', event);
                this.crashReportingService.reportError('Publisher component', 'publisher error', omit(event, [ 'stack' ]));
                this.$scope.$emit('publisher:accessDenied');
            });

            this.publisher.on('destroyed', (event) => {
                console.error('[Publisher component] publisher destroyed');
                this.handleError(event);
            });

            this.publisher.on('streamDestroyed', (event) => {
                console.error('[Publisher component] publisher stream destroyed');
                event.preventDefault();
                this.publishToSession();
            });
        }

        publishToSession(err) {
            if (err) return this.handleError(err);
            this.session.publish(this.publisher, (err) => {
                if (err) {
                    return this.handleError(err);
                }
                this.$scope.$emit('publisher:published');
                console.log('[Publisher component] publisher published');
            });
        }

        updateAudioSource() {
            this.removeAudioMediaTrackListeners();
            this.publisher.setAudioSource(this.mediaDevicesService.audioDeviceSelected.deviceId).then((err) => {
                if (err) {
                    return this.$scope.$emit('publisher:deviceChangeError', err);
                }
                this.addAudioMediaTrackListeners();
            });
        }

        updateVideoSource() {
            this.removeVideoMediaTrackListeners();
            this.publisher.setVideoSource(this.mediaDevicesService.videoDeviceSelected.deviceId).then((err) => {
                if (err) {
                    return this.$scope.$emit('publisher:deviceChangeError', err);
                }
                this.addVideoMediaTrackListeners();
            });
        }

        addVideoMediaTrackListeners() {
            const mediaStreamTrack = this.publisher.getVideoSource().track;
            if (!mediaStreamTrack) {
                return;
            }
            mediaStreamTrack.addEventListener('ended', this.onVideoMediaStreamTrackEnded);
            mediaStreamTrack.addEventListener('mute', this.onVideoMediaStreamTrackMuted);
        }

        addAudioMediaTrackListeners() {
            const mediaStreamTrack = this.publisher.getAudioSource();
            if (!mediaStreamTrack) {
                return;
            }
            mediaStreamTrack.addEventListener('ended', this.onAudioMediaStreamTrackEnded);
            mediaStreamTrack.addEventListener('mute', this.onAudioMediaStreamTrackMuted);
        }

        removeVideoMediaTrackListeners() {
            try {
                const mediaStreamTrack = this.publisher.getVideoSource().track;
                if (!mediaStreamTrack) {
                    return;
                }
                mediaStreamTrack.removeEventListener('ended', this.onVideoMediaStreamTrackEnded);
                mediaStreamTrack.removeEventListener('mute', this.onVideoMediaStreamTrackMuted);
            } catch (e) {
                console.error(e);
            }
        }

        removeAudioMediaTrackListeners() {
            try {
                const mediaStreamTrack = this.publisher.getAudioSource();
                if (!mediaStreamTrack) {
                    return;
                }
                mediaStreamTrack.removeEventListener('ended', this.onAudioMediaStreamTrackEnded);
                mediaStreamTrack.removeEventListener('mute', this.onAudioMediaStreamTrackMuted);
            } catch (e) {
                console.error(e);
            }
        }

        addMediaStreamTrackErrorHandlers() {
            const onAudioMediaStreamTrackIssue = (event) => {
                console.log('[Publisher component] audio mediaStreamTrack has ' + event);
                this.crashReportingService.reportError('Publisher component', 'audio media stream track', event);
                // It is not clear why/when we can get here, my guess is that the device had an issue (with the browser?)
                // Below we will connect to another device, or to the same if still available, in this case updateAudioSource will simply recreate the track.
                // TODO: this may need to toggle mic off, to show user they have been muted ?
                this.$timeout(() => {
                    this.updateAudioSource();
                }, 1000);
            };

            const onVideoMediaStreamTrackEnded = (event) => {
                console.log('[Publisher component] video mediaStreamTrack has ' + event);
                this.crashReportingService.reportError('Publisher component', 'video media stream track ', event);
                // It is not clear why/when we can get here, my guess is that the device had an issue (with the browser?)
                // Below we will connect to another device, or to the same if still available, in this case updateVideoSource will simply recreate the track.
                this.$timeout(() => {
                    this.updateVideoSource();
                }, 1000);
            };

            this.onAudioMediaStreamTrackEnded = () => {
                onAudioMediaStreamTrackIssue('ended');
            };

            this.onVideoMediaStreamTrackEnded = () => {
                onVideoMediaStreamTrackEnded('ended');
            };

            this.onAudioMediaStreamTrackMuted = () => {
                onAudioMediaStreamTrackIssue('mute');
            };

            this.onVideoMediaStreamTrackMuted = () => {
                onVideoMediaStreamTrackEnded('mute');
            };
        }

        addErrorTriggers() {
            this.listeners.push(
                this.triggerService.addTrigger('destroyPublisher', () => {
                    this.publisher.destroy();
                }),
                this.triggerService.addTrigger('destroyPublisherStream', () => {
                    this.publisher.stream.destroy();
                }),
                this.triggerService.addTrigger('destroyPublisherVideoMediaTrack', () => {
                    const mediaStreamTrack = this.publisher.getVideoSource().track;
                    mediaStreamTrack.stop();
                    // .stop() does not trigger the ended event
                    mediaStreamTrack.dispatchEvent(new Event('ended'));
                }),
                this.triggerService.addTrigger('destroyPublisherAudioMediaTrack', () => {
                    const mediaStreamTrack = this.publisher.getAudioSource();
                    mediaStreamTrack.stop();
                    // .stop() does not trigger the ended event
                    mediaStreamTrack.dispatchEvent(new Event('ended'));
                })
            );
        }

        handleError(e) {
            if (e) {
                console.error('[Publisher component] handleError', e);
                this.$scope.$emit('publisher:error');
                this.crashReportingService.reportError('Publisher component', 'publisher error', omit(e, [ 'stack', 'target', 'stream', '_defaultPrevented' ]));
            }
        }
    }
};
