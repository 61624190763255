// bootstrap and jquery are not nice [npm packages]
// this file is using requires and not imports because of some external
//  dependencies not handling the import well

window.jQuery = window.$ = require('jquery');

const angular = require('angular');
let debugEnabled = false;

// for IE, with love
// and dev builds of FSTG
if (!('console' in window)) {
    window.console = { log: function() {}, error: function() {} };
}

// prevents a strange bug where moment-timezone can't
// require moment from happening less often
require('moment');

require('slick-carousel');
require('angular-slick-carousel');
require('angularjs-scroll-glue');
require('angularjs-bootstrap-datetimepicker');
require('oclazyload');
require('ng-quill');

require('./templates');
require('./services');
require('./filters');
require('./directives');
require('./frontloader');
require('./navs');

angular
    .module('maestro', [
        // 3rd party libraries
        require('angular-sanitize'),
        require('angular-cookies'),
        'luegg.directives',
        'slickCarousel',
        // core services
        'maestro.config',
        'maestro.auth',
        'maestro.navs',
        'maestro.navs.router',
        'maestro.services',
        'maestro.services.metrics',
        'maestro.services.appcues',
        'maestro.filters',
        'maestro.directives',
        'maestro.layoutmanager',
        'maestro.cache.templates',
        'ui.bootstrap.datetimepicker',

        // components
        require('./components').ComponentsModule,

        // common services
        require('frontloader-common/meta-service'),
        require('frontloader-common/device-provider'),
        require('frontloader-common/theme-provider'),
        require('frontloader-common/i18n'),
        require('frontloader-common/asset-utils'),

        'dbinterface',
        'oc.lazyLoad',
        'ngQuill'
    ])
    .config(/* @ngInject */ function(
        $compileProvider,
        $locationProvider,
        $sceDelegateProvider,
        $logProvider,
        $ocLazyLoadProvider
    ) {
        // not using ENV so that envify can do it's magic
        if (!true) {
            $compileProvider.debugInfoEnabled(false);
            $logProvider.debugEnabled(false);
        }

        debugEnabled = $compileProvider.debugInfoEnabled();

        $compileProvider.aHrefSanitizationWhitelist(
            /^\s*(https?|ftp|mailto|tel|open\-document|appscript):/
        );
        $compileProvider.imgSrcSanitizationWhitelist(
            /^\s*((https?|ftp|file|blob|fp\-asset):|data:image\/)/
        );

        $sceDelegateProvider.resourceUrlWhitelist([ 'self' ]);

        $locationProvider.html5Mode(true).hashPrefix('!');

        $ocLazyLoadProvider.config({
            debug: false
        });
    }
    )
    .run(/* @ngInject */
        function(
            ACTIVATED_PERSON,
            EID,
            $rootScope,
            metaService,
            signalService,
            appcuesService,
            metricsService // eslint-disable-line no-unused-vars
        ) {
            console.log('[maestro] run');
            signalService.init(EID);
            appcuesService.load();

            $rootScope.activatedPerson = ACTIVATED_PERSON;

            if (debugEnabled) {
                metaService.exposeGlobalHelper();
            }
        }
    );

require('./bootloader');
