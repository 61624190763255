/**
 * At first glance the card block is just an empty container
 * that will host a group of elements (aka Card block elements).
 *
 * These elements will be arranged vertically following the
 * order in which they have been defined in the configuration
 * file.
 *
 * A Card block and his content is defined through a json
 *
 * The properties that can be set on the main Card block
 * container are the following
 *
 * ----------------------------------------------------------------------------
 * Parameter    Type       Default  Min    Max    Encoding    Mandatory   Notes
 * ----------------------------------------------------------------------------
 * id           String     -        -      -      -           yes
 * block_type   String     -        -      -      -           yes         (1)
 * data         Json       -        -      -                  yes         (2)
 * elements     Json[]     -        -      -                  yes         (3)
 * action       Json       -        -      -      -           no          (4)
 * theme        Json       -        -      -      -           no          (5)
 * ----------------------------------------------------------------------------
 *
 * (1) The value to be set for generate a Card Block is block_card
 *
 * (2) Json that contains the card block elements, the action
 *     and the theme properties
 *
 * (3) Json array that contains all the card block elements
 *     supported (see following documentation)
 *
 * (4) Action that should be triggered when user touch the card
 *
 * (5) Card block theme where is possible to set the card
 *     background color and enable the card elevation effect
 */

import { has, get, isEmpty } from 'lodash';

export const CardComponent = {
    bindings: {
        config: '<'
    },
    template: require('./card-block.jade'),
    controller: class CardComponent {
        /* @ngInject */
        constructor(navService, databaseService, $element, THEME) {
            this.navService = navService;
            this.databaseService = databaseService;
            this.$element = $element;
            this.THEME = THEME;
        }

        $onInit() {
            // console.debug('[CardComponent] init', this.config);
            this.noCard = has(this.config, 'data.theme.card')
                ? !this.config.data.theme.card
                : false;
            this.noAction = isEmpty(get(this.config, 'data.action'));
        }

        performAction($event) {
            // Panning, not firing action on card.
            if (this.$element.closest('.panning').length !== 0) {
                return;
            }

            $event.stopPropagation();
            const action = this.config.data.action;
            if (!action) return;

            if (action.path) {
                this.databaseService.runAppScript(action.path, action.params);
            } else {
                this.navService.performActions([ action ]);
            }
        }
    }
};
