export const CalendarComponent = {
    bindings: {
        days: '<',
        onDayChange: '&',
        preselectedIndex: '<selectedDay'
    },
    template: require('./calendar.jade'),
    controller: class CalendarComponent {
        /* @ngInject */
        constructor(EventEmitter, $element, $timeout, $window, $i18n) {
            this.EventEmitter = EventEmitter;
            this.$element = $element;
            this.$timeout = $timeout;
            this.$window = angular.element($window);
            this.$i18n = $i18n;
        }

        $onInit() {
            console.log('[CalendarComponent] init');
            // we always display a minimum of 7 days
            this.days = [ {}, {}, {}, {}, {}, {}, {} ];
        }

        $onChanges(changes) {
            console.log('[CalendarComponent] changes', changes);
            if (changes.days && changes.days.currentValue) {
                this.days = angular.copy(this.days);
            }

            if (changes.preselectedIndex && changes.days.currentValue) {
                this.selectDayAtIndex(null, this.preselectedIndex, false);
            }
        }

        selectDayAtIndex($event, index, bubble = true) {
            console.log('[CalendarComponent] selectDayAtIndex', index);
            this.$window.trigger('assets:load');

            const day = this.days[index];
            if (!day || !day.enabled || this.selected === index) return;

            this.selected = index;

            if (bubble) {
                this.onDayChange(this.EventEmitter({ index }));
            }

            if (!$event) {
                this.$timeout(() => this.scrollToDay());
            }
        }

        scrollToDay() {
            const selectedDay = this.$element.find('.selected');
            if (selectedDay.length !== 0) {
                this.$element[0].scrollLeft = selectedDay[0].offsetLeft;
            }
        }

        localizeNumber(n) {
            return this.$i18n.localizeNumber(n);
        }
    }
};
