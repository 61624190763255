export const ListBannerComponent = {
    template: require('./list-banner.jade'),
    bindings: {
        image: '<'
    },
    controller: class ListBannerComponent {
        $onInit() {
            const cfg = this.image;
            console.info('[ListBannerComponent] init', cfg);
        }
    }
};
