// R.I.P.
// This service was a victim of over-engineering so it is being deprecated
// This definition will be overwritten by frontstage

class DocumentService {
    /* @ngInject */ constructor(navService) {
        this.navService = navService;
    }

    open() {
        console.warn(
            '[DocumentService/open] this method has been deprecated, please use navService.openDocument instead'
        );
        this.navService.openDocument.apply(this.navService, arguments);
    }
}

angular.module('maestro.services').service('documentService', DocumentService);
