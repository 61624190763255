import { AvatarComponent } from './avatar.component';

function tryModule() {
    try {
        const mod = angular.module('frontstage.components.avatar');
        console.info('[Avatar] Module already installed, skipping installation from webapp');
        return mod.name;
    } catch (error) {
        console.info('[Avatar] Module not installed yet; installing from webapp');
        return angular
            .module('maestro.components.common.avatar', [])
            .component('avatar', AvatarComponent).name;
    }
}

export const AvatarModule = tryModule();
