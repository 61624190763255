const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.25;
const MAX_SCALE = 10.0;

export const DocumentNavComponent = {
    bindings: {
        config: '<',
        onReject: '&'
    },
    template: require('./document-nav.jade'),
    controller: class DocumentNavComponent {
        /* @ngInject */
        constructor(
            EID,
            $filter,
            $element,
            $timeout,
            $q,
            pdfService,
            databaseService,
            navService,
            progressBarService,
            metricsService,
            $scope
        ) {
            this.EID = EID;

            this.$filter = $filter;
            this.$element = $element;
            this.$timeout = $timeout;
            this.$q = $q;

            this.pdfService = pdfService;
            this.databaseService = databaseService;
            this.navService = navService;
            this.progressBarService = progressBarService;
            this.metricsService = metricsService;

            this.minScale = MIN_SCALE;
            this.maxScale = MAX_SCALE;
            this.pdfReader = true;
            this.pdfLoading = true;
            this.annotationsEnabled = false;
            this.sharingEnabled = false;
            this.sharingUrl = null;
            this.viewer = null;
            this.viewerState = {
                page: 1,
                searchTerm: '',
                numPages: 1
            };
            this.noteId = null;
            this.url = null;

            this.addNoteActive = false;
            // search
            this.initialSearch = true;
            this.$scope = $scope;
        }

        $onInit() {
            const config = this.config;
            let url = (this.url =
                config.url || config.media_url || config.doc_location);
            if (!url || !url.length) {
                console.warn('[DocumentNavCtrl] No url to open');
                this.navService.resetNavigation();
                return;
            }
            console.log('[DocumentNavCtrl] init', url);

            const openExternal = (error) => {
                console.log('[DocumentNavCtrl] Opening as external', url);
                this.external = true;
                if (this.onReject) {
                    this.onReject(error);
                }
                this.navService.openExternalUri({
                    uri: url
                });
            };

            let noteId = (this.noteId = config.doc_id);
            const isPdf = url.toLowerCase().indexOf('.pdf') !== -1;
            const withAnnotation = !!config.annotations;
            if (url.indexOf('://') === -1 && url[0] !== '/') {
                url = this.$filter('assetUrl')(url);
            }

            if (!isPdf) {
                return openExternal();
            }

            this.pdfReader = true;
            this.annotationsEnabled = withAnnotation;
            this.sharingEnabled = config.file_sharing_enabled;
            this.sharingUrl = url;

            // delay init so that dom element is ready
            this.progressBarService.startTask();
            this.$timeout(() => {
                this.$q
                    .all([
                        this.initPDFViewer(),
                        withAnnotation && noteId
                            ? this.databaseService.docWithId(noteId)
                            : null
                    ])
                    .then(([ viewer, annotations ]) => {
                        if (!withAnnotation) return;
                        this.annotationController = this.pdfService.mapAnnotations(
                            viewer,
                            annotations,
                            config.parent_doc_id,
                            ({ data }) => (this.noteId = data.id)
                        );
                        this.annotationController.onActiveEnd(() =>
                            this.$timeout(() => (this.addNoteActive = false))
                        );
                    })
                    .catch(error => {
                        console.error('[DocumentNavCtrl] Unable to initialize PDF viewer', error);
                        openExternal(error);
                    })
                    .finally(() => {
                        this.pdfLoading = false;
                        this.progressBarService.finishTask();
                    });
            });
        }

        initPDFViewer() {
            const container = this.$element.find(
                '.nav-document-pdf-container'
            )[0];
            return this.pdfService
                .getViewer(this.sharingUrl, container)
                .then(({ viewer, document, findController }) => {
                    this.viewer = viewer;
                    this.findController = findController;
                    this.viewerState.numPages = document.numPages;

                    container.addEventListener('pagechange', evt =>
                        this.$timeout(
                            () => (this.viewerState.page = evt.pageNumber)
                        )
                    );

                    this.findController.onUpdateState = () => {
                        this.$timeout(() => {
                            if (this.findController.matchCount === 0) {
                                this.noMatches = true;
                            } else {
                                this.noMatches = false;
                            }
                            this.$scope.$apply();
                        }, 250);
                    };

                    return viewer;
                });
        }

        search() {
            this.findController.executeCommand(
                this.initialSearch ? 'find' : 'findagain',
                {
                    query: this.viewerState.searchTerm
                }
            );

            this.initialSearch = false;
        }

        changePage() {
            let page = parseInt(this.viewerState.page, 10);
            if (isNaN(page)) {
                page = this.viewer.currentPageNumber;
            } else {
                page = Math.min(page, this.viewerState.numPages);
                page = Math.max(page, 1);
                this.viewer.currentPageNumber = page;
            }
            this.viewerState.page = page;
        }

        nextPage() {
            this.viewer.currentPageNumber++;
        }

        previousPage() {
            this.viewer.currentPageNumber--;
        }

        zoomIn() {
            let newScale = this.viewer.currentScale;
            newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
            newScale = Math.ceil(newScale * 10) / 10;
            newScale = Math.min(MAX_SCALE, newScale);
            this.viewer.currentScaleValue = newScale;
            this.viewerState.currentScaleValue = newScale;
        }

        zoomOut() {
            let newScale = this.viewer.currentScale;
            newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
            newScale = Math.floor(newScale * 10) / 10;
            newScale = Math.max(MIN_SCALE, newScale);
            this.viewer.currentScaleValue = newScale;
            this.viewerState.currentScaleValue = newScale;
        }

        share() {
            const noteId = this.noteId;
            const url = this.url;
            this.navService.openExternalUri({
                uri: noteId
                    ? `/api/v1/eid/${this.EID}/pdf/annotations/note/${noteId}`
                    : url
            });
        }

        toggleAddNote() {
            this.addNoteActive = !this.addNoteActive;
            this.annotationController.setActive(this.addNoteActive);
        }

        toggleSearch() {
            this.showSearch = !this.showSearch;
            // disable annotation not to have UI conflicts
            if (this.showSearch) {
                this.addNoteActive = false;
                this.annotationController.setActive(false);
            }
        }

        onSearchTermChange() {
            if (this.viewerState.searchTerm.length === 0) {
                this.noMatches = false;
            }
            this.initialSearch = true;
        }
    }
};
