import $LAB from 'frontloader-common/lab';
import moment from 'moment-timezone';
import rangeFix from 'rangefix';
import { chain, each, filter, reduce, invoke } from 'lodash';
import { hexShorthandToFull } from '../utils/color';

// scaling use to have proper positioning of annotations
// this is more or less found by experiment
const ANNOTATION_SCALING = 1.333;
// iOS annotation are based on the lower bottom corner, but there scale with the page
// so we need realignment so the small triangle is properly positionned
const HORIZONTAL_SHIFT = 25;
const VERTICAL_SHIFT = 0;

const BORDER_WIDTH = 9;

// helpers to translate coordonnates
const xToWeb = x => x * ANNOTATION_SCALING;
const yToWeb = y => y * ANNOTATION_SCALING;
const xFromWeb = x => x / ANNOTATION_SCALING;
const yFromWeb = y => y / ANNOTATION_SCALING;

const normalizeDoc = (doc, parentId, PID) => {
    if (!doc) {
        return {
            // default annotation file
            parent_doc_id: parentId,
            annotations: [],
            type: 'pdf',
            fp_type: 'note',
            fp_owner: PID,
            timestamp: moment().unix()
        };
    }
    delete doc._rev;
    return doc;
};

// get current selection rects and text, or null if nothing is selected
const getSelection = () => {
    let allRects = [];
    let highlighted = '';
    let parent = null;
    try {
        const selection = window.getSelection();
        for (let i = 0; i < selection.rangeCount; i++) {
            let range = selection.getRangeAt(i);
            // safari have selection with width === 1
            let rects = filter(
                rangeFix.getClientRects(range),
                rect => rect.width && rect.height && rect.width > 1
            );
            // fo this the old way
            if (rects) {
                allRects = allRects.concat(rects);
                highlighted += range.toString();
                parent = range.commonAncestorContainer || parent;
            }
        }
    } catch (x_X) {
        console.log('[pdfService] selection not supported');
        return null;
    }

    if (!allRects.length) {
        return null;
    }

    return {
        rects: allRects,
        highlighted,
        parent
    };
};

// retrieved page rectangle adjusted with border width
const getPageRect = pageDom => {
    const rect = pageDom.getBoundingClientRect();
    // adjust with pageDom borders
    return {
        left: rect.left + BORDER_WIDTH,
        bottom: rect.bottom - BORDER_WIDTH
    };
};

// get the rectangular bounds of list of quads for iOS
const getRect = quads => {
    return reduce(
        quads,
        (previous, { x1, y1, x4, y4 }) => {
            if (!previous.lower_left_x || x1 < previous.lower_left_x) {
                previous.lower_left_x = x1;
            }
            if (!previous.upper_right_x || x4 > previous.upper_right_x) {
                previous.upper_right_x = x4;
            }
            if (!previous.lower_left_y || y4 < previous.lower_left_y) {
                previous.lower_left_y = y4;
            }
            if (!previous.upper_right_y || y1 > previous.upper_right_y) {
                previous.upper_right_y = y1;
            }
            return previous;
        },
        {
            lower_left_y: null,
            upper_right_y: null,
            lower_left_x: null,
            upper_right_x: null
        }
    );
};

const filterEmptyAnnotations = annotations =>
    filter(annotations, ann => {
        if (ann.prune) return false;
        if (ann.type === 'text' && !ann.contents) return false;
        return true;
    });

/* @ngInject */
function PdfService(
    $q,
    $popover,
    $i18n,
    $filter,
    $timeout,
    NAV_TEMPLATES,
    PID,
    databaseService
) {
    let initPromise = null;

    const initPdfService = () => {
        return $q((resolve /* , reject */) => {
            // load pdfjs code on-demand
            const appResource = $filter('appResource');
            $LAB.script([
                appResource('js/compatibility.js'),
                appResource('js/pdfjs-dist.js'),
                appResource('js/pdf_viewer.js')
            ]).wait(() => {
                require('pdfjs-dist/web/compatibility');
                require('pdfjs-dist');
                require('pdfjs-dist/web/pdf_viewer');

                PDFJS.workerSrc = appResource('js/pdf.worker.js');

                resolve();
            });
        });
    };

    const init = () => {
        if (!initPromise) {
            initPromise = initPdfService();
        }
        return initPromise;
    };

    return {
        /**
         * init and build a viewer
         * @param {string} url
         * @param {DOM} container
         * @return {promise} promise resolving with viewer, document and findController
         */
        getViewer(url, container) {
            return init().then(() => {
                // enable hyperlinks within PDF files.
                const pdfLinkService = new PDFJS.PDFLinkService();

                const pdfViewer = new PDFJS.PDFViewer({
                    container: container,
                    linkService: pdfLinkService
                });
                pdfLinkService.setViewer(pdfViewer);

                // enable find controller.
                const pdfFindController = new PDFJS.PDFFindController({
                    pdfViewer: pdfViewer
                });
                pdfViewer.setFindController(pdfFindController);

                container.addEventListener('pagesinit', function() {
                    // We can use pdfViewer now, e.g. let's change default scale.
                    $timeout(() => (pdfViewer.currentScaleValue = 'auto'));
                });

                // Loading document.
                return $q((resolve, reject) => {
                    console.log('[pdfService] Loading a document', url);
                    PDFJS.getDocument(url).then(pdfDocument => {
                        // Document loaded, specifying document for the viewer and
                        // the linkService.
                        pdfViewer.setDocument(pdfDocument);
                        pdfLinkService.setDocument(pdfDocument, null);
                        resolve({
                            viewer: pdfViewer,
                            document: pdfDocument,
                            findController: pdfFindController
                        });
                    }, (error) => {
                        console.error('[pdfService] Unable to load document (reject)', error);
                        return reject(error);
                    });
                });
            });
        },

        /**
         * Add annotations handlers on a pdf viewer
         * @params {PDFViewer} viewer
         * @params {object} doc existing note document
         * @params {string} parentId pdf document id
         * @params {function} saveCb called whenever the note document is saved
         * @return {object} annotationController used to toggle the add annotation state on the viewer
         */
        mapAnnotations(viewer, doc, parentId, saveCb) {
            doc = normalizeDoc(doc, parentId, PID);

            // state
            let active = false;
            let activeEndCb = angular.noop;

            // DOM for simple text annotation
            const buildAnnotation = (pageDom, annotation, scale) => {
                const node = document.createElement('div');
                pageDom.appendChild(node);
                node.style.left =
                    (xToWeb(annotation.pos_x) + HORIZONTAL_SHIFT) * scale +
                    'px';
                node.style.bottom =
                    (yToWeb(annotation.pos_y) + VERTICAL_SHIFT) * scale + 'px';
                node.className = 'nav-document-pdf-annotation';
                node.innerHTML = '<i class="icon-menu"></i>';
                node.addEventListener('click', () =>
                    showAnnotationEditor([ node ], annotation)
                );
                return [ node ];
            };

            // DOM for text highlighting
            const buildHighlightAnnotation = (pageDom, annotation, scale) => {
                const nodes = [];
                each(annotation.quad_points, ({ x1, y1, x4, y4 }) => {
                    const node = document.createElement('div');
                    const style = node.style;
                    pageDom.appendChild(node);
                    style.left = xToWeb(x1) * scale + 'px';
                    style.bottom = yToWeb(y4) * scale + 'px';
                    style.width = xToWeb(x4 - x1) * scale + 'px';
                    style.height = xToWeb(y1 - y4) * scale + 'px';
                    style.backgroundColor = annotation.color;
                    node.className = 'nav-document-pdf-highlight';
                    nodes.push(node);
                    node.addEventListener('click', () =>
                        showAnnotationEditor(nodes, annotation)
                    );
                });
                return nodes;
            };

            const showAnnotationEditor = (nodes, annotation) => {
                let saveAction = {
                    id: 'save',
                    label: $i18n('general.save'),
                    action: popover => popover.accept()
                };
                let deleteAction = {
                    id: 'delete',
                    label: $i18n('general.delete'),
                    action: popover => {
                        annotation.prune = true;
                        popover.accept();
                    }
                };
                let closeAction = {
                    id: 'close',
                    label: $i18n('general.close'),
                    action: popover => popover.reject()
                };
                let cancelAction = {
                    id: 'close',
                    label: $i18n('general.cancel'),
                    action: popover => popover.reject()
                };

                return $popover({
                    // configuration
                    target: nodes[0],
                    templateUri: NAV_TEMPLATES.document_note,

                    // scope
                    annotation: {
                        contents: annotation.contents,
                        color: annotation.color ? hexShorthandToFull(annotation.color) : undefined,
                        type: annotation.type
                    },
                    actions: annotation.new
                        ? [ saveAction, cancelAction ]
                        : [ saveAction, deleteAction, closeAction ]
                }).then(
                    $scope => {
                        annotation.contents = $scope.annotation.contents;

                        if (annotation.prune) {
                            doc.annotations = filterEmptyAnnotations(
                                doc.annotations
                            );
                            invoke(nodes, 'remove');
                            // no need to save
                            if (annotation.new) return;
                        }
                        if (annotation.type === 'highlight') {
                            annotation.color = $scope.annotation.color;
                            each(
                                nodes,
                                node =>
                                    (node.style.backgroundColor =
                                        annotation.color)
                            );
                        }
                        if (
                            annotation.type === 'text' &&
                            annotation.new &&
                            !annotation.contents
                        )
                            return;

                        delete annotation.new;

                        return databaseService.saveDoc(doc).then(saveCb);
                    },
                    () => {
                        if (annotation.new) {
                            annotation.prune = true;
                            doc.annotations = filterEmptyAnnotations(
                                doc.annotations
                            );
                            invoke(nodes, 'remove');
                        }
                    }
                );
            };

            const container = viewer.container;
            container.addEventListener('pagerendered', e => {
                const page = e.detail.pageNumber;
                const pageDom = e.target;
                const scale = viewer.currentScale;

                const annotationsByType = chain(doc.annotations)
                    .filter(ann => ann.page === page)
                    .groupBy('type')
                    .value();
                each(annotationsByType.highlight, ann =>
                    buildHighlightAnnotation(pageDom, ann, scale)
                );
                each(annotationsByType.text, ann =>
                    buildAnnotation(pageDom, ann, scale)
                );

                // add event listener on page
                const addTextAnnotation = e => {
                    // create new annotation and open it
                    const x = e.clientX;
                    const y = e.clientY;
                    const rect = getPageRect(pageDom);
                    const annotation = {
                        new: true,
                        pos_x: xFromWeb(
                            (x - rect.left - HORIZONTAL_SHIFT) / scale
                        ),
                        pos_y: yFromWeb((rect.bottom - y) / scale),
                        page,
                        type: 'text',
                        timestamp: moment().unix()
                    };
                    doc.annotations.push(annotation);
                    const nodes = buildAnnotation(pageDom, annotation, scale);
                    showAnnotationEditor(nodes, annotation);
                };

                const addHighLightAnnotation = (e, selection) => {
                    const quads = [];
                    const rect = getPageRect(pageDom);
                    const getQuad = ({ bottom, left, height, width }) => {
                        if (!height || !width) {
                            return;
                        }
                        const x1 = xFromWeb((left - rect.left) / scale);
                        const x4 = xFromWeb(width / scale) + x1;
                        const y4 = yFromWeb((rect.bottom - bottom) / scale);
                        const y1 = yFromWeb(height / scale) + y4;
                        quads.push({
                            x1,
                            y1,
                            x2: x4,
                            y2: y1,
                            x3: x1,
                            y3: y4,
                            x4,
                            y4
                        });
                    };

                    selection = selection || getSelection();
                    if (selection) {
                        selection.rects.forEach(getQuad);
                    }

                    if (!quads.length) {
                        return;
                    }

                    if (e) e.preventDefault();
                    const annotation = {
                        new: true,
                        page,
                        quad_points: quads,
                        type: 'highlight',
                        timestamp: moment().unix(),
                        color: '#f00',
                        highlighted_text: selection.highlighted,
                        rect: getRect(quads)
                    };
                    doc.annotations.push(annotation);
                    const nodes = buildHighlightAnnotation(
                        pageDom,
                        annotation,
                        scale
                    );
                    showAnnotationEditor(nodes, annotation, saveCb);
                };

                const textlayer = pageDom.querySelector('.textLayer');
                // pdfjs is changing the content of the textLayer during the mousedown/mouseup
                // in the end the mouseup event occurs on a different element than the mousedown
                // (endOfContent) so safari thinks there is no click -> use mouseup instead.
                const triggerSelection = (selection, e) => {
                    if (selection) {
                        addHighLightAnnotation(e, selection);
                    } else {
                        addTextAnnotation(e);
                    }
                    active = false;
                    activeEndCb();
                };
                textlayer.addEventListener('mouseup', e => {
                    if (!active) return;
                    // check if selection of simple click
                    const selection = getSelection();
                    // add a little delay for $apply crazyness
                    $timeout(() => triggerSelection(selection, e));
                });
                textlayer.triggerSelection = triggerSelection;
            });

            return {
                onActiveEnd: cb => {
                    activeEndCb = cb;
                },
                setActive: activeState => {
                    active = activeState;
                    if (!active) {
                        return;
                    }
                    const selection = getSelection();
                    let parentNode = selection ? selection.parent : null;
                    // it might be a text node or an annotation, retrieve a page
                    while (parentNode && !$(parentNode).hasClass('page')) {
                        parentNode = parentNode.parentNode;
                    }
                    if (parentNode) {
                        // open annotation editor with current selection
                        const layer = $(parentNode).find('.textLayer')[0];
                        if (layer)
                            $timeout(() => layer.triggerSelection(selection));
                    }
                }
            };
        }
    };
}

angular.module('maestro.services').service('pdfService', PdfService);
