/**
 * Actions is an element that contains one ore more actions in a horizontal row.
 * The actions could be placed on the left, on the right or both.
 * The properties that can be set on an Actions element are the following
 *
 * ----------------------------------------------------------------------------------------
 * Parameter     Type    Default      Min     Max   Encoding              Mandatory   Notes
 * ----------------------------------------------------------------------------------------
 * left          JSON      -          -       -      -                    no          (1)
 * right         JSON      -          -       -      -                    no          (2)
 * icon_url      String    -          -       -      -                    no
 * label         String    -          -       -      -                    no
 * label_color   String    #808080    -       -      #RRGGBB or           no
 *                                                   #AARRGGBB
 * label_size    int       14pt       >= 1    -      -                    no
 * label_style   String    bold       -       -      normal, bold,        no
 *                                                   italic, bold-italic
 * path          String    -          -       -      -                    no
 * params        JSON      -          -       -      -                    no
 * margin        JSON                                                     no          (3)
 * `- left       int       8          >= 0    -      points/dp            no
 * `- top        int       8          >= 0    -      points/dp            no
 * `- right      int       8          >= 0    -      points/dp            no
 * `- bottom     int       8          >= 0    -      points/dp            no
 * ----------------------------------------------------------------------------------------
 *
 * (1) Contains all actions that should be displayed on the left
 *
 * (2) Contains all actions that should be displayed on the right
 *
 * (3) Contains margin properties (left,top, right and bottom), a margin
 *     value missing will be set to a default value.
 */

import { each } from 'lodash';

export const ActionsComponent = {
    bindings: {
        row: '<'
    },
    replace: true,
    template: require('./actions.jade'),
    controller: class ActionsComponent {
        /* @ngInject */
        constructor(styleParser, $filter, databaseService) {
            this.parser = styleParser;
            this.$filter = $filter;
            this.databaseService = databaseService;
        }

        $onInit() {
            this.config = this.row.config;
            // console.debug('[ActionsComponent] init', this.config);

            this.style = this.parser.parseMargins(this.config);

            if (this.config.left) {
                this.parseButtonsStyles(this.config.left);
            }

            if (this.config.right) {
                this.parseButtonsStyles(this.config.right);
            }
        }

        performAction(button, $event) {
            $event.stopPropagation();
            // console.debug('[ActionsComponent]', button);
            if (button.path) {
                this.databaseService.runAppScript(button.path, button.params);
            }
        }

        parseButtonsStyles(buttonsGroup) {
            each(buttonsGroup, btn => {
                btn.style = this.parser.parseTextsStyles(btn, 'label');
                if (btn.icon_url) {
                    const img =
                        'url(' + this.$filter('assetUrl')(btn.icon_url) + ')';
                    btn.icon_style = { 'background-image': img };
                }
            });
        }
    }
};
