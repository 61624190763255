import { get, isEmpty, isFunction } from 'lodash';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

export const AppSearchComponent = {
    template: require('./app-search.pug'),
    bindings: {
        config: '<'
    },
    controller: class AppSearchComponent {

        /* @ngInject */
        constructor(THEME, navService, databaseService, navRouterService, $eventBus, $i18n, $element) {
            this.navService = navService;
            this.databaseService = databaseService;
            this.navRouterService = navRouterService;
            this.$eventBus = $eventBus;
            this.$element = $element;

            this.listeners = [];
            this.query = '';
            this.appearance = THEME.get('appearance') || 'blank';

            this.searchPlaceholder = i18nWithFallback($i18n, 'general.search', 'Search');
            this.searchAriaDescription = i18nWithFallback($i18n, 'general.perform_search', 'Perform search');
            this.closeAriaDescription = i18nWithFallback($i18n, 'general.clear_search', 'Clear the search query');
        }

        $onInit() {
            console.log('[AppSearch] init', this.config);
            const route = this.navRouterService.extractNavIdAndParamsFromUrl(location.href);

            if (route.navId === this.config._id) {
                const query = get(route, 'routeParams.query');
                if (query) {
                    this.query = decodeURIComponent(query);
                    this.search();
                }
            }

            this.registerListeners();
        }

        $onDestroy() {
            this.close();
            this.unregisterListeners();
        }

        /**
         * Registers all the listeners needed for the component
         */
        registerListeners() {
            const navChangeListener = this.$eventBus.on('$navChange', nav => {
                if (nav._id === this.config._id) {
                    if (nav.query && nav.query !== this.query) {
                        this.query = decodeURIComponent(nav.query);
                        this.search();
                    }
                    return;
                }
                this.close();
            });

            this.listeners.push(navChangeListener);
        }

        /**
         * Unregister from all the listeners
         */
        unregisterListeners() {
            for (const unsub of this.listeners) {
                if (isFunction(unsub)) unsub();
            }
        }

        /**
         * Performs the search
         */
        search() {
            const source = get(this.config, 'ds.source', {});
            const { path, params = {} } = source;

            if (!path) {
                console.error('[AppSeach] No search datasource provided');
                return;
            }

            if (isEmpty(this.query)) {
                console.info('[AppSearch] empty query, giving up');
                this.$element.find('input').focus();
                return;
            }

            console.log('[AppSearch] Search for ', this.query);

            params.search = this.query;

            this.databaseService
                .runAppScript(path, params)
                .then(({ data }) => {
                    console.info('[AppSearch] Search results:', data.response);
                    const nav = Object.assign({}, this.config, {
                        query: this.query,
                        results: data.response
                    });

                    this.navService.openNav(nav);
                })
                .finally(() => {
                    this.$element.find('input').focus();
                });
        }

        /**
         * Closes the search bar
         *
         * @param {Event} $event the UI event
         */
        close($event) {
            if ($event) {
                $event.preventDefault();
                this.$element.find('input').focus();
            }

            console.debug('[AppSearch] Closing');
            this.query = '';
        }
    }
};
