import { throttle } from 'lodash';
const BANNER_RATIO = 20 / 5;

/* @ngInject */
function BannerRatioDirective($window) {
    return {
        restrict: 'C',
        link(scope, elem) {
            let _adoptHeight;
            let adoptHeight = () => {
                let h = Math.floor(elem.width() / BANNER_RATIO);

                // when restoring blocks from cache the width is 0
                // because not all elements and directives have time to
                // finish their setups
                if (!h) {
                    return _adoptHeight();
                }

                elem.height(h);
            };
            _adoptHeight = throttle(adoptHeight, 500);

            let $$window = angular.element($window);
            $$window.bind('resize', _adoptHeight);
            scope.$on('$destroy', () => $$window.off('resize', _adoptHeight));

            _adoptHeight();
        }
    };
}

angular
    .module('maestro.directives')
    .directive('bannerRatio', BannerRatioDirective);
