import { debounce, each } from 'lodash';

export const ZoomComponent = {
    bindings: {
        allMedia: '='
    },
    template: require('./zoom.jade'),
    controller: class Zoom {
        /* @ngInject */
        constructor(navService, $element, $scope, $timeout, $compile, $q) {
            this.navService = navService;
            this.element = $element;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$compile = $compile;
            this.$q = $q;
        }

        $onInit() {
            this.fullscreenElement = this.element;
            this.wrapper = this.element.find('.zoom-container');
            this.content = this.wrapper.find('.content');
            this.totalMedia = 0;
            // TODO: change to proper value once
            // Spotme/frontloader-common/pull/18 is accepted and merged.
            this.animationsEnabled = false;
            this.zoomed = false;

            this.SLIDES_NUM = 10;
            this.firstIndex = 0;
            this.currentIndex = this.firstIndex;
            this.lastIndex = this.SLIDES_NUM;
            this.carouselMedia = [];
            this.prevSlide = 0;

            this.preload = debounce((elements, onTop) => {
                this.buildCarousel(elements, onTop);
            }, 100);

            this.$scope.$on('zoom', (e, { element, media }) => {
                this.zoom(element, media);
            });

            this.$scope.$on('closeZoom', () => {
                this.close();
            });

            const elem = this.fullscreenElement[0];
            this.hasFullscreen = false;
            this.fullScreen =
                elem.requestFullScreen ||
                elem.webkitRequestFullScreen ||
                elem.mozRequestFullScreen ||
                elem.msRequestFullScreen ||
                elem.msRequestFullscreen;
            if (this.fullScreen) {
                this.hasFullscreen = true;
            }

            this.exitFullscreen =
                document.exitFullscreen ||
                document.webkitExitFullscreen ||
                document.mozCancelFullScreen ||
                document.msExitFullscreen;

            this.inFullscreen = false;

            this.carouselTemplate = require('./carousel.jade');

            this.$scope.$on('nav:content-update', (ev, elements, onTop) => {
                // Reinit the zoom...
                this.preload(elements, onTop);
                if (this.zoomed) {
                    this.zoom(
                        this.lastElement,
                        { index: this.currentIndex },
                        true
                    );
                }
            });

            this.$scope.$on('media:pause', () => {
                if (this.slick) {
                    this.slick.options.swipeToSlide = false;
                    this.slick.options.swipe = false;
                    this.$scope.$apply();
                }
            });

            this.$scope.$on('media:start', () => {
                if (this.slick) {
                    this.slick.options.swipeToSlide = true;
                    this.slick.options.swipe = true;
                    this.$scope.$apply();
                }
            });

            angular
                .element(document)
                .on(
                    'fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange',
                    () => {
                        this.inFullscreen =
                            document.webkitIsFullScreen ||
                            document.mozFullScreen ||
                            document.msFullscreenElement;
                        this.$scope.$apply();
                    }
                );

            this.detailed = false;
        }

        $onDestroy() {
            this.close();
        }

        bindKeys() {
            angular.element(window).on('keyup.zoom', e => {
                const code = window.event ? e.keyCode : e.which;
                switch (code) {
                    case 27: // esc key
                        this.close();
                        break;
                    case 37: // left arrow
                        this.prevMedia(e);
                        break;
                    case 39: // right arrow
                        this.nextMedia(e);
                        break;
                    default:
                        break;
                }
            });
        }

        unbindKeys() {
            angular.element(window).off('keyup.zoom');
        }

        zoom(element, media, reset) {
            this.currentIndex = media.index;
            this.totalMedia = this.allMedia.length;

            if (!this.zoomed || reset) {
                this.zoomed = true;

                let clone = null;
                if (this.animationsEnabled) {
                    const styles = this.getElementPosition(element);
                    clone = angular.copy(element);
                    this.content.append(clone);
                    this.wrapper.css(styles).data('originalPosition', styles);
                }

                this.wrapper.addClass('in');
                if (this.animationsEnabled) {
                    this.wrapper.addClass('animating');
                }

                requestAnimationFrame(() => {
                    this.wrapper.css({
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%'
                    });

                    this.buildCarousel();

                    this.wrapper.removeClass('animating');
                    this.$timeout(
                        () => {
                            if (this.slick) {
                                this.slick.setDimensions();
                            }

                            clone && clone.remove();

                            this.wrapper.addClass('completed');
                        },
                        this.animationsEnabled ? 250 : 1
                    );
                });
            }

            if (!reset) {
                this.bindKeys();
                // this.$scope.$apply();
            }
        }

        getElementPosition(element) {
            const offset = element.offset();
            return {
                top: offset.top,
                left: offset.left,
                height: element.outerHeight(true),
                width: element.outerWidth(true)
            };
        }

        buildCarousel(elements) {
            if (!this.carousel || (this.carousel && !elements)) {
                this.offset =
                    this.currentIndex < this.offset
                        ? this.currentIndex
                        : this.SLIDES_NUM / 2;

                this.calculateCarouselMedia();

                this.carousel = this.$compile(this.carouselTemplate)(
                    this.$scope
                );

                this.carousel
                    .on('init', (e, slick) => {
                        this.slick = slick;
                        this.prevSlide = this.offset;
                    })
                    .on('afterChange', (event, slick, currentSlide) => {
                        if (this.animationsEnabled) {
                            this.lastElement = angular.element(
                                '.sections .medium[data-index="' +
                                    this.currentIndex +
                                    '"]'
                            );
                            this.wrapper.data(
                                'originalPosition',
                                this.getElementPosition(this.lastElement)
                            );
                        }

                        if (this.zoomed && currentSlide !== this.prevSlide) {
                            const l = this.allMedia.length;
                            const k = this.prevSlide <= currentSlide ? 1 : -1;
                            this.currentIndex += k;
                            this.calculateCarouselMedia();
                            const i =
                                k > 0 ? this.lastIndex - 1 : this.firstIndex;

                            if (i <= l) {
                                const m = this.allMedia[i];
                                if (
                                    m &&
                                    $('.media-type[data-mid="' + m.id + '"]')
                                        .length === 0
                                ) {
                                    const e = this.buildCarouselElement(m);
                                    this.carousel.slick(
                                        'slickAdd',
                                        e,
                                        null,
                                        k < 0
                                    );

                                    if (k < 0) {
                                        slick.currentSlide += 1;
                                        currentSlide++;
                                    }
                                }
                            }

                            if (this.currentIndex < 0) {
                                this.currentIndex = 0;
                            }

                            if (this.currentIndex >= l) {
                                this.currentIndex = this.allMedia.length - 1;
                            }

                            this.prevSlide = currentSlide;
                        }

                        this.$scope.$apply();
                        this.$scope.$broadcast('media:slide-changed');
                    });

                this.content.empty().append(this.carousel);
            }
        }

        buildCarouselElement(medium) {
            const template = document.createElement(medium.type + '-medium');
            const attrs = {
                media: 'medium',
                class: 'media-type',
                zoomed: true,
                'data-mid': medium.id
            };
            each(attrs, (value, key) => template.setAttribute(key, value));
            this.$scope.medium = medium;
            let media = $('<div class="item">')
                .append('<div class="medium">')
                .append(this.$compile(template)(this.$scope));
            return media;
        }

        calculateCarouselMedia() {
            this.firstIndex = this.currentIndex - this.offset;
            if (this.firstIndex < 0) {
                this.firstIndex = 0;
            }

            this.lastIndex = this.firstIndex + this.SLIDES_NUM;
            this.carouselMedia = this.allMedia.slice(
                this.firstIndex,
                this.lastIndex
            );
        }

        toggleFullscreen() {
            if (this.inFullscreen) {
                this.exitFS();
            } else {
                this.enterFS();
            }
        }

        enterFS() {
            if (this.hasFullscreen) {
                this.fullScreen.call(this.fullscreenElement[0]);
                this.$scope.$broadcast(
                    'media:fullscreen:enter',
                    this.currentIndex
                );
            }
        }

        exitFS() {
            this.exitFullscreen && this.exitFullscreen.call(document);
            this.$scope.$broadcast('media:fullscreen:exit', this.currentIndex);
        }

        close() {
            this.unbindKeys();
            this.exitFS();
            this.inFullscreen = false;

            if (this.wrapper.hasClass('animating')) {
                return;
            }
            this.wrapper.addClass('closing').removeClass('completed');

            let clone;
            if (this.animationsEnabled) {
                clone = angular.copy(angular.element('.slick-current .medium'));
                this.content.prepend(clone);

                const styles = this.wrapper.data('originalPosition');
                if (styles) {
                    this.wrapper.css(styles);
                }
            }

            this.$timeout(
                () => {
                    this.wrapper.removeClass('in closing');
                    this.zoomed = false;
                    clone && clone.remove();
                },
                this.animationsEnabled ? 200 : 1
            );

            this.detailed = false;
            this.offset = this.SLIDES_NUM / 2;
            this.$scope.$broadcast('media:close');
        }

        toggleDetails() {
            this.detailed = !this.detailed;
        }

        nextMedia() {
            if (this.slick) {
                this.slick.next();
            }
        }

        prevMedia() {
            if (this.slick) {
                this.slick.prev();
            }
        }
    }
};
