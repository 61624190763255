function OnRepeatDoneDirective() {
    return {
        restrict: 'A',
        priority: 2,
        link(scope, elem, attrs) {
            if (scope.$last) {
                scope.$parent.$$repeatDone = true;
                scope.$eval(attrs.onRepeatDone);
            }
        }
    };
}

function EmitPrependedItemsDirective() {
    return {
        restrict: 'A',
        priority: 1,
        link(scope, elem) {
            if (scope.$parent.$$repeatDone && scope.$first) {
                scope.$prepended = true;
                scope.$emit('itemPrepended', elem);
            }
        }
    };
}

function EmitAppendedItemsDirective() {
    return {
        restrict: 'A',
        priority: 1,
        link(scope, elem) {
            if (scope.$parent.$$repeatDone && scope.$last) {
                scope.$appended = true;
                scope.$emit('itemAppended', elem);
            }
        }
    };
}

angular
    .module('maestro.directives')
    .directive('onRepeatDone', [ OnRepeatDoneDirective ])
    .directive('emitPrependedItems', [ EmitPrependedItemsDirective ])
    .directive('emitAppendedItems', [ EmitAppendedItemsDirective ]);
