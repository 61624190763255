import { extend } from 'lodash';
import { CommonFeedBlock } from '../common-feed-block';

export const FeedTopicBlockComponent = {
    template: require('./feed-topic-block.jade'),
    bindings: {
        config: '<',
        onUpdate: '&'
    },
    controller: class FeedTopicBlockComponent extends CommonFeedBlock {
        /* @ngInject */ constructor(
            NAV_TEMPLATE_API_PREFIX,
            $timeout,
            databaseService,
            navService,
            EventEmitter,
            $filter,
            $sheet,
            $i18n,
            THEME
        ) {
            super(NAV_TEMPLATE_API_PREFIX, $filter, $sheet, $i18n, THEME);
            this.$timeout = $timeout;
            this.databaseService = databaseService;
            this.navService = navService;
            this.runScript = navService.runScript.bind(navService);
            this.EventEmitter = EventEmitter;
        }

        onToolbarActionClick(toolbarAction) {
            const { action, loading } = toolbarAction;

            if (loading || !action || !action.target) {
                return;
            }

            toolbarAction.loading = true;

            this.databaseService
                .runAppScript(action.target, action.params)
                .then(({ data: { response } }) =>
                    extend(toolbarAction, response)
                )
                .finally(() => {
                    toolbarAction.loading = false;
                });
        }
    }
};
