import { BaseService } from './baseService';

// Utils
import { get, isEmpty } from 'lodash';

const RETRY_START_WATCHING_TIMEOUT = 1000;
const INITIAL_STATE_CACHE_EXPIRE = 10 * 1000;

/**
 * Mapping of available stream provider keys
 * @const {Object} LIVE_STREAM_CONNECTIVITY_EXPORT_ENDPOINT
 * @private
 */
const PROVIDER_TYPES = {
    RTMPS: 'RTMPS',
    THIRD_PARTY: '3rdparty',
    STUDIO: 'studio',
    STREAMLESS: 'none'
};

const SESSION_TYPES = {
    IN_PERSON: 'in_person',
    REMOTE: 'remote'
};

class LiveStreamService extends BaseService {

    /** @ngInject */
    constructor(EID, $eventBus, $http, $timeout, cdnCookiesProvider) {
        super($http);

        this.eventId = EID;
        this.$eventBus = $eventBus;
        this.$http = $http;
        this.$timeout = $timeout;
        this.cdnCookiesProvider = cdnCookiesProvider;
    }

    /**
     * Launches the PIP with the live stream
     *
     * @param {object} params the starting parameters
     * @param {string} params.id the id of the stream to start
     * @param {boolean} [params.fullscreen] whether the live stream should occupy the whole screen or not
     * @param {boolean} [params.startInPip] whether the live stream should be automatically started in the #pip-wrapper element
     */
    async start({ id, fullscreen, startInPip = true }) {
        const liveStreamDoc = await this.loadConfiguration(id);
        const config = this.getConfigFromLiveStreamDoc(liveStreamDoc);
        config.fullscreen = fullscreen;
        if (startInPip) {
            console.info('[LiveStreamService] Starting live stream', id);
            this.$eventBus.emit('pip:start', config);
        }
        return config;
    }

    getConfigFromLiveStreamDoc(liveStream) {
        const config = {
            ...liveStream,
            liveStreamId: liveStream.id,
            isStreamless: this.isStreamless(liveStream),
            isThirdParty: this.isThirdParty(liveStream),
            url: (liveStream.url || '').toString()
        };
        delete config.id;
        delete config.who_is_watching; // deprecated
        delete config.clapping; // deprecated
        return config;
    }

    /**
     * Loads the live stream configuration
     *
     * @param {string} liveStreamId the ID of the livestream to load data for
     *
     * @returns {Promise<Object>} the server response containing the configuration
     */
    async loadConfiguration(liveStreamId) {
        const { data: liveStreamDoc } = await this.getWithRetries(`/api/v1/eid/${this.eventId}/live-stream/${liveStreamId}`);
        return liveStreamDoc;
    }

    /**
     * @param {Object} liveStream
     * @return {Boolean|boolean}
     */
    needsAuthCookies(liveStream) {
        return this.isRtmps(liveStream) || this.isStudio(liveStream);
    }

    /**
     * Tells if a given object qualifies as a live stream config
     * @param {Object} object
     * @return {boolean}
     */
    isLiveStream(object) {
        return !isEmpty(object) && object.hasOwnProperty('is_live');
    }

    /**
     * Tells if a live stream is using Studio
     *
     * @param {object} liveStream
     *
     * @returns {boolean}
     */
    isStudio(liveStream) {
        if (!this.isLiveStream(liveStream)) {
            return false;
        }

        return get(liveStream, 'provider', '') === PROVIDER_TYPES.STUDIO
            || get(liveStream, 'use_studio', false); // backward compatibility
    }

    /**
     * Tells if a live stream is using a third party stream
     *
     * @param {Object} liveStream
     *
     * @returns {Boolean}
     */
    isThirdParty(liveStream) {
        if (!this.isLiveStream(liveStream)) {
            return false;
        }

        return get(liveStream, 'provider', '') === PROVIDER_TYPES.THIRD_PARTY
            || get(liveStream, 'is_third_party', false); // backward compatibility
    }

    /**
     * @param {Object} liveStream
     *
     * @returns {Boolean} returns true if session type is remote
     */
    isRemote(liveStream) {
        return get(liveStream, 'resolved_session_type', 'remote') === SESSION_TYPES.REMOTE;
    }

    /**
     * @param {Object} liveStream
     *
     * @returns {Boolean} returns true if session type is in_person
     */
    isInPerson(liveStream) {
        return get(liveStream, 'resolved_session_type', 'remote') === SESSION_TYPES.IN_PERSON;
    }

    /**
     * Tells if a live stream is using an external RTMPS stream
     *
     * @param {Object} liveStream
     *
     * @returns {Boolean}
     */
    isRtmps(liveStream) {
        if (!this.isLiveStream(liveStream)) {
            return false;
        }

        return get(liveStream, 'provider', '') === PROVIDER_TYPES.RTMPS
            || (!this.isStreamless(liveStream) && !this.isStudio(liveStream) && !this.isThirdParty(liveStream)); // backward compatibility
    }

    /**
     * Tells if a live stream is streamLess
     *
     * @param {Object} liveStream
     *
     * @returns {Boolean}
     */
    isStreamless(liveStream) {
        if (!this.isLiveStream(liveStream)) {
            return false;
        }

        return get(liveStream, 'provider', '') === PROVIDER_TYPES.STREAMLESS;
    }

    /**
     * Get the initial qna state
     *
     * @param {string} liveStreamId
     *
     * @returns {Promise<Object>}
     */
    getInitialState(liveStreamId) {
        return this.getCachedOrMakeRequest(`/api/v1/eid/${this.eventId}/live-stream/${liveStreamId}/initial-state`, INITIAL_STATE_CACHE_EXPIRE);
    }

    /**
     * Determines if upvoting is allowed in the liveStream.
     * @param {Object} config
     * @return {Boolean}
     */
    isUpvotingAllowed(config = {}) {
        return config.qna_upvoting_enabled && !this.isVod(config) && !this.isFinished(config);
    }

    /**
     * whether the config is from a livestream in vod state
     * @param {Object} config
     * @return {Boolean}
     */
    isVod(config = {}) {
        return !!config.liveStreamId && !!config.is_published && !!config.is_live;
    }

    /**
     * whether the config is from a livestream that is live
     * @param {Object} config
     * @return {Boolean}
     */
    isLive(config = {}) {
        return !!config.liveStreamId && !!config.is_live;
    }

    /**
     * whether the config is from a livestream that is finished
     * @param config
     */
    isFinished(config = {}) {
        return !!config.is_finished;
    }

    /**
     * Sends the `start-watching` event to the server
     */
    sendStartWatching(livestreamId, retry = 1) {
        this.$timeout.cancel(this.startWatchingTimeout);

        if (!livestreamId) {
            return;
        }

        const url = `/api/v1/eid/${this.eventId}/live-stream/${livestreamId}/start-watching`;

        this.$http.post(url)
            .catch(() => {
                if (retry < 5) {
                    retry++;
                    this.startWatchingTimeout = this.$timeout(() => this.sendStartWatching(livestreamId, retry), (retry * RETRY_START_WATCHING_TIMEOUT));
                }
            });
    }

    /**
     * Sends the `stop-watching` event to the server
     */
    sendStopWatching(livestreamId) {
        this.$timeout.cancel(this.startWatchingTimeout);

        if (!livestreamId) {
            return;
        }

        const url = `/api/v1/eid/${this.eventId}/live-stream/${livestreamId}/stop-watching`;

        navigator.sendBeacon(url);
    }

    /**
     * get request with retries
     * @param url
     * @param maxRetries
     * @return {*}
     */
    getWithRetries(url, maxRetries = 3) {
        const makeRequest = (retries = maxRetries) => this.$http.get(url)
            .catch(err => {
                console.error(`getting ${url} failed, retrying`, err);
                if (retries === 1) {
                    throw err;
                }
                return this.$timeout(() => makeRequest(retries - 1), 5000);
            });
        return makeRequest();
    }

    async getSlideImageUrl(slideshowDocId, slideIndex) {
        const baseSlideCdnUrl = await this.cdnCookiesProvider.getCookiesForDirectory(slideshowDocId);
        return `${baseSlideCdnUrl}/desktop/image${slideIndex}.jpeg`;
    }
}

angular
    .module('maestro.services')
    .service('liveStreamService', LiveStreamService);
