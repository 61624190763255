import { OnboardingComponent } from './onboarding.component';
import { PublisherComponent } from './publisher.component';
import { SubscriberComponent } from './subscriber.component';
import { ParticipantsListComponent } from './participants-list.component';
import { VideoCallComponent } from './video-call.component';
import { JoinCallButtonComponent } from './join-call-button.component';
import { ModeratorOptions } from './moderator-options.component';
import { VideoCallModalComponent } from './video-call-modal.component';

export const VideoCallModule = angular
    .module('maestro.components.common.video-call', [])
    .component('onboarding', OnboardingComponent)
    .component('publisher', PublisherComponent)
    .component('subscriber', SubscriberComponent)
    .component('participantsList', ParticipantsListComponent)
    .component('videoCall', VideoCallComponent)
    .component('joinCallButton', JoinCallButtonComponent)
    .component('videoCallModal', VideoCallModalComponent)
    .component('moderatorOptions', ModeratorOptions).name;
