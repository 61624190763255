export default class NavAppscript {
    /* @ngInject */
    constructor(databaseService) {
        this.databaseService = databaseService;
    }

    // target was introduced in feed blocks
    runScript({ path, target, params }) {
        return this.databaseService.runAppScript(path || target, params);
    }
}
