/**
 * Text is an element that just display guess what, text.
 *
 * The properties that can be set on a Text element are the following
 *
 * ---------------------------------------------------------------------------------------
 * Parameter   Type     Default     Min    Max     Encoding              Mandatory   Notes
 * ---------------------------------------------------------------------------------------
 * text        String   -           -      -       -                     yes
 * style       String   normal      -      -       normal, bold,         no
 *                                                 italic, bold-italic
 * alignment   String   left        -      -       left, center,         no
 *                                                 right
 * size        int      14pt        > 0    -       -   no
 * color       String   #808080     -      -       #RRGGBB or            no
 *                                                 #AARRGGBB
 * margin      Json                                                      no          (1)
 * `- left     int      8           >= 0   -       points/dp             no
 * `- top      int      8           >= 0   -       points/dp             no
 * `- right    int      8           >= 0   -       points/dp             no
 * `- bottom   int      8           >= 0   -       points/dp             no
 * max_lines   int      unlimited   >= 0   100     -                     no
 * ---------------------------------------------------------------------------------------
 *
 * (1) Contains margin properties (left,top, right and bottom),
 *     a margin value missing will be set to a default value.
 *
 */

export const TextComponent = {
    bindings: {
        row: '<'
    },
    replace: true,
    template: require('./text.jade'),
    controller: class TextComponent {
        /* @ngInject */
        constructor(navService, styleParser) {
            this.navService = navService;
            this.parser = styleParser;
        }

        $onInit() {
            this.config = this.row.config;
            // console.debug('[TextComponent] init', this.config);

            const texts = this.parser.parseTextsStyles(this.config);
            const margins = this.parser.parseMargins(this.config);
            const boxing = this.parser.parseBoxing(this.config);

            this.style = angular.merge(texts, margins, boxing);
        }
    }
};
