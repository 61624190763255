import { each, get, isFunction } from 'lodash';
import { getItemBadge } from '../../../utils/menu';

export const AppNavBarComponent = {
    template: require('./app-nav-bar.jade'),
    controller: class AppNavBarComponent {
        /* @ngInject */
        constructor(
            THEME,
            NAV_BAR_CONFIG,
            EVENT_NAVS,
            $device,
            $rootScope,
            $eventBus,
            navService,
            databaseService
        ) {
            this.theme = THEME;
            this.nav = NAV_BAR_CONFIG;
            this.$device = $device;
            this.$rootScope = $rootScope;
            this.$eventBus = $eventBus;
            this.navService = navService;
            this.databaseService = databaseService;

            this.listeners = [];

            this.searchNav = EVENT_NAVS['nav_app_search'];
        }

        $onInit() {
            if (!this.nav || !this.nav.enabled) return;

            const toolbarTheme = this.theme.toolbar || {};

            this.notificationBadgeStyle = {
                color: toolbarTheme.background_color,
                background: toolbarTheme.font_color,
                borderColor: toolbarTheme.background_color
            };

            if (this.theme.version === 3 && this.theme.activeLinkStyle === 'underline') {
                this.notificationBadgeStyle.background = this.theme.backgroundColor3;
            }

            const spotmanListener = this.$eventBus.on('spotman:click', (row) => {
                let id = row._id;

                if (id.startsWith('back_to_')) {
                    id = id.replace(/^back_to_/, '');
                }

                this.activeItemId = id;
            });

            this.listeners.push(spotmanListener);

            this.inflate();
        }

        $onDestroy() {
            for (const unsubscribe of this.listeners) {
                if (isFunction(unsubscribe)) {
                    unsubscribe();
                }
            }
        }

        inflate() {
            if (this.inflated) return;

            this.inflated = true;
            this.navService.inflateNav(this.nav).then(
                ({ elements }) => {
                    this.items = elements;
                    this.initBadges();
                    this.initNotificationListeners();
                },
                () => (this.inflated = false)
            );
        }

        // notification badges

        initBadges() {
            this.badges = {};
            each(this.items, item => this.getItemBadge(item));
        }

        initNotificationListeners() {
            const subscribeToRowNotifications = (notifications, action) =>
                this.navService.subscribeToEvents(
                    this.navService.flattenNotificationMap(notifications),
                    action
                );

            each(this.items, item => {
                if (!item.badge || !item.badge.ds || !item.badge.ds.reload_on) {
                    return;
                }

                subscribeToRowNotifications(
                    item.badge.ds.reload_on,
                    this.getItemBadge.bind(this, item)
                );
            });

            const notificationInAppListener = this.$eventBus.on('notification:in-app', () => {
                for (const element of this.items) {
                    this.getItemBadge(element);
                }
            });

            this.listeners.push(notificationInAppListener);
        }

        getItemBadge(item) {
            getItemBadge(item, this.databaseService, (i, count) => {
                item.badge.value = count;

                if (this.badges[item._id] !== count) {

                    if (this.badges[item._id] || count > 0) {
                        this.$rootScope.$emit('badges:updated', {
                            _id: item._id,
                            value: count
                        });
                    }

                    this.badges[item._id] = count;
                }
            });
        }

        // action handlers

        activate(item) {
            this.activeItemId = item._id;
            this.navService.performActions(item.actions, 'app-nav-bar');
        }

        iconColor(item) {
            // TODO: support v3 theme
            const colorizeIcons = Boolean(get(this, 'theme.spotman.view.icon_color'));
            if (!colorizeIcons) {
                return;
            }

            const { background_color, font_color } = this.theme.toolbar;
            return this.activeItemId === item._id ? background_color : font_color;
        }
    }
};
