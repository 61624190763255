import { map, each, filter, some, isFunction } from 'lodash';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

/*

Filters:
[
    {
        "mode": "list",
        "field": "_status",
        "label": "Registration",
        "options":
            [
                {
                "value": "registered",
                "label": "Show only sessions I'm registered to"
                }
            ]
        }
    }
]

Selection:
[
    {
        "field": "tags",
        "value": ["keynote"] // <- this should always be an array
    }
]

*/
export const FilteringComponent = {
    template: require('./filtering.pug'),
    bindings: {
        filters: '=',
        selection: '<',
        onAccept: '&',
        onApplyFilters: '&',
        searchQuery: '<'
    },
    controller: class FilteringComponent {
        /* @ngInject */
        constructor($i18n, THEME) {
            this.selectedOptionStyle = {
                'color': THEME.foregroundColor1 || THEME.toolbar.font_color || 'white',
                'background-color': THEME.toolbar.background_color
            };
            this.moreLabel = i18nWithFallback(
                $i18n,
                'agenda_nav.filters.show_all',
                'Show all...'
            );
            this.clearLabel = i18nWithFallback(
                $i18n,
                'general.clear',
                'Clear'
            );
            this.noMatchesFoundLabel = i18nWithFallback(
                $i18n,
                'general.no_matches_found',
                'No matches found'
            );

            this.applied = false;
        }

        $onInit() {
            this.filters = this.filters.map(f => {
                if (f.mode === 'tags') {
                    f.options = f.options.map(o => {
                        o.notSelectedLabelStyle = { color: o.background_color };
                        o.selectedLabelStyle = {
                            color: o.background_color,
                            'background-color': o.background_color
                        };
                        o.selectedFontStyle = { color: o.font_color };

                        return o;
                    });

                    f.shownOptions = f.shownOptions || f.options.slice(0, 4);
                }

                return f;
            });

            if (this.selection?.length) {
                this.applied = true;
            }

            console.log('[FilteringComponent] Init', this.filters);
        }

        $onChanges(changes) {
            if (changes.filters && changes.filters.currentValue) {
                this.filters = angular.copy(this.filters);
            }

            if (this.filters && this.selection) {
                console.log('[FilteringComponent] $onChange', this.filters, this.selection);

                each(this.filters, _filter =>
                    each(_filter.options, option => {
                        option.selected = some(
                            this.selection,
                            selectedItem =>
                                selectedItem.field === _filter.field &&
                                selectedItem.value.indexOf(option.value) !== -1
                        );
                    })
                );
            }
        }

        $onDestroy() {
            if (!this.applied && isFunction(this.onApplyFilters)) {
                this.onApplyFilters({ value: 'closed' });
            }
        }

        showClearFilters() {
            return (this.searchQuery && this.searchQuery.length) || this.filters
                .map(filter => filter.options)
                .flat()
                .filter(filter => filter.selected).length;
        }

        clearFilters(specificFilter) {
            each(this.filters, _filter => {
                if (specificFilter && _filter.field !== specificFilter.field) return;
                each(_filter.options, option => {
                    option.selected = false;
                });
                if (_filter.mode === 'select') {
                    _filter.selectedOptionsCount = 0;
                }
            });

            return this.applyFilters();
        }

        applyFilters() {
            let selection = {};

            each(this.filters, _filter => {
                const field = _filter.field;
                const selectedValues = filter(
                    _filter.options,
                    option => option.selected
                ).map(option => option.value);

                if (selectedValues && selectedValues.length) {
                    selection[field] = (selection[field] || []).concat(
                        selectedValues
                    );
                }
            });

            selection = map(selection, (value, field) => ({ field, value }));

            console.log('[FilteringComponent] applyFilters', selection);

            if (isFunction(this.onApplyFilters)) {
                this.onApplyFilters({ value: selection.length ? selection : 'cleared' });
                this.applied = true;
            }

            this.onAccept({ value: selection });
        }

        toggleFilterOptions(filter = {}) {
            each(this.filters, _filter => {
                _filter.showOptions = _filter.field === filter.field ? !filter.showOptions : false;
            });
        }

        showAllOptions(filter, $event) {
            $event.stopPropagation();
            filter.shownOptions = filter.options;
        }

        selectOption(filter, option) {
            option.selected = true;
            option.order = (filter.lastOptionIndex || 0) + 1;
            filter.lastOptionIndex = option.order;
            filter.selectedOptionsCount++;
            this.toggleFilterOptions(filter);
            this.applyFilters();
        }

        removeOption(filter, option) {
            option.selected = false;
            filter.selectedOptionsCount--;
            filter.lastOptionIndex = option.order--;
            this.applyFilters();
        }
    }
};
