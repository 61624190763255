import { each } from 'lodash';

export default function mixin(target, source) {
    let targetProto = target.prototype;
    let sourceProto = source.prototype;
    let sourceProps = Object.getOwnPropertyNames(sourceProto);

    // copy all properties from source to target except for the constructor method
    each(sourceProps, name => {
        if (name !== 'constructor') {
            Object.defineProperty(
                targetProto,
                name,
                Object.getOwnPropertyDescriptor(sourceProto, name)
            );
        }
    });

    // if source depended on Angular DI, add those dependencies to target
    if (source.$inject && source.$inject.length) {
        target.$inject = target.$inject || [];
        each(source.$inject, dep => {
            if (target.$inject.indexOf(dep) === -1) {
                target.$inject.push(dep);
            }
        });
    }

    return target;
}
