import { extend } from 'lodash';

export const FooterButtonsComponent = {
    template: require('./footer-buttons.jade'),
    bindings: {
        buttons: '<'
    },
    controller: class FooterButtonsComponent {
        /* @ngInject */ constructor(databaseService) {
            this.databaseService = databaseService;
        }

        onActionClick(button) {
            if (button.loading) return;

            button.loading = true;

            const action = button.action;
            if (!action || !action.target) {
                button.loading = false;
                return;
            }

            this.databaseService
                .runAppScript(action.target, action.params)
                .then(({ data: { response } }) => extend(button, response))
                .finally(() => (button.loading = false));
        }
    }
};
