import {
    clone,
    extend,
    filter,
    findIndex,
    isArray,
    isFunction,
    isEmpty,
    merge,
    uniq,
    get
} from 'lodash';

import { FilteringController } from '../../common/filtering';

const NEW_POST_BLOCK = {
    id: 'feed-new-post-block',
    block_type: 'block_feed_new_post',
    data: {
        action: {
            action_key: 'compose-post',
            target: 'actions/compose-post'
        }
    }
};
const configBlockId = 'block-list-config';

export const BlocksListNavComponent = {
    template: require('./blocks-list-nav.jade'),
    bindings: {
        config: '<'
    },
    controller: class BlocksListNavComponent extends FilteringController {
        /* @ngInject */ constructor(
            NAV_TEMPLATES,
            THEME,
            EID,
            navService,
            progressBarService,
            paginatorFactory,
            databaseService,
            $i18n,
            $popover,
            dataSourceEvaluatorService,
            $q,
            $scope,
            $element,
            $interval,
            $window,
            $http
        ) {
            super(THEME, EID, $q, $popover, $i18n, $http, dataSourceEvaluatorService);

            this.navTemplates = NAV_TEMPLATES;
            this.theme = THEME;
            this.$window = $window;

            this.getPaginator = ({ limit } = {}) =>
                paginatorFactory({
                    getNextStartKey: ({ next_page }) => next_page,
                    buildQueryParams: nextPage =>
                        nextPage
                            ? { page: extend(nextPage, { limit }) }
                            : { page: { limit } }
                });
            this.paginator = this.getPaginator();

            this.databaseService = databaseService;
            this.progressBarService = progressBarService;
            this.navService = navService;
            this.$i18n = $i18n;
            this.$popover = $popover;

            this.dse = dataSourceEvaluatorService;
            this.$q = $q;

            this.selectedFilters = [];

            this.$scope = $scope;
            this.element = $element;
            this.hasNewContentButton = false;
            this.showNewContentButton = false;
            this.loading = false;
            this.loadingNextPageFromBottom = false;
            this.$interval = $interval;

            this.EMPTY_STATES_BLOCKS = [ 'no-comments', 'no-matches' ];
        }

        $onInit() {
            console.log('[BlocksListNavComponent] init', this.config);
            this.loading = true;

            const config = this.config;
            if (this.config.theme) {
                this.theme = extend(this.theme, this.config.theme);
            }

            if (this.config.list_padding) {
                this.theme.view = this.theme.view || {};
                this.theme.view.paddings = this.config.list_padding;
            }

            const navService = this.navService;

            this.searchable = config.searchable;

            this.blocks = [];

            const reloadOn = get(this.config, 'ds.reload_on');

            if (reloadOn) {
                this.unsubFromNotifications = navService.subscribeToEvents(
                    navService.flattenNotificationMap(reloadOn),
                    (event, data) => {
                        switch (event.name) {
                            case 'blocks:filter':
                                this.filter(data);
                                break;
                            case 'blocks:insert':
                                this.insert(data);
                                break;
                            case 'blocks:remove':
                                this.remove(data);
                                break;
                            case 'blocks:update':
                                data.blocks.forEach(b => {
                                    this.updateBlock({
                                        id: b.id,
                                        updates: b
                                    });
                                });
                                break;
                            case 'set-loading':
                                if (data.value) {
                                    this.progressBarService.startTask();
                                } else {
                                    this.progressBarService.finishTask();
                                }
                                break;
                            case 'reload-with':
                                this.reloadWithParams(data);
                                break;
                            default:
                                this.reload(true);
                                break;
                        }
                    }
                );

                const fpTypes = reloadOn.fp_type || reloadOn.fp_types || [];

                if (fpTypes.some(t => t.match(/^feed/))) {
                    console.info(
                        '[BlocksListNavComponent] setting reload interval'
                    );
                    this.reloadInterval = this.$interval(
                        () => this.checkForNewBlocks(),
                        60 * 1000
                    ); // 1min timer

                    this.hasNewContentButton = true;
                }
            }

            this.blockStyles = {};
            if (this.config.list_divider) {
                const d = this.config.list_divider;
                this.blockStyles = {
                    'border-bottom': `${d.size}px solid ${d.color}`
                };
            }

            if (this.searchQuery) {
                this.performSearch();
            } else {
                this.reload(false).finally(() => {
                    this.loading = false;
                });
            }

            this.navBarActions = this.config.nav_bar_actions || [];
            this.processNavBarActions();

            this.$scope.$on('bottom-reached', () => {
                if (this.loadingNextPageFromBottom) {
                    return;
                }
                this.loadingNextPageFromBottom = true;
                this.nextPage().finally(() => {
                    this.loadingNextPageFromBottom = false;
                });
            });
        }

        $onDestroy() {
            super.$onDestroy();

            if (isFunction(this.unsubFromNotifications)) {
                this.unsubFromNotifications();
            }

            if (this.reloadInterval) {
                this.$interval.cancel(this.reloadInterval);
            }
        }

        $onChanges(changes) {
            if (
                'searchQuery' in changes &&
                !changes.searchQuery.isFirstChange()
            ) {
                this.performSearch();
            }
        }

        // pagination

        reload(clearSearch) {
            console.info('[BlocksListNavComponent] reloading...');
            if (clearSearch) {
                this.clearSearch();
            }
            this.paginator.reset();
            return this.nextPage({ reset: true });
        }

        reloadWithParams({ target, params }) {
            console.info(`[BlocksListNavComponent] reloading nav "${target}" with params...`, params);
            this.navService.presentNav({
                _id: this.config._id,
                ds: {
                    source: {
                        params: {
                            reload_with_params: params
                        }
                    }
                }
            });
        }

        showNewContent() {
            this.showNewContentButton = false;
            window.scrollTo(0, 0);
            this.reload(false);
        }

        nextPage({ reset } = { reset: false }) {
            if (this.config.results) {
                console.info('[BlocksListNavComponent] Injecting config elements');
                this.blocks = this.config.results;
                return Promise.resolve();
            }

            console.info('[BlocksListNavComponent] loading next page...');
            if (!this.paginator.hasMorePages()) {
                this.progressBarService.finishTask();
                return Promise.resolve();
            }

            if (reset) {
                const params = get(this, 'config.ds.source.params', {});

                if (!params.page) {
                    // This parameter is not present when deeplinking, leading
                    // to unwanted outcome (wrong or not expected block_type).
                    params.page = { first: true };
                }

                delete params.search;
            }

            this.progressBarService.startTask();
            return this.navService
                .inflatePaginatedNav(
                    this.config,
                    {
                        cache: false,
                        filters: this.selectedFilters.length
                            ? this.selectedFilters
                            : undefined
                    },
                    this.paginator
                )
                .then(({ paginatedElements }) => {
                    // We want to static blocks only on reset.
                    return reset
                        ? [].concat(paginatedElements)
                        : this.blocks.concat(paginatedElements);
                })
                .then(blocks => (this.blocks = uniq(blocks, ({ id }) => id)))
                .then(this.processConfigBlocks.bind(this))
                .finally(() => {
                    if (get(this, 'config.ds.source.params.page')) {
                        delete this.config.ds.source.params.page;
                    }
                    this.progressBarService.finishTask();
                    // This will make the eventual lazy images to load
                    this.element.find('.lazy-asset-grid').trigger('ready');
                });
        }

        processConfigBlocks() {
            const configBlock = this.blocks.find(block => block.id === configBlockId);
            this.blocks = this.blocks.filter(block => block.id !== configBlockId);

            if (configBlock) {
                // extend config with incoming config
                merge(this.config, configBlock.data);
                this.navBarActions = this.config.nav_bar_actions;
            }

            // update action blocks
            this.processNavBarActions();
        }

        processNavBarActions() {
            this.config.nav_bar_actions = filter(this.navBarActions, action => {
                if (action.action_key === 'compose-post') {
                    NEW_POST_BLOCK.data.action.params = action.params;
                    // inject new post block
                    if ((this.blocks[0] || {}).id !== NEW_POST_BLOCK.id) {
                        this.blocks.unshift(NEW_POST_BLOCK);
                    }
                    return false;
                }
                return true;
            });
        }

        checkForNewBlocks() {
            const lastElementPagination = this.getPaginator({
                // 5 latest post, so if you posted something, you still see other people posts
                limit: 5
            });
            this.navService
                .inflatePaginatedNav(
                    this.config,
                    {
                        cache: false,
                        filters: this.selectedFilters.length
                            ? this.selectedFilters
                            : undefined
                    },
                    lastElementPagination
                )
                .then(({ paginatedElements }) => {
                    const existingIds = this.blocks.map(block => block.id);
                    if (
                        !paginatedElements ||
                        paginatedElements.every(block => {
                            if (block.id === configBlockId) return true;
                            return existingIds.includes(block.id);
                        })
                    ) {
                        return;
                    }
                    if (this.element.closest('main.scrolled').length === 0) {
                        this.showNewContentButton = false;
                        this.reload(false);
                    } else {
                        this.showNewContentButton = true;
                    }
                });
        }

        // blocks

        templateUrlForBlock({ block_type }) {
            return this.navTemplates[block_type];
        }

        classNamesForBlock({ block_type: type, layout = {} }) {
            return [
                type,
                `block-width-${layout.width}`,
                `block-height-${layout.height}`,
                'animated'
            ].join(' ');
        }

        updateBlock({ id, updates }) {
            if (!id || !updates) return;

            const blockIndex = findIndex(
                this.blocks,
                ({ id: _id }) => id === _id
            );
            if (blockIndex === -1) return;

            console.log('[BlocksListNavComponent] updateBlock', id, updates);
            if (updates.content && updates.content.inline_blocks) {
                const block = clone(this.blocks[blockIndex]);
                block.data.content.inline_blocks =
                    updates.content.inline_blocks;
                this.blocks[blockIndex] = block;
            } else {
                const block = this.blocks[blockIndex];
                merge(block.data, updates);
            }

            this.originalBlocks = this.blocks;
        }

        remove({ target }) {
            this.filter({
                remove: isArray(target) ? target : [ target ]
            });
        }

        filter({ remove, keep }) {
            const pool = remove || keep;
            if (pool) {
                const direction = keep !== undefined;
                this.blocks = filter(this.blocks, block => {
                    return direction !== (pool.indexOf(block.id) === -1);
                });
            }
        }

        insert({ blocks, block, add_to }) {
            blocks = block ? [ block ] : blocks || [];
            console.info(
                '[BlocksListNavComponent] Inserting new blocks',
                blocks
            );

            if (add_to === 'bottom') {
                this.blocks = (this.blocks || []).concat(blocks);
            } else {
                this.blocks = (blocks || []).concat(this.blocks);
            }
            angular.element(this.$window).trigger('assets:load');
        }

        // backwards-compatibility
        performActions(actions) {
            return this.navService.performActions(actions, this.config.nuid);
        }

        toggleSearch() {
            this.showSearch = !this.showSearch;
            if (!this.showSearch) {
                this.clearSearch();
            }
        }

        clearSearch() {
            this.showSearch = false;

            if (this.originalBlocks) {
                this.blocks = this.originalBlocks;
            } else if (this.searchQuery && this.searchQuery.length) {
                this.searchQuery = '';
                this.search();
            }
        }

        performSearch() {
            if (this.searchQuery && this.searchQuery.length >= 3) {
                this.search();
            } else if (this.originalBlocks && this.searchQuery.length === 0) {
                console.debug('[BlocksListNavComponent] Restoring blocks');
                this.blocks = this.originalBlocks;
                this.originalBlocks = null;
            }
        }

        search() {
            if (!this.searchable) return;

            console.debug(
                '[BlocksListNavComponent] Search for:',
                this.searchQuery
            );

            if (this.config.ds.source.path) {
                const params = this.config.ds.source.params || {};

                if (isEmpty(this.searchQuery)) {
                    delete params.search;
                } else {
                    params.search = this.searchQuery;
                }

                this.databaseService
                    .runAppScript(this.config.ds.source.path, params)
                    .then(({ data }) => {
                        console.info(
                            '[BlocksListNavComponent] Filtered results:',
                            data.response
                        );
                        if (!this.originalBlocks) {
                            this.originalBlocks = this.blocks;
                        }
                        this.blocks = data.response;
                    });
            }
        }
    }
};
