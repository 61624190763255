import { each, get } from 'lodash';

export const ToggleBlockComponent = {
    template: require('./toggle-block.jade'),
    bindings: {
        parentNavId: '<',
        config: '<'
    },
    controller: class ToggleBlockComponent {
        /* @ngInject */ constructor(
            $q,
            databaseService,
            progressBarService,
        ) {
            this.$q = $q;
            this.databaseService = databaseService;
            this.progressBarService = progressBarService;

            this.disabled = true;
        }

        $onInit() {
            this.content = get(this.config, 'data.content');
            this.disabled = !(get(this.config, 'data.content.enabled'));
            this.selected = this.content.selected;
            this.loading = false;
            console.log('[ToggleBlockComponent] init', this.config);
        }

        triggerAction() {
            if (this.disabled || this.loading) return;

            this.selected = !this.selected;
            const actions = get(this.config, 'data.actions') || {};
            const actionsToExecute = [];

            each(actions, action => {
                const params = action.params || {};
                const path = action.path || action.target;
                actionsToExecute.push(this.databaseService.runAppScript(path, params));
            });

            this.loading = true;
            this.progressBarService.startTask();

            this.$q.all(actionsToExecute).finally(() => {
                this.loading = false;
                this.progressBarService.finishTask();
            });
        }
    }
};
