// Port of some nodehandlers from pkg-webapp-skeleton for performance reasons

import _ from 'lodash';
import handlebars from 'handlebars';

// WARNING: this should be kept in sync with handlers/webapp/navutils

const fixedSpotManOrder = {
    my_profile_row: 0.001,
    universal_search_row: 0.002,
    back_to_home_row: 0.003
};
const hiddenSpotmanRows = [ 'profile_separator_row' ];
const stripTrLambda = function(str) {
    if (!str) return;
    return str.replace(/\{{2,3}(\#|\/)spotme\.tr\}{2,3}/g, '');
};
const mergePlatformOverrides = function(el) {
    const overrides = (el.platform || {}).web;
    if (!overrides) {
        return el;
    }

    for (let key in overrides) {
        if (overrides.hasOwnProperty(key)) {
            const val = overrides[key];
            if (val === null) {
                delete el[key];
            } else {
                el[key] = val;
            }
        }
    }

    return el;
};
// {{couchbase_url}}/{{default_db}}/icon.spotman.notes/file.png -> icon.spotman.notes/file.png
const extractIconFromImgPath = function(path) {
    if (typeof path !== 'string') return;
    return path.slice(path.indexOf('icon.'));
};
const getSpotManItemTitle = function(item, originalMappings) {
    return (
        item.render.title ||
        stripTrLambda(item.ds.source.title) ||
        stripTrLambda(originalMappings.title) ||
        'Untitled'
    );
};
const getSpotManItemIcon = function(item, originalMappings) {
    return (
        (item.render && item.render.icon) ||
        (item.ds && item.ds.source && item.ds.source.img) ||
        extractIconFromImgPath(originalMappings.img)
    );
};
const fixNativeSpotMan = function(spotman) {
    spotman.nav_type = 'spotman';
    spotman.ds = {
        type: 'enum',
        elements: []
    };
    let elements = _.chain(spotman.el || [])
        .filter(function(el) {
            if (hiddenSpotmanRows.indexOf(el._id) !== -1) {
                return false;
            }

            let navType = el.render && el.render.navtype;
            const platforms = el.platforms || [];
            if (
                navType === 'spotmansectionheaderrow_nav' &&
                !platforms.length
            ) {
                return true;
            }

            return platforms.indexOf('web') !== -1;
        })
        .map(function(el, i) {
            // needed for icon extraction but could be overriden by platform.webapp
            const originalMappings = (el.render || {}).mapping || {};
            mergePlatformOverrides(el);

            if (
                el.render &&
                el.render.navtype === 'spotmansectionheaderrow_nav'
            ) {
                el.row_type = 'separator_row';
            }

            if (!el.row_type || el.visible === false) {
                return;
            }

            if (!el._id) {
                el._id = 'autogen_' + (Date.now() * Math.random()).toString(16);
            }

            el.order =
                el._id in fixedSpotManOrder ? fixedSpotManOrder[el._id] : i;
            if (el.row_type === 'separator_row') {
                return _.pick(el, '_id', 'order', 'row_type');
            }

            el.ds = el.ds || {
                source: {}
            };
            el.render.title = getSpotManItemTitle(el, originalMappings);
            el.render.icon = getSpotManItemIcon(el, originalMappings);
            delete el.render.mapping;

            let element = _.pick(
                el,
                '_id',
                'order',
                'row_type',
                'render',
                'actions',
                'dataSources',
                'is_in_nav_bar',
                'badge'
            );
            if (el.ds && el.ds.type === 'javascript') {
                element.ds = el.ds;
            }

            if (_.get(el, 'dataSources.visible')) {
                element.visible = false;
            }

            return element;
        })
        .compact()
        .sortBy('order')
        // if we don't ensure unique ids the whole spotman wouldn't appear
        // due to angular's array tracking algorithm (track by _id)
        .uniq('_id')
        .value();

    // remove duplicate separators
    elements = _.filter(elements, function(el, i) {
        let nextEl = elements[i + 1];
        if (!nextEl) return true;

        if (
            el.row_type === 'separator_row' &&
            nextEl.row_type === 'separator_row'
        ) {
            return false;
        }

        return true;
    });

    spotman.ds.elements = elements;
    delete spotman.el;

    return spotman;
};

const checkAndStripTrLambda = function(obj, key) {
    const value = obj[key];
    if (value) {
        obj[key] = stripTrLambda(value);
    }
};

const normalizeNativeNav = function(nav) {
    if (nav._id) {
        nav._id = nav._id.replace('webnav_', 'nav_');
    }

    // normalize no_content
    const no_content = nav.no_content || nav.nocontent;
    if (no_content) {
        delete no_content.on;
        checkAndStripTrLambda(no_content, 'button_text');
        checkAndStripTrLambda(no_content, 'subtitle_text');
        checkAndStripTrLambda(no_content, 'title_text');
    }
    nav.no_content = no_content;

    if (!nav.nav_type && nav.navtype) {
        nav.nav_type = nav.navtype.replace('_nav', '');
    }

    checkAndStripTrLambda(nav, 'title');

    if (nav._id === 'nav_spotman' && nav.fp_type === 'nav') {
        nav = fixNativeSpotMan(nav);
    }
    /*
    old format is:
        ds: { source: 'appscript', params: {}}
    proper format is:
        ds: {
            source: { path: 'appscript', params: {}}
        }
    */
    if (
        nav.ds &&
        nav.ds.type === 'javascript' &&
        typeof nav.ds.source === 'string'
    ) {
        nav.ds.source = {
            path: nav.ds.source,
            params: nav.ds.params
        };
        delete nav.ds.params;
    }
    if (nav.render) {
        if (!nav.render.row_type && nav.render.navtype) {
            nav.render.row_type = nav.render.navtype.replace('row_nav', '_row');
        }
        if (!nav.render.params && nav.render.mapping) {
            nav.render.params = {
                mapping: nav.render.mapping
            };
            delete nav.render.mapping;
        }
    }

    if (nav._id === 'nav_fstg') {
        return _.pick(nav, '_id', 'nav_type', 'page_params', 'title', 'route', 'model_route_param');
    }

    return _.omit(
        nav,
        '_rev',
        'fp_owner',
        'fp_type',
        'on',
        'navtype',
        'platform'
    );
};

const parseTheme = (theme, event) => {
    let parsedTheme = {};
    try {
        const toolbar = theme.layouts.navigation_bar;
        const spotman = theme.navs.nav_spotman;
        const spotmanListRow = spotman.list_row;
        const spotmanHeaderRow = spotman.spotmansectionheaderrow_nav;
        const home = theme.navs.nav_home || {
            view: {
                background_color: '#e6e6e6'
            }
        };

        // Default to font color
        let iconColor = spotmanListRow.title.font_color;
        let iconInfo =
            _.get(spotmanListRow, 'image_view.icons_color') || {};

        if (iconInfo.enabled) {
            iconColor = iconInfo.color;
        } else if (iconInfo.enabled === false) {
            // Use the original icon color (multicolor icons)
            iconColor = null;
        }

        parsedTheme = {
            /**
             * Get raw value from theme document
             *
             * @param {string} path
             * @returns {stirng}
             */
            get: path => _.get(theme, path),
            version: theme.theming_version,

            // flag to indicates that the new theme editor is used,
            // this can be used to simplify weird behaviours like the one
            // for the icon color
            editorV2Enabled: event.theme_editor_v2_enabled,

            // v3 theme
            foregroundColor1: theme.foregroundColor1,
            foregroundColor2: theme.foregroundColor2,
            backgroundColor1: theme.backgroundColor1,
            backgroundColor2: theme.backgroundColor2,
            backgroundColor3: theme.backgroundColor3,
            backgroundColor4: theme.backgroundColor4,
            backgroundColor5: theme.backgroundColor5,
            contrastColor: theme.contrastColor,
            activeLinkStyle: theme.activeLinkStyle,

            // v2 theme
            primary_color:
                theme.primary_color || toolbar.background_color,
            toolbar: {
                background_color: toolbar.background_color,
                font_color: toolbar.title.font_color
            },
            spotman: {
                view: {
                    background_color: spotmanListRow.background_color,
                    font_color: spotmanListRow.title.font_color,
                    icon_color: iconColor
                },
                alt_view: {
                    background_color: spotmanHeaderRow.background_color,
                    font_color: spotmanHeaderRow.title.font_color
                },
                badge: {
                    font_color: theme.layouts.list_row.badge.font_color,
                    background_color:
                        theme.layouts.list_row.badge.inset_color
                }
            },
            home: {
                background_color: home.view.background_color
            }
        };
    } catch (e) {
        console.error('Error while parsing theme', e);
    }
    return parsedTheme;
};

const normalizeNavs = (allNavs) => {
    const navs = {};

    for (const [ id, nav ] of Object.entries(allNavs)) {
        if (nav.fp_type !== 'webnav') {
            navs[id] = normalizeNativeNav(nav);
        }
    }

    return navs;
};

const handlebarizeObject = obj => {
    return _.chain(obj)
        .map((value, param) => {
            const isTemplate = _.isString(value) && value.indexOf('{') !== -1;
            const valueType = isTemplate ? 'template' : (_.isObject(value) ? 'object' : 'const');
            let templateFn;
            switch (valueType) {
                case 'template':
                    templateFn = handlebars.compile(value);
                    break;
                case 'object':
                    templateFn = handlebarizeObject(value);
                    break;
                default:
                    templateFn = function() {
                        return value;
                    };
            }

            if (_.isArray(value))
                templateFn = _.values(templateFn);

            return [ param, templateFn ];
        })
        .object()
        .value();
};

const unHandlebarizeObject = (obj, context) => {
    if (!_.isObject(obj))
        return obj;

    const out = _.chain(obj)
        .map((value, param) => {
            return [ param, _.isFunction(value) ? value(context) : unHandlebarizeObject(value, context) ];
        })
        .object()
        .value();

    return out[0] ? _.values(out) : out;
};

const renderRows = (config, rows) => {
    return rows.map(row => {
        const rowContext = row || {};
        rowContext.doc = rowContext.doc || row;
        rowContext.value = rowContext.value || {};

        _.extend(rowContext, rowContext.value);

        let renderParams = handlebarizeObject(config.render || {});
        const renderType = rowContext.$render_type;
        if (renderType && renderParams[renderType]) {
            renderParams = config.render[renderType] || {};
        }
        const actions = _.map(config.actions, function(action) {
            return handlebarizeObject(action.params) || {};
        });

        let renderedRender, renderedActionParams;
        try {
            renderedRender = unHandlebarizeObject(renderParams, rowContext);
            renderedActionParams = unHandlebarizeObject(actions, rowContext);
        } catch (e) {
            console.log('Failed to render', renderParams);
            return row;
        }

        return {
            _id: rowContext._id || rowContext.id || rowContext.doc._id || Math.random(),
            key: rowContext.key || [],
            render: renderedRender,
            actionParams: renderedActionParams,
        };
    });
};

module.exports = { parseTheme, normalizeNavs, renderRows };
