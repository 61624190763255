import { isEmpty } from 'lodash';

// using a precompile phase instead of post-compile (which link does by default)
// guarantees that $theme will be available on child scopes before
/* @ngInject */
function ThemeDirective($parse) {
    return {
        restrict: 'A',
        compile() {
            return {
                pre(scope, elem, attrs) {
                    const theme = $parse(attrs.theme)(scope);
                    scope.$theme = theme;
                    if (!scope.$theme) {
                        return;
                    }

                    elem.css('background-color', scope.$theme.background_color);
                    const sideTag = scope.$theme.side_tag_view;
                    if (
                        sideTag &&
                        !sideTag.hidden &&
                        sideTag.background_color
                    ) {
                        elem.css(
                            'border-left',
                            `5px solid ${sideTag.background_color}`
                        );
                    }

                    if (!isEmpty(theme.text_color)) {
                        elem.css('color', theme.text_color);
                    }
                },
                post: angular.noop
            };
        }
    };
}

function ThemeSelectorDirective() {
    return {
        restrict: 'A',
        compile() {
            return {
                pre(scope, elem, attrs) {
                    let theme = (scope.$theme || {})[attrs.themeSelector];
                    if (!theme) {
                        return;
                    }

                    theme.paddings = theme.paddings || {};

                    elem.css({
                        display: theme.hidden ? 'none' : '',
                        backgroundColor: theme.background_color,
                        color: theme.font_color,
                        fontSize: theme.font_size,
                        fontWeight:
                            theme.font_type === 'bold' ? 'bold' : undefined,
                        fontStyle:
                            theme.font_type === 'italic' ? 'italic' : undefined,
                        backgroundSize:
                            theme.scale_mode === 'aspect_fill'
                                ? 'cover'
                                : 'contain',
                        textAlign: theme.alignment,
                        paddingTop: theme.paddings.vertical,
                        paddingBottom: theme.paddings.vertical,
                        paddingLeft: theme.paddings.horizontal,
                        paddingRight: theme.paddings.horizontal
                    });

                    if (theme.alignment_vertical) {
                        elem.addClass(
                            'theme-vertical-align-' + theme.alignment_vertical
                        );
                    }
                },
                post: angular.noop
            };
        }
    };
}

angular
    .module('maestro.directives')
    .directive('theme', ThemeDirective)
    .directive('themeSelector', ThemeSelectorDirective);
