import i18nWithFallback from '../../../utils/i18n-with-fallback';

import { FilteringComponent } from './filtering.component';

import { isArray, map } from 'lodash';

export const FilteringModule = angular
    .module('maestro.components.common.filtering', [])
    .component('filtering', FilteringComponent).name;

/**
 * Extend this class on your controller to give it the ability to manage
 * filters.
 *
 * Expects a `reload(clearSearch?:boolean)` function to exist on the extending class.
 */
export class FilteringController {
    /* @ngInject */
    constructor(THEME, EID, $q, $popover, $i18n, $http, dataSourceEvaluatorService) {
        this.theme = THEME;
        this.EID = EID;

        this.$q = $q;
        this.$popover = $popover;
        this.i18n = $i18n;
        this.$http = $http;

        this.dse = dataSourceEvaluatorService;
        this.selectedFilters = [];
    }

    $onDestroy() {
        // Ensure filter is dismissed / destroyed as well
        this.$popover.clearAll();
    }

    getFilters() {
        if (this.filters) return this.$q.when(this.filters);

        if (this.config.remote_ds_filters) {
            const filtersToQuery = this.config.remote_ds_filters.filters.join();
            return this.$http
                .get(`/api/v1/eid/${this.EID}/${this.config.remote_ds_filters.path}`, {
                    params: {
                        filters: filtersToQuery
                    }
                })
                .then(({ data } = {}) => {
                    const filters = data;
                    this.filters = map(filters, filter => {
                        return {
                            field: filter.key,
                            label: this.i18n(`nav_personlist.filters.${filter.key}`),
                            placeholder: this.i18n(`nav_personlist.filters.${filter.key}_placeholder`),
                            mode: 'select',
                            options: map(filter.options, opt => {
                                return {
                                    value: opt,
                                    selected: false
                                };
                            }),
                            showOptions: false,
                            selectedOptionsCount: 0
                        };
                    });
                });
        }

        if (this.config.filters_ds) {
            return this.dse
                .eval(this.config.filters_ds)
                .then(filters => {
                    return this.filters = filters;
                });
        }
    }

    clearFilters() {
        this.selectedFilters = [];
        this.reload(true);
    }

    showFilters($event) {
        return this.getFilters()
            .then(filters =>
                this.$popover({
                    // config
                    template:
                        '<filtering filters="::filters" selection="selection" on-accept="accept(value)" on-apply-filters="onApplyFilters(value)"></filtering>',
                    target: $event.target,
                    options: {
                        title: this.$i18n('agenda_nav.filters.title'),
                        // otherwise the popover would be stuck inside the nav
                        container: 'body'
                    },

                    // scope
                    filters,
                    selection: this.selectedFilters,
                    onApplyFilters: this.onApplyFilters.bind(this)
                })
            )
            .then(selection => {
                console.log('filter selection', selection);
                this.selectedFilters = isArray(selection) ? selection : [];
            }).finally(() => {
                this.appliedFiltersLabel = this.getAppliedFiltersLabel();
                this.reload(true);
            });
    }

    getAppliedFiltersLabel() {
        const filtersCount = this.selectedFilters.reduce(
            (count, filter) => count + filter.value.length,
            0
        );
        let label = 'agenda_nav.filters.single_selected_filter';

        if (filtersCount > 1) {
            label = i18nWithFallback(
                this.i18n,
                'agenda_nav.filters.multiple_selected_filter',
                '%d filters selected'
            );
        } else {
            label = i18nWithFallback(
                this.i18n,
                'agenda_nav.filters.single_selected_filter',
                '%d filter selected'
            );
        }

        return label.replace('%d', filtersCount);
    }

    /**
     * This callback is called whenever a change happens on the filters
     *
     * @param {Boolean} filtersApplied
     */
    onApplyFilters(filtersApplied) {
        // ... to be implemented on child controller
        console.warn('[FilteringController] onApplyFilters should be implemented on child controller');
        return filtersApplied;
    }
}
