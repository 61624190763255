import { AppChromeModule } from './app-chrome';
import { AvatarModule } from './common/avatar';
import { PipModule } from './common/pip';
import { VideoPlayerModule } from './common/video-player';
import { CookiesNotificationModule } from './common/cookies-notification';
import { OfflineNotificationModule } from './common/offline-notification';
import { UpdateBrowserModule } from './common/update-browser';
import { TestWatermarkModule } from './common/test-watermark';
import { InAppNotificationModule } from './common/in-app-notification';
import { SuggestionsModule } from './common/suggestions';
import { FilteringModule } from './common/filtering';
import { ImageMediumModule } from './common/image';
import { ZoomModule } from './common/zoom';
import { VideoCallModule } from './common/video-call';

import { NavHeaderModule } from './navs/common/nav-header';
import { FooterButtonsModule } from './navs/common/footer-buttons';

import { AgendaNavModule } from './navs/agenda';
import { AppScriptLauncherNavModule } from './navs/appscript-launcher';
import { BlocksNavModule } from './navs/blocks';
import { BlocksListNavModule } from './navs/blocks-list';
import { ListNavModule } from './navs/list';
import { DocumentNavModule } from './navs/document';
import { NoteNavModule } from './navs/note';
import { ConversationNavModule } from './navs/conversation';
import { ScanNavModule } from './navs/scan';
import { SpotmanNavModule } from './navs/spotman';
import { NewMeetingNavModule } from './navs/new-meeting';
import { NewMessageNavModule } from './navs/new-message';

import { ActionBtnBlockModule } from './blocks/common/action-btn';
import { CardModule } from './blocks/cards';
import { FeedPostAttachmentsModule } from './blocks/feed/post-attachments';
import { FeedTopicBlockModule } from './blocks/feed/topic';
import { FeedCommentBlockModule } from './blocks/feed/comment';
import { FeedNewPostBlockModule } from './blocks/feed/new-post';
import { FeedReplyBlockModule } from './blocks/feed/reply';
import { EditTextBlockModule } from './blocks/common/edit-text';
import { EmptyStateModule } from './blocks/common/empty-state';
import { SelectBlockModule } from './blocks/common/select';
import { ToggleBlockModule } from './blocks/common/toggle';
import { UploadBlockModule } from './blocks/common/upload';
import { ListHeaderModule } from './blocks/common/list-header';
import { ListBannerModule } from './blocks/common/list-banner';
import { InlineBlockPaginatorModule } from './blocks/common/inline-block-paginator';
import { MeetingBlockModule } from './blocks/meeting';

import { UniversalSearchRowModule } from './rows/universal-search';
import { ProfileRowModule } from './rows/profile';

export const ComponentsModule = angular.module('maestro.components', [
    AppChromeModule,
    AvatarModule,
    PipModule,
    VideoPlayerModule,
    CookiesNotificationModule,
    OfflineNotificationModule,
    UpdateBrowserModule,
    TestWatermarkModule,
    InAppNotificationModule,
    SuggestionsModule,
    FilteringModule,
    ImageMediumModule,
    ZoomModule,
    VideoCallModule,

    NavHeaderModule,
    FooterButtonsModule,

    AgendaNavModule,
    AppScriptLauncherNavModule,
    BlocksNavModule,
    BlocksListNavModule,
    ListNavModule,
    DocumentNavModule,
    NoteNavModule,
    ConversationNavModule,
    ScanNavModule,
    SpotmanNavModule,
    NewMeetingNavModule,
    NewMessageNavModule,

    ActionBtnBlockModule,
    CardModule,
    EmptyStateModule,
    FeedPostAttachmentsModule,
    FeedTopicBlockModule,
    FeedCommentBlockModule,
    FeedNewPostBlockModule,
    FeedReplyBlockModule,
    EditTextBlockModule,
    SelectBlockModule,
    ToggleBlockModule,
    UploadBlockModule,
    ListHeaderModule,
    ListBannerModule,
    InlineBlockPaginatorModule,
    MeetingBlockModule,

    UniversalSearchRowModule,
    ProfileRowModule
]).name;
