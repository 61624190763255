export const ListHeaderComponent = {
    template: require('./list-header.jade'),
    bindings: {
        config: '<'
    },
    controller: class ListHeaderComponent {
        $onInit() {
            const cfg = this.config;
            console.info('[ListHeader] init', cfg);

            if (cfg.data) {
                // this.theme = cfg.data.theme;
                this.title = cfg.data.content.title;
            }
        }
    }
};
