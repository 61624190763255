export const AppScriptLauncherNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./appscript-launcher-nav.jade'),
    controller: class AppScriptLauncherNavComponent {
        /* @ngInject */
        constructor(THEME, databaseService, progressBarService) {
            this.theme = THEME;
            this.databaseService = databaseService;
            this.progressBarService = progressBarService;
        }

        $onInit() {
            const config = this.config;
            let appscript;
            try {
                appscript = JSON.parse(atob(config.content));
            } catch (x_X) {
                // invalid appscript conf
            }
            if (appscript && appscript.path) {
                this.progressBarService.startTask();
                this.databaseService.runAppScript(
                    appscript.path,
                    appscript.params,
                    () => this.progressBarService.finishTask()
                );
            }
        }
    }
};
