// check if page is visible or in background
/* @ngInject */
function VisibilityService($eventBus) {
    const eventEmitter = $eventBus.spawn();

    let hidden = 'hidden';
    // Standards:
    if (hidden in document) {
        document.addEventListener('visibilitychange', onchange);
    } else if ((hidden = 'mozHidden') in document) {
        document.addEventListener('mozvisibilitychange', onchange);
    } else if ((hidden = 'webkitHidden') in document) {
        document.addEventListener('webkitvisibilitychange', onchange);
    } else if ((hidden = 'msHidden') in document) {
        document.addEventListener('msvisibilitychange', onchange);
        // IE 9 and lower:
    } else if ('onfocusin' in document) {
        document.onfocusin = document.onfocusout = onchange;
    } else {
        // All others:
        window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
    }

    function onchange(evt) {
        let v = 'visible',
            h = 'hidden',
            evtMap = {
                focus: v,
                focusin: v,
                pageshow: v,
                blur: h,
                focusout: h,
                pagehide: h
            };
        evt = evt || window.event;

        if (evt.type in evtMap) {
            eventEmitter.emit(evtMap[evt.type]);
        } else {
            eventEmitter.emit(this[hidden] ? 'hidden' : 'visible');
        }
    }

    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[hidden] !== undefined)
        onchange({
            type: document[hidden] ? 'blur' : 'focus'
        });

    return eventEmitter;
}

angular
    .module('maestro.services')
    .service('visibilityService', VisibilityService);
