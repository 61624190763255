import { each, debounce, isObject, isFunction, isString, get } from 'lodash';
import { getLightFromHex } from '../../../utils/color';
import { getItemBadge } from '../../../utils/menu';

const VISIBILITY_SCRIPTS = [
    'morsel-simple-spotman-entry/visibility'
];

const NO_SERVER_CALL = [
    'profile/row'
];

export const SpotmanNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./spotman-nav.jade'),
    controller: class SpotmanNavComponent {
        /* @ngInject */
        constructor(
            NAV_BAR_CONFIG,
            NAV_TEMPLATES,
            THEME,
            $q,
            $timeout,
            $rootScope,
            $eventBus,
            navService,
            databaseService,
            progressBarService,
            targetingService
        ) {
            this.navBarConfig = NAV_BAR_CONFIG;
            this.navTemplates = NAV_TEMPLATES;
            this.theme = THEME;
            this.$q = $q;
            this.$timeout = $timeout;
            this.$rootScope = $rootScope;
            this.$eventBus = $eventBus;
            this.navService = navService;
            this.databaseService = databaseService;
            this.progressBarService = progressBarService;

            this.isSpotmanActive = false;
            this.searchFocused = false;
            this.hasGlobalActiveTasks = false;

            /** @type {import('../../../services/targetingService')} */
            this.targetingService = targetingService;

            this.listeners = [];
        }

        $onInit() {
            console.log('[SpotmanNavComponent] init', this.config);

            let self = this; // Needed this binding because of mangling transformation in production env.
            let dsEvaluators = {
                visible(ds, element) {
                    console.log('[SpotmanNavComponent] evaluating row ds for key "visible"', ds);
                    element.visible = false;

                    let serial = self.$q.resolve();
                    let run = () => {
                        serial = self
                            .evalDs(serial, ds)
                            .then(({ data: { response } }) => (element.visible = response));
                    };

                    run();
                    self.subscribeToRowNotifications(ds.reload_on, run);
                },
                badge(ds, element) {
                    console.log('[SpotmanNavComponent] evaluating row ds for key "badge"', ds);
                    let serial = self.$q.resolve();
                    let run = () => {
                        serial = self
                            .evalDs(serial, ds)
                            .then(({ data: { response } }) => (element.badgeCount = response));
                    };

                    run();
                    self.subscribeToRowNotifications((ds.reload_on, run));
                }
            };

            // We do not provide default icon color here because this will lead
            // to undesired outcome if the editor's provided icons have a solid
            // background. Therefore if the "Apply tint" option in the theme
            // editor is disabled we don't recolorize the icons and just draw the
            // them as they come. (@see https://spotme.atlassian.net/browse/SS-10101)

            // TODO: support v3 theme

            const iconsColor = get(this, 'theme.spotman.view.icon_color');
            const toolbColor = get(this, 'theme.spotman.view.background_color');
            if (this.theme.editorV2Enabled) {
                this.iconsColor = iconsColor;
            } else if (iconsColor && toolbColor) {
                if (
                    this.theme.get('appearance') === 'dark' && getLightFromHex(iconsColor) < getLightFromHex(toolbColor) ||
                    this.theme.get('appearance') !== 'dark' && getLightFromHex(iconsColor) > getLightFromHex(toolbColor)
                ) {
                    this.iconsColor = toolbColor;
                } else {
                    this.iconsColor = iconsColor;
                }
            }

            const menuItems = this.config.ds.elements.filter(el => !el.is_in_nav_bar || get(el, 'dataSources.visible') || el.row_type === 'profile_row');

            // / DS initialisation
            for (const element of menuItems) {
                if (this.isDsValid(element.ds)) {
                    console.log('[SpotmanNavComponent] evaluating row DS in compatibility mode', element.ds);
                    this.evalCompatDs(element);
                    continue;
                }

                // lodash.each breaks out of the loop if iteratee returns false
                each(element.dataSources, (ds, key) => {
                    this.isDsValid(ds) && isFunction(dsEvaluators[key]) && dsEvaluators[key](ds, element);
                });
            }

            this.$rootScope.$on('progress:activity', (event, activity) => {
                this.hasGlobalActiveTasks = activity;
                if (!this.$rootScope.$$phase) {
                    this.$rootScope.$apply();
                }
            });

            const navBarItems = this.config.ds.elements.filter(el => el.is_in_nav_bar);

            this.$rootScope.$on('badges:updated', (event, badgeNewValue) => {
                for (const element of navBarItems) {
                    if (element._id === badgeNewValue._id) {
                        element.badgeCount = badgeNewValue.value;
                        break;
                    }
                }
            });

            const notificationInAppListener = this.$eventBus.on('notification:in-app', () => {
                for (const item of menuItems) {
                    getItemBadge(item, this.databaseService, (i, count) => i.badgeCount = count);
                }
            });

            this.listeners.push(notificationInAppListener);
        }

        $onDestroy() {
            for (const unsubscribe of this.listeners) {
                if (isFunction(unsubscribe)) {
                    unsubscribe();
                }
            }
        }

        toggleSpotman() {
            if (this.isSpotmanActive) {
                return this.hideSpotman();
            } else {
                return this.showSpotman();
            }
        }

        showSpotman() {
            this.isSpotmanActive = true;
            angular.element('body').addClass('menu-open');
        }

        hideSpotman($event) {
            if ($event && event.target.nodeName === 'INPUT') {
                return true;
            }

            this.isSpotmanActive = false;
            angular.element('body').removeClass('menu-open');
        }

        performActions(actions, row) {
            this.$eventBus.emit('spotman:click', row);

            this.isSpotmanActive = false;
            this.navService.performActions(actions, this.config.nuid);
            this.hideSpotman();
        }

        $debounce(fn, time) {
            return debounce(() => this.$timeout(fn), time);
        }

        subscribeToRowNotifications(notifications, action) {
            this.listeners.push(this.navService.subscribeToEvents(
                this.navService.flattenNotificationMap(notifications),
                this.$debounce(action, 500)
            ));
        }

        // / data sources
        isDsValid(ds) {
            return (
                isObject(ds) &&
                ds.type === 'javascript' &&
                isString(ds.source.path)
            );
        }

        evalDs(serial, ds) {
            let askTheServer = !NO_SERVER_CALL.includes(ds.source.path);
            if (VISIBILITY_SCRIPTS.includes(ds.source.path)) {
                askTheServer = this.targetingService.shouldAskTheServer(ds.source.params);
            }

            if (askTheServer) {
                return serial.then(() => this.databaseService.runAppScript(ds.source.path, ds.source.params));
            } else {
                return serial.then(() => ({ data: {
                    response: {
                        visible: this.targetingService.canUserSeeItem(ds.source.params)
                    }
                } }));
            }
        }

        evalCompatDs(element) {
            let serial = this.$q.resolve();
            let run = () => {
                serial = this.evalDs(serial, element.ds).then(({ data: { response } }) => {
                    if (response.hasOwnProperty('badge')) {
                        response.badgeCount = response.badge;
                        delete response.badge;
                    }
                    angular.extend(element, response);
                });
            };
            run();
            this.subscribeToRowNotifications(element.ds.reload_on, run);
        }
    }
};
