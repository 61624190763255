import { extend, clone, throttle, map, compact } from 'lodash';

let depsLoaded = false;

export const EditTextBlockComponent = {
    template: require('./edit-text-block.jade'),
    bindings: {
        parentNavId: '<',
        config: '<',
        // FIXME: is it used anywhere ?
        onSetValue: '&'
    },
    controller: class EditTextBlockComponent {
        /* @ngInject */ constructor(
            $element,
            $q,
            $filter,
            $timeout,
            $eventBus,
            navFormService,
            databaseService
        ) {
            this.$q = $q;
            this.$timeout = $timeout;
            this.$eventBus = $eventBus;
            this.navFormService = navFormService;
            this.databaseService = databaseService;

            this.appResource = $filter('appResource');
            this.assetUrl = $filter('assetUrl');
            this.cache = {};
            this.$editor = $element.find('.editor');

            // calls to `updateValue` and `getSuggestions` will be throttled
            const updateValue = this.updateValue.bind(this);
            this.updateValue = throttle(
                () => $timeout(() => updateValue()),
                500
            );
            const getSuggestions = this.getSuggestions.bind(this);
            this.getSuggestions = throttle(
                text => $timeout(() => getSuggestions(text)),
                1000
            );

            this.ensureDependenciesAreLoaded().then(() =>
                this.initSuggestions()
            );
        }

        $onInit() {
            console.log('[EditTextBlockComponent] init', this.config);

            this.value = this.config.data.content.text || {};
            this.links = clone(this.config.data.content.links || []);

            // DOM gloves on
            // `input` is HTML5
            // `change` not so old and not so new browser
            // `selectionchange` IE10<
            this.$editor.on('input change blur keyup paste', () =>
                this.updateValue()
            );
            // DOM gloves off

            if (this.config.required) {
                this.navFormService.setRequiredField(this.parentNavId, this.config.input_key, true);
            }
        }

        $onDestroy() {
            if (this.$editor && this.$editor.atwho) {
                this.$editor.atwho('destroy');
                this.$editor.off('input change blur keyup paste');
            }

            this.navFormService.set(
                this.parentNavId,
                this.config.input_key,
                null
            );
        }

        updateValue() {
            this.navFormService.set(this.parentNavId, this.config.input_key, {
                text: this.extractTextFromEditor(),
                links: this.extractLinksFromEditor()
            });
        }

        initSuggestions() {
            // DOM
            this.$editor.atwho({
                at: '@',
                searchKey: 'text',
                displayTpl: ({ icon_url, text }) => {
                    const iconUrl = this.assetUrl(icon_url);
                    return `<li><img src="${iconUrl}" class="icon" onload="this.classList.add('loaded')"/>${text}</li>`;
                },
                insertTpl: ({ text, metadata }) => {
                    const meta = encodeURIComponent(JSON.stringify(metadata));
                    return `<span class="link mention" link-metadata="${meta}">@${text}</a>`;
                },
                delay: 10,
                data: [],
                callbacks: {
                    matcher: (flag, subtext) => {
                        const regexp = new RegExp(
                            // match any list of one to 3 words
                            flag + '([^\\s@#]+(\\s+[^\\s@#]+){0,2})$',
                            'gi'
                        );
                        const matched = regexp.exec(
                            subtext.replace(/\s/g, ' ')
                        );
                        return matched && matched.length ? matched[1] : null;
                    },
                    remoteFilter: (text, cb) =>
                        this.getSuggestions(`@${text}`).then(cb)
                }
            });

            this.$editor.atwho({
                at: '#',
                searchKey: 'text',
                displayTpl: ({ text }) => `<li>${text}</li>`,
                insertTpl: ({ text, metadata }) => {
                    const meta = encodeURIComponent(JSON.stringify(metadata));
                    return `<span class="link hashtag" link-metadata="${meta}">#${text}</a>`;
                },

                delay: 10,
                data: [],
                callbacks: {
                    remoteFilter: (text, cb) => {
                        this.getSuggestions(`#${text}`).then(cb);
                    }
                }
            });
        }

        // suggestions

        getSuggestions(text) {
            if (!text || text.length < 2) return this.$q.when([]);

            const cache = this.cache[text];
            if (cache) {
                return this.$q.when(cache);
            }

            const actions = this.config.data.actions;
            const action = actions && actions.autocomplete;
            if (!action || !action.path) return this.$q.resolve([]);
            const params = extend({}, action.params || {}, { text });

            // TODO handle multiple requests better
            return this.databaseService
                .runAppScript(action.path, params)
                .then(({ data: { response } }) => {
                    this.cache[text] = response;
                    return this.cache[text];
                });
        }

        // DOM
        extractTextFromEditor() {
            return (
                this.$editor
                    .html()
                    .replace(/&amp;/g, '&')
                    .replace(/&nbsp;/g, ' ')
                    .replace(/&#160;/g, ' ')
                    // \r from \r\n on Windows or shift+return on Mac
                    .replace(/&#10;/g, '')
                    // replace br for newlines
                    .replace(/<br(\s*)\/*>/gi, '\n')
                    // replace div for newlines
                    .replace(/<[div>]+>/gi, '\n')
                    // remove closing div tags
                    .replace(/<\/[div>]+>/gm, '')
                    // strips any other tags
                    .replace(/<\S[^><]*>/g, '')
                    // at.js inserts ZWJ char right after where the suggestion is inserted
                    // these chars are invisible but later mess up with index calculations
                    .replace(/(\u00ad|\u200b|\u200c|\u200d)/g, '')
                    .trim()
            );
        }

        extractLinksFromEditor() {
            const linkElements = this.$editor.find('.link');
            let fullText = this.extractTextFromEditor();
            let consumedTextLength = 0;

            const links = map(linkElements, el => {
                const text = el.innerText.trim();
                const metadata = JSON.parse(
                    decodeURIComponent(el.getAttribute('link-metadata'))
                );
                const relativeIndex = fullText.indexOf(text);
                // It may happen that a the text returned by `extractTextFromEditor`
                // and the link text don't match. When this happens we simply discard
                // the link, as we are not able to find its exact position.
                if (relativeIndex < 0) {
                    return null;
                }
                const index = consumedTextLength + fullText.indexOf(text);

                fullText = fullText.slice(relativeIndex + text.length);
                consumedTextLength += relativeIndex + text.length;

                return { index, text, metadata };
            });
            return compact(links);
        }

        // loading caret.js and at.js
        ensureDependenciesAreLoaded() {
            if (depsLoaded) {
                this.autocompleteReady = true;
                return this.$q.when(true);
            }

            if (this.loadingDepsPromise) {
                return this.loadingDepsPromise;
            }

            const deferred = this.$q.defer();
            this.loadingDepsPromise = deferred.promise;

            console.log('[EditTextBlockComponent] loading caret.js and at.js');

            require('frontloader-common/lab')
                .script([
                    this.appResource('js/jquery.caret.js'),
                    this.appResource('js/jquery.atwho.js')
                ])
                .wait(() => {
                    console.log(
                        '[EditTextBlockComponent] loaded caret.js and at.js'
                    );
                    depsLoaded = true;
                    deferred.resolve(true);
                });

            return this.loadingDepsPromise;
        }
    }
};
