import { each } from 'lodash';
import { v4 as uuid } from 'uuid';

export const NewMessageNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./new-message-nav.pug'),
    controller: class NewMessageNavComponent {
        /* @ngInject */
        constructor(
            ACTIVATED_PERSON,
            dataSourceEvaluatorService,
            progressBarService,
            videoCallService,
            databaseService,
            $scope,
            $http
        ) {
            this.actPerson = ACTIVATED_PERSON;
            this.ds = dataSourceEvaluatorService;
            this.progressBarService = progressBarService;
            this.videoCallService = videoCallService;
            this.databaseService = databaseService;
            this.$scope = $scope;
            this.$http = $http;

            this.sent = false;
        }

        $onInit() {
            console.info('[NewMessageNavComponent] init', this.config);
            this.participants_ds = this.config.participants_list.ds;

            if (this.config.recipients) {
                this.participants = this.config.recipients.map(r => r.value);
            }

            if (this.config.message) {
                this.message = this.config.message;
            }
        }

        /**
         * Checks chat creation form validity.
         *
         * @return {Boolean} whether the form is valid or not.
         */
        validate() {
            const form = this.$scope.chat;

            // Reset errors
            this.errors = {};
            form.participants.$setValidity('required', true);
            form.message.$setValidity('required', true);

            if (this.participants.length === 0) {
                form.participants.$setValidity('required', false);
            }

            if ((this.message || '').trim().length === 0) {
                form.message.$setValidity('required', false);
            }

            return form.$valid;
        }

        /**
         * Collects and sends all data to the server to start the chat.
         */
        startChat() {
            // Little check before going further.
            if (!this.validate()) {
                return;
            }

            // Please stand by...
            if (this.sending) return;
            this.sending = true;

            this.videoCallService.createSession().then(sessionId => {

                const paxes = {};
                paxes[this.actPerson._id] = [
                    this.actPerson.fname,
                    this.actPerson.lname
                ]
                    .join(' ')
                    .trim();
                each(this.participants, p => {
                    paxes[p._id] = [ p.fname, p.lname ].join(' ').trim();
                });

                const params = {
                    brand_new: true,
                    message: {
                        _id: uuid(),
                        body: this.message,
                        conversation_id: uuid(),
                        session_id: sessionId,
                        participants: paxes
                    }
                };

                this.progressBarService.startTask();
                this.databaseService
                    .runAppScript(this.config.actions.send_button.path, params)
                    .then(({ data }) => {
                        if (data.status && data.status === 'error') {
                            const form = this.$scope.chat;
                            this.errors = {};
                            Object.keys(data.response).forEach(k => {
                                if (form.hasOwnProperty(k)) {
                                    form[k].$setValidity('required', false);
                                    this.errors[k] = data.response[k];
                                }
                            });
                        }
                    })
                    .finally(() => {
                        this.progressBarService.finishTask();
                        this.sending = false;
                        this.sent = true;
                    });
            });
        }

        /**
         * Used to representing the suggestion document (a person in our case).
         * @param  {Object} doc the suggested document
         * @return {String}     a string representing the person document.
         */
        representation(doc) {
            return [ doc.fname, doc.lname ].join(' ').trim();
        }
    }
};
