/**
 * A divider is a thin, lightweight rule that groups content in lists and page layouts.
 *
 * The properties that can be set on a Divider element are the following
 *
 * ---------------------------------------------------------------------------
 * Parameter   Type    Default   Min      Max   Encoding     Mandatory   Notes
 * ---------------------------------------------------------------------------
 * color      String   #808080   -        -     #RRGGBB or   no
 *                                              #AARRGGBB
 * thickness  int      1         >= 0     -     -            no
 * margin     JSON     -                                     no          (1)
 * `- left    int      8         >= 0     -     points/dp    no
 * `- top     int      8         >= 0     -     points/dp    no
 * `- right   int      8         >= 0     -     points/dp    no
 * `- bottom  int      8         >= 0     -     points/dp    no
 * ---------------------------------------------------------------------------
 *
 * (1) Contains margin properties (left,top, right and bottom), a margin value
 *     missing will be set to a default value.
 *
 */

export const DividerComponent = {
    bindings: {
        row: '<'
    },
    template: require('./divider.jade'),
    controller: class DividerComponent {
        /* @ngInject */
        constructor(navService, styleParser) {
            this.navService = navService;
            this.parser = styleParser;
        }

        $onInit() {
            this.config = this.row.config;
            // console.debug('[DividerComponent] init', this.config);

            const bg = {
                'background-color': this.parser.parseColor(
                    this.config.color || '#808080'
                )
            };
            const margins = this.parser.parseMargins(this.config);
            const boxing = this.parser.parseBoxing(this.config);

            this.style = angular.merge(bg, margins, boxing);
        }
    }
};
