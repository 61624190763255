export const ChatDetailsComponent = {
    bindings: {
        config: '='
    },
    template: require('./chat-details.pug'),
    controller: class ChatDetailsComponent {
        /* @ngInject */
        constructor(
            EVENT_NAVS,
            ACTIVATED_PERSON,
            $window,
            $scope,
            $sheet,
            databaseService,
            navService,
            progressBarService,
            dataSourceEvaluatorService
        ) {
            this.newMsgNav = EVENT_NAVS['nav_newmessage'];
            this.actPerson = ACTIVATED_PERSON;
            this.$window = angular.element($window);
            this.$scope = $scope;
            this.sheet = $sheet;
            this.databaseService = databaseService;
            this.navService = navService;
            this.progressBarService = progressBarService;
            this.ds = dataSourceEvaluatorService;
        }

        $onInit() {
            if (!this.newMsgNav) {
                console.warn(
                    '[ChatDetailsComponent] Old message package detected, skipping init.'
                );
                return;
            }

            console.log('[ChatDetailsComponent] init', this.config);

            this.original_chat_name = this.config.chat_name;
            this.participants_ds = this.newMsgNav.participants_list.ds;

            this.reset();

            this.$scope.$on('conversation:load_details', () => {
                this.reload();
            });
        }

        reload() {
            this.sheet.dismissAllSheets();
            this.progressBarService.startTask();
            this.ds
                .eval(this.config.details.ds, {
                    conversation_id: this.config.conversation_id
                })
                .then(resp => {
                    console.info(
                        '[ChatDetailsComponent] Details loaded:',
                        resp
                    );

                    this.reset();

                    if (resp.chat_name) {
                        this.config.chat_name = resp.chat_name;
                    }

                    const admins = resp.admins || [];
                    this.isAdmin = admins.indexOf(this.actPerson._id) !== -1;

                    resp.participants.forEach(pax => {
                        // Exclude current participants from suggestions
                        this.suggestions_exclusions.push(pax.id);

                        if (!pax.menu) {
                            pax.menu = [];
                        }

                        // We don't add kick menu item for admin or if the
                        // act user is not an admin.
                        if (pax.id !== this.actPerson._id && this.isAdmin) {
                            pax.menu.push({
                                id: 'kick',
                                action: {
                                    method: 'runScript',
                                    params: {
                                        path: this.config.actions
                                            .participant_interactions.path,
                                        params: {
                                            conversation_id: this.config
                                                .conversation_id,
                                            kick_participant: pax.id
                                        }
                                    }
                                },
                                label:
                                    'conversation_nav.details.kick_participant'
                            });
                        }
                    });

                    this.participants = resp.participants;
                })
                .finally(() => {
                    this.progressBarService.finishTask();
                    this.$window.trigger('assets:load');
                });
        }

        /**
         * Resets forms fields and errors.
         */
        reset() {
            this.errors = [];
            this.suggestions_exclusions = [];
            this.participants = [];
        }

        /**
         * Disposes the details panel and saves all changes.
         */
        closeDetails() {
            this.config.detailsIn = false;
            this.renameChat();
        }

        /**
         * Dismiss the invitations panel and saves changes.
         */
        closeInvites() {
            this.addFormIn = false;
            this.sendInvites();
        }

        /**
         * Form submission action (and prevents defaults).
         */
        changeGroupName() {
            this.renameChat();
        }

        /**
         * Sumbits the form to the server and tries to save chat details.
         */
        renameChat() {
            if ((this.config.chat_name || '').trim().length === 0) {
                this.config.chat_name = this.original_chat_name;
            }

            if (
                this.sending ||
                this.original_chat_name === this.config.chat_name
            ) {
                return;
            }

            this.sending = true;
            this.progressBarService.startTask();
            this.databaseService
                .runAppScript(this.config.actions.rename_chatroom.path, {
                    conversation_id: this.config.conversation_id,
                    chat_name: this.config.chat_name
                })
                .finally(() => {
                    this.sending = false;
                    this.original_chat_name = this.config.chat_name;
                    this.progressBarService.finishTask();
                    this.config.title = this.config.chat_name;
                });
        }

        /**
         * Opens a nav containing details of the provided profile.
         *
         * @param  {Object} profile the profile to open details of
         */
        openProfile(profile) {
            const lastNav = this.navService.activeNav.nav;
            this.navService.openDocument(
                { id: profile.id },
                { _parent_nav_id: lastNav.nuid }
            );
        }

        /**
         * Displays the user contextual menu.
         * @param  {Event}  e   the event that triggered the action
         * @param  {Object} pax the user's object
         */
        showUserMenu(e, pax) {
            e.preventDefault();
            e.stopPropagation();
            this.navService.showNavToolbarMenu(pax.menu)(e);
        }

        // Actions
        // -------

        /**
         * Displays the form for adding new participants to the group chat
         */
        showAddParticipantsForm() {
            this.addFormIn = true;
        }

        /**
         * Removes the current user from the group chat.
         */
        leaveGroup() {
            this.navService.alert({
                title: 'conversation_nav.leaving_alert.title',
                message: 'conversation_nav.leaving_alert.message',
                buttons: [
                    {
                        title: 'general.cancel'
                    },
                    {
                        title: 'conversation_nav.details.leave_group',
                        target: {
                            method: 'runScript',
                            params: {
                                path: this.config.actions
                                    .participant_interactions.path,
                                params: {
                                    conversation_id: this.config
                                        .conversation_id,
                                    leave_conversation: true
                                }
                            }
                        }
                    }
                ]
            });
        }

        /**
         * Adds the selected participants to the current chat.
         */
        sendInvites() {
            const newInvites = this.participants.filter(p => p._id);
            if (this.sending || newInvites.length === 0) return;

            console.info('[ChatDetailsComponent] Sending invites', newInvites);

            this.progressBarService.startTask();
            this.sending = true;
            this.databaseService
                .runAppScript(
                    this.config.actions.participant_interactions.path,
                    {
                        conversation_id: this.config.conversation_id,
                        add_participant: newInvites.map(p => p._id)
                    }
                )
                .finally(() => {
                    this.sending = false;
                    this.participants = [];
                    this.progressBarService.finishTask();
                });
        }

        /**
         * Used to representing the suggestion document (a person in our case).
         * @param  {Object} doc the suggested document
         * @return {String}     a string representing the person document.
         */
        representation(doc) {
            if (doc.full_name) {
                return doc.full_name;
            }

            return [ doc.fname || doc.first_name, doc.lname || doc.last_name ].join(' ').trim();
        }
    }
};
