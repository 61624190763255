import { compact, uniq } from 'lodash';

import { CommonFeedBlock } from '../common-feed-block';

export const FeedReplyBlockComponent = {
    template: require('./feed-reply-block.jade'),
    bindings: {
        config: '<',
        onParentUpdate: '&onUpdate'
        // onUpdate: '&',
    },
    controller: class FeedReplyBlockComponent extends CommonFeedBlock {
        /* @ngInject */ constructor(
            NAV_TEMPLATE_API_PREFIX,
            navService,
            databaseService,
            EventEmitter,
            $element,
            $filter,
            $sheet,
            $i18n,
            THEME
        ) {
            super(NAV_TEMPLATE_API_PREFIX, $filter, $sheet, $i18n, THEME);

            this.databaseService = databaseService;
            this.navService = navService;
            this.runScript = navService.runScript.bind(navService);
            this.EventEmitter = EventEmitter;
            this.$element = $element;
        }

        $onInit() {
            super.$onInit();
            this.$element.trigger('ready');
        }

        $onChanges(changes) {
            super.$onChanges(changes);

            if (!changes.config) return;

            const data = this.config.data.content;
            this.author = {
                photo: data.avatar_url,
                title: data.title,
                subtitle: compact([ data.company, data.position ]).join(' • ')
            };
        }

        prependBlocks({ blocks }) {
            console.log('[FeedReplyBlockComponent] prependBlocks', blocks);

            this.onUpdate(
                this.EventEmitter({
                    id: this.config.id,
                    updates: {
                        content: {
                            inline_blocks: uniq(
                                blocks.concat(this.inlineBlocks),
                                ({ id }) => id
                            )
                        }
                    }
                })
            );
        }

        onUpdate($event) {
            console.log('[FeedReplyBlockComponent] onUpdate', $event);
            // this is a little bit odd that we have to wrap things twice in EventEmitter
            this.onParentUpdate(this.EventEmitter($event));
        }
    }
};
