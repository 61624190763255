import { cloneDeep, get, isFunction } from 'lodash';
import { debounceAndUnique } from '../../../utils/promise';
import {
    convertEmbedableUrl,
    isPlayableVideoUrl,
    isSameUrlWithoutQuery
} from '../../../utils/url';
import { addEventListener, exitNativeFullscreen, replaceReferrer } from '../../../utils/dom';
import { timer } from '../../../utils/audioTimer';
import 'frontend-video-player/webcomponent';
import { nowUnixTimestamp } from '../../../utils/time';
import { getStaticAsset } from '../../../utils/assets';

const START_BROADCAST_DELAY_WINDOW = 45;
const STOP_BROADCAST_DELAY_WINDOW = 90;

export const PipComponent = {
    template: require('./pip.jade'),
    bindings: {
        config: '<',
        onClose: '&',
        analytics: '<'
    },
    controller: class PipComponent {
        /* @ngInject */ constructor(
            EVENT,
            $element,
            $eventBus,
            $filter,
            $sce,
            $scope,
            pictureInPictureService,
            signalService,
            libLoaderService,
            liveStreamService,
            triggerService,
            websocketConnectionReportingService,
            $rootScope,
            videoPlayerService,
            visibilityService,
            offlineService
        ) {
            this.$element = $element;
            this.$eventBus = $eventBus;
            this.$filter = $filter;
            this.$sce = $sce;
            this.$scope = $scope;
            this.isWebinar = EVENT.is_webinar;

            this.pictureInPictureService = pictureInPictureService;
            this.signalService = signalService;
            this.libLoaderService = libLoaderService;
            this.liveStreamService = liveStreamService;
            this.triggerService = triggerService;
            this.visibilityService = visibilityService;
            this.offlineService = offlineService;
            this.videoPlayerService = videoPlayerService;
            this.websocketConnectionReportingService = websocketConnectionReportingService;

            this.broadcastStarted = false;
            this.isIframeContent = false;
            this.ready = false;
            this.oldUrl = '';
            this.hasReceivedCookies = false;
            this.useMetadata = false;
            this.isSafari = $rootScope.device.browser === 'Safari';
            this.listeners = [];
            this.signalListeners = {};

            try {
                this.isLocalDevNode = "aws-dev" === 'dev';
            } catch (e) {
                console.log(e);
            }
        }

        async $onInit() {
            console.log('[PipComponent] init', this.config);

            this.websocketConnectionReportingService.setLiveStreamId(this.liveStreamId);

            this.reloadConfigDebounced = debounceAndUnique(this.reloadConfig.bind(this), 5000, 5000);

            this.listeners.push(
                this.$scope.$on('theatreMode:on', () => this.maximise()),
                this.$scope.$on('theatreMode:off', () => this.minimise()),
                this.$eventBus.on('theatreMode:off', () => this.minimise()),
                this.$scope.$on('pip:close', () => this.close()),
                this.$scope.$on('isSmallScreen', (event, isSmallScreen) => {
                    isSmallScreen ? this.$element.addClass('is-small-screen') : this.$element.removeClass('is-small-screen');
                }),
                this.$scope.$on('hasSideBar', (event, hasSideBar) => {
                    hasSideBar ? this.$element.addClass('has-side-bar') : this.$element.removeClass('has-side-bar');
                }),
                this.$scope.$on('live-stream:start-broadcast-from-id3', () => {
                    // third parts streams do not control start/stop broadcast from id3
                    if (!this.isThirdParty) {
                        console.log('[PipComponent] start broadcast from id3');
                        this.onStartBroadcast();
                    }
                }),
                this.$scope.$on('live-stream:stop-broadcast-from-id3', () => {
                    // third parts streams do not control start/stop broadcast from id3
                    if (!this.isThirdParty) {
                        console.log('[PipComponent] stop broadcast from id3');
                        this.onStopBroadcast();
                    }
                }),
                this.$eventBus.on('notification:in-app', () => {
                    if (this.isIframeContent && document.fullscreenElement) {
                        exitNativeFullscreen();
                    }
                }),
                this.$scope.$watch('$ctrl.config', () => {
                    this.resolvePlayerRendering();
                    this.setupExpirationHook();
                    this.checkForSignalCleanup();
                }, true),
                addEventListener(window, 'unload', () => this.liveStreamService.sendStopWatching(this.liveStreamId)),
                this.triggerService.addTrigger('forceConfigReload', async () => {
                    this.reloadConfig();
                }),
                this.triggerService.addTrigger('fakeIsLiveID3', (isLive) => {
                    isLive ? this.onStartBroadcast() : this.onStopBroadcast();
                }),
                this.visibilityService.on('visible', () => {
                    if (!this.broadcastStarted) {
                        this.reloadConfigDebounced();
                    }
                }),
                this.offlineService.on('online', () => {
                    if (!this.broadcastStarted) {
                        this.reloadConfigDebounced();
                    }
                })
            );

            this.isThirdParty = this.config.isThirdParty;
            this.isStreamless = this.config.isStreamless;
            this.isStreamless ? this.$element.removeClass('with-stream') : this.$element.addClass('with-stream');
            this.isInPerson = this.liveStreamService.isInPerson(this.config);
            this.needsCookies = this.liveStreamService.needsAuthCookies(this.config);
            this.hasReceivedCookies = !!this.config.cookies;

            await this.initSignals();

            this.resolveSigning({ newConfig: this.config });
            await this.applyConfig(this.config);

            this.liveStreamService.sendStartWatching(this.liveStreamId);
        }

        get liveStreamId() {
            return get(this, 'config.liveStreamId');
        }

        get isFinished() {
            return this.liveStreamService.isFinished(this.config);
        }

        shouldRegisterToSignals() {
            if (this.isThirdParty) {
                return true;
            }
            return !this.isFinished;
        }

        async initSignals() {
            if (!this.shouldRegisterToSignals()) return;

            this.signalListeners['onUpdate'] = await this.signalService.addSignalListener(
                `liveStream/${this.liveStreamId}/appUpdate`,
                (data) => this.onNewLiveStreamDoc({ liveStreamDoc: data.liveStream })
            );
            this.signalListeners['onConnection'] = await this.signalService.addEventListener(
                'open', // 'open' will be emitted after the initial connection and after a reconnection
                () => this.reloadConfigDebounced()
            );
        }

        checkForSignalCleanup() {
            if (this.shouldRegisterToSignals()) return;

            get(this.signalListeners, 'onUpdate', () => {})();
            get(this.signalListeners, 'onConnection', () => {})();

            this.signalListeners = {};
        }

        /**
         * triggered on start broadcast
         */
        onStartBroadcast() {
            console.log('[PipComponent] start broadcast');
            if (this.broadcastStarted || this.isFinished) {
                return;
            }
            this.broadcastStarted = true;
            clearTimeout(this.stopBroadcastTimeout);
            this.clearDoubleTimeout();
            this.$scope.$applyAsync();
        }

        /**
         * triggered on stop broadcast
         */
        onStopBroadcast() {
            console.log('[PipComponent] stop broadcast');
            if (!this.broadcastStarted) {
                return;
            }
            clearTimeout(this.stopBroadcastTimeout);
            this.clearDoubleTimeout();
            this.broadcastStarted = false;

            // There might be some rare cases where id3 contains some incorrect is_live: false tag after the beginning of the stream.
            // This makes the stream stop, in such case we want to restart if we get a subsequent is_live: true tag,
            // so we only clear the source if we are 100% sure the stream is finished.
            if (this.isFinished) {
                this.config.url = '';
                this.sourceUrl = '';
                this.oldUrl = '';
            } else {
                this.$scope.$broadcast('video-player:premature-stop');
            }

            this.$scope.$applyAsync();
        }

        setupExpirationHook() {
            const expires = get(this.config, 'expires');
            if (expires) {
                clearTimeout(this.updateConfTimeout);
                const now = Date.now();
                const timeout = expires * 1000 - now - 30000;

                console.log(
                    '[PipComponent] Video config will be refreshed at',
                    new Date(now + timeout)
                );
                // Refresh config when the URL is about to expire (within 30 seconds)
                this.updateConfTimeout = setTimeout(
                    () => {
                        // Here we know the video won't change, only the expires in the url will, so we will keep the same url to be passed down to the player
                        // but we will consume the new url to update the cookies. This way there will be no glitch in the playback
                        this.reloadConfig();
                    },
                    timeout
                );
            }
        }

        async onNewLiveStreamDoc({ liveStreamDoc }) {
            console.log('[PipComponent] received new live stream doc', liveStreamDoc);
            const newConfig = this.liveStreamService.getConfigFromLiveStreamDoc(liveStreamDoc);

            if (this.liveStreamId !== get(newConfig, 'liveStreamId')) return;

            const needsSigning = this.resolveSigning({ newConfig });
            if (needsSigning) {
                // If config.is_live turns true, we need to reload the config to get the cookies below in resolveSigning.
                // Then we do not apply the config to the video player component yet as it will fail with islive true without cookies
                // But we need to stop listening to signal for polls right away, so we still need to apply the config to useMetadata
                this.resolveUseMetadata(newConfig);
                this.$scope.$applyAsync();
                return;
            }

            const doesNewOneHaveHigherVersion = get(this, 'config.version', 0) < get(newConfig, 'version', 0);
            if (!doesNewOneHaveHigherVersion) return;

            await this.applyConfig(newConfig);
        }

        resolveUseMetadata(config) {
            if (this.isInPerson || this.isThirdParty || this.isLocalDevNode) {
                this.useMetadata = false;
                return;
            }

            const isVod = this.liveStreamService.isVod(config);
            const isLive = this.liveStreamService.isLive(config);

            this.useMetadata = isVod || this.broadcastStarted || isLive || this.isFinished;
        }

        resolveSigning({ newConfig }) {
            if (!this.needsCookies) {
                return false;
            }

            if (newConfig.cookies && newConfig.url) {
                this.hasReceivedCookies = true;
                this.onNewUrl(newConfig.url);
                return false;
            }

            if (!this.hasReceivedCookies && newConfig.is_live) {
                console.log('[PipComponent] fetching self signing url for live stream');
                // BE only sends the self signing url when is_live is true, and only on the livestream get route.
                // If we receive a new doc with is_live true through signal, it does not come with right url.
                // We need the cookies to set up the player, otherwise it will fail.
                // There are 30s of delay between BE sending is_live true and the first reception of the stream, so we
                // can safely debounce the call to get that URL, and apply the config afterward.
                this.reloadConfigDebounced();
                return true;
            }
            return false;
        }

        resolvePlayerRendering() {
            if (this.isWebinar) {
                return;
            }

            const shouldRenderPlayer = !this.isInPerson ||
                (get(this.config, 'qna', false) ||
                get(this.config, 'polls_enabled', false));

            shouldRenderPlayer ? this.pictureInPictureService.show() : this.pictureInPictureService.hide();
        }

        $onDestroy() {
            // start with this, this is the most important
            this.liveStreamService.sendStopWatching(this.liveStreamId);

            this.websocketConnectionReportingService.setLiveStreamId(undefined);

            clearTimeout(this.updateConfTimeout);
            clearTimeout(this.stopBroadcastTimeout);
            this.clearDoubleTimeout();

            // add signal listeners to main listeners array
            this.listeners.push(
                ...Object.keys(this.signalListeners)
                    .map(key => this.signalListeners[key])
            );

            for (const unsub of this.listeners) {
                if (isFunction(unsub)) {
                    unsub();
                }
            }
        }

        async reloadConfig() {
            console.log('[PipComponent] reload config');

            if (!this.liveStreamId) {
                console.warn('[PipComponent] Cannot reload config because liveStreamId is not set');
                return;
            }

            const liveStreamDoc = await this.liveStreamService.loadConfiguration(this.liveStreamId);
            // reset version to 0, so that we can force apply the new config
            this.config.version = 0;
            await this.onNewLiveStreamDoc({ liveStreamDoc });
        }

        clearDoubleTimeout() {
            clearTimeout(this.startBroadcastTimeout);
            if (isFunction(this.killStartBroadcastAudioTimer)) {
                this.killStartBroadcastAudioTimer();
            }
            this.startBroadcastTimeout = null;
            this.killStartBroadcastAudioTimer = null;
        }

        setDoubleStartTimeout(delay) {
            this.clearDoubleTimeout();
            const handler = () => {
                console.log('[PipComponent] start broadcast after timeout');
                this.clearDoubleTimeout();
                this.onStartBroadcast();
            };
            this.startBroadcastTimeout = setTimeout(handler, delay);
            this.killStartBroadcastAudioTimer = timer(handler, delay);
        }

        async applyConfig(config) {
            console.log('[PipComponent] apply config', config, this.config);

            await this.importHive(config);

            const oldConfig = cloneDeep(this.config);
            this.config = config;

            this.resolveBroadcastState({ oldConfig, newConfig: config });
            this.resolveUseMetadata(this.config);

            if (!this.needsCookies) {
                this.onNewUrl(config.url);
            }

            this.ready = true;
            this.$scope.$applyAsync();
        }

        async importHive() {
            if (!get(this, 'config.hiveConfig.jwt')) {
                return;
            }
            await this.libLoaderService.load(getStaticAsset, 'hiveHls').catch(err => console.error('[PipComponent] could not load hiveHls', err));
            await this.libLoaderService.load(getStaticAsset, 'hive').catch(err => console.error('[PipComponent] could not load hive', err));
        }

        resolveBroadcastState({ oldConfig, newConfig }) {
            const nowTimestamp = nowUnixTimestamp();

            if (!this.broadcastStarted && newConfig.is_live && !newConfig.is_finished) {
                const isStarting = !oldConfig.is_live;
                const hasStartedALongTimeAgo = newConfig.is_live_timestamp < nowTimestamp - START_BROADCAST_DELAY_WINDOW;
                const hasJustStarted = !hasStartedALongTimeAgo;

                if (hasStartedALongTimeAgo || this.isThirdParty) {
                    console.info(`[PipComponent] Stream went live more than ${START_BROADCAST_DELAY_WINDOW} secs ago or is thirdParty. Starting player`);
                    this.onStartBroadcast(newConfig);
                } else if (isStarting || hasJustStarted) {
                    console.info(`[PipComponent] Start broadcast action will be scheduled for ${START_BROADCAST_DELAY_WINDOW}s after is_live_timestamp if not triggered before.`);
                    const delayBeforeEndOfDelayWindow = newConfig.is_live_timestamp + START_BROADCAST_DELAY_WINDOW - nowTimestamp;
                    this.setDoubleStartTimeout(delayBeforeEndOfDelayWindow * 1000);
                }
            } else if (this.broadcastStarted && !newConfig.is_live &&
                (newConfig.is_finished || this.isThirdParty)) {
                const isStopping = !oldConfig.is_finished;
                const hasStoppedALongTimeAgo = newConfig.is_finished_timestamp < nowTimestamp - STOP_BROADCAST_DELAY_WINDOW;
                const hasJustStopped = !hasStoppedALongTimeAgo;

                if (hasStoppedALongTimeAgo || this.isThirdParty) {
                    console.info(`[PipComponent] stopped live more than ${STOP_BROADCAST_DELAY_WINDOW} secs ago or Third party stream or Id3 not supported, stopping player.`);
                    this.onStopBroadcast(newConfig);
                }
                if (isStopping || hasJustStopped) {
                    console.info(`[PipComponent] Stop broadcast action will be scheduled in ${STOP_BROADCAST_DELAY_WINDOW}s if not triggered before.`);
                    this.stopBroadcastTimeout = setTimeout(() => {
                        this.onStopBroadcast();
                    }, STOP_BROADCAST_DELAY_WINDOW * 1000);
                }
            }
        }

        onNewUrl(newUrl) {
            console.log('[PipComponent] onNewUrl', { newUrl, oldUrl: this.oldUrl });
            if (!newUrl) {
                this.sourceUrl = null;
                this.oldUrl = newUrl;
                return;
            }

            if (isSameUrlWithoutQuery(newUrl, this.oldUrl)) {
                console.log('same url, not updating');

                if (newUrl && this.needsCookies) {
                    // the expires params of the query string might have changed, so we need to update the cookies
                    this.videoPlayerService.getCookies(newUrl);
                }

                return;
            }
            this.oldUrl = newUrl;

            if (!this.isSafari) {
                return this.setMedia(newUrl);
            }

            // Safari doesn't support `referrerpolicy` attribute on the iframe
            // so we change the meta referrer for a bit and restore it when the page is loaded
            // needed for Vimeo secured stream that check whitelisted domains from referrer
            const prevReferrerVal = replaceReferrer('origin');
            this.setMedia(newUrl);

            angular.element(() => replaceReferrer(prevReferrerVal));
        }

        setMedia(url) {
            console.log('[PipComponent] set media', { url });
            if (!url) {
                return;
            }

            this.sourceUrl = url.toString();
            this.isIframeContent = !isPlayableVideoUrl(this.sourceUrl);

            if (this.isIframeContent) {
                const embedable = convertEmbedableUrl(this.sourceUrl);
                this.sourceUrl = this.$sce.trustAsResourceUrl(embedable);
            }
        }

        maximise() {
            if (this.pictureInPictureService.isFloating) {
                this.pictureInPictureService.embed();
            }

            // this should ideally use ngClass but we can't access $element in any other way
            if (this.$element.is('.pinned')) {
                this.positionToRestore = {
                    top: this.$element.css('top'),
                    left: this.$element.css('left')
                };
            } else {
                this.positionToRestore = null;
            }

            this.$element.css({ top: '', left: '' }).addClass('maximized');
            this.$eventBus.emit('theatreMode:enabled');
        }

        minimise() {
            if (this.isWebinar) return;

            if (this.positionToRestore) {
                this.$element.css(this.positionToRestore);
                delete this.positionToRestore;
            }

            this.$element.removeClass('maximized');
            this.$eventBus.emit('theatreMode:disabled');
        }

        close() {
            this.$eventBus.emit('theatreMode:disabled');
            this.pictureInPictureService.embed();
            this.onClose();
        }
    }
};
