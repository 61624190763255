export const FeedPostAttachmentsComponent = {
    template: require('./feed-post-attachments.jade'),
    bindings: {
        attachments: '<'
    },
    controller: class FeedPostAttachmentsComponent {
        /* @ngInject */ constructor(navService) {
            this.navService = navService;
        }

        open({ action }) {
            this.navService.openDocument(action.params);
        }
    }
};
