import { cloneDeep, debounce, get, isEmpty, isEqual, isFunction } from 'lodash';
import { questionLinks, unixFromNow } from '../../../../filters';
import { Tab } from './Tab';
import { nowUnixTimestamp } from '../../../../utils/time';

const SortOptions = Object.freeze({
    Newest: 'newest',
    Upvotes: 'upvotes'
});

const AlignOptions = Object.freeze({
    Bottom: 'bottom',
    Top: 'top'
});

export class Qna extends Tab {

    async init(livestreamId, initialLivestreamUpdate) {
        const sortOptions = [
            {
                id: SortOptions.Newest,
                showOrientationToggler: true,
                defaultOrientation: 'bottomToTop'
            },
            {
                id: SortOptions.Upvotes,
                showOrientationToggler: false,
                defaultOrientation: 'topToBottom'
            }
        ];
        this.livestreamId = livestreamId;
        this.showUserInitials = get(this.services.settings, 'person.show_initials', true);
        this.upvotedQuestionIds = [];
        this.pendingUpvotes = [];
        this.pendingDownvotes = [];
        this.sorting = {
            sortBy: SortOptions.Newest,
            options: sortOptions
        };
        this.debouncedVoteQuestionsRequest = debounce(this.sendVoteQuestionsRequest.bind(this), 1000);

        this.listeners.push(
            await this.services.signalService.addSignalListener(
                this.services.qnaService.updateSignal(livestreamId),
                signal => this.onUpdateSignal(signal)
            ),
            // on reconnect only get initial state (polls/qna)
            this.services.signalService.addEventListener(
                'open', // 'open' will be emitted after the initial connection and after a reconnect
                () => this.getInitialState(livestreamId)
            )
        );

        if (initialLivestreamUpdate) {
            // Qna can be enabled mid stream, to avoid overloading the server with all the pax requesting the qna list at the same time,
            // we don't fetch the current list, which is likely empty. Next qna Signal/id3 will fill the list.
            await this.getInitialState(livestreamId);
        }
    }

    isUpvotingEnabled(context = this.context) {
        return get(context, 'livestream.qna_upvoting_enabled', false);
    }

    isAskOnVodEnabled(context = this.context) {
        return get(context, 'livestream.qna_ask_on_vod', false);
    }

    /**
     * Get the initial live-stream state
     */
    async getInitialState(livestreamId) {
        const {
            qna: questions,
            qnaSortedByUpvotes: questionsSortedByUpvotes,
            qnaUpvotedQuestions: upvotedQuestionIds
        } = await this.services.qnaService.getQuestions(livestreamId) || {};
        this.upvotedQuestionIds = upvotedQuestionIds;
        this.updateQuestions({ questions, questionsSortedByUpvotes });
    }

    addUpvote(questionId) {
        this.markAsUpvoted(questionId);
        this.updatePlayerHandler();

        if (this.pendingDownvotes.includes(questionId)) {
            this.pendingDownvotes.splice(this.pendingDownvotes.indexOf(questionId), 1);
            return;
        }
        this.pendingUpvotes.push(questionId);
        this.debouncedVoteQuestionsRequest();
    }

    markAsUpvoted(questionId) {
        this.upvotedQuestionIds.push(questionId);
        this.getConcatenatedQuestions()
            .filter(q => q.id === questionId)
            .forEach(q => {
                q.upvoted = true;
                q.upvotes += 1;
            });
    }

    removeUpvote(questionId) {
        this.unmarkAsUpvoted(questionId);
        this.updatePlayerHandler();

        if (this.pendingUpvotes.includes(questionId)) {
            this.pendingUpvotes.splice(this.pendingUpvotes.indexOf(questionId), 1);
            return;
        }
        this.pendingDownvotes.push(questionId);
        this.debouncedVoteQuestionsRequest();
    }

    unmarkAsUpvoted(questionId) {
        const index = this.upvotedQuestionIds.indexOf(questionId);
        if (index !== -1) {
            this.upvotedQuestionIds.splice(index, 1);
        }
        this.getConcatenatedQuestions()
            .filter(q => q.id === questionId)
            .forEach(q => {
                q.upvoted = false;
                q.upvotes -= 1;
            });
    }

    getConcatenatedQuestions() {
        const questions = this.questions || [];
        const questionsSortedByUpvotes = this.questionsSortedByUpvotes || [];
        return questions.concat(questionsSortedByUpvotes);
    }

    sendVoteQuestionsRequest() {
        if (!this.pendingUpvotes.length && !this.pendingDownvotes.length) {
            return;
        }

        this.services.qnaService.voteQuestions(this.livestreamId, { upvotes: this.pendingUpvotes, downvotes: this.pendingDownvotes });
        this.pendingUpvotes = [];
        this.pendingDownvotes = [];
    }

    getQuestion(questionId) {
        return this.questions && this.questions.find(q => q.id === questionId) ||
            this.questionsSortedByUpvotes && this.questionsSortedByUpvotes.find(q => q.id === questionId);
    }

    onUpdateSignal(signal) {
        if (signal.questions) signal.questions.forEach(q => this.adjustUpvotesOfPendingQuestion(q));
        if (signal.questionsSortedByUpvotes) signal.questionsSortedByUpvotes.forEach(q => this.adjustUpvotesOfPendingQuestion(q));
        this.updateQuestions(signal);
    }

    /**
     * Checks if there is an upvote or downvote pending to be sent for the given question.
     * If there is, the question is modified to include the pending upvote/downvote.
     * @param {Object} question question coming from a signal
     */
    adjustUpvotesOfPendingQuestion(question) {
        if (question.upvotes != null) {
            if (this.pendingUpvotes.includes(question.id)) {
                question.upvotes++;
                question.upvoted = true;
            } else if (this.pendingDownvotes.includes(question.id)) {
                question.upvotes--;
                question.upvoted = false;
            }
        }
    }

    /**
     * Loads the questions
     * @param {Object} [signal] received signal if any
     */
    updateQuestions(signal) {
        if (!signal) {
            return;
        }

        const oldQuestions = cloneDeep(this.questions || []);
        const oldQuestionsSortedByUpvotes = cloneDeep(this.questionsSortedByUpvotes || []);
        const oldQuestionsMap = oldQuestions
            .concat(oldQuestionsSortedByUpvotes)
            .reduce((acc, q) => ({ ...acc, [q.id]: q }), {});

        if (signal.questions) {
            this.questions = signal.questions
                .reverse()
                .map(q => this.formatQuestion(q, oldQuestionsMap[q.id]))
                .filter(q => q);
        }

        if (signal.questionsSortedByUpvotes) {
            this.questionsSortedByUpvotes = signal.questionsSortedByUpvotes
                .map(q => this.formatQuestion(q, oldQuestionsMap[q.id]))
                .filter(q => q);
        }

        const visibleQuestionsHaveChanged =
            (this.sorting.sortBy === SortOptions.Newest && !isEqual(oldQuestions, this.questions)) ||
            (this.sorting.sortBy === SortOptions.Upvotes && !isEqual(oldQuestionsSortedByUpvotes, this.questionsSortedByUpvotes));
        if (visibleQuestionsHaveChanged) {
            this.updatePlayerHandler();
        }
    }

    formatQuestion(question, oldQuestion = {}) {
        if (isEmpty(question)) {
            return null;
        }
        question = this.services.qnaService.resolveProfilePicture(question, this.context.eventIcon);
        const fromBstg = this.services.qnaService.isFromBSTG(question);
        const darkMode = this.services.THEME.get('appearance') === 'dark';

        const formattedQuestion = {
            id: question.id,
            question: questionLinks(this.services.$filter, this.services.$i18n, question.question),
            sent: unixFromNow(minimizeQuestionTime(question.sent)),
            status: question.status,
            showUserInitials: this.showUserInitials,
            upvotes: get(question, 'upvotes', oldQuestion.upvotes || 0),
            upvoted: get(question, 'upvoted', oldQuestion.upvoted || false),
            userName: question.participant,
            userId: question.pid,
            profilePicture: question.profile_pic,
            styles: {
                'background-color': fromBstg ? darkMode ? '#18191A' : '#F3F3F3' : ''
            }
        };

        // If the question was upvoted before being archived and is now unarchived we set the upvoted boolean to true
        // Also applies to questions reappearing in the list because of the page limit
        if (!formattedQuestion.upvoted && this.upvotedQuestionIds.includes(formattedQuestion.id)) {
            formattedQuestion.upvoted = true;
        }

        return formattedQuestion;
    }

    onSideBarEvent({ newQuestion, anonymous, onSuccess, onError }) {
        if (!newQuestion) return;

        console.info('[Qna] Asking the question:', anonymous, newQuestion);

        this.services.qnaService.postQuestion(this.livestreamId, { anonymous, question: newQuestion })
            .then(() => {
                if (isFunction(onSuccess)) {
                    onSuccess();
                }
            })
            .catch(error => {
                console.error("[LiveStreamSidebar] Couldn't post the question.", error);
                if (isFunction(onError)) {
                    onError();
                }
            });
    }

    onEvent(payload) {
        this.onQnaMetadata(payload);
    }

    onQnaMetadata(payload) {
        if (!this.useMetadata) return;
        this.highlightedQuestionFromMetadata = payload;
        this.updatePlayerHandler();
    }

    updateSortBy(sortOption) {
        if (!Object.values(SortOptions).includes(sortOption)) {
            return;
        }
        this.sorting.sortBy = sortOption;
        this.updatePlayerHandler();
    }

    getSortedQuestions() {
        if (!this.isUpvotingEnabled()) {
            return this.questions;
        }
        return this.sorting.sortBy === SortOptions.Newest ? this.questions : this.questionsSortedByUpvotes;
    }

    getAlign() {
        if (!this.isUpvotingEnabled() || !this.sorting) {
            return AlignOptions.Bottom;
        }
        return this.sorting.sortBy === SortOptions.Newest ? AlignOptions.Bottom : AlignOptions.Top;
    }

    get config() {
        const highlightedQuestionOnTop = this.isStreamless || this.isSmallScreen;
        let sortedQuestions = this.getSortedQuestions() || [];
        let highlightedQuestion;
        if (highlightedQuestionOnTop) {
            if (this.useMetadata) {
                const qid = this.highlightedQuestionFromMetadata?.id;
                // Coming from metadata, highlightedQuestionFromMetadata might not match 100% the same in the list coming from signal
                // It might have been archived in the meantime, or been modified. To prevent any unexpected behavior,
                // we will display the version coming from the metadata to match what's seen in BTSG at the time of the highlight
                highlightedQuestion = this.formatQuestion(this.highlightedQuestionFromMetadata);
                // upvotes on the other hand need to be sync in real time
                const signalQuestion = this.getQuestion(highlightedQuestion?.id);
                if (signalQuestion) {
                    highlightedQuestion.upvoted = signalQuestion.upvoted;
                    highlightedQuestion.upvotes = signalQuestion.upvotes;
                }
                sortedQuestions = sortedQuestions.filter(q => q.id !== qid);
            } else {
                highlightedQuestion = sortedQuestions.find(q => q.status === 'highlighted');
                sortedQuestions = sortedQuestions.filter(q => q.status !== 'highlighted');
            }
        }

        if (!isEmpty(highlightedQuestion)) {
            this.showNotification(null);
        }

        return {
            id: 'qna',
            align: this.getAlign(),
            questions: sortedQuestions,
            highlightedQuestion,
            anonymousAllowed: get(this, 'context.livestream.qna_anonymous_enabled'),
            throttleAskingQuestions: !get(this, 'context.livestream.qna_moderation_enabled', true),
            upvotingEnabled: this.isUpvotingEnabled(),
            upvotingAllowed: this.services.liveStreamService.isUpvotingAllowed(get(this, 'context.livestream')),
            sorting: this.isUpvotingEnabled() ? this.sorting : null,
            disableSendingQuestions: this.streamEnded && !this.isAskOnVodEnabled()
        };
    }
}

function minimizeQuestionTime(ts) {
    // we minimize the timestamp to guarantee that there's no weird displays SS-12448
    const now = nowUnixTimestamp();
    return Math.min(ts, now);
}

