export default function i18nWithFallback($i18n, key, fallback, context) {
    const translated = $i18n(key);
    let label = translated === key ? fallback : translated;
    if (context) {
        label = label.replace(
            /\{\{([^\}]+)\}\}/gi,
            (match, capture) => context[capture] || ''
        );
    }
    return label;
}
