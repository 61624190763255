import { cloneDeep, get, isEmpty, isEqual, isFunction, isString } from 'lodash';
import { Tab } from './Tab';

const POLL_TYPES = {
    WORD_CLOUD: 'word_cloud',
    MULTIPLE_CHOICE: 'multiple_choice'
};

const ERROR_TYPES = {
    ANSWER_CONTAINS_BLOCKED_WORDS: 'ANSWER_CONTAINS_BLOCKED_WORDS',
    ANSWER_LIMIT_EXCEEDED: 'ANSWER_LIMIT_EXCEEDED',
    CHARACTER_LIMIT_EXCEEDED: 'CHARACTER_LIMIT_EXCEEDED',
    MULTI_WORDS_NOT_ALLOWED: 'MULTI_WORDS_NOT_ALLOWED',
};

const VOTING_ERROR_MESSAGES = (isWordCloud) => ({
    [ERROR_TYPES.MULTI_WORDS_NOT_ALLOWED]: 'youCannotSubmitMultipleWords',
    [ERROR_TYPES.CHARACTER_LIMIT_EXCEEDED]: 'charLimitExceeded',
    [ERROR_TYPES.ANSWER_LIMIT_EXCEEDED]: isWordCloud ? 'youCannotSubmitMore' : 'alreadyVoted',
    DEFAULT: 'anErrorOccurred',
    [ERROR_TYPES.ANSWER_CONTAINS_BLOCKED_WORDS]: 'blockedWords'
});

export class Polls extends Tab {

    constructor(...args) {
        super(...args);

        this.userAnswers = {};
        this.blockedAnswers = {};
        this.targetingCache = {};
        this.showPollIncoming = false;
    }

    /* --------------
     * lifecycle
     * -------------- */

    async init(livestreamId, initialLivestreamUpdate) {
        this.pollResultsListeners = {};

        this.livestreamId = livestreamId;

        this.services.pollService
            .onPollExecuted(this.livestreamId, payload => this.onPollExecutedSignal(payload))
            .then(remover => this.listeners.push(remover));

        this.services.pollService
            .onExecutionUpdate(this.livestreamId, payload => this.onExecutionUpdate(payload))
            .then(remover => this.listeners.push(remover));

        this.services.pollService
            .onHideResults(this.livestreamId, payload => this.onHideResultsSignal(payload))
            .then(remover => this.listeners.push(remover));

        this.listeners.push(
            // on reconnect only get initial state (polls/qna)
            this.services.signalService.addEventListener(
                'open', // 'open' will be emitted after the initial connection and after a reconnect
                () => this.getInitialState()
            )
        );

        if (initialLivestreamUpdate) {
            // Polls can be enabled mid-stream, to avoid overloading the server with all the pax requesting the current poll at the same time,
            // We don't fetch it, BE should close a poll if polls are being disabled
            await this.getInitialState();
        }
    }

    destroy() {
        super.destroy();
        this.removeAllResultsListeners();
    }

    async getInitialState() {
        const response = await this.services.pollService.getCurrentPoll(this.livestreamId) || {};
        const pollExecutionId = get(response, 'pollExecution.id');
        if (!pollExecutionId) {
            return;
        }
        this.setUserAnswer(pollExecutionId, response.userAnswers);
        this.setBlockedAnswers(pollExecutionId, response.blockedUserAnswers);

        if (!this.useMetadata) {
            // When we use the id3 metadata, we should not display the poll any other way
            // But we still need the user's own answers if they reload the page,
            // so we always set the user answers on getInitialState, but not the pollExecution, which will come later on
            this.setPollExecution(response.pollExecution);
        } else {
            this.toggleShowPollIncoming(true);
        }

        // on initial state, if there's a poll execution, always open that tab first
        // when on metadata, we will see the 'poll incoming' message on the tab if id3 did not arrive yet
        this.forceUserToGoToPolls();
    }

    setUserAnswer(pollExecutionId, userAnswers) {
        if (!userAnswers) {
            return;
        }
        const previousAnswers = get(this, `userAnswers.${pollExecutionId}`, []);
        if (isString(userAnswers)) {
            userAnswers = [ userAnswers ];
        }
        this.userAnswers[pollExecutionId] = [ ...previousAnswers, ...userAnswers ];
    }

    setBlockedAnswers(pollExecutionId, answers = []) {
        if (!answers || !answers.length) return;
        const previousBlocked = get(this, `blockedAnswers.${pollExecutionId}`, []);
        this.blockedAnswers[pollExecutionId] = [ ...previousBlocked, ...answers ];
    }

    /* --------------
     * Signal/metadata handlers
     * -------------- */

    onPollExecutedSignal(payload) {
        this.toggleShowPollIncoming(true);
        if (this.useMetadata) return;
        this.setPollExecution(get(payload, 'pollExecution', {}));
    }

    onExecutionUpdate(payload) {
        this.toggleShowPollIncoming(false);
        if (this.useMetadata) return;
        this.setPollExecution(get(payload, 'pollExecution', {}));
    }

    onHideResultsSignal(payload) {
        this.toggleShowPollIncoming(false);
        if (this.useMetadata) return;
        if (get(payload, 'hiddenExecutionsIds', []).includes(this.executionId)) {
            this.setPollExecution({});
        }
    }

    toggleShowPollIncoming(enable) {
        this.showPollIncoming = enable;
        this.applyConfig();
    }

    onWordCloudResultsSignal(executionId, payload = {}) {
        if (executionId !== this.executionId || !payload || !payload.pollExecution) {
            // result signals are throttled, so we might get results from a previous poll
            return;
        }

        if (this.useMetadata && !this.isVod) {
            // During live, we base the word cloud state on the id3 tags, while the results on the signal
            if (this.executionId === payload.pollExecution.id) {
                this.setPollExecution({
                    ...this._pollExecution,
                    word_cloud_results: payload.pollExecution.word_cloud_results
                });
            }
        } else {
            this.setPollExecution(payload.pollExecution);
        }
    }

    onMultipleChoiceResultsSignal(executionId, payload = {}) {
        if (executionId !== this.executionId || !payload || !payload.pollExecution) {
            // result signals are throttled, so we might get results from a previous poll
            return;
        }

        if (this.useMetadata && !this.isVod) {
            if (this.executionId === payload.pollExecution.id) {
                this.setPollExecution({
                    ...this._pollExecution,
                    results: payload.pollExecution.results
                });
            }
        } else {
            this.setPollExecution({
                ...payload.pollExecution,
                results: payload.results
            });
        }
    }

    onPollMetadata(payload) {
        if (!this.useMetadata) return;
        this.setPollExecution(payload || {});
    }

    onEvent(payload) {
        this.onPollMetadata(payload);
    }

    /**
     * A notification on top of the player will appear, inviting user to go to the poll tab
     * A click on that notification will bring the user to the poll tab
     */
    inviteUserToGoToPolls(state) {
        console.info('[Polls] Inviting user to change to poll tab');
        if (!state || !this.question) {
            return this.showNotification(null);
        }
        const pollsLabel = get(this, 'context.labels.side-bar.polls', {});
        const buttonText = state === 'results' ? pollsLabel.seeResults : pollsLabel.goToPoll;
        const title = state === 'results' ? pollsLabel.seeResults : pollsLabel.joinPoll;

        this.showNotification({
            toTab: 'polls',
            title,
            subtitle: this.question,
            buttonText
        });
    }

    /**
     * The user will land immediately in the polls tab
     */
    forceUserToGoToPolls() {
        console.info('[Polls] Changing to poll tab immediately');
        this.showNotification({
            toTab: 'polls',
            immediateTabSwitch: true
        });
    }

    onSideBarEvent({ pollId: executionId, answer, answers, onSuccess, onError }) {
        if (!executionId || executionId !== this.executionId) return;

        console.info('[Poll] new vote:', executionId, answers);

        if (this.isWordCloud && !isString(answer)) {
            return;
        }

        if (this.pollType === POLL_TYPES.MULTIPLE_CHOICE && (!answers || !answers.length)) {
            return;
        }

        const errorHandler = (message) => {
            if (isFunction(onError)) {
                onError(message);
            }
        };

        const successHandler = (message) => {
            if (isFunction(onSuccess)) {
                onSuccess(message);
            }
        };

        if (this.isWordCloud) {
            answer = answer
                .toLowerCase()
                .trim()
                .replace(/\s+/g, ' ');
        }

        const pollsLabel = get(this, 'context.labels.side-bar.polls', {});
        if (this.isWordCloud && get(this, `userAnswers.${executionId}`, []).includes(answer)) {
            return errorHandler(pollsLabel.alreadySubmittedAnswer);
        }

        const newUserAnswers = answers || [ answer ];
        this.services.pollService.vote(this.livestreamId, executionId, newUserAnswers)
            .then(() => {
                this.setUserAnswer(executionId, newUserAnswers);
                const canSubmitMore = this.answerLimit - get(this, `userAnswers.${executionId}.length`, 0) > 0;
                successHandler(canSubmitMore ? pollsLabel.youCanSubmitMore : pollsLabel.thankYouForAnswer);
                this.applyConfig();
            })
            .catch(error => {
                console.error("[LiveStreamSidebar] Couldn't vote.", error);
                const errorType = get(error, 'data.error', 'DEFAULT');
                if (errorType === ERROR_TYPES.ANSWER_CONTAINS_BLOCKED_WORDS) {
                    this.setBlockedAnswers(executionId, newUserAnswers);
                } else if (errorType === ERROR_TYPES.ANSWER_LIMIT_EXCEEDED) {
                    // This should not happen, normally the UI should prevent sending more answers than what backend will allow,
                    // but just in case we add last answer to blocked ones, to disable the input
                    this.setBlockedAnswers(executionId, newUserAnswers);
                }
                const errorLabelKey = VOTING_ERROR_MESSAGES(this.isWordCloud)[errorType];
                errorHandler(pollsLabel[errorLabelKey]);
                this.applyConfig();
            });
    }

    /* --------------
     * Word cloud real time result signal listener
     * -------------- */

    async addWordCloudResultsListener(executionId) {
        if (this.pollResultsListeners.hasOwnProperty(executionId)) {
            return;
        }
        this.removeAllResultsListeners();

        if (this.isWordCloud) {
            this.pollResultsListeners[executionId] = await this.services.pollService
                .onWordCloudResults(this.livestreamId, executionId, (payload) => {
                    this.onWordCloudResultsSignal(executionId, payload);
                });
        }
    }

    async addMultipleChoiceResultsListener(executionId) {
        if (this.pollResultsListeners.hasOwnProperty(executionId)) {
            return;
        }
        this.removeAllResultsListeners();

        this.pollResultsListeners[executionId] = await this.services.pollService
            .onMultipleChoiceResults(this.livestreamId, executionId, (payload) => {
                this.onMultipleChoiceResultsSignal(executionId, payload);
            });
    }

    removeResultsListener(executionId) {
        const remover = this.pollResultsListeners[executionId];

        if (isFunction(remover)) {
            remover();
        }

        delete this.pollResultsListeners[executionId];
    }

    removeAllResultsListeners() {
        const resultsListeners = Object.keys(this.pollResultsListeners);
        for (const executionId of resultsListeners) {
            this.removeResultsListener(executionId);
        }
    }

    applyConfig() {
        if (isEqual(this.config, this.previousConfig)) {
            return;
        }
        this.previousConfig = cloneDeep(this.config);
        this.updatePlayerHandler();
    }

    reset() {
        this._pollExecution = {};
        this.services.pollService.onResultsHidden();
        this.inviteUserToGoToPolls();
        this.applyConfig();
    }

    /* --------------
     * getters and setters
     * -------------- */

    async setPollExecution(pollExecution) {

        // Resolve states in which to reset the poll state
        if (isEmpty(pollExecution)) {
            return this.reset();
        }

        // Remove deprecated properties that shouldn't be used
        if (!isEmpty(pollExecution)) {
            delete pollExecution.results_released;
        }

        // Store some previous values for comparison
        const oldExecutionId = this.executionId;
        const newExecutionId = get(pollExecution, 'id');
        const oldShowFinalResults = this.showFinalResults;

        // Check if the user can see the new poll
        if (this.services.EVENT.is_webinar) {
            this.targetingCache[newExecutionId] = true; // no poll targeting on webinars
        }
        else if (!this.targetingCache.hasOwnProperty(newExecutionId)) {
            this.targetingCache[newExecutionId] = await this.services.targetingService.isUserTargeted(pollExecution);
        }

        if (!this.targetingCache[newExecutionId]) {
            return this.reset();
        }

        // Compute the end of countdown if any
        pollExecution = addCountdownEndTimestamp(pollExecution, this._pollExecution, this.useMetadata);

        // For word clouds we listen to both id3 tags and signals, so we receive out of order intermediate results.
        // Votes can never go down, so we always keep the version with the most votes
        if (!isEmpty(pollExecution) && newExecutionId === oldExecutionId) {
            if (this.isWordCloud) {
                const oldWordCloudResults = get(this, '_pollExecution.word_cloud_results', []);
                const newWordCloudResults = pollExecution.word_cloud_results || [];
                const oldVotes = oldWordCloudResults.reduce((acc, { votes }) => acc + votes, 0);
                const newVotes = newWordCloudResults.reduce((acc, { votes }) => acc + votes, 0);
                if (oldVotes > newVotes) {
                // set the new word_cloud_results to the old one, as it contained more votes
                    pollExecution.word_cloud_results = oldWordCloudResults;
                }
            } else {
                const oldResults = get(this, '_pollExecution.results', {});
                const newResults = pollExecution.results || {};
                const oldVotes = Object.values(oldResults).reduce((acc, votes) => acc + votes, 0);
                const newVotes = Object.values(newResults).reduce((acc, votes) => acc + votes, 0);
                if (oldVotes > newVotes) {
                // set the new results to the old one, as it contained more votes
                    pollExecution.results = oldResults;
                }
            }
        }

        // Save new state
        this._pollExecution = pollExecution;

        // voting was closed without showing the results, we can clear it all
        if (this.votingClosed && !this.showFinalResults) {
            return this.reset();
        }

        // On VOD for multi-choice polls we need to check if showResultsLive
        // is enabled or if results are already released after voting
        if (this.isVod && !this.isWordCloud && !this.showResults) {
            return this.reset();
        }

        // Resolve real time results listener state
        if (isEmpty(this._pollExecution) || this.votingClosed) {
            this.removeAllResultsListeners();
        } else if (this.isWordCloud) {
            this.addWordCloudResultsListener(this.executionId).catch(error => console.error(error));
        } else if (this.showLiveResultsAfterUserVoted) {
            this.addMultipleChoiceResultsListener(this.executionId).catch(error => console.error(error));
        } else {
            this.removeAllResultsListeners();
        }

        // Resolves user notification (above video player 'go to poll' message)
        if (this.votingClosed && this.showFinalResults && (oldShowFinalResults !== this.showFinalResults || oldExecutionId !== newExecutionId)) {
            this.services.pollService.onResultsShown(newExecutionId);
            this.inviteUserToGoToPolls('results');
        } else if (newExecutionId && oldExecutionId !== newExecutionId && !this.hasVoted) {
            this.services.pollService.onExecution(newExecutionId);
            this.inviteUserToGoToPolls('executed');
        }

        // apply the resulting config
        this.applyConfig();
    }

    get executionId() {
        return get(this, '_pollExecution.id');
    }

    get question() {
        return get(this, '_pollExecution.question');
    }

    get answers() {
        const answers = get(this, '_pollExecution.answers', []);
        const userAnswers = get(this, `userAnswers.${this.executionId}`);
        if (userAnswers) {
            for (const answer of answers) {
                answer.selected = userAnswers.includes(answer.id);
            }
        }
        return answers;
    }

    get showLiveResultsAfterUserVoted() {
        if (!this._pollExecution) {
            return false;
        }
        return !!this._pollExecution.show_results_live;
    }

    get showFinalResults() {
        if (!this._pollExecution) {
            return false;
        }
        return !!this._pollExecution.show_results;
    }

    get showLiveResults() {
        if (!this._pollExecution) {
            return false;
        }
        return this.hasVoted && this.showLiveResultsAfterUserVoted;
    }

    get showResults() {
        return this.showFinalResults || this.showLiveResults;
    }

    get votingClosed() {
        if (!this._pollExecution) {
            return false;
        }
        return !!this._pollExecution.voting_closed;
    }

    get results() {
        if (this.isWordCloud) {
            const word_cloud_results = get(this, '_pollExecution.word_cloud_results', []);
            const userAnswers = get(this, `userAnswers.${this.executionId}`, []);
            userAnswers.forEach(answer => {
                const match = word_cloud_results.find(e => e.text === answer);
                // If an answer already exists, we can't know if it's our own or someone else's.
                // To prevent adding our own answers twice (which eventually happens when id3 arrives with our votes),
                // we only add our own answer if it doesn't exist.
                if (!match) {
                    word_cloud_results.push({ text: answer, votes: 1 });
                }
            });
            return word_cloud_results;
        }

        if (!this.showResults) {
            return null;
        }

        const results = get(this, '_pollExecution.results', {});
        const answers = get(this, '_pollExecution.answers', []);
        const total = Object.keys(results).reduce((acc, x) => acc + results[x], 0);
        return answers.map((answer) => ({
            ...answer,
            votes: results[answer.id] || 0,
            percentage: (((results[answer.id] || 0) / total) * 100) || 0
        }));
    }

    get isWordCloud() {
        return this.pollType === POLL_TYPES.WORD_CLOUD;
    }

    get hasVoted() {
        return !!get(this, `userAnswers.${this.executionId}.length`);
    }

    get pollType() {
        return get(this, '_pollExecution.poll_type');
    }

    get characterLimit() {
        return get(this, '_pollExecution.character_limit');
    }

    get allowMultiWords() {
        return get(this, '_pollExecution.allow_multi_words');
    }

    get blockedWordsCount() {
        return get(this, `blockedAnswers.${this.executionId}.length`, 0);
    }

    get answerLimit() {
        const votedCount = get(this, `userAnswers.${this.executionId}.length`, 0);
        const allowedCount = get(this, '_pollExecution.answer_limit', 0);
        return Math.max(allowedCount - votedCount - this.blockedWordsCount, 0);
    }

    get countdownEndTimestamp() {
        return get(this, '_pollExecution.countdownEndTimestamp', -1);
    }

    get config() {
        const allowVoting = !this.votingClosed && !this.isVod;

        const config = {
            id: 'polls',
            pollId: this.executionId,
            pollType: this.pollType,
            question: this.question,
            characterLimit: this.characterLimit,
            allowMultiWords: this.allowMultiWords,
            answerLimit: this.answerLimit,
            answers: this.answers,
            hasVoted: this.hasVoted,
            allowVoting,
            results: this.results,
            showResults: this.showResults,
            showPollIncoming: this.showPollIncoming,
            wordCloudConfig: {
                colors: get(this, 'livestream.polls_colours'),
                largeWordCloud: false,
            },
            ...(this.isSmallScreen || this.isInPerson ? { preventExpand: true } : {}),
        };

        if (allowVoting) {
            config.countdownEndTimestamp = this.countdownEndTimestamp;
            config.countdownDuration = get(this, '_pollExecution.countdown_duration');
        }

        return config;
    }
}


function addCountdownEndTimestamp(newExecution, oldExecution = {}, useMetadata = false) {
    if (isEmpty(newExecution)) {
        return newExecution;
    }

    const countdownDuration = newExecution.countdown_duration;
    const votingOpenedTimestamp = newExecution.voting_opened_timestamp;

    if (!countdownDuration || !votingOpenedTimestamp) {
        return newExecution;
    }

    if (oldExecution.id === newExecution.id && oldExecution.countdownEndTimestamp) {
        // on id3 you can get the same countdown data multiple time, meaning that the backend's now_timestamp is increasingly old
        // we should only consider the first countdown data we get
        const oldDuration = get(oldExecution, 'countdown_duration');
        if (countdownDuration <= oldDuration) {
            newExecution.countdownEndTimestamp = oldExecution.countdownEndTimestamp;
            return newExecution;
        }
    }

    const offset = useMetadata ? 25 : 0;

    newExecution.countdownEndTimestamp = votingOpenedTimestamp + countdownDuration + offset;
    return newExecution;
}
