import $LAB from 'frontloader-common/lab'; // This util is used to load scripts on demand

const libs = {
    opentok: 'js/opentok.js',
    hive: 'js/hivejs.js',
    hiveHls: 'js/hive-hls-module.js'
};

export default class LibLoaderService {

    constructor() {
        this.loadPromises = {};
    }

    async load(appResource, lib) {
        let path = libs[lib];

        if (!path) {
            throw new Error(`[LibLoaderService] Library ${lib} not found`);
        }

        if (this.loadPromises[lib]) {
            return this.loadPromises[lib];
        }

        path = appResource(path);

        this.loadPromises[lib] = loadLibrary(path);

        return this.loadPromises[lib];
    }
}

const loadLibrary = (path) => {
    return new Promise(function(resolve, reject) {
        $LAB.script([ path ]).wait((err) => {
            if (err) {
                return reject(err);
            } else {
                resolve();
            }
        });
    });
};
