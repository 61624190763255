const { debounce } = require('lodash');

/**
 * Make sure the targeted function is executed only once at a time,
 * with a provided delay. Useful for API calls.
 * @param {Function} fn to be executed. Should return a Promise
 * @param {Number} [delay] to be added to debounce
 * @param {Number} [randomJitter] jitter to add on the debounce
 * @return {Function}
 */
module.exports.debounceAndUnique = function(fn, delay = 0, randomJitter = 0) {
    let running = false;
    return debounce(function(...args) {
        if (running) {
            console.log(`${fn.name} is already running`);
            return running;
        }
        running = Promise.resolve(fn(...args));
        running.catch(() => {
            // failsafe
            console.error('Failed to execute debounceAndUnique. Considering adding an error handler on the provided function');
        }).then(() => running = false);
        return running;
    }, delay + Math.random() * randomJitter);
};
