export class CarouselController {
    /* @ngInject */
    constructor(THEME, databaseService, $element, $timeout, styleParser) {
        this.theme = THEME;
        this.databaseService = databaseService;
        this.$element = $element;
        this.$timeout = $timeout;
        this.parser = styleParser;
    }

    $onInit() {
        this.config = this.row.config;
        console.debug(`[${this.constructor.name}] init`, this.config);

        this.style = this.parser.parseMargins(this.config);
        this.title_style = this.parser.parseTextsStyles(this.config, 'title');

        if (this.config.detail) {
            this.label_style = this.parser.parseTextsStyles(
                this.config.detail,
                'label'
            );
        }

        this.$timeout(() => {
            // Setting up negative margins (stick carousel to borders).
            let mLeft = this.$element.find('.carousel').css('marginLeft');
            mLeft = mLeft ? parseInt(mLeft, 10) : 0;

            let mRight = this.$element.find('.carousel').css('marginRight');
            mRight = mRight ? parseInt(mRight, 10) : 0;

            this.$element.find('.outer').css({
                'margin-left': mLeft * -1,
                'margin-right': mRight * -1
            });

            // Forcing slide items widths
            const w = w || this.$element.find('.outer').width();
            const slides = this.$element.find('.item');
            slides.find('.card-block').css({
                height: this.config.card_height,
                width: w * this.config.card_weight - mLeft - mRight,
                'flex-basis': this.config.card_weight * 100 + '%'
            });

            slides.first().css('margin-left', mLeft);
            slides.last().css('margin-right', mRight);

            this.showArrows = false;
            const vpWidth = this.$element.find('.viewport').width();
            let ctWidth = 0;

            this.$element.find('.item').each(function() {
                ctWidth += parseInt($(this).outerWidth(true), 10);
            });

            if (ctWidth - vpWidth > 15) {
                this.showArrows = true;
            }
        });

        const vp = this.$element.find('.viewport');
        const next = this.$element.find('.arrow.right');
        const prev = this.$element.find('.arrow.left');

        next.on('click', e => {
            e.stopPropagation();
            vp.animate(
                {
                    scrollLeft: vp[0].scrollLeft + 200
                },
                { queue: false }
            );
        });

        prev.on('click', e => {
            e.stopPropagation();
            vp.animate(
                {
                    scrollLeft: vp[0].scrollLeft - 200
                },
                { queue: false }
            );
        });
    }

    $onDestroy() {
        this.$element
            .find('.viewport')
            .off('mousedown.carousel mousemove.carousel mouseup.carousel');
        $(document.body).on('mouseup.carousel');
        this.$element.removeClass('panning');
    }

    performLabelAction($event, action) {
        $event.stopPropagation();
        if (this.$element.hasClass('panning')) return;

        action = action
            ? action
            : this.config.detail
                ? this.config.detail.action
                : null;
        // console.debug(`[${this.constructor.name}] action`, action);
        if (!action) return;

        this.databaseService.runAppScript(action.path, action.params);
    }
}
