export const OnFileChangeDirective = [
    'EventEmitter',
    function(EventEmitter) {
        return {
            restrict: 'A',
            scope: {
                onFileChange: '&'
            },
            link(scope, elem) {
                elem.on('change', () =>
                    scope.onFileChange(EventEmitter({ file: elem[0].files[0] }))
                );

                scope.$on('$destroy', () => elem.off('change'));
            }
        };
    }
];
