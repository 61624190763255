import { curryRight, defaults, get, each, isFunction } from 'lodash';

export const AppChromeComponent = {
    template: require('./app-chrome.jade'),
    controller: class AppChromeComponent {
        /* @ngInject */ constructor(
            THEME,
            EVENT_NAVS,
            NAV_TEMPLATES,
            NAV_BAR_CONFIG,
            PID,
            $rootScope,
            $scope,
            $timeout,
            $window,
            $i18n,
            progressBarService,
            navService,
            pictureInPictureService,
            databaseService,
            signalService,
            notificationsService,
            uiService,
            $eventBus,
            $compile,
            $element,
            websocketConnectionReportingService
        ) {
            // necessary until all navs are migrated to components
            $scope.activatedPerson = $rootScope.activatedPerson;
            $scope.device = $rootScope.device;
            $scope.eventTheme = THEME;
            $scope.navTemplates = NAV_TEMPLATES;
            this.pid = PID;

            this.navTemplates = NAV_TEMPLATES;
            this.useNavBar = NAV_BAR_CONFIG.enabled;

            this.$rootScope = $rootScope;
            this.$scope = $scope;
            this.$compile = $compile;
            this.$element = $element;
            this.$eventBus = $eventBus;
            this.$timeout = $timeout;
            this.$window = $window;
            this.$i18n = $i18n;
            this.progressBarService = progressBarService;
            this.navService = navService;
            this.pictureInPictureService = pictureInPictureService;
            this.uiService = uiService;
            this.signalService = signalService;
            this.databaseService = databaseService;
            this.notificationsService = notificationsService;
            this.websocketConnectionReportingService = websocketConnectionReportingService;

            this.appLoading = true;
            this.activeNav = navService.activeNav;
            this.performActions = curryRight(
                navService.performActions.bind(navService)
            )(null);

            this.hasGlobalBlockingTasks =
                progressBarService.hasGlobalBlockingTasks;

            this.containerClass = [];
            if (this.useNavBar) {
                this.containerClass.push('app-nav-bar-mode');
            }

            // used in the template to instantiate the sidebar menu
            this.spotmanNav = EVENT_NAVS.nav_spotman;
            this.currentNuid = null;

            this.back = () => $window.history.back();
            this.onPushNotification = this._onPushNotification.bind(this);

            this.listeners = [];
        }

        $onInit() {
            angular.element('html').attr('lang', this.$i18n.lang || 'en');

            this.websocketConnectionReportingService.init();

            this.$timeout(() => {
                this.appLoading = false;
            });

            this.$timeout(() => {
                this.notificationsService.checkForNewNotification(this.onPushNotification);
                this.notificationsService.startCheckInterval(this.onPushNotification);
            });

            const theatreOnListener = this.$eventBus.on('theatreMode:enabled', () => {
                this.navPanelsClass = 'theatre-mode';
            });
            const theatreOffListener = this.$eventBus.on('theatreMode:disabled', () => {
                this.navPanelsClass = undefined;
            });

            this.signalService.addSignalListener(`pushNotification/${this.pid}`, this.onPushNotification)
                .then(remover => this.listeners.push(remover));

            const notificationInAppListener = this.$eventBus.on('notification:in-app', config => {
                this.$scope.$applyAsync(() => {
                    const scope = this.$rootScope.$new(/* isolate */ true);
                    angular.extend(scope, config);

                    // Append and forget - the component will destroy itself after config.duration seconds
                    this.$element.append(this.$compile('<in-app-notification></in-app-notification>')(scope));
                });
            });

            this.listeners.push(
                theatreOnListener,
                theatreOffListener,
                notificationInAppListener,
                this.$scope.$on('error-banner:display', (event, isVisible) => {
                    const errorBannerVisibleClass = 'error-banner-visible';
                    if (isVisible) {
                        this.containerClass.push(errorBannerVisibleClass);
                    } else {
                        this.containerClass = this.containerClass.filter(
                            c => c !== errorBannerVisibleClass
                        );
                    }
                })
            );

            const body = angular.element(document.body);

            if ('objectFit' in document.documentElement.style === false) {
                body.addClass('no-objectfit');
            }

            // TODO: remove once Spotme/frontloader-common/pull/18 is merged
            const ua = window.navigator.userAgent;
            const iphone = !!ua.match(/iPhone/i);
            const ipad = !!ua.match(/iPad/i);
            const iOS = iphone || ipad;
            const webkit = !!ua.match(/WebKit/i);
            const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

            if (iphone) body.addClass('iphone');
            if (ipad) body.addClass('ipad');
            if (iOSSafari) body.addClass('mobile-safari');

            // add load / unload lifecycle hooks
            this.databaseService.runLifeCycleScripts('on-load');
        }

        scrolled(toTop) {
            if (toTop) {
                this.containerClass = this.containerClass.filter(
                    c => c !== 'scrolled'
                );
            } else {
                this.containerClass.push('scrolled');
            }
        }

        bottomReached() {
            this.$rootScope.$broadcast('bottom-reached');
        }

        _onPushNotification({ notificationLinkId, notificationPayload }) {
            const ctx = get(notificationPayload, 'ctx');
            const docId = get(notificationPayload, 'doc_id');

            if (!this.notificationsService.shouldDisplayNotification(ctx, docId)) {
                return;
            }

            this.notificationsService.trackNotificationReception(notificationPayload);

            notificationPayload = defaults(notificationPayload, {
                backgroundColor: get(this.$scope, 'eventTheme.toolbar.background_color'),
                contentColor: get(this.$scope, 'eventTheme.toolbar.font_color'),
                iconColor: get(this.$scope, 'eventTheme.toolbar.font_color')
            });

            const message = this.uiService.buildNotificationMessage(notificationPayload);

            if (!notificationLinkId) {
                return this.uiService.inAppNotification(message);
            }

            this.databaseService.docWithId(notificationLinkId)
                .then(() => this.uiService.inAppNotification(message))
                .catch(() => console.log(`Notification was not sent, doc with id: ${notificationLinkId} has been deleted!`));
        }

        $onDestroy() {
            this.websocketConnectionReportingService.dispose();

            each(this.listeners, unsub => {
                if (isFunction(unsub)) {
                    unsub();
                }
            });
            this.$window.removeEventListener('unload', this.unloadHandler);
            this.notificationsService.cancelCheckInterval();
        }

        $doCheck() {
            // all the include templates rely on this being available
            // so update it on each cycle
            const actualNuid = (this.activeNav.nav || {}).nuid;
            if (this.currentNuid !== actualNuid) {
                this.currentNuid = actualNuid;
                this.$scope.nav = null;
                this.$timeout(() => (this.$scope.nav = this.activeNav.nav));
            }
        }
    }
};
