import { cloneDeep, get, isFunction } from 'lodash';
import { Qna } from './Qna';
import { Viewers } from './Viewers';
import { Polls } from './Polls';
import { Translations } from './Translations';

export class Configurator {

    constructor(services, context, getters, updatePlayerHandler) {
        this.services = services;
        this.context = context;
        this.getters = getters;
        this.updatePlayerHandler = updatePlayerHandler;

        this.listeners = [];

        this.tabs = [
            {
                id: 'viewers',
                condition: () => getters.showViewers(),
                handlerClass: Viewers,
                instance: null,
                sidebarEventName: 'sideBarViewers'
            }, {
                id: 'polls',
                condition: (config) => get(config, 'polls_enabled', true),
                handlerClass: Polls,
                instance: null,
                sidebarEventName: 'sideBarPolls',
                eventName: 'polls'
            }, {
                id: 'qna',
                condition: (config) => get(config, 'qna', false),
                handlerClass: Qna,
                instance: null,
                sidebarEventName: 'sideBarQna',
                eventName: 'qna'
            }, {
                id: 'translations',
                condition: () => getters.showTranslationsTab(),
                handlerClass: Translations,
                instance: null
            }
        ];
    }

    getTabInstance(tabId) {
        const tab = this.tabs.find(tab => tab.id === tabId);
        return tab && tab.instance;
    }

    onLivestreamUpdate(livestream, initialUpdate) {
        const oldContext = cloneDeep(this.context);
        this.context.livestream = livestream;
        this.livestreamId = get(this, 'context.livestream.liveStreamId');
        if (!this.livestreamId) {
            return this.resolveTabs({});
        }
        this.resolveTabs(this.context.livestream, initialUpdate, oldContext);
    }

    resolveTabs(livestream = this.context.livestream, initialUpdate = false, oldContext = null) {
        if (!livestream || !this.livestreamId) {
            return;
        }
        for (let tab of this.tabs) {
            if (tab.condition(livestream)) {
                if (!tab.instance) {
                    tab.instance = new tab.handlerClass(this.services, this.context, this.getters, this.updatePlayerHandler);
                    tab.instance.init(this.livestreamId, initialUpdate);
                } else {
                    tab.instance.onContextUpdated(oldContext);
                }
            } else if (!tab.condition(livestream) && tab.instance) {
                tab.instance.destroy();
                tab.instance = null;
            }
        }
    }

    onSideBarEvent(context, payload) {
        for (let tab of this.tabs) {
            if (tab.instance && tab.sidebarEventName === context) {
                tab.instance.onSideBarEvent(payload);
            }
        }
    }

    onEvent(context, payload) {
        for (let tab of this.tabs) {
            if (tab.instance && tab.eventName === context) {
                tab.instance.onEvent(payload);
            }
        }
    }

    async init(livestream) {
        await this.onLivestreamUpdate(livestream);
    }

    destroy() {
        for (const unsub of this.listeners) {
            if (isFunction(unsub)) {
                unsub();
            }
        }
        this.resolveTabs({});
    }

    get viewersCount() {
        const viewersTab = this.tabs.find((tab) => tab.id === 'viewers');
        return get(viewersTab, 'instance.viewers.length', 0);
    }

    get hasTabs() {
        return this.tabs.reduce((count, tab) => count + (tab.instance ? 1 : 0), 0);
    }

    get isIframeContent() {
        return this.getters.isIframeContent();
    }

    get isStreamless() {
        return this.getters.isStreamless();
    }

    get isPrivateQna() {
        return this.getters.isPrivateQna();
    }

    get config() {
        const components = [];
        for (let tab of this.tabs) {
            components.push(...(tab.instance ? [ tab.instance.config ] : []));
        }
        return {
            components,
            showFullscreenToggler: this.isIframeContent,
            theme: {
                tabHighlightColor: this.services.THEME.contrastColor || this.services.THEME.primary_color,
                btnBackgroundColor: this.services.THEME.toolbar.background_color,
                btnColor: this.services.THEME.toolbar.font_color,
                borderColor: this.services.THEME.toolbar.background_color
            },
            isPrivateQna: this.isPrivateQna
        };
    }
}
