export default function localCache(cacheId) {
    let hasLocalStorage = (() => {
        try {
            // this seems to be the best way to test as in Safari's
            // Private Mode the object exists but throws an exception as soon
            // as you try to access it
            localStorage._test = 'true';
            if (localStorage._test !== 'true') {
                return false;
            }

            delete localStorage._test;
            return true;
        } catch (e) {
            return false;
        }
    })();

    let cache = hasLocalStorage
        ? JSON.parse(localStorage[cacheId] || '{}')
        : {};
    let noCommit = () => {};
    let commitToLocalStorage = () =>
        (localStorage[cacheId] = angular.toJson(cache));
    let commit = hasLocalStorage ? commitToLocalStorage : noCommit;

    return {
        get(key, defaultValue) {
            return cache.hasOwnProperty(key) ? cache[key] : defaultValue;
        },
        put(key, value) {
            if (cache[key] !== value) {
                cache[key] = value;
                commit();
            }
        },
        remove(key) {
            if (!cache.hasOwnProperty(key)) return;
            delete cache[key];
            commit();
        },
        reset() {
            cache = {};
            commit();
        }
    };
}
