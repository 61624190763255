import { each } from 'lodash';
import { sanitizeHex } from '../utils/color';

class StyleParserService {
    /**
     * Given a symbol and a desired key, returns a valid object key.
     * If the symbol exists it is used as key prefix otherwise
     * key is returned.
     *
     * @param symbol the prefix to use on the object key.
     * @param key the key of the object property.
     *
     * @return a prefixed or a pristine object key.
     */
    _getKey(symbol, key) {
        return symbol ? symbol + '_' + key : key;
    }

    /**
     * Given an element configuration this method
     * parses the boxing model (heights and widths, etc).
     *
     * @param config the block configuration.
     * @param symbol the prefix to use on the object key.
     *
     * @return a inline CSS string containing the boxing model.
     */
    parseBoxing(config, symbol) {
        let styles = {};
        let key = this._getKey(symbol, 'width');
        if (config[key]) {
            styles['width'] = config[key] + 'px';
        }

        key = this._getKey(symbol, 'height');
        if (config[key]) {
            styles['height'] = config[key] + 'px';
        }

        key = this._getKey(symbol, 'thickness');
        if (config[key]) {
            styles['height'] = config[key] + 'px';
        }

        key = this._getKey(symbol, 'max_width');
        if (config[key]) {
            styles['max-width'] = config[key] + 'px';
        }

        key = this._getKey(symbol, 'max_height');
        if (config[key]) {
            styles['max-height'] = config[key] + 'px';
        }

        key = this._getKey(symbol, 'max_lines');
        if (config[key] === 1) {
            styles['overflow'] = 'hidden';
            styles['white-space'] = 'nowrap';
            styles['text-overflow'] = 'ellipsis';
        }

        return styles;
    }

    /**
     * Given an element configuration this method
     * parses its margins.
     *
     * @param config the block configuration.
     *
     * @return a inline CSS string containing the margins.
     */
    parseMargins(config) {
        let styles = {};
        if (config.margin) {
            each(config.margin, (v, k) => {
                const key = 'margin-' + k;
                styles[key] = v + 'px';
            });
        }
        return styles;
    }

    /**
     * Given an hexadecimal string this method
     * returns a valid web format.
     *
     * @param color the hex color string.
     *
     * @return a valid hexadecimal value.
     */
    parseColor(color) {
        return sanitizeHex(color);
    }

    /**
     * Given an element configuration and a prefix this method will parse
     * text styles values.
     *
     * @param config the block configuration.
     * @param symbol the prefix to use on the object key.
     *
     * @return a inline CSS string containing text styles.
     */
    parseTextsStyles(config, symbol) {
        let styles = {};

        let key = this._getKey(symbol, 'color');
        if (config[key]) {
            styles['color'] = this.parseColor(config[key]);
        }

        if (config.text_color) {
            styles['color'] = '#ffffff';
            if (config.text_color.default) {
                styles['color'] = this.parseColor(config.text_color.default);
            }
        }

        key = this._getKey(symbol, 'size');
        if (config[key]) {
            styles['font-size'] = config[key] + 'px';
        }

        key = this._getKey(symbol, 'style');
        switch (config[key]) {
            case 'bold':
            case 'normal':
                styles['font-weight'] = config[key];
                break;

            case 'bold-italic':
                styles['font-weight'] = config[key];
                styles['font-style'] = config[key];
                break;

            case 'italic':
                styles['font-style'] = 'italic';
                break;
        }

        key = this._getKey(symbol, 'alignment');
        if (config[key]) {
            styles['text-align'] = config[key];
        }

        return styles;
    }

    /**
     * Given an element configuration method will parse
     * image styles values.
     *
     * @param config the block configuration.
     *
     * @return a inline CSS string containing image styles.
     */
    parseImageStyles(config) {
        let styles = this.parseBoxing(config, 'image');

        if (config.image_shape === 'circle') {
            styles['border-radius'] = '100%';
        }

        if (config.image_height || config.image_width) {
            styles['object-fit'] = 'cover';
        }

        return styles;
    }
}

function StyleParserProvider() {
    this.$get = function() {
        return new StyleParserService();
    };
}

angular.module('maestro.services').provider('styleParser', StyleParserProvider);
