/* @ngInject */
function AppcuesService(
    USE_APPCUES,
    EID,
    PID,
    $window,
    $rootScope
) {
    if (!USE_APPCUES) {
        return {
            load() {
                console.log('[appcuesService] disabled on this workspace');
            }
        };
    }

    let loadingStarted = false;

    /**
     * Loads and instantiantes AppCues.
     *
     * User is identified with their _id, EID and product type to differentiate them from BSTG users.
     * URL change listener is installed to notify AppCues of page changes.
     */
    const load = () => {
        if (loadingStarted) return;

        loadingStarted = true;

        $rootScope.$on('$locationChangeSuccess', () => {
            if ($window.Appcues) {
                $window.Appcues.page();
            }
        });

        const script = document.createElement('script');
        script.onload = () => {
            console.log('[appcuesService] Loaded');
            $window.Appcues.identify(PID, {
                eid: EID,
                product: 'webapp-vx'
            });
        };
        script.src = "//fast.appcues.com/56484.js";
        document.head.append(script);
    };

    return { load };
}

angular.module('maestro.services.appcues', [])
    .service('appcuesService', AppcuesService);
