import { get } from 'lodash';
import i18nWithFallback from '../../../../utils/i18n-with-fallback';

// Expressed in seconds
const MIN_VIDEO_DURATION = 3;
const MAX_VIDEO_DURATION = 60;
const MAX_WIDTH = 1200;
const MAX_HEIGHT = 1200;
const ACCEPTED_VIDEO_MIME = [ 'video/mp4' ];

export const UploadBlockComponent = {
    template: require('./upload-block.jade'),
    bindings: {
        parentNavId: '<',
        config: '<',
        accept: '<',
        maxSize: '<'
    },
    controller: class UploadBlockComponent {
        /* @ngInject */
        constructor($timeout, navFormService, $element, $sheet, $i18n, $eventBus) {
            this.$timeout = $timeout;
            this.navFormService = navFormService;
            this.el = $element;
            this.$sheet = $sheet;
            this.$i18n = $i18n;
            this.$eventBus = $eventBus;
        }

        $onInit() {
            this.enabled = 'FileReader' in window;
            if (!this.enabled) return;

            this.btnLabel = i18nWithFallback(this.$i18n, 'feed.photo_upload_btn_label', 'Photo/Video');
            this.accept = this.accept ? this.accept.split(',') : null;

            this.maxVideoDuration = get(this.config, 'data.content.video_max_length') || MAX_VIDEO_DURATION;

            this.setFormValue = value =>
                this.navFormService.set(
                    this.parentNavId,
                    this.config.input_key,
                    value
                );
            this.setFormMetaValue = value =>
                this.navFormService.set(
                    this.parentNavId,
                    `_${this.config.input_key}_meta`,
                    value
                );
            this.setFormThumbnailValue = value =>
                this.navFormService.set(
                    this.parentNavId,
                    this.config.thumbnail_key,
                    value
                );
            this.setFormThumbnailMetadata = value =>
                this.navFormService.set(
                    this.parentNavId,
                    `_${this.config.thumbnail_key}_meta`,
                    value
                );
            console.info('[UploadBlockComponent] init', this.config);

            this.preSelectedFile = this.navFormService.getPreSelectedFile();
            if (this.preSelectedFile[this.config.input_key]) {
                this.fileSelected({ file: this.preSelectedFile[this.config.input_key] });

                this.navFormService.setPreSelectedFile(this.config.input_key, null);
                this.preSelectedFile = {};
            }
        }

        $onDestroy() {
            this.setFormValue(null);
            this.setFormMetaValue(null);
            this.setFormThumbnailValue(null);
            this.setFormThumbnailMetadata(null);
        }

        fileSelected({ file }) {
            // reset error state
            this.errors = {};
            if (!file) {
                // resets form
                this.removeFile();
                return;
            }

            const mimeType = file.type;
            if (
                this.accept &&
                (!mimeType || this.accept.indexOf(mimeType) === -1)
            ) {
                this.$timeout(() => (this.errors.type = true));
                this.removeFile();
                return;
            }

            // We will perform this check only if we're uploading an image OR
            // a video AND video max length is not set
            const maxLength = get(this.config, 'data.content.video_max_length');
            const isVideo = ACCEPTED_VIDEO_MIME.includes(mimeType);

            if (!isVideo || (isVideo && !maxLength)) {
                const size = file.size;
                if (this.maxSize && size > this.maxSize) {
                    this.$timeout((this.errors.size = true));
                    this.removeFile();
                    return;
                }
            }

            const reader = new FileReader(file);
            reader.onload = ({ target: { result } }) =>
                this.$timeout(() => {
                    // the following was breaking image orientation on chrome & firefox - SS-10671
                    // const imageUrl = this.stripExif(file.type, result);

                    let urlCreator = window.URL || window.webkitURL;
                    let br = new Blob([ result ], { type: file.type });
                    let imageUrl = urlCreator.createObjectURL(br);

                    this.getMediaMetadata(
                        file,
                        imageUrl
                    );
                });
            reader.onerror = () => this.$timeout(() => this.removeFile());
            reader.readAsArrayBuffer(file);
            this.setFormValue(file);

            if (!this.preSelectedFile[this.config.input_key]) {
                this.$eventBus.emit('upload-block:file-selected', true);
            }
        }

        getMediaMetadata(file, data) {
            const mimeType = file.type;
            if (mimeType) {
                if (mimeType.indexOf('image/') === 0) {
                    this.downSizeLargeImages(file, data);
                }

                if (mimeType.indexOf('video/') === 0) {
                    this.getVideoMetadata(mimeType, data);
                }
            }
        }

        getVideoMetadata(mimeType, data) {
            let v = document.createElement('video');
            v.src = data;
            v.autoplay = true;
            v.preload = 'metadata';
            v.addEventListener('loadeddata', () => {
                v.pause();

                if (v.duration > this.maxVideoDuration) {
                    this.removeFile();
                    return this.showErrorPopup(`The maximum video duration allowed is ${this.maxVideoDuration}s.`);
                }
                if (v.duration < MIN_VIDEO_DURATION) {
                    this.removeFile();
                    return this.showErrorPopup(`The minimum video duration allowed is ${MIN_VIDEO_DURATION}s.`);
                }

                let canvas = document.createElement('canvas');
                canvas.width = v.videoWidth;
                canvas.height = v.videoHeight;
                canvas
                    .getContext('2d')
                    .drawImage(v, 0, 0, v.videoWidth, v.videoHeight);
                this.filePreview = canvas.toDataURL('image/jpeg');
                this.$timeout(() => {
                    this.setFormMetaValue({
                        contentType: mimeType,
                        size: {
                            width: v.videoWidth,
                            height: v.videoHeight
                        }
                    });

                    canvas.toBlob(
                        blob => {
                            let f = new File([ blob ], 'poster.jpg', {
                                type: blob.type
                            });
                            this.setFormThumbnailValue(f);
                        },
                        'image/jpeg',
                        0.95
                    );

                    this.setFormThumbnailMetadata({
                        contentType: 'image/jpeg',
                        size: {
                            width: v.videoWidth,
                            height: v.videoHeight
                        }
                    });

                    canvas = null;
                    v = null;
                });
            });
        }

        downSizeLargeImages(file, data) {
            // There is no thumbnail for images
            this.setFormThumbnailValue(null);
            this.setFormThumbnailMetadata(null);

            let img = new Image();
            img.onload = () => {
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                this.setFormMetaValue({
                    contentType: file.type,
                    size: {
                        image_width: width,
                        image_height: height
                    }
                });

                canvas.toBlob(blob => {
                    const f = new File([ blob ], file.name, { type: blob.type });

                    this.$timeout(() => {
                        const urlCreator = window.URL || window.webkitURL;
                        const imageUrl = urlCreator.createObjectURL(f);

                        this.filePreview = imageUrl;
                        this.setFormValue(f);
                    });

                }, file.type, 0.6);
            };
            img.src = data;
        }

        removeFile() {
            this.filePreview = null;
            this.el.find('input.file-input').val('');
            this.setFormValue(null);
            this.setFormMetaValue(null);
            this.setFormThumbnailValue(null);
            this.setFormThumbnailMetadata(null);
        }

        showErrorPopup(message) {
            this.$sheet({
                title: this.$i18n('general.error'),
                message,
                pin: false,
                scrollable: false,
                prompt: true,
                actions: [
                    {
                        id: 'close',
                        label: this.$i18n('general.close'),
                        action: sheet => sheet.reject()
                    }
                ]
            });
        }
    }
};
