import { get, isEmpty, isFunction } from 'lodash';
import VideoPipService from './videoPipService';

export default class VideoCallService extends VideoPipService {
    /* @ngInject */
    constructor($http, EVENT, databaseService, $document, $compile, $rootScope, $eventBus, $location, navService, SETTINGS) {
        super($document, $eventBus, $location, $rootScope, $http, navService);

        this.$http = $http;
        this.eid = EVENT._id;
        this.databaseService = databaseService;

        this.$compile = $compile;
        this.SETTINGS = SETTINGS;

        this.listeners = [];
    }

    /**
     * Starts a new video call instance that could be set in PIP mode
     *
     * @param {Object} params
     * @param {Object} docType the type of page on which to display the call, 'session' || 'sponsor' || 'chat'
     * @param {Object} parentDocumentId id of the page document
     * @param {Object} sessionId the vonage session id of the call
     * @param {Object} conversationId the conversation id of current page, in the case of a chat
     * @param {Object} actions actions to be taken care of (for now only in the case of a chat)
     */
    start({ docType, parentDocumentId, sessionId, conversationId, actions }) {
        if (this.currentSessionId === sessionId) {
            console.info('[VideoBreakoutService] Meeting already open');
            return;
        }

        if (!isEmpty(this.currentSessionId)) {
            this.stop();
        }

        console.log('[VideoCallService] Opening video call with ID ', sessionId);

        // it is not possible to create the videoScope as the child of another component, otherwise the pip will be destroyed
        // on this parent component destruction
        this.videoScope = this.$rootScope.$new(true);
        this.videoScope.sessionId = sessionId;
        this.videoScope.docType = docType;
        this.videoScope.parentDocumentId = parentDocumentId;
        this.videoScope.conversationId = conversationId;
        this.videoScope.actions = actions;
        this.currentSessionId = sessionId;

        const videoChat = this.$compile('<video-call ' +
            'session-id="sessionId" ' +
            'doc-type="docType" ' +
            'parent-document-id="parentDocumentId" ' +
            'conversation-id="conversationId" ' +
            'actions="actions" ' +
            '></video-call>')(this.videoScope);

        const videoJoined = this.$eventBus.on('video-call:joined', () => this.busy = true);

        const navChange = this.$eventBus.on('$navChange', () => {
            if (!this.busy) return this.stop();
        });

        const restart = this.$eventBus.on('restartCall', () => {
            this.stop();
            this.start({ docType, parentDocumentId, sessionId, conversationId, actions });
        });

        this.listeners.push(
            videoJoined,
            navChange,
            restart
        );

        super.init(videoChat);
        this.pipWrapper.addClass('video-call');
        this.pipWrapper.addClass('fixed-size');
    }

    reset() {
        super.reset();
        this.listeners = [];
        this.busy = false;
        this.currentSessionId = null;
    }

    /**
     * stops the video call component
     */
    stop() {
        if (this.videoScope) {
            this.videoScope.$destroy();
        }

        this.busy = false;
        this.currentSessionId = null;

        for (const unsub of this.listeners) {
            if (isFunction(unsub)) unsub();
        }
        this.listeners = [];

        super.stop();
    }


    /**
     * Creates a video call session ID
     *
     * @returns {Promise}
     */
    createSession() {
        return this.$http.post(`/api/v1/eid/${this.eid}/video-calls/start`, {}).then(({ data }) => data.sessionId);
    }

    /**
     * Get the api key and token for a session
     *
     * @param {string} sessionId session id
     * @param {string} parentDocumentId the id of the parentDocument
     *
     * @returns {Promise}
     */
    getTokenAndApiKey(sessionId, parentDocumentId) {
        return this.$http.post(`/api/v1/eid/${this.eid}/video-calls/token`, { sessionId, parentDocumentId });
    }

    /**
     * Get moderator list of the parent document's video call
     *
     * @param {string} parentDocumentId the id of the parentDocument
     *
     * @returns {Array<String>} list of moderator pids
     */
    async getModerators(parentDocumentId) {
        const response = await this.$http.get(`/api/v1/eid/${this.eid}/video-calls/${parentDocumentId}/moderators`);
        return get(response, 'data.moderators', []);
    }

    /**
     * Save pinned Participant list on the video call doc
     *
     * @param {string} videoCallDocId the id of the video call document
     * @param {Array<String>} list the pids of the pinned participants
     *
     * @returns {Object} response
     */
    async savePinnedParticipants(videoCallDocId, list) {
        return await this.postWithRetries(`/api/v1/eid/${this.eid}/video-calls/pinned-participants`, { videoCallDocId, list }, 3);
    }

    /**
     * Insert the session id in a conversation doc
     *
     * @param {string} conversation_id conversation id
     * @param {string} session_id session id
     *
     * @returns {Promise}
     */
    addSessionIdToConversation(conversation_id, session_id) {
        return this.databaseService
            .runAppScript('chats/add-session-id', { conversation_id, session_id });
    }

    /**
     * Get conversation doc
     *
     * @param {string} conversation_id conversation id
     *
     * @returns {Promise}
     */
    getConversation(conversation_id) {
        return this.databaseService
            .runAppScript('chats/get-conversation', { conversation_id })
            .then(({ data }) => data.response);
    }
}

angular
    .module('maestro.services')
    .service('videoCallService', VideoCallService);
