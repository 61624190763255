import { checkIfImageLoads } from '../../../utils/assets';

export const InAppNotificationComponent = {
    template: require('./in-app-notification.pug'),
    controller: class InAppNotificationComponent {
        /* @ngInject */
        constructor($timeout, $log, $scope, $element, $eventBus, databaseService, metricsService) {
            $scope.isDestroyed = false;

            this.appscriptLoading = false;

            this.$timeout = $timeout;
            this.$log = $log;
            this.$scope = $scope;
            this.$element = $element;
            this.databaseService = databaseService;
            this.metricsService = metricsService;
            this.$eventBus = $eventBus;

            this.$scope = $scope.$parent;

            const { duration } = this.$scope;

            this.scheduleSelfDestruction(duration);
        }

        $onInit() {
            this.action = this.$scope.action;
            this.icon_url = this.$scope.icon_url;
            checkIfImageLoads(this.icon_url).then(result => this.iconError = !result);
            this.content_color = this.$scope.content_color;
            this.title = this.$scope.title;
            this.subtitle = this.$scope.subtitle;

            const { background_color, content_color } = this.$scope;
            this.innerEl = this.$element.find('.in-app-notification');

            this.innerEl.css('backgroundColor', background_color);
            this.innerEl.css('color', content_color);

            if (this.$scope.notificationId) {
                this.metricsService.trackAnalytics({
                    name: 'view',
                    document_id: this.$scope.notificationId,
                    document_type: 'notification',
                });
            }
        }

        /**
         * @param {Number} duration
         */
        scheduleSelfDestruction(duration) {
            this.$timeout(
                this.triggerSelfDestruction.bind(this),
                duration * 1000
            );
        }

        triggerSelfDestruction() {
            this.innerEl.on('animationend', () => this.performSelfDestruction());

            this.isDestroyed = true;
        }

        performSelfDestruction() {
            this.$scope.$destroy();
            this.$element.remove();
        }

        onClick() {

            if (!this.action || this.appscriptLoading || this.$scope.isDestroyed) {
                return;
            }

            this.appscriptLoading = true;

            let params = this.action.params;

            if (this.$scope.notificationId) {
                params = {
                    ...this.action.params,
                    notification: {
                        _id: this.$scope.notificationId
                    }
                };
            }

            if (this.$scope.ctx === 'polls') {
                // in case the video is in pip mode, clicking on the notification should reopen the nav
                this.$eventBus.emit('video-pip:reopen-nav', params.id);
            }

            this.databaseService
                .runAppScript(this.action.path, params)
                .then(() => {
                    this.triggerSelfDestruction();
                })
                .catch(error =>
                    this.$log.error(
                        `[in-app-notification] An error occurred while processing appscript ${
                            this.action.path
                        }: ${String(error)}`
                    )
                )
                .finally(() => {
                    this.appscriptLoading = false;
                });
        }
    }
};
