import { reduce, each } from 'lodash';

// ({}, 'a.b.c', 4) => {a: {b: {c: 4}}}
const setPath = function(source, path, value) {
    const keys = path.split('.');
    const lastKey = keys.pop();

    let current = source;
    each(keys, key => {
        current[key] = current[key] || {};
        current = current[key];
    });
    current[lastKey] = value;

    return source;
};

// {"a": 1, "b.b": 3, "b.c.d": 2}, {e:4} => {a:1, b: {b:3, c: {d: 2}}, e:4}
export default function unflattenObject(obj, target) {
    return reduce(obj, (memo, value, key) => setPath(memo, key, value), target);
}
