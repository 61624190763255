import { each, find, extend, isEqual } from 'lodash';
import { isSessionLive, isSessionPast } from '../../../../../utils/agenda-sessions';

export const MasterRowComponent = {
    bindings: {
        row: '<'
    },
    template: require('./master-row.jade'),
    controller: class MasterRowComponent {
        /* @ngInject */
        constructor(dataSourceEvaluatorService, navService, $element) {
            this.ds = dataSourceEvaluatorService;
            this.navService = navService;

            this.expanded = false;
            this.trackSessions = [];
            this.notificationSubscriptions = [];
            this.$element = $element;
            this.isLive = false;
        }

        $onInit() {
            this.notificationSubscriptions.push(
                this.navService.on(
                    'rows:update',
                    this.onTrackSessionUpdate.bind(this)
                )
            );

            this.notificationSubscriptions.push(
                this.navService.on('agenda:reload-slot', data =>
                    this.onTrackSessionUpdate(data)
                )
            );

            this.isLive = isSessionLive(this.row);
            this.isPast = isSessionPast(this.row);
        }

        $onChanges(changes) {
            const prevRow = (changes.row || {}).previousValue;
            const currRow = (changes.row || {}).currentValue;

            if (currRow) {
                this.row = angular.copy(this.row);

                if (this.expanded !== this.row.expanded) {
                    this.expand(this.row.expanded);
                } else if (!isEqual(prevRow.in_slots_ds, currRow.in_slots_ds)) {
                    this.getTrackSessions();
                }
            }
        }

        $onDestroy() {
            const subs = this.notificationSubscriptions;
            if (subs && subs.length) {
                each(subs, unsub => unsub());
            }
        }

        toggle() {
            this.expand(!this.expanded);
            this.$element.trigger('ready');
        }

        expand(state) {
            if (this.expanded === state) return;

            this.expanded = state;

            if (this.expanded) {
                this.getTrackSessions();
            }
        }

        getTrackSessions() {
            if (this.loading) return;

            console.log('[MasterRowComponent] getTrackSessions', this.row);
            this.loading = true;
            this.ds
                .eval(this.row.in_slots_ds)
                .then(sessions => {
                    console.log('[MasterRowComponent] getTrackSessions received', sessions);
                    this.trackSessions = sessions;
                    this.isLive = sessions.some(isSessionLive);
                })
                .finally(() => {
                    this.loading = false;
                    this.trackSessionsReady = true;
                });
        }

        onTrackSessionUpdate({ target, updates }) {
            const sessions = this.trackSessions;
            const targetedSession = find(sessions, ({ id }) => id === target);

            // agenda:reload-slot
            if (!targetedSession && this.row.id === target) {
                this.trackSessions = [];
                return this.getTrackSessions();
            }

            if (!targetedSession) return;

            extend(targetedSession, updates);
        }
    }
};
