/**
 * This component is used to show a video calls modal
 */
export const VideoCallModalComponent = {
    bindings: {
        params: '='
    },
    template: require('./video-call-modal.pug'),
    controller: class VideoCallModalComponent {

        buttonClicked(buttonOptions) {
            buttonOptions.action();
        }

    }
}
;
