import { curryRight, filter } from 'lodash';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

const ANIMATION_DELAY_FACTOR = 20;

const ACTION_BTN_BLOCK = {
    id: 'action-btn',
    block_type: 'block_action_btn',
    data: {
        non_empty_fields_group: [
            'content',
            'media'
        ],
        content: {
            title: 'feed.post'
        },
        actions: {
            post: {
                action_key: 'send_data',
                target: 'feed-ui/create-post'
            }
        }
    }
};

export const BlocksNavComponent = {
    template: require('./blocks-nav.jade'),
    bindings: {
        config: '<'
    },
    controller: class BlocksNavComponent {
        /* @ngInject */ constructor(
            THEME,
            NAV_TEMPLATES,
            $i18n,
            handlerService,
            navService,
            databaseService,
            progressBarService
        ) {
            this.theme = THEME;
            this.navTemplates = NAV_TEMPLATES;
            this.handlerService = handlerService;
            this.navService = navService;
            this.databaseService = databaseService;
            this.progressBarService = progressBarService;

            this.cookiePolicyUrl = "https://spotme.com/specifications#cookies";
            this.privacyPolicyUrl = "https://spotme.com/app-privacy-policy/";
            this.blocksToAdd = [];

            this.cookiePolicyLabel = i18nWithFallback(
                $i18n,
                'general.cookie_policy',
                'Cookie Policy'
            );
            this.privacyPolicyLabel = i18nWithFallback(
                $i18n,
                'general.privacy_policy',
                'Privacy Policy'
            );

            this.loading = false;
        }

        $onInit() {
            console.log('[BlockNavComponent] init', this.config);

            this.config.nav_bar_actions = filter(this.config.nav_bar_actions, action => {
                if (action.action_key === 'send_data') {
                    ACTION_BTN_BLOCK.data.actions.post.params = action.params;
                    this.blocksToAdd.push(ACTION_BTN_BLOCK);
                    return false;
                }
                return true;
            });

            const config = this.config;

            this.performActions = curryRight(
                this.navService.performActions.bind(this.navService)
            )(config.nuid);

            this.progressBarService.startTask();
            this.navService
                .inflateNav(config)
                .then(({ elements }) => {
                    this.groupBlockContainers(elements.concat(this.blocksToAdd));
                })
                .finally(() => {
                    this.progressBarService.finishTask();
                    this.isHome = config._id === 'nav_home';
                });
        }

        groupBlockContainers(blocks) {
            this.containerGroups = [];
            if (blocks && blocks.length) {
                let groups = [];
                let blockIds = 0;
                for (let i = 0, n = blocks.length; i < n; i++) {
                    let b = blocks[i];
                    let prevBlock = blocks[i - 1];
                    let prevGroup = groups[groups.length - 1];
                    if (!b.id) b.id = blockIds++;

                    b.templateUrl = this.navTemplates[b.block_type];

                    if (
                        prevBlock &&
                        prevGroup &&
                        prevBlock.layout &&
                        prevBlock.layout.group_with_next &&
                        prevGroup.blocks.length <= 2 &&
                        b.layout.width !== 'full'
                    ) {
                        prevGroup.blocks.push(b);
                    } else {
                        groups.push({
                            id: i,
                            blocks: [ b ],
                            width: (b.layout && b.layout.width) || 'full',
                            delay: (i + 1) / ANIMATION_DELAY_FACTOR
                        });
                    }
                }

                console.log('[BlockNavComponent] showing blocks', groups);

                this.containerGroups = groups;
            }

            return this.containerGroups;
        }
    }
};
