import moment from 'moment-timezone';
import { extend, cloneDeep } from 'lodash';
import { renderRows } from '../../utils/webapp-skeleton';

const paramSerializer = angular.injector([ 'ng' ]).get('$httpParamSerializer');

const evaluators = {
    javascript(nav, params = {}, key) {
        const requestParams = angular.merge(
            {},
            nav[key].params || nav[key].source.params,
            params
        );
        const requestData = {
            nav_id: nav._id,
            params: requestParams,
            lang: this.$i18n.lang,
            tz: moment.tz().zoneAbbr()
        };

        if (nav._dynamic_nav) {
            params.cache = false;
            requestData.full_nav = nav;
        }

        let qs;
        if (requestParams.cache === true) {
            qs = paramSerializer(requestData);
            const cached = this.inflatorRequestCache.get(qs);
            if (cached) {
                return this.$q.resolve(cloneDeep(cached));
            }
        }

        const service = this.databaseService
            .runAppScript('webapp/inflate-nav', requestData)
            .then(({ data }) => data.response);


        return service.then(data => {
            if (requestParams.cache !== false && qs) {
                this.inflatorRequestCache.put(qs, data);
            }
            return cloneDeep(data);
        });
    },
    enum(nav, params, key) {
        return this.$q.when({
            elements: angular.copy(nav[key].elements || [])
        });
    },
    handler(nav, params, key) {
        const ds = nav[key].source;
        return this.handlerService.callHandler(ds.handler, ds.params);
    },
    remote(nav, params, key) {
        const path = nav[key].source.path;
        let bookmark = null;

        return this.$http
            .get(`api/v1/eid/${this.EID}/${path}`, { params: params })
            .then(({ data } = {}) => {
                if (data.bookmark) bookmark = data.bookmark;
                return {
                    elements: renderRows({
                        render: nav.render,
                        actions: nav.actions
                    }, data.documents),
                    bookmark
                };
            })
            .catch(err => console.error(err));
    }
};

export default class NavInflator {
    /* @ngInject */
    constructor(
        $cacheFactory,
        $q,
        $i18n,
        handlerService,
        databaseService,
        $http,
        EID
    ) {
        this.$q = $q;
        this.$i18n = $i18n;
        this.$http = $http;
        this.EID = EID;
        this.handlerService = handlerService;
        this.databaseService = databaseService;
        this.inflatorRequestCache = $cacheFactory('navInflator');
    }

    inflateNav(nav, params, key = 'ds') {
        const ds = nav[key];
        if (!ds) {
            throw new Error('missing ds');
        }
        const evaluator = evaluators[ds.type];
        if (!evaluator) {
            throw new Error('unknown ds: ' + ds.type);
        }
        return evaluator.call(this, nav, params, key);
    }

    inflatePaginatedNav(nav, params, paginator) {
        if (!paginator.hasMorePages()) {
            return this.$q.reject();
        }

        return this.inflateNav(
            nav,
            extend({}, params || {}, paginator.queryParams())
        ).then(resp => {
            resp.paginatedElements = paginator.processNewPage(resp.elements, resp);
            return resp;
        });
    }
}
