// Utils
import { get, isFunction } from 'lodash';
import EventEmitter from '../../../utils/event-emitter';
import { addEventListener } from '../../../utils/dom';

/**
 * This component is used to render the subscriber video
 *
 */
export const SubscriberComponent = {
    bindings: {
        session: '=',
        conversationId: '<',
        hasVideo: '<',
        hasAudio: '<',
        isVisible: '<',
        isPinned: '<',
        name: '<',
        subscriber: '=',
        publisherPublished: '=',
        showModeratorControls: '='
    },
    template: require('./subscriber.pug'),
    controller: class SubscriberComponent {
        /* @ngInject */
        constructor($i18n, $scope, $element, $eventBus, $timeout) {
            this.$scope = $scope;
            this.$eventBus = $eventBus;
            this.$element = $element;
            this.listeners = [];
            this.showSubscriberOptions = false;
            this.hideModeratorControls = true;

            this.eventEmitter = new EventEmitter($timeout);
        }

        $postLink() {
            this.init();
        }

        async init() {
            try {
                const subscriberEl = this.$element.find('.subscriber-video').get(0);
                this.listeners.push(
                    addEventListener(subscriberEl, 'contextmenu', e => e.preventDefault())
                );

                await this.subscriber.init(subscriberEl, this.eventEmitter, { subscribeToAudio: this.publisherPublished });

                this.$eventBus.emit(`chat:updatecall:${this.conversationId}`);
                this.$scope.$emit('subscriber:initiated', this.subscriber);

                console.log('adding the listener with ', this.subscriber);
                this.listeners.push(
                    this.$scope.$on('layout:changed', (event, layoutData) => {
                        this.subscriber.adaptResolution(layoutData);
                    }),
                    this.eventEmitter.addEventListener('subscriber:subscribed', () => {
                        // here we leverage the $timeout from the eventEmitter util, which will force angular to
                        // digest the new otSubscriber object and trigger the talking indicator watcher
                        console.log('[Subscriber] subscriber was properly resubscribed');
                    }),
                    this.$scope.$watch('$ctrl.publisherPublished', () => this.subscribeToAudio(), true)
                );

                this.subscribeToAudio();

                this.hideModeratorControls = !this.showModeratorControls;
                this.$scope.$applyAsync();
            } catch (err) {
                this.handleError(err);
            }
        }

        subscribeToAudio() {
            if (this.publisherPublished && isFunction(get(this, 'subscriber.otSubscriberObject.subscribeToAudio'))) {
                this.subscriber.otSubscriberObject.subscribeToAudio(true);
            }
        }

        $onDestroy() {
            this.subscriber.destroy();

            for (let listener of this.listeners) {
                if (isFunction(listener)) {
                    listener();
                }
            }
            this.listeners = [];
        }

        handleError(err) {
            // TO DO - add better error handling
            // https://tokbox.com/developer/guides/subscribe-stream/js/#troubleshooting
            if (err) console.error('[Subscriber] handle error', err);
        }
    }
};
