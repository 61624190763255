import { each, extend, get, isEmpty, every } from 'lodash';

export const ActionBtnBlockComponent = {
    template: require('./action-btn-block.jade'),
    bindings: {
        parentNavId: '<',
        config: '<'
    },
    controller: class ActionBtnBlockComponent {
        /* @ngInject */ constructor(
            $q,
            databaseService,
            navFormService,
            navService,
            progressBarService,
            $eventBus,
        ) {
            this.$q = $q;
            this.databaseService = databaseService;
            this.navFormService = navFormService;
            this.navService = navService;
            this.progressBarService = progressBarService;
            this.$eventBus = $eventBus;

            this.disaabled = true;
        }

        $onInit() {
            this.btnLabel = get(this.config, 'data.content.title');
            this.loading = false;
            console.log('[ActionBtnBlockComponent] init', this.config);

            this.$eventBus.on('sheet:form-data-changed', () => this.checkForm());
        }

        checkForm() {
            const form = this.navFormService.get(this.parentNavId);

            const requiredFields = Object.keys(this.navFormService.getRequiredFields(this.parentNavId));
            const hasEmptyRequiredField = !isEmpty(requiredFields) && this.allFieldsAreEmpty(form, requiredFields);

            const nonEmptyFieldsGroup = get(this.config, 'data.non_empty_fields_group');
            const hasEmptyGroup = !isEmpty(nonEmptyFieldsGroup) && this.allFieldsAreEmpty(form, nonEmptyFieldsGroup);

            this.disaabled = hasEmptyRequiredField || hasEmptyGroup;
        }

        allFieldsAreEmpty(form, fields) {
            return every(fields, key => {
                if (form[key] instanceof File) return false;
                if (typeof form[key] === 'object') {
                    return every(form[key], item => isEmpty(item));
                }
                return isEmpty(form[key]);
            });
        }

        triggerBtnAction() {
            if (this.loading) return;

            const actions = get(this.config, 'data.actions') || {};
            const actionsToExecute = [];

            each(actions, action => {
                const params = action.params || {};

                if (action.action_key === 'send_data' && this.parentNavId) {
                    extend(params, this.navFormService.get(this.parentNavId));
                }

                actionsToExecute.push(this.databaseService.runAppScript(action.target, params));
            });

            this.loading = true;
            this.progressBarService.startTask();

            this.$q.all(actionsToExecute).finally(() => {
                this.loading = false;
                this.progressBarService.finishTask();
            });
        }
    }
};
