import { get, isFunction } from 'lodash';

/**
 * @typedef {Object} Device
 *
 * @property {String} deviceId
 * @property {'videoInput'|'audioInput'} kind
 * @property {String} label
 */

/**
 * @typedef {Object} Devices
 *
 * @property {Device[]} audioDevices
 * @property {Device[]} videoDevices
 * @property {Error} error
 */

const DEVICE_CHANGE_EVENT = 'device-change';

/**
 * Service to manage the media devices.
 */
export default class MediaDevicesService {
    /* @ngInject */
    constructor($eventBus, localCacheFactory) {

        this.$eventBus = $eventBus;

        this.devicesError = null;

        this.audioDevices = [];
        this.videoDevices = [];

        this.audioDeviceSelected = null;
        this.videoDeviceSelected = null;

        this.streamsWithAudio = [];
        this.streamsWithVideo = [];

        this.mediaDevicesProvider = navigator.mediaDevices;

        this.lastSelectedDeviceCache = localCacheFactory('breakout/device');

        this.mediaDevicesProvider.ondevicechange = async () => {
            console.log('[MediaDevicesService] Detected a change of devices');
            await this.storeDeviceList();
        };

        this.listeners = [];

        this.storeDeviceList();
    }

    /**
     * @returns {Devices}
     */
    getDeviceList() {
        return { audioDevices: this.audioDevices, videoDevices: this.videoDevices, error: this.devicesError };
    }

    async storeDeviceList(retry = 0) {
        this.devicesError = null;
        try {
            const devices = await this.mediaDevicesProvider.enumerateDevices();

            // default microphone has issues if it disconnects, see EP-15390
            const cleanUpDevices = devices => devices
                .map(({ deviceId, label }) => ({ deviceId, label }))
                .filter(({ deviceId }) => deviceId !== 'default');

            const audioDevices = cleanUpDevices(devices
                .filter(input => input.kind === 'audioinput' && input.deviceId));
            const videoDevices = cleanUpDevices(devices
                .filter(input => input.kind === 'videoinput' && input.deviceId));

            this.audioDevices = audioDevices || [];
            this.videoDevices = videoDevices || [];
            console.log('[MediaDevicesService] stored device list');

            const audioDeviceLabel = get(this, 'audioDevices[0].label');
            const videoDeviceLabel = get(this, 'videoDevices[0].label');
            if (retry < 5 && (audioDeviceLabel === '' || videoDeviceLabel === '')) {
                retry++;
                console.warn('[MediaDevicesService] stored device list: due to temporary device permission on firefox, label was empty', { retry });
                this.retryStoreDeviceListTimeout = setTimeout(() => {
                    this.storeDeviceList(retry);
                }, 1000);
            }
            else {
                clearTimeout(this.retryStoreDeviceListTimeout);
            }
            this.selectDefaultVideo();
            this.selectDefaultAudio();
            this.updateSelectedDeviceName();
        } catch (error) {
            console.error('[MediaDevicesService] Failed to retrieve the devices', error);
            clearTimeout(this.retryStoreDeviceListTimeout);
            this.audioDevices = [];
            this.videoDevices = [];
            this.devicesError = error;
        }
        this.$eventBus.emit(DEVICE_CHANGE_EVENT, this.getDeviceList());
    }

    updateSelectedDeviceName() {
        try {
            if (this.audioDeviceSelected) {
                for (const device of this.audioDevices) {
                    if (device.deviceId === this.audioDeviceSelected.deviceId && device.label !== '') {
                        this.audioDeviceSelected.label = device.label;
                    }
                }
            }
            if (this.videoDeviceSelected) {
                for (const device of this.videoDevices) {
                    if (device.deviceId === this.videoDeviceSelected.deviceId && device.label !== '') {
                        this.videoDeviceSelected.label = device.label;
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    selectDevice(type, device) {
        if (type === 'audio') {
            if (this.audioDeviceSelected && device && device.deviceId === this.audioDeviceSelected.deviceId) {
                return;
            }
            if (this.isDeviceValid(this.audioDevices, device)) {
                this.audioDeviceSelected = device;
                this.lastSelectedDeviceCache.put('audioDeviceIdSelected', device.deviceId);
            } else {
                this.audioDeviceSelected = null;
            }
        } else if (type === 'video') {
            if (this.videoDeviceSelected && device && device.deviceId === this.videoDeviceSelected.deviceId) {
                return;
            }
            if (this.isDeviceValid(this.videoDevices, device)) {
                this.videoDeviceSelected = device;
                this.lastSelectedDeviceCache.put('videoDeviceIdSelected', device.deviceId);
            } else {
                this.videoDeviceSelected = null;
            }
        }
    }

    selectDefaultVideo() {
        if (!this.videoDevices.length || this.isDeviceValid(this.videoDevices, this.videoDeviceSelected)) {
            return;
        }

        const deviceId = this.lastSelectedDeviceCache.get('videoDeviceIdSelected');
        const lastSelectedDevice = this.videoDevices.find(device => device.deviceId === deviceId);
        if (lastSelectedDevice) {
            this.videoDeviceSelected = lastSelectedDevice;
        } else {
            this.videoDeviceSelected = this.videoDevices[0];
        }
    }

    selectDefaultAudio() {
        if (!this.audioDevices.length || this.isDeviceValid(this.audioDevices, this.audioDeviceSelected)) {
            return;
        }

        const deviceId = this.lastSelectedDeviceCache.get('audioDeviceIdSelected');
        const lastSelectedDevice = this.audioDevices.find(device => device.deviceId === deviceId);
        if (lastSelectedDevice) {
            this.audioDeviceSelected = lastSelectedDevice;
        } else {
            this.audioDeviceSelected = this.audioDevices[0];
        }
    }

    selectDefaultDevices() {
        this.selectDefaultAudio();
        this.selectDefaultVideo();
    }

    isDeviceValid(deviceList, device) {
        if (!device || !device.deviceId || !deviceList || !deviceList.length) {
            return false;
        }
        const deviceIds = deviceList.map(device => device.deviceId);
        return deviceIds.includes(device.deviceId);
    }

    /**
     * Subscribes to the `device-change` event.
     *
     * @param {Function} handler Function to be executed whenever the devices change. It must follow the signature:
     * ({ audioDevices: Object, videoDevices: Object, error: Error }) => Any
     */
    onDevicesChange(handler) {
        console.log(`[MediaDevicesService] New subscription to '${DEVICE_CHANGE_EVENT}'`);
        if (!handler || !isFunction(handler)) {
            return;
        }
        return this.$eventBus.on(DEVICE_CHANGE_EVENT, handler);
    }

    /**
     * navigator's getUserMedia + storage of all accessed streams and release when new ones are gotten
     *
     * @param {Object} constraints options for navigator.mediaDevices.getUserMedia(constraints)
     */
    async getUserMedia(constraints) {
        console.log('[MediaDevicesService] getUserMedia');

        if (constraints.audio) {
            this.stopStreamsWithAudio();
        }
        if (constraints.video) {
            this.stopStreamsWithVideo();
        }

        let stream;
        try {
            stream = await this.mediaDevicesProvider.getUserMedia(constraints);
        } catch (error) {
            if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                console.error('[MediaDevicesService] getUserMedia error: required track is missing');
            } else if (error.name === 'NotReadableError' || error.name === 'TrackStartError') {
                console.error('[MediaDevicesService] getUserMedia error: webcam or mic are already in use');
            } else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError') {
                console.error('[MediaDevicesService] getUserMedia error: constraints can not be satisfied by avb. devices');
            } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                console.error('[MediaDevicesService] getUserMedia error: permission denied in browser');
            } else if (error.name === 'TypeError' || error.name === 'TypeError') {
                console.error('[MediaDevicesService] getUserMedia error: empty constraints object');
            } else {
                console.error('[MediaDevicesService] getUserMedia error:', error);
            }
            throw error;
        }

        if (constraints.audio) {
            this.streamsWithAudio.push(stream);
        }
        if (constraints.video) {
            this.streamsWithVideo.push(stream);
        }

        return stream;
    }

    /**
     * Request webcam and mic permissions just to avoid doing it once the user has entered the Studio room.
     *
     * @returns {Promise<Object>}
     */
    async requestPermissions() {

        this.permissions = { audio: true, video: true };
        try {
            // create a dummy stream just to get the user's permission
            await this.getUserMedia({ audio: true });
        } catch (error) {
            this.permissions.audio = false;
        }
        try {
            // create a dummy stream just to get the user's permission
            await this.getUserMedia({ video: true });
        } catch (error) {
            this.permissions.video = false;
        }
        this.stopAllStreams();

        // when permission are not granted yet, the storeDeviceList of the constructor fetches empty arrays
        await this.storeDeviceList();

        return this.permissions;
    }

    /**
     * stop all tracks of all stored audio streams
     */
    stopStreamsWithAudio() {
        this.stopAllStreamTracks(this.streamsWithAudio);
    }

    /**
     * stop all tracks of all stored video streams
     */
    stopStreamsWithVideo() {
        this.stopAllStreamTracks(this.streamsWithVideo);
    }

    /**
     * stop all tracks of all stored streams
     */
    stopAllStreams() {
        this.stopStreamsWithVideo();
        this.stopStreamsWithAudio();
    }

    /**
     * Stop an HTML video element and its tracks
     */
    stopVideoElement(videoEl, killStream = true) {
        console.log('[MediaDevicesService] stop video element');
        if (videoEl) {
            try {
                videoEl.pause();
            } catch (error) {
                /** videoEl already paused or in a stale state */
            }
            try {
                if (killStream) {
                    const stream = videoEl.srcObject;
                    this.stopAllStreamTracks([ stream ]);
                }
                videoEl.srcObject = null;
            } catch (error) {
                /** videoEl already empty */
                console.warn('[MediaDevicesService] stopVideoElement error', error);
            }
        }
    }

    /**
    * stop all tracks of all streams in a stream array
    *
    * @param {Array} streams array of stream
    */
    stopAllStreamTracks(streams) {
        if (!streams || !streams.length) {
            return;
        }
        for (const stream of streams) {
            if (!stream) {
                continue;
            }
            if (isFunction(stream.getTracks)) {
                for (const track of stream.getTracks()) {
                // backward compatibility (https://developers.google.com/web/updates/2015/07/mediastream-deprecations?hl=en#stop-ended-and-active)
                    if (isFunction(track.stop)) {
                        track.stop();
                    }
                }
            }
            // backward compatibility (https://developers.google.com/web/updates/2015/07/mediastream-deprecations?hl=en#stop-ended-and-active)
            if (isFunction(stream.stop)) {
                stream.stop();
            }
        }
    }
}

angular
    .module('maestro.services')
    .service('mediaDevicesService', MediaDevicesService);
