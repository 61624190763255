// Utils
import VideoCallService from './videoCallService';

class VideoBreakoutService extends VideoCallService {
    /* @ngInject */
    constructor($http, EVENT, databaseService, $document, $compile, $rootScope, $eventBus, $location, navService, SETTINGS) {
        super($http, EVENT, databaseService, $document, $compile, $rootScope, $eventBus, $location, navService, SETTINGS);

        this.$compile = $compile;
        this.SETTINGS = SETTINGS;

        this.listeners = [];
    }

    /**
     * Returns if the video call has to be auto joined or not
     *
     * @param {object} nav the nav of origin of the video
     * @return {Boolean} whether to auto join or not
     */
    shouldAutoJoin(nav) {
        const docType = nav.page_params.fp_type || nav.analytic_params.document_type;
        const settingName = `${docType}-video-call`;
        if (this.SETTINGS.hasOwnProperty(settingName)) {
            return this.SETTINGS[settingName]['auto-join'];
        }
        return true;
    }

    /**
     * Checks if the nav is configured for an auto join and
     * eventually joins the given meeting.
     *
     * @param {object} nav the nav of origin of the video
     * @param {object} meetingId the ID of the video call
     */
    attemptAutoStart(nav, meetingId) {
        if (this.shouldAutoJoin(nav)) {
            this.start(nav, meetingId);
        }
    }

    /**
     * Starts a new video call instance that could be set in PIP mode
     *
     * @param {object} nav the nav of origin of the video
     * @param {object} sessionId the ID of the video call
     */
    start(nav, sessionId) {

        const docType = nav.page_params.fp_type || nav.analytic_params.document_type;
        const parentDocumentId = nav.page_params.model_id || nav.analytic_params.document_id;

        super.start({ docType, sessionId, parentDocumentId });

        this.$eventBus.emit('join-call-button:hide');

        const ongoingListener = this.$eventBus.on('join-call-button:created', (location) => {
            if (this.originalLocation && location) {
                if (this.originalLocation === location) {
                    this.$eventBus.emit('join-call-button:hide');
                } else {
                    // trying to open another video call, but it's hidden until user clicks on "join", we will close
                    // the current video call at that moment
                    this.$eventBus.emit('join-call-button:show');
                }
            }
        });

        this.listeners.push(
            ongoingListener,
        );
    }
}

angular
    .module('maestro.services')
    .service('videoBreakoutService', VideoBreakoutService);
