import { map } from 'lodash';

class EventBusService {
    /* @ngInject */
    constructor($rootScope) {
        // creates a new event bus emitter
        this.spawn = target => {
            if (!target) target = {};
            let eventBus = $rootScope.$new(true);

            target.on = eventBus.$on.bind(eventBus);
            target.on = (name, handler) =>
                eventBus.$on(name, (_ev, name, data) => handler(name, data));
            target.emit = (name, data) => {
                console.log('[$eventBus]', name, data);
                eventBus.$emit(name, data);
            };
            target.subscribeToEvents = (events, callback) => {
                let unsubs = map(events, e => eventBus.$on(e, callback)) || [];
                return () => map(unsubs, fn => fn());
            };
            return target;
        };
        this.spawn(this);
    }
}

angular.module('maestro.services').service('$eventBus', EventBusService);
