// simple autofocus directive with parametrized timeouts to deal with animations
angular.module('maestro.directives').directive('autofocus', [
    '$timeout',
    $timeout => ({
        restrict: 'A',
        link: function($scope, $element, attrs) {
            $timeout(function() {
                console.log('[autofocus]', $element[0]);
                $element[0].focus();
            }, parseInt(attrs.autofocus, 10) || 10);
        }
    })
]);
