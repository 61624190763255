/* @ngInject */
function BlockAutoSliderDirective($parse, $interval) {
    return {
        restrict: 'AC',
        link: function(scope, elem, attrs) {
            let autoSlidingInterval = $parse(attrs.blockAutoSlider)(scope);

            let updateInterval;
            let nextSlide = () => scope.nextSlide();
            let cancel = () => $interval.cancel(updateInterval);
            if (autoSlidingInterval > 0) {
                console.log(
                    '[BlockAutoSliderDirective] init',
                    autoSlidingInterval
                );
                updateInterval = $interval(
                    nextSlide,
                    autoSlidingInterval * 1000
                );
                scope.$on('$destroy', cancel);
            }
        }
    };
}

angular
    .module('maestro.directives')
    .directive('blockAutoSlider', BlockAutoSliderDirective);
