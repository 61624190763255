import { extend, cloneDeep } from 'lodash';

export const NavHeaderActionsComponent = {
    template: require('./nav-header-actions.jade'),
    bindings: {
        parentNavId: '<',
        actions: '<',
        toolbar: '<'
    },
    controller: class NavHeaderActionsComponent {
        /* @ngInject */ constructor(
            THEME,
            databaseService,
            navFormService,
            navService,
            progressBarService,
            $scope
        ) {
            this.databaseService = databaseService;
            this.navFormService = navFormService;
            this.navService = navService;
            this.progressBarService = progressBarService;
            this.iconColor = THEME.primary_color || '#999999';
            this.buttonStyle = $scope.$$sheetId ? '' : 'btn-transparent';
        }

        $onInit() {
            this.loading = false;
            this.showToolbarMenu = this.navService.showNavToolbarMenu(
                this.toolbar
            );
        }

        trigger(action) {
            if (this.loading) return;

            const params = cloneDeep(action.params);

            if (action.action_key === 'send_data' && this.parentNavId) {
                extend(params, this.navFormService.get(this.parentNavId));
            }

            this.loading = true;
            this.progressBarService.startTask();
            this.databaseService
                .runAppScript(action.target, params)
                .finally(() => {
                    this.loading = false;
                    this.progressBarService.finishTask();
                });
        }
    }
};
