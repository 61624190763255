import { get, isEmpty, isObject, remove } from 'lodash';
import { isUnsupported } from '../utils/browser';
import VideoPipService from './videoPipService';


class PictureInPictureService extends VideoPipService {
    /* @ngInject */ constructor(
        EVENT,
        $document,
        $q,
        $compile,
        $rootScope,
        $eventBus,
        $location,
        $http,
        metricsService,
        visibilityService,
        navService
    ) {
        super($document, $eventBus, $location, $rootScope, $http, navService);

        this.event = EVENT;
        this.$q = $q;
        this.$compile = $compile;
        this.metricsService = metricsService;

        this.theatreMode = false;
        this.player = null;

        this.activeTrackings = [];

        // turn this service into an event emitter
        $eventBus.spawn(this);

        $eventBus.on('pip:start', params => this.start(params));
        this.currentUrl = null;
        this.currentLiveStreamId = null;
    }

    start(config) {
        console.log('[PictureInPictureService] start');
        if (isEmpty(config) || !isObject(config)) {
            return;
        }

        const { width, height, url, liveStreamId } = config;

        if (liveStreamId && get(this, 'config.liveStreamId') === liveStreamId) {
            // already playing this stream
            return;
        }

        this.config = config;

        this.initialWidth = width;

        if (url && this.currentUrl === url && liveStreamId === this.currentLiveStreamId) {
            this.setStaticPosition(width);
            return;
        }
        this.stop();
        this.currentUrl = url;
        this.currentLiveStreamId = liveStreamId;

        this.startTracking(config);

        const deferred = this.$q.defer();
        this.pipScope = this.$rootScope.$new(true);

        this.player = {
            deferred,
            scope: this.pipScope
        };

        this.pipScope.config = config;
        this.pipScope.onClose = () => { this.stop(); };
        this.pipScope.analytics = config.analytic_params;

        console.log('[PictureInPictureService] starting playback', url);

        let videoElement;
        if (isUnsupported()) {
            console.log('[PictureInPictureService] video streaming not supported on this browser');
            videoElement = this.$compile('<update-browser-box></update-browser-box>')(this.pipScope);
        } else {
            const isInSession = get(this, 'navService.activeNav.nav.page_params.fp_type', '') === 'session';
            const homeFeedWidthClass = config.isStreamless && !isInSession ? 'home-feed-width' : '';
            videoElement = this.$compile(`<pip class="hls ${homeFeedWidthClass}" config="config" on-close="onClose()" analytics="analytics"></pip>`)(this.pipScope);
        }

        super.init(videoElement, { scope: this.pipScope, width, height });

        this.setStaticPosition(width);

        if (config.fullscreen) {
            this.enterTheatreMode();
        }

        return deferred.promise;
    }

    stop() {
        if (!this.player) {
            return;
        }

        console.log('[PictureInPictureService] finished');

        this.player.deferred.resolve();
        this.player = null;
        this.pipScope.$destroy();
        this.pipScope = null;

        this.currentUrl = null;
        this.currentLiveStreamId = null;
        this.config = null;

        this.stopTracking();
        super.stop();
    }

    startTracking(config) {
        this.trackingConfiguration = {
            analytic_params: config.analytic_params
        };
        this.activeTrackings.push(this.trackingConfiguration);
    }

    stopTracking() {
        // remove tracking configuration
        remove(
            this.activeTrackings,
            conf => conf === this.trackingConfiguration
        );
    }

    float() {
        if (get(this, 'pipScope.config.isStreamless')) {
            return this.stop();
        }

        super.float();
    }

    setStaticPosition(width) {
        this.embed();
        if (width) {
            // keep ratio
            this.videoElement.removeClass('pinned').css({ width: '', left: '', top: '', height: '' });
        }
    }

    restore() {
        this.setStaticPosition(this.initialWidth);
    }

    enterTheatreMode() {
        this.theatreMode = true;
    }

    leaveTheatreMode() {
        if (this.event.is_webinar) return;
        this.theatreMode = false;
    }
}

angular
    .module('maestro.services')
    .service('pictureInPictureService', PictureInPictureService);
