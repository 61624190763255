const { map, isArray } = require('lodash');
const moment = require('moment-timezone');
const MAX_TAG_WIDTH = 45;
const DAY_IN_SECONDS = 24 * 60 * 60 - 1;

/* @ngInject */
function AgendaNavCtrl(
    $rootScope,
    $scope,
    progressBarService,
    navService,
    databaseService,
    localCacheFactory,
    paginatorFactory
) {
    const nav = $scope.nav;
    const paginator = paginatorFactory({ pageSize: 100 });
    const now = moment();
    const today = now.startOf('day').unix();
    $scope.selectedDay = null;

    const localCalendarCache = localCacheFactory(
        `${nav._dynamic_nav ? new Date().getTime() : nav._id}/calendar`
    );
    let eventCalendarCache = localCalendarCache.get('days');
    const eventCalendarCacheExpires = localCalendarCache.get('expires', -1);
    const calendarCacheExpired = function() {
        if (!eventCalendarCacheExpires) return false;
        return eventCalendarCacheExpires < moment.unix();
    };
    const cacheEventCalendar = function(days) {
        if (!days || !isArray(days.days)) {
            return days;
        }
        localCalendarCache.put('days', days);
        localCalendarCache.put(
            'expires',
            moment()
                .add(1, 'hour')
                .unix()
        );
        eventCalendarCache = localCalendarCache.get('days');
        return days;
    };
    const defaultDay = function() {
        const { days } = eventCalendarCache;
        return days.indexOf(today) === -1 ? days[0] : today;
    };
    const buildCalendarStrip = function(days) {
        const dayWidth = `${100 / days.days.length}%`;
        $scope.calendarDays = map(days.tags, (tags, day) => {
            day = parseInt(day, 10);
            const date = moment.unix(day);
            let totalSessions = 0;
            for (let tag in tags) {
                if (tags.hasOwnProperty(tag)) {
                    totalSessions += tags[tag];
                }
            }

            return {
                ts: day,
                day: date.format('ddd'),
                date: date.date(),
                today: today === date.unix(),
                width: dayWidth,
                tags: map(tags, (v, k) => {
                    return {
                        backgroundColor: k,
                        'max-width':
                            5 + Math.round((v / totalSessions) * MAX_TAG_WIDTH)
                    };
                })
            };
        });
    };
    const getEventDaysAndBuildCalendar = function() {
        const { source, params } = $scope.nav.agendaDays;
        if (!source) {
            console.warn(
                '[AgendaNavCtrl] missing agendaDays data source',
                $scope.nav
            );
            return;
        }

        if (eventCalendarCache) {
            buildCalendarStrip(eventCalendarCache);
            $scope.setDay(defaultDay());

            if (calendarCacheExpired()) {
                console.log(
                    '[AgendaNavCtrl] calendar cache expired, refreshing'
                );
                databaseService
                    .runAppScript(source, params)
                    .then(({ data: { response: days } }) =>
                        cacheEventCalendar(days)
                    );
            }
        } else {
            progressBarService.startTask();
            databaseService
                .runAppScript(source, params)
                .then(({ data: { response: days } }) =>
                    buildCalendarStrip(cacheEventCalendar(days))
                )
                .then(() => $scope.setDay(defaultDay()))
                .finally(progressBarService.finishTask);
        }
    };

    const join = (dest, source) => Array.prototype.push.apply(dest, source);
    const updateNav = function() {
        progressBarService.startTask();
        navService
            .inflatePaginatedNav(
                $scope.nav,
                {
                    start: $scope.selectedDay,
                    end: $scope.selectedDay + DAY_IN_SECONDS
                },
                paginator
            )
            .then(resp =>
                resp
                    ? join($scope.nav.ds.elements, resp.paginatedElements)
                    : null
            )
            .finally(() => {
                progressBarService.finishTask();
            });
    };

    // Pagination
    $scope.nextPage = function() {
        if (paginator.hasMorePages()) updateNav();
    };

    // Day switcher
    $scope.setDay = function(date) {
        if ($scope.selectedDay === date) return;

        $scope.selectedDay = date;
        paginator.reset();
        $scope.nav.ds.elements = [];
        updateNav();
    };

    // Row actions
    $scope.performRowActions = navService.mergeAndPerformRowActions(
        nav.actions,
        nav.nuid
    );

    // Breakouts
    $scope.toggleBreakout = function(row) {
        if (row.expanded) {
            row.expanded = false;
        } else {
            $scope.expandBreakout(row);
        }
    };
    $scope.expandBreakout = function(row) {
        row.expanded = true;
        if (!row.elements || !row.elements.length) {
            navService
                .inflateNav($scope.nav, {
                    parent: row.render.params.mapping.breakout_id
                })
                .then(({ elements }) => (row.elements = elements));
        }
    };

    // TODO depracate as this is no longer required
    const initCtrl = function() {
        $scope.nav.ds.elements = [];
        $scope.showCalendar =
            'show_calendar' in ($scope.nav.theme || {})
                ? $scope.nav.theme.show_calendar
                : true;

        paginator.reset();
        getEventDaysAndBuildCalendar();
    };
    $scope.$watch('nav', initCtrl);
}

angular.module('maestro.navs').controller('AgendaNavCtrl', AgendaNavCtrl);
