
/**
 * returns true if element has a working audio and video
 * @param {HTMLElement} element
 * @param {Boolean} checkAudio
 * @param {Boolean} checkVideo
 * @returns {Boolean}
 */
module.exports.videoElementSanityCheck = function(element, checkAudio, checkVideo) {
    if (!element || !element.srcObject) {
        console.warn('[SanityCheck] mediaStreamSanityCheck failed', element);
        return false;
    }

    const mediaStream = element.srcObject;
    const audioMediaStreamTracks = mediaStream.getAudioTracks() || [];
    const videoMediaStreamTracks = mediaStream.getVideoTracks() || [];

    return mediaStreamSanityCheck(mediaStream)
        && (checkAudio ? mediaStreamTracksSanityCheck(audioMediaStreamTracks) : true)
        && (checkVideo ? mediaStreamTracksSanityCheck(videoMediaStreamTracks) : true);
};

/**
 * returns true if mediaStream seems to be working
 * @param {MediaStream} mediaStream
 * @returns {Boolean}
 */
const mediaStreamSanityCheck = function(mediaStream) {
    const sane = mediaStream.active;
    if (!sane) {
        console.warn('[SanityCheck] mediaStreamSanityCheck failed', mediaStream);
    }
    return sane;
};

/**
 * returns true if at least 1 mediaStreamTrack is working
 * @param {Array<MediaStreamTrack>} mediaStreamTracks
 * @returns {Boolean}
 */
const mediaStreamTracksSanityCheck = function(mediaStreamTracks) {
    if (!mediaStreamTracks || !mediaStreamTracks.length) {
        console.warn('[SanityCheck] mediaStreamTracksSanityCheck failed', mediaStreamTracks);
        return false;
    }

    for (const mediaStreamTrack of mediaStreamTracks) {
        if (mediaStreamTrackSanityCheck(mediaStreamTrack)) {
            return true;
        }
    }

    return false;
};

/**
 * returns true if mediaStreamTrack is working
 * @param {MediaStreamTrack} mediaStreamTrack
 * @returns {Boolean}
 */
const mediaStreamTrackSanityCheck = function(mediaStreamTrack) {
    if (!mediaStreamTrack) {
        console.warn('[SanityCheck] mediaStreamTrackSanityCheck failed', mediaStreamTrack);
        return false;
    }
    const sane = mediaStreamTrack && mediaStreamTrack.readyState === 'live' && !mediaStreamTrack.muted && mediaStreamTrack.enabled;
    if (!sane) {
        console.warn('[SanityCheck] mediaStreamTrackSanityCheck failed', mediaStreamTrack);
    }

    return sane;
};
