import { map, uniq, compact, isArray } from 'lodash';

export default class NavEvents {
    /* @ngInject */
    constructor($eventBus) {
        this.$eventBus = $eventBus;
    }

    on() {
        return this.$eventBus.on.apply(null, arguments);
    }

    emit(name, data = {}) {
        return this.$eventBus.emit(name, data);
    }

    subscribeToEvents() {
        return this.$eventBus.subscribeToEvents.apply(null, arguments);
    }

    // alias for native method
    postNotification({ name, data }) {
        return this.$eventBus.emit(name, data);
    }

    flattenNotificationMap({
        notification,
        notifications,
        doc_ids,
        fp_types
    } = {}) {
        const clean = items => (isArray(items) ? items : []);

        return uniq(
            compact(
                []
                    .concat(clean(notification))
                    .concat(clean(notifications))
                    .concat(map(clean(doc_ids), id => `doc:${id}`))
                    .concat(map(clean(fp_types), id => `type:${id}`))
            )
        );
    }
}
