import { get } from 'lodash';

export default class QnaService {
    /* @ngInject */
    constructor(EID, $http, liveStreamService, metricsService, signalService) {
        this.$http = $http;
        this.eid = EID;
        this.liveStreamService = liveStreamService;
        this.metricsService = metricsService;
        this.signalService = signalService;

        this.trackedHighlightedQuestions = [];

        this.jitter = Math.round(Math.random() * 10 * 1000);
    }

    updateSignal(livestreamId) {
        return `liveStream/${livestreamId}/questions`;
    }

    /**
     * Get the initial live-stream state
     */
    async getQuestions(livestreamId) {
        const state = await this.liveStreamService.getInitialState(livestreamId);
        return {
            qna: get(state, 'data.qna', []),
            qnaSortedByUpvotes: get(state, 'data.qnaSortedByUpvotes', []),
            qnaUpvotedQuestions: get(state, 'data.qnaUpvotedQuestions')
        };
    }

    /**
     * Get the initial live-stream state
     */
    postQuestion(livestreamId, { anonymous, question }) {
        return this.$http.post(`/api/v1/eid/${this.eid}/live-stream/${livestreamId}/questions`, { anonymous, question });
    }

    isAnonymous(question) {
        if (!question) {
            return false;
        }
        return !question.participant || question.participant.toLowerCase() === 'anonymous';
    }

    isFromBSTG(question) {
        if (!question) {
            return false;
        }
        return !question.pid && !this.isAnonymous(question);
    }

    resolveProfilePicture(question, eventIcon) {
        if (!question) {
            return question;
        }
        const fromBstg = this.isFromBSTG(question);
        question.profile_pic = question.profile_pic || (eventIcon && fromBstg) ? eventIcon : '';
        return question;
    }

    onQnaHighlight(questionId) {
        if (!questionId || this.trackedHighlightedQuestions.includes(questionId)) {
            return;
        }

        this.trackedHighlightedQuestions.push(questionId);

        const stats = {
            name: 'view',
            document_type: 'live_qna_question',
            document_id: questionId,
            jitter: this.jitter
        };

        setTimeout(() => this.metricsService.trackAnalytics(stats), this.jitter);
    }

    voteQuestions(livestreamId, { upvotes = undefined, downvotes = undefined }) {
        return this.$http.post(`/api/v1/eid/${this.eid}/live-stream/${livestreamId}/questions/vote`, { upvotes, downvotes });
    }
}

angular
    .module('maestro.services')
    .service('qnaService', QnaService);
