import {
    map,
    each,
    zipObject,
    filter,
    findKey,
    isEmpty,
    isFunction,
    cloneDeep
} from 'lodash';
import handlebars from 'handlebars';
import unflattenObject from '../utils/unflattenObject';
import { v4 as uuid } from 'uuid';

// {nav._id: nav.route}
const ROUTES = {};
// {nav.id: /regexp test that matches nav.route/}
const ROUTE_TESTS = {};
// {nav.id: handlebars.compile(nav.route)}
const COMPILED_ROUTES = {};

let ignoreNextUrlChange = null;

class NavRouterService {
    /* @ngInject */
    constructor(EVENT_NAVS, $location, $rootScope, $timeout) {
        this.eventNavs = EVENT_NAVS;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;

        // version 1: with stacked navigation
        // version 2: only one nav visible at a time
        this.NAV_VERSION = 2;
    }

    setUrlForNav(nav, navService) {
        const currentUrl = this.$location.$$url;

        if (!nav.route || nav.set_route === false) {
            // still does a navigation to have back behaving properly
            this.$location.url(currentUrl);
            return;
        }

        const urlFn = COMPILED_ROUTES[nav._id];
        let url = currentUrl;

        if (isFunction(urlFn)) {
            url = urlFn(nav);
        }

        if (nav._dynamic_nav) {
            if (!decodeURIComponent(url).includes('#_dynamic')) {
                url = url + (nav.nuid ? `#${nav.nuid}` : '#_dynamic_' + uuid());
                this.eventNavs[nav.nuid] = nav;
            }
        }

        if (this.$location.$$url === url) return;

        if (nav.modal) {
            url = url + '?modal';
        }

        console.log('[navRouterService] url set to', url);
        ignoreNextUrlChange = url;

        try {
            // extract the current navigation minus the nav_spotman, and save it as a state
            const nav = cloneDeep(navService.activeNav.nav);
            const sheets = cloneDeep(navService.getSavedSheets());
            this.$location.state(
                JSON.stringify({
                    nav,
                    sheets,
                    version: this.NAV_VERSION
                })
            );
        } catch (error) {
            // it will degrade properly even if HTML5 history is not supported
            console.warn('[navRouterService] browser does not support HTML5 history', error);
        }

        if (currentUrl.includes('?modal')) {
            this.$timeout(() => {
                this.$location.url(url);
                this.$rootScope.$apply();
            });
        } else {
            this.$location.url(url);
        }
    }

    extractNavFromUrl(url) {
        const { navId, routeParams } = this.extractNavIdAndParamsFromUrl(url);
        if (!navId) return {};

        const nav = unflattenObject(routeParams, cloneDeep(this.eventNavs[navId]));

        if (!nav) return {};

        if (nav.model_route_param) {
            nav.model_id = routeParams[nav.model_route_param];
        }

        return nav;
    }

    extractNavIdAndParamsFromUrl(url) {
        if (url.includes('#_dynamic_')) {
            const [ , nid ] = url.split('#');
            return { navId: nid, routeParams: {} };
        }

        const navId = this.extractNavIdFromUrl(url);
        if (!navId) return {};

        const route = ROUTES[navId];
        const test = ROUTE_TESTS[navId];
        const mustacheShaver = /{|}/g;
        // "/{{a}}/{{{b}}}/{{c.d}}" => ["a", "b", "c.d"]
        const paramKeys = map(route.match(test).slice(1), param =>
            param.replace(mustacheShaver, '')
        );

        // "/v1/v2/v3" => ["v1", "v2", "v3"]
        const paramValues = url.match(test).slice(1);
        const routeParams = zipObject(paramKeys, paramValues);

        return { navId, routeParams };
    }

    extractNavIdFromUrl(url) {
        // An url can match multiple routes. We sort them from the longer to the
        // shortest in order to pick the best match for a particular route.
        const matchedRoutes = filter(ROUTE_TESTS, t => t.test(url)).sort((a, b) => {
            const al = a.toString().length,
                bl = b.toString().length;
            const res = al < bl ? 1 : -1;

            // Preffer perfect match over a pattern.
            return al === bl && /\(\.\*\)/.test(b) ? -1 : res;
        });

        return findKey(ROUTE_TESTS, t => t === matchedRoutes[0]);
    }
}

const modalUrl = function(url, oldUrl) {
    const urlHasModal = (url || '').includes('?modal');
    const oldUrlHasModal = (oldUrl || '').includes('?modal');
    if (url !== oldUrl || urlHasModal || oldUrlHasModal) {
        const bothModal = urlHasModal && oldUrlHasModal;
        return bothModal || url.split('?modal')[0] === oldUrl.split('?modal')[0];
    }
    return false;
};

const buildNavRoutes = /* @ngInject */ function(
    EVENT_NAVS,
    EID,
    ROUTE_PREFIX,
    HOME_NAV,
    $rootScope,
    $location,
    $timeout,
    navService,
    navRouterService
) {
    const TEMPL_REGEX = /{{[^}}]+}}/g;
    const prefix = `${ROUTE_PREFIX}/${EID}`;
    each(EVENT_NAVS, ({ route }, id) => {
        if (!route) return;
        route = prefix + route;
        ROUTES[id] = route;
        ROUTE_TESTS[id] = new RegExp(route.replace(TEMPL_REGEX, '(.*)'));
        COMPILED_ROUTES[id] = handlebars.compile(route);
    });

    let firstLocationChange = false;
    $rootScope.$on('$locationChangeStart', (event, url, oldUrl, newState) => {
        const parsedState = newState ? JSON.parse(newState) : {};
        // This is to prevent a corner case where a url with modal is
        // shared with a third party and the webapp is directly open
        // with this url. The problem that is solving is that in this
        // scenario it won't be possible to close the last modal if there
        // are more than one. Example bookmarkable documents.
        // The key here is to detect that no nav is present but a modal is
        // requested.
        if (!firstLocationChange && url.includes('?modal')) {
            url = url.split('?')[0];
            oldUrl = oldUrl.split('?')[0];
        }

        firstLocationChange = true;

        let path = $location.path() + ($location.hash() ? `#${$location.hash()}` : '');
        if ($location.search().modal) {
            path += '?modal';
        }

        // if prefix with eid do not match, reload
        if (
            url !== oldUrl &&
            path.substr(0, prefix.length) !== prefix
        ) {
            console.warn(`[navRouter] Path ${path} does not match current event prefix (${prefix}), reloading.`);
            return navService.reload();
        }

        if (ignoreNextUrlChange === path) {
            ignoreNextUrlChange = null;
            return;
        }

        if (modalUrl(url, oldUrl)) {
            const lastSheetId = navService.getLastSheetId();
            const lastSheet = navService.getSavedSheets().filter(sheet => sheet.sheetId === lastSheetId);
            const bothModal = url && oldUrl && url.includes('?modal') && oldUrl.includes('?modal');

            if (
                !bothModal &&
                navService.$sheet.hasOpenSheets() &&
                oldUrl.includes('?modal') &&
                lastSheet.length > 0
            ) {
                // special case for the back button (we still need to close the modal)
                event.preventDefault();
                navService.closeLastNav();
                navService.removeSavedSheet(navService.getLastSheetId());
            } else if (
                !navService.$sheet.hasOpenSheets() &&
                url.includes('?modal')
            ) {
                event.preventDefault();
            }
            return; // if it's a modal nav change lets not emit navigation
        }

        // this is an actual navigation
        $rootScope.$emit('navigate');

        const nav = navRouterService.extractNavFromUrl(url);

        if (newState && !nav.model_route_param) {
            const { nav, sheets, version } = parsedState;
            if (version === navRouterService.NAV_VERSION) {
                if (
                    !url.includes('?modal') &&
                    sheets.find(sheet => sheet.modal)
                ) {
                    // to prevent bugs while refreshing page SS-9681
                    return navService.applyNavs(nav, []);
                }

                // apply navs
                return navService.applyNavs(nav, sheets);
            }
        }

        if (!url || !url.length) {
            return navService.resetNavigation();
        }

        if (!nav || isEmpty(nav)) {
            console.log('[navRouter] unknown route', url, 'returning home');
            return navService.resetNavigation();
        }

        // special case, the home nav is dynamic
        if (nav.route === HOME_NAV.route) {
            return navService.resetNavigation();
        }

        // open home below the popup
        if (nav.modal) {
            navService.resetNavigation();
            // differed nav opening
            $timeout(() => navService.openNav(nav));
        } else if (nav.model_id && nav.page_id !== 'profile' && nav.fp_ext_id !== 'nav_app_search') {
            // profile comes with model ID, but if we open it as document we end up on /person/{modelId} (SS-17231)
            navService.openDocument({ id: nav.model_id });
        } else if (nav._id === 'nav_video_content' && nav.video_id && !(nav.url || nav.media_url)) {
            // when accessing video/{{videoId}} routes directly, the nav is missing the video url (SS-42084)
            navService.openDocument({ id: nav.video_id });
        } else {
            navService.presentNav(nav);
        }
    });

    $rootScope.$on('$locationChangeSuccess', (_, url, oldUrl) => {
        // we don't want to scroll to the top when opening modals SS-11871
        if (!modalUrl(url, oldUrl)) {
            window.scrollTo(0, 0);
        }
    });
};

angular
    .module('maestro.navs.router', [ 'maestro.navs.types' ])
    .service('navRouterService', NavRouterService)
    .run(buildNavRoutes);
