import { debounce, throttle } from 'lodash';

function detectTalking(emitter, startedTalkingHandler, isContinuouslyTalkingHandler) {
    let isTalking = false;
    let lastTalkingTimestamp = null;

    function setIsTalking(val) {
        isTalking = val;
        startedTalkingHandler(val);
    }

    let movingAvg;

    const onAudioLevelUpdated = event => {
        const now = Date.now();
        if (movingAvg === undefined || movingAvg <= event.audioLevel) {
            movingAvg = event.audioLevel;
        } else {
            movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
        }

        let logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);

        if (logLevel > 0.2) {
            if (!lastTalkingTimestamp) {
                // user has started talking
                lastTalkingTimestamp = now;
            } else if (isTalking) {
                // user is still talking
                lastTalkingTimestamp = now;
            } else if (now - lastTalkingTimestamp > 1000) {
                // detected audio activity for more than 1s
                // for the first time.
                setIsTalking(true);
            }
        } else if (lastTalkingTimestamp !== null && now - lastTalkingTimestamp > 3000) {
            // detected low audio activity for more than 3s
            setIsTalking(false);
            lastTalkingTimestamp = null;
        }

        if (isTalking) {
            isContinuouslyTalkingHandler();
        }
    };

    emitter.on('audioLevelUpdated', onAudioLevelUpdated);

    return () => {
        emitter.off('audioLevelUpdated', onAudioLevelUpdated);
    };
}

function talkingIndicator() {
    return {
        restrict: 'A',
        link(scope, elem, attrs) {
            const parent = elem.parent();

            let unsubscriber;
            scope.$watch(attrs.talkingIndicator, tryInit);
            tryInit();

            function tryInit() {
                // emitter is either a subscriber or publisher of an audio
                // we pass it to this directive like that:
                // <div talking-indicator="publisher">
                // to get the value of the talkingIndicator attribute we need to understand
                // what `publisher` is on current scope, that's why we need to $eval
                const attr = scope.$eval(attrs.talkingIndicator);
                if (!attr) {
                    return;
                }

                const emitter = attr && attr.otSubscriberObject ? attr.otSubscriberObject : attr;
                if (!emitter || !emitter.on || !emitter.id) {
                    return;
                }

                const startedTalkingHandler = isTalking => {
                    const otVideoPosterEl = elem.find('.OT_video-poster');

                    if (isTalking) {
                        debounce(() => {
                            parent
                                .find('.focused-speaker')
                                .removeClass('focused-speaker');
                        }, 1000, { leading: true, trailing: false });

                        elem.addClass('talking-indicator focused-speaker');
                        otVideoPosterEl.addClass('talking-indicator poster');
                    } else {
                        elem.removeClass('talking-indicator');
                        otVideoPosterEl.removeClass('talking-indicator poster');
                    }
                };

                let isContinuouslyTalkingHandler = () => {};

                if (attr.pid) {
                    // only subscribers have a pid property
                    isContinuouslyTalkingHandler = throttle(() => {
                        scope.$emit('talkingIndicator:isTalking', attr.pid);
                    }, 2000);
                }

                if (unsubscriber) {
                    unsubscriber();
                }
                unsubscriber = detectTalking(emitter, startedTalkingHandler, isContinuouslyTalkingHandler);

                console.log('[TalkingIndicator] successfully (re)instantiated');
            }
        }
    };
}

angular
    .module('maestro.directives')
    .directive('talkingIndicator', talkingIndicator);
