// Tabbabbing prevention shield:
// https://www.owasp.org/index.php/HTML5_Security_Cheat_Sheet#Tabnabbing

angular.module('maestro.directives').directive('nabShield', [
    () => ({
        restrict: 'A',
        link: function($scope, $element) {
            $element.on(
                'click.nabbing',
                '.nav-panels a[target="_blank"]',
                function(ev) {
                    // https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/HTML5_Security_Cheat_Sheet.md#tabnabbing
                    // add to all anchor noopener and noreferrer to prevent tabnabbing.
                    $('.nav-panels a[target="_blank"]').attr(
                        'rel',
                        'noopener noreferrer'
                    );
                    // intercept normal link opening, to open it with noopener, noreferrer params
                    ev.preventDefault();
                    // manual open a window, incase the "jquery attr rel" is failing because of timing or other issues
                    let newWindow = window.open(
                        this.getAttribute('href'),
                        'noopener,noreferrer,'
                    );
                    // Reset the opener link
                    newWindow.opener = null;
                }
            );
        }
    })
]);
