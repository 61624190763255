import { datadogRum } from '@datadog/browser-rum';

export default class CrashReportingService {

    /**
     * Captures the exception and sends it to the reporting service
     *
     * @param {String} message the error message
     */
    captureException(message) {
        console.error('[CrashReporting] an error occurred:', message);
        if ("f8c3d163-f1f3-4427-bbb7-49b15bcf195f") {
            datadogRum.addError(message);
        }
    }

    /**
     * Logs and sends an error to the crash reporting tool by following the syntax:
     * '[label] reason - error'
     * @param {String} [label] something to identify the reporter
     * @param {String} [reason] a textual description on the error
     * @param {Error} [error] the error itself to report
     */
    reportError(label, reason, error) {
        const msgParts = [];
        if (label) {
            msgParts.push('[', label, ']');

            if (reason || error) {
                msgParts.push(' ');
            }
        }
        if (reason) {
            msgParts.push(reason);

            if (error) {
                msgParts.push(' - ');
            }
        }
        if (error) {
            if (!(error instanceof Error) && typeof error === 'object') {
                msgParts.push(JSON.stringify(error));
            } else {
                msgParts.push(error);
            }
        }
        const msg = msgParts.join('');

        if (msg) {
            this.captureException(msg);
        }
    }
}
