import moment from 'moment-timezone';
import { each, extend, isObject, last } from 'lodash';

const AUTO_SAVE_INTERVAL = 15 * 1000;
const MAX_FILE_SIZE = 10000000; // 10mb

export const NoteNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./note-nav.jade'),
    controller: class NoteNavComponent {
        /* @ngInject */
        constructor(
            PID,
            THEME,
            $interval,
            progressBarService,
            databaseService,
            navService,
            dataSourceEvaluatorService,
            $filter,
            $scope,
            $sheet,
            $i18n
        ) {
            this.theme = THEME;
            this.loading = true;
            this.saving = false;
            this.note = {
                fp_type: 'note',
                fp_owner: PID,
                timestamp: moment().unix(),
                body: null
            };
            // if this is a note containing annotations, its body it not editable
            this.annotationsNote = false;
            this.prevNoteBody = null;
            this.doc_id = null;
            this.autoSaveTimer = null;

            this.$interval = $interval;
            this.progressBarService = progressBarService;
            this.databaseService = databaseService;
            this.navService = navService;
            this.ds = dataSourceEvaluatorService;
            this.stripHTML = $filter('stripHTML');
            this.assetUrl = $filter('assetUrl');
            this.$scope = $scope;
            this.$sheet = $sheet;
            this.$i18n = $i18n;
        }

        $onInit() {
            const config = this.config;

            if (this.config.features) {
                extend(this.config, this.config.features);
                delete this.config.features;
            }

            console.log('[NoteNavComponent] init', config);

            this.doc_id = config.doc_id;
            this.note.parent_doc_id = this.config.parent_doc_id;
            this.autoSaveInterval =
                config.autosave_interval * 1000 || AUTO_SAVE_INTERVAL;
            this.showParentPreview =
                this.note.parent_doc_id &&
                !this.config.modal &&
                !this.config.hide_session_link;

            this.performActions = actions => {
                this.saveNote();
                return this.navService.performActions(actions, config.nuid);
            };

            if (!this.config.allow_rich_media_text) {
                this.fetchNote();
            }
        }

        $onDestroy() {
            this.saveNote();
            this.$interval.cancel(this.autoSaveTimer);
        }

        setEditor(editor) {
            this.editor = editor;
            this.fetchNote();
        }

        addMedia() {
            const toolbar = this.editor.getModule('toolbar');
            toolbar.handlers.image.call(toolbar);
        }

        hasChangesToCommit() {
            const currentBody = this.note.body;
            return (
                // do not save an empty note expect if an already non-empty note has been saved
                (currentBody || this.prevNoteBody) &&
                currentBody !== this.prevNoteBody
            );
        }

        saveNote() {
            const currentBody = this.note.body;
            if (this.saving || !this.hasChangesToCommit()) {
                return;
            }

            if (this.config.allow_rich_media_text) {
                this.encodeNote();
            }

            if (
                !this.config.allow_rich_media_text &&
                this.note.rich_text_body
            ) {
                console.warn(
                    '[NoteNavComponent] cannot save legacy note over a new note format',
                    this.note
                );
                return;
            }

            console.log('[NoteNavComponent] saving note', this.note);

            const save = () => {
                this.saving = true;
                this.progressBarService.startTask();
                if (this.config.allow_rich_media_text) {
                    return this.databaseService.runAppScript(
                        this.config.actions.save.path,
                        this.data
                    );
                } else {
                    return this.databaseService.saveDoc(this.note);
                }
            };

            save()
                .then(({ data: { id, rev } }) => {
                    if (this.config.allow_rich_media_text) {
                        return this.fetchNote();
                    }
                    this.prevNoteBody = currentBody;
                    this.doc_id = id;
                    this.note._id = id;
                    this.note._rev = rev;
                })
                .finally(() => {
                    this.progressBarService.finishTask()((this.saving = false));
                });
        }

        fetchNote() {
            if (!this.doc_id) {
                this.loading = false;
                return;
            }

            const fetch = () => {
                this.loading = true;
                this.progressBarService.startTask();

                if (this.config.get_from_ds) {
                    return this.ds.eval(this.config.ds, {
                        id: this.config.doc_id
                    });
                } else {
                    return this.databaseService.docWithId(this.doc_id);
                }
            };

            fetch()
                .then(note => {
                    this.note = note;
                    if (this.config.get_from_ds) {
                        this.decodeNote();
                    }
                    this.prevNoteBody = note.body;

                    if (note.annotations && note.annotations.length) {
                        this.annotationsNote = true;
                        this.navService.openDocument({
                            _id: note.parent_doc_id
                        });
                    }

                    this.autoSaveTimer = this.$interval(
                        () => this.saveNote(),
                        this.autoSaveInterval
                    );

                    this.getParentDoc();
                })
                .finally(() => {
                    this.loading = false;
                    this.progressBarService.finishTask();
                });
        }

        /**
         * Transforms quill format into package compliant format.
         */
        encodeNote() {
            this.note.rich_text_body = [];

            if (!this.note.body) {
                return;
            }

            this.data = {};
            this.data.note = this.note;
            each(this.note.body.ops, (op, idx) => {
                if (!op.insert) {
                    return;
                }

                let block = {};
                if (isObject(op.insert)) {
                    block.type = Object.keys(op.insert)[0];
                    let b64String = op.insert[block.type];

                    if (b64String.indexOf('base64,') !== -1) {
                        const ts = Math.round(Date.now() / 1000) + idx;
                        const ctype = this.base64MimeType(b64String);
                        block.timestamp = ts;
                        block.content_type = ctype;
                        block.media_key = 'media-' + ts;

                        const b64data = last(b64String.split('base64,'));
                        this.data[block.media_key] = this.b64toFile(
                            b64data,
                            ctype,
                            block.media_key
                        );
                        if (this.data[block.media_key].size > MAX_FILE_SIZE) {
                            this.data[block.media_key] = null;
                            this.showErrorPopup(`Maximum file size allowed is ${MAX_FILE_SIZE / 1000000}MB.`);
                        }
                    } else {
                        block.id = this.mediaURLs[b64String].id;
                        block.timestamp = this.mediaURLs[b64String].ts;
                        block.url = b64String;
                    }
                } else {
                    block.type = 'text';
                    block.text = this.stripHTML(op.insert);
                }
                this.note.rich_text_body.push(block);
            });

            delete this.note.body;
        }

        /**
         * Transforms package note format into quill compatible one.
         */
        decodeNote() {
            let contents = [];
            this.note.body = this.note.body || {};
            this.mediaURLs = {};
            each(this.note.rich_text_body, item => {
                if (item.type === 'text') {
                    contents.push({
                        insert: this.stripHTML(item.text)
                    });
                } else {
                    contents.push({
                        insert: {
                            image: this.assetUrl(item.url)
                        }
                    });

                    this.mediaURLs[this.assetUrl(item.url)] = {
                        ts: item.timestamp,
                        id: item.id
                    };
                }
            });

            requestAnimationFrame(() => {
                console.info('[NoteNavComponent] Setting contents', contents);
                this.editor.setContents(contents, 'api');
            });
        }

        getParentDoc() {
            const parentId =
                this.config.parent_doc_id || this.note.parent_doc_id;
            console.log('[NoteNavComponent] getting note parent doc', parentId);

            if (!parentId || !this.config.parentDocDs) {
                return;
            }

            this.databaseService
                .runAppScript(this.config.parentDocDs.source, {
                    id: parentId
                })
                .then(({ data: { response } }) => (this.parentDoc = response));
        }

        /**
         * Given a base64 data string this method returns its mime type.
         *
         * @param {String} encoded base64 encoded file data
         *
         * @return {String} the mime type of the file
         */
        base64MimeType(encoded) {
            let result = null;

            if (typeof encoded !== 'string') {
                return result;
            }

            const mime = encoded.match(
                /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
            );

            if (mime && mime.length) {
                result = mime[1];
            }

            return result;
        }

        /**
         * Given a base64 data encoded string this method returns a valid File.
         *
         * @param {String} b64Data base64 encoded file data
         * @param {String} contentType the file mime type
         * @param {String} filename the file name
         * @param {Number} sliceSize size of the buffer chunk
         *
         * @return {File} the converted file
         */
        b64toFile(b64Data, contentType = '', filename, sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            const blob = new File(byteArrays, filename, { type: contentType });
            return blob;
        }

        showErrorPopup(message) {
            this.$sheet({
                title: this.$i18n('general.error'),
                message,
                pin: false,
                scrollable: false,
                prompt: true,
                actions: [
                    {
                        id: 'close',
                        label: this.$i18n('general.close'),
                        action: sheet => sheet.reject()
                    }
                ]
            });
        }
    }
};
