import { recolorize } from '../utils/color';

/* @ngInject */
function IconDirective($filter, $q) {
    const assetUrl = $filter('iconUrl');

    return {
        restrict: 'A',
        scope: {
            icon: '=',
            forceColor: '<'
        },
        link(scope, elem) {
            scope.$watchGroup([ 'icon', 'forceColor' ], () => {
                const { icon, forceColor } = scope;

                if (!icon || !icon.length) return;

                if (icon.indexOf('icon-') === 0) {
                    elem.addClass(icon);
                    return;
                }

                let iconAsset = icon;
                // if filename is missing, assume it's a .png
                if (iconAsset.indexOf('/') === -1) {
                    iconAsset += '/file.png';
                }

                elem.addClass('asset-icon');

                const iconAssertUrl = assetUrl(iconAsset);

                if (forceColor) {
                    return recolorize(
                        iconAssertUrl,
                        forceColor,
                        $q
                    ).then(recolorizedUrl =>
                        elem.css(
                            'background-image',
                            `url('${recolorizedUrl}')`
                        )
                    );
                }

                elem.css('background-image', `url('${iconAssertUrl}')`);
            });
        }
    };
}

angular.module('maestro.directives').directive('icon', IconDirective);
