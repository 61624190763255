const sendReaction = (http, endpoint, reactions) => {
    http.post(endpoint, reactions)
        .catch(error => {
            console.error(
                "[Reactions] Couldn't update reaction count:",
                error.message || error.statusText
            );
        });
};

export default class ReactionsService {
    /* @ngInject */
    constructor(EID, $http, signalService) {
        this.$http = $http;
        this.eid = EID;
        this.signalService = signalService;
    }

    /**
     * @param {string} documentId
     * @param {number} claps
     */
    sendClaps(documentId, claps) {
        sendReaction(
            this.$http,
            this.endpoint(documentId),
            { claps }
        );
    }

    /**
     * @param {string} documentId
     * @param {(payload: unknown) => void} handler
     * @returns
     */
    onClapsSignal(documentId, handler) {
        return this.signalService.addSignalListener(
            `liveStream/${documentId}/interactions`,
            handler
        );
    }

    // getters

    endpoint(documentId) {
        return `/api/v1/eid/${this.eid}/log/live-stream/${documentId}/reactions`;
    }
}

angular
    .module('maestro.services')
    .service('reactionsService', ReactionsService);
