import { get } from 'lodash';

export default class PollService {
    /* @ngInject */
    constructor(EID, $http, liveStreamService, metricsService, signalService) {
        this.$http = $http;
        this.eid = EID;
        this.liveStreamService = liveStreamService;
        this.metricsService = metricsService;
        this.signalService = signalService;

        this.trackedExecutions = [];
        this.trackedResultsShown = [];

        this.jitter = Math.round(Math.random() * 10 * 1000);
    }

    /**
     * @param {string} documentId
     * @param {(payload: unknown) => void} handler
     * @returns {() => undefined}
     */
    onPollExecuted(documentId, handler) {
        if (!documentId) {
            return console.error('[PollsService] documentId is required');
        }
        return this.signalService.addSignalListener(
            `${documentId}/poll/executed`,
            handler
        );
    }

    /**
     * @param {string} documentId
     * @param {(payload: unknown) => void} handler
     * @returns {() => undefined}
     */
    onExecutionUpdate(documentId, handler) {
        if (!documentId) {
            return console.error('[PollsService] documentId is required');
        }
        return this.signalService.addSignalListener(
            `${documentId}/poll/execution/update`,
            handler
        );
    }

    /**
     * @param {string} documentId
     * @param {(payload: unknown) => void} handler
     * @returns {() => undefined}
     */
    onHideResults(documentId, handler) {
        if (!documentId) {
            return console.error('[PollsService] documentId is required');
        }
        return this.signalService.addSignalListener(
            `${documentId}/poll/hide-results`,
            handler
        );
    }

    /**
     * @param {string} livestreamId
     * @param {string} executionId
     * @param {(payload: unknown) => void} handler
     * @returns {() => undefined}
     */
    onWordCloudResults(livestreamId, executionId, handler) {
        return this.signalService.addSignalListener(
            `${livestreamId}/word-cloud/results/${executionId}`,
            (payload) => {
                // deprecated property removed to avoid future confusion
                delete payload.results;
                handler(payload);
            }
        );
    }

    /**
     * @param {string} livestreamId
     * @param {string} executionId
     * @param {(payload: unknown) => void} handler
     * @returns {() => undefined}
     */
    onMultipleChoiceResults(livestreamId, executionId, handler) {
        return this.signalService.addSignalListener(
            `${livestreamId}/poll/results/${executionId}`,
            (payload) => {
                handler(payload);
            }
        );
    }

    /**
     * @param {string} documentId
     * @param {string} executionId
     * @param {string[]} answers
     * @returns {Promise}
     */
    vote(documentId, executionId, answers) {
        return this.$http
            .post(`/api/v1/eid/${this.eid}/live-stream/${documentId}/poll/${executionId}`, {
                answers
            });
    }

    /**
     * Get the initial polls state
     *
     * @param {string} liveStreamId
     *
     * @returns {Promise<Object>}
     */
    async getCurrentPoll(liveStreamId) {
        const state = await this.liveStreamService.getInitialState(liveStreamId);
        return get(state, 'data.pollState', {});
    }

    onExecution(executionId) {
        this.onResultsHidden();

        if (!executionId || this.trackedExecutions.includes(executionId)) {
            return;
        }

        this.trackedExecutions.push(executionId);

        const stats = {
            name: 'view',
            document_type: 'poll-execution',
            document_id: executionId,
            jitter: this.jitter
        };

        setTimeout(() => this.metricsService.trackAnalytics(stats), this.jitter);
    }

    onResultsShown(executionId) {
        if (!executionId || this.trackedResultsShown.includes(executionId)) {
            return;
        }

        this.trackedResultsShown.push(executionId);

        const stats = {
            name: 'view',
            document_type: 'poll-execution-result',
            document_id: executionId,
            jitter: this.jitter
        };

        setTimeout(() => this.metricsService.trackAnalytics(stats), this.jitter);
    }

    onResultsHidden() {
        // polls results can be shown and hidden multiple times, so we need to avoid resending the tracking event only on the same show event
        // on the next show event, we will send the tracking event again
        this.trackedResultsShown = [];
    }
}

angular
    .module('maestro.services')
    .service('pollService', PollService);
