import i18nWithFallback from '../../../utils/i18n-with-fallback';
import { isUnsupported } from '../../../utils/browser';

export const UpdateBrowserComponent = {
    template: require('./update-browser.jade'),
    controller: class UpdateBrowserComponent {
        /* @ngInject */
        constructor($i18n, $element) {
            this.$element = $element;
            this.$i18n = $i18n;

            this.labels = {
                bannerText: i18nWithFallback(
                    $i18n,
                    'update-browser.banner',
                    'You need to switch to one of recommended browsers to use all the functionalities in the app.'
                ),
            };

            this.showModal = true;
        }

        $onInit() {
            this.isUnsupported = isUnsupported();

            if (!this.isUnsupported) {
                this.clearElement();
            }
        }

        $onDestroy() {
            this.$element.remove();
        }

        clearElement() {
            this.$element.remove();
        }

        dismiss() {
            this.showModal = false;
            angular.element('body').addClass('update-browser-banner');
        }

        bannerClick() {
            this.showModal = true;
        }
    }
};
