import { get, isEmpty } from 'lodash';

/**
 * Checks if the given menu item has a valid badge data source.
 *
 * @param {Object} item a menu item to check DS validity for.
 *
 * @return {Boolean} true if the item has a valid DS
 */
function hasValidBadgeDs(item) {
    const ds = get(item, 'badge.ds', {});
    const { type, source: path } = ds;
    const validDs = type === 'javascript' && !isEmpty(path);

    return validDs;
}

module.exports.getItemBadge = function(item, databaseService, callback) {
    if (!hasValidBadgeDs(item)) return;

    const ds = get(item, 'badge.ds').source;

    return databaseService
        .runAppScript(ds.path, ds.params)
        .then(({ data: { response } }) => callback(item, response.badge));
};
