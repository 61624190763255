import { cloneDeep, last, isArray } from 'lodash';

const PaginatorFactory = function() {
    const getNextStartKeyOfList = ({ _id, key, bookmark }) => ({ id: _id, key, bookmark });
    const paginator = function(config = {}) {

        let nextStartKey = config.startKey || null;
        let currentState;

        const buildQueryParamsForList = (nextPage) => {
            if (!nextPage) {
                return { limit: pageSize };
            }

            if (nextStartKey.bookmark) {
                return {
                    bookmark: nextStartKey.bookmark
                };
            }

            return {
                startkey: nextStartKey.key,
                startkey_docid: nextStartKey.id,
                limit: pageSize
            };
        };

        let {
            pageSize = 10,
            getNextStartKey = getNextStartKeyOfList,
            buildQueryParams = buildQueryParamsForList
        } = config;

        return {
            getCurrentState() {
                return currentState || { limit: pageSize };
            },

            reset() {
                nextStartKey = null;
            },

            setPageSize(_size) {
                pageSize = _size;
            },

            hasMorePages() {
                return nextStartKey !== Infinity && nextStartKey !== undefined;
            },

            queryParams() {
                return buildQueryParams(nextStartKey);
            },

            processNewPage(respElements = [], resp) {
                // bookmark based pagination: we just have to keep the bookmark
                if (resp && resp.bookmark) {
                    currentState = cloneDeep(nextStartKey);
                    nextStartKey = getNextStartKey({
                        bookmark: resp.bookmark
                    });
                    return;
                }

                let elements;
                if (respElements.hasOwnProperty('list')) {
                    elements = respElements.list;
                } else {
                    elements = respElements;
                }

                if (!isArray(elements) || !elements.length) {
                    nextStartKey = undefined;
                    return [];
                }

                // checks if the first element in the view is the last element from
                // the previous page, if that is the case then it's removed
                // to prevent 'duplicate keys' error in ng-repeat
                const firstElement = elements[0];
                if (firstElement && nextStartKey && firstElement._id === nextStartKey.id) {
                    elements = elements.slice(1);
                }

                // TODO this will be a problem in filtered views
                // we've reached the end, render everything
                // if (finite && elements.length < pageSize - 2) {
                //     nextStartKey = Infinity;
                //     return elements;
                // }

                const lastElement = last(elements);
                if (lastElement) {
                    if (nextStartKey) {
                        currentState = cloneDeep(nextStartKey);
                        currentState.limit = pageSize;
                    }

                    nextStartKey = getNextStartKey(lastElement);
                }

                console.debug('[PaginatorFactory] Current and next page:', currentState, nextStartKey);

                return respElements;
            }
        };
    };

    this.$get = paginator;
    return paginator;
};

angular
    .module('maestro.services')
    .factory('paginatorFactory', [ PaginatorFactory ]);
