
/**
 * returns icon url
 * @param {Object} event event
 * @returns {String}
 */
module.exports.getEventIconUrl = function(event) {
    const eid = event._id;
    const iconUrl = `/api/v1/eid/${eid}`;
    return event.is_webinar ? `${iconUrl}/webapp/webinar-icon` : `${iconUrl}/event-icon`;
};

/**
 * Given a relative static asset path, this method returns
 * the proper fully qualified path of the given asset.
 *
 * @param assetPath the path of the asset
 * @returns {string} the full qualified asset path
 */
module.exports.getStaticAsset = function(assetPath) {
    let basePath = window.WEBAPP.assetsPath;
    if (!basePath.endsWith('/') && !assetPath.startsWith('/')) {
        basePath += '/';
    }
    if (basePath.endsWith('/') && assetPath.startsWith('/')) {
        basePath = basePath.slice(0, -1);
    }
    return `${basePath}${assetPath}`;
};

/**
 * Check if "src" loads
 * @type {(src: string) => Promise<boolean>}
 */
module.exports.checkIfImageLoads = async function(src) {
    return new Promise(resolve => {
        const image = new Image();
        image.addEventListener('load', () => resolve(true));
        image.addEventListener('error', () => resolve(false));
        image.src = src;
    });
};
