import i18nWithFallback from '../../../utils/i18n-with-fallback';

export const CookiesNotificationComponent = {
    template: require('./cookies-notification.jade'),
    controller: class CookiesNotificationComponent {
        /* @ngInject */
        constructor($i18n, $cookies, $element, $timeout, metricsService, EVENT) {
            this.$cookies = $cookies;
            this.$element = $element;
            this.$timeout = $timeout;
            this.tracker = metricsService;
            this.event = EVENT;
            this.key = 'cookies-policy';

            this.cookiePolicyUrl = i18nWithFallback($i18n, 'general.cookies-disclaimer-url', "https://spotme.com/specifications#cookies");

            this.cookieLinkLabel = i18nWithFallback($i18n, 'general.cookies-disclaimer-link', 'cookie policy');

            let introLabel = i18nWithFallback($i18n, 'general.cookies-disclaimer', 'You can share anonymized usage statistics with us to improve your experience. Read our {0}');

            introLabel = introLabel.replace(
                '{0}',
                `<a href="${this.cookiePolicyUrl}"  target="_blank" rel="noopener noreferrer">${this.cookieLinkLabel}</a>`
            );

            this.introLabel = introLabel;

            this.agreeLabel = i18nWithFallback($i18n, 'general.agree', 'Yes, please!');
            this.rejectLabel = i18nWithFallback($i18n, 'general.disagree', 'No, thanks!');
        }

        $onInit() {
            const accepted = this.$cookies.get(this.key);
            if (this.event.show_cookie_banner && !accepted) {
                this.$timeout(() => {
                    this.$element.addClass('in');
                }, 1000);
            } else {
                this.tracker.init();
                this.clearElement();
            }
        }

        $onDestroy() {
            this.$element.remove();
        }

        accept() {
            this.$cookies.put(this.key, 1);
            this.tracker.init();
            this.clearElement();
        }

        reject() {
            this.$cookies.put('cookies-policy', 0);
            this.tracker.stopTracking();
            this.clearElement();
        }

        clearElement() {
            this.$element.removeClass('in');
            this.$timeout(() => {
                this.$element.remove();
            }, 1000);
        }
    }
};
