import { each } from 'lodash';

/* @ngInject */
function VendorFixes($window) {
    const forceRepaint = elem => {
        elem.style.display = 'none';
        elem.offsetHeight; // no need to store this anywhere, the reference is enough
        elem.style.display = '';
    };

    const linker = ($scope, elements) => {
        const body = angular.element($window.document.body);

        // Due to a browser glitch, fstg pages height on mobile safari, are not
        // properly calculated/considered. Only after a repaint the page
        // become scrollable. This is a safari issue we don't have control on
        // thus we simply force a repaint on the page.
        if (body.is('.mobile-safari')) {
            each(elements, elem => {
                $scope.$on('fstg:page:loaded', function() {
                    setTimeout(() => {
                        console.debug('[VendorFixes] Forcing repaing on', elem);
                        forceRepaint(elem);
                    }, 3000);
                });
            });
        }
    };

    return {
        restrict: 'C',
        link: linker
    };
}

// We limit our fixes to FSTG pages.
angular.module('maestro.directives').directive('navFstg', VendorFixes);
