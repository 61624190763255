export default class NavFormService {
    /* @ngInject */
    constructor($eventBus) {
        this.$$forms = {};
        this.$eventBus = $eventBus;
    }

    get(navId) {
        const store = this.$$forms[navId];
        if (!store) {
            return (this.$$forms[navId] = {});
        }
        return store;
    }

    set(navId, key, value) {
        if (!navId || !key) return;
        this.get(navId)[key] = value;
        this.$eventBus.emit('sheet:form-data-changed', true);
    }

    getRequiredFields(navId) {
        const store = this.$$forms[`${navId}-required-fields`];
        if (!store) {
            return (this.$$forms[`${navId}-required-fields`] = {});
        }
        return store;
    }

    setRequiredField(navId, key, value) {
        if (!navId || !key) return;
        this.get(`${navId}-required-fields`)[key] = value;
    }

    getPreSelectedFile() {
        const store = this.$$forms['selected-file'];
        if (!store) {
            return (this.$$forms['selected-file'] = {});
        }
        return store;
    }

    setPreSelectedFile(key, value) {
        if (!key) return;
        this.get('selected-file')[key] = value;
    }

    destroy(navId) {
        delete this.$$forms[navId];
    }
}

angular.module('maestro.services').service('navFormService', NavFormService);
