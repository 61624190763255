export const TestWatermarkComponent = {
    template: require('./test-watermark.pug'),
    controller: class TestWatermarkComponent {
        /* @ngInject */
        constructor(EVENT, ACTIVATED_PERSON) {
            this.isTestEvent = Boolean(EVENT.test_event);
            this.userEmail = ACTIVATED_PERSON.email || '';
        }

        $onInit() {
            const isSpotme = this.userEmail.endsWith('@spotme.com');

            this.showWatermark = this.isTestEvent && !isSpotme;
        }
    }
};
