import { extend, map } from 'lodash';

export default class NavUi {
    /* @ngInject */
    constructor($sheet, metaService) {
        this.$sheet = $sheet;
        this.metaService = metaService;
    }

    alert(config) {
        const $sheet = this.$sheet;
        const runAction = ({ service, method, params }) =>
            this.metaService.callServiceMethod(service, method, [ params ]);

        let actions = config.buttons || [];

        if (!actions.length) {
            actions.push({
                label: 'general.dismiss',
                action: scope => scope.accept()
            });
        } else {
            actions = map(config.buttons, (button, i) => ({
                id: i,
                label: button.title,
                action: scope => {
                    scope.accept();

                    if (button.target) {
                        runAction(button.target);
                    }
                }
            }));
        }

        $sheet(
            extend({}, config, {
                prompt: true,
                alert: true,
                actions
            })
        );
    }
}
