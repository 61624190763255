import localCache from '../utils/localCache';

class LocalCacheFactory {
    /* @ngInject */ constructor(EID, PID) {
        return id => localCache(`${EID}:${PID}/${id}`);
    }
}

angular
    .module('maestro.services')
    .service('localCacheFactory', LocalCacheFactory);
