// Utils
import i18nWithFallback from '../../../utils/i18n-with-fallback';
import { each, get, isFunction, pluck } from 'lodash';
import { MODERATOR_ACTIONS } from '../../../services/videoCallModeratorService';
import { addEventListener } from '../../../utils/dom';

/**
 * This component is used to show a list of all participants in the call
 */
export const ParticipantsListComponent = {
    bindings: {
        participants: '<',
        pinnedParticipants: '<',
        conversationId: '<',
        showList: '=',
        showModeratorControls: '='
    },
    template: require('./participants-list.pug'),
    controller: class ParticipantsListComponent {
        /* @ngInject */
        constructor($http, EVENT, $element, $timeout, navService, databaseService, $i18n, $scope, ACTIVATED_PERSON, uiService, videoCallModeratorService, THEME) {
            this.eid = EVENT._id;
            this.$element = $element;
            this.$timeout = $timeout;
            this.$http = $http;
            this.$i18n = $i18n;
            this.$scope = $scope;
            $scope.eventTheme = THEME;
            this.navService = navService;
            this.databaseService = databaseService;
            this.ACTIVATED_PERSON = ACTIVATED_PERSON;
            this.uiService = uiService;
            this.videoCallModeratorService = videoCallModeratorService;
            this.selectedPax = null;
            this.listeners = [];

            this.labels = {
                chat_opened: i18nWithFallback($i18n, 'video_calls.labels.already_on_chat', 'Chat already opened'),
                mute_all: i18nWithFallback($i18n, 'video_calls.actions.mute_all', 'Mute all'),
                ask_all_unmute: i18nWithFallback($i18n, 'video_calls.actions.ask_all_unmute', 'Ask all to unmute'),
                ask_unmute: i18nWithFallback($i18n, 'video_calls.actions.ask_unmute', 'Ask to unmute'),
                mute: i18nWithFallback($i18n, 'video_calls.actions.mute', 'Mute'),
                more_actions: i18nWithFallback($i18n, 'video_calls.actions.more_actions', 'More Actions'),
                kickout: i18nWithFallback($i18n, 'video_calls.actions.kickout', 'Kickout participant'),
                send_message: i18nWithFallback($i18n, 'video_calls.actions.send_message', 'Chat'),
                add_to_contacts: i18nWithFallback($i18n, 'video_calls.actions.add_to_contacts', 'Add contact'),
                pin: i18nWithFallback($i18n, 'video_calls.actions.pin', 'Pin participant'),
                unpin: i18nWithFallback($i18n, 'video_calls.actions.unpin', 'Unpin participant'),
                all_participants: i18nWithFallback($i18n, 'video_calls.message.all_participants', 'All participants'),
                unmute_request_sent: i18nWithFallback($i18n, 'video_calls.message.unmute_request_sent', 'Unmute request sent')
            };
        }

        $onInit() {
            this.contactsActions = get(this.navService, 'EVENT_NAVS.nav_business_cards.video_calls_actions');
            if (this.contactsActions) this.getBcxStatus(this.contactsActions.request_states_action.target);

            this.chatActions = get(this.navService, 'EVENT_NAVS.nav_conversation.video_calls_actions');
            this.enableMessages = !!this.chatActions;

            const isInChat = get(this.navService, 'activeNav.nav.nav_type', '') === 'conversation';
            if (isInChat) {
                // this.participants contains the list of participants in the call, not in the chat
                const participantsIds = Object.keys(get(this.navService, 'activeNav.nav.participants', {}));
                const is1to1 = participantsIds.length === 2;
                if (is1to1) {
                    this.disableOpenChatForPaxIds = participantsIds.filter((pax) => { return pax.id !== this.ACTIVATED_PERSON._id; });
                }
            }

            const hidePaxMenu = () => {
                if (this.selectedPax) {
                    this.$timeout(() => {
                        this.selectedPax = null;
                    });
                }
            };
            this.listeners.push(
                addEventListener(document, 'mouseup', hidePaxMenu)
            );
        }

        $onDestroy() {
            for (let listener of this.listeners) {
                if (isFunction(listener)) {
                    listener();
                }
            }
            this.listeners = [];
        }

        shouldDisableOpenPaxChat(pid) {
            if (!pid || !this.disableOpenChatForPaxIds) {
                return false;
            }
            return this.disableOpenChatForPaxIds.includes(pid);
        }

        getBcxStatus(targetAppscript) {
            this.databaseService.runAppScript(targetAppscript, {
                peerIds: pluck(this.participants, 'id')
            }).then(({ data: { response } }) => {
                each(this.participants, participant => {
                    if ([ 'pending', 'reviewing', 'ignored' ].includes(response.states[participant.id])) {
                        participant.bcxStatus = 'pending';
                    } else {
                        participant.bcxStatus = response.states[participant.id];
                    }
                });
            });
        }

        /**
         * Opens the specified profile
         *
         * @param {string} pid the ID of the profile to open
         */
        openProfile(pid) {
            this.navService.openDocument({
                _id: pid
            }, {
                navParams: {
                    modal: true,
                    fullscreen: true
                }
            });
        }

        /**
         * Opens chat with a participant
         *
         * @param {string} pid the ID of the profile to open
         */
        openChat(pid) {
            this.databaseService.runAppScript(this.chatActions.open_conversation_action.target, {
                participant: pid,
            }).then(({ data: { response } }) => {
                if (response.id) {
                    if (response.id === this.conversationId) return;
                    this.navService.openDocument({ _id: response.id });
                } else {
                    this.databaseService.docWithId(pid)
                        .then(({ fp_ext_id }) => {
                            return this.databaseService.runAppScript(this.chatActions.new_message_action.target, {
                                recipients: [ fp_ext_id ]
                            });
                        });
                }
            });
        }

        /**
         * Sends a connect request to a participant
         *
         * @param {string} pid the ID of the profile to open
         */
        addContact(pid) {
            for (const participant of this.participants) {
                if (pid === participant.id) {
                    if (participant.bcxStatus === 'pending') return;
                    participant.bcxStatus = 'pending';
                }
            }

            this.databaseService.runAppScript(this.contactsActions.send_request_action.target, { peerId: pid });
        }

        closeList() {
            this.showList = false;
        }

        moderatorAction(action, participant = {}) {
            const connection = get(participant, 'subscriber.otSubscriberObject.stream.connection');

            if (action === 'KICKOUT' && connection) {
                return this.$scope.$emit('moderator:showKickoutModal', { connection });
            }

            this.videoCallModeratorService.sendAction(MODERATOR_ACTIONS[action], { connection });

            if (action === 'UNMUTE') {
                const message = this.uiService.buildNotificationMessage({
                    title: participant.name || this.labels.all_participants,
                    message: {
                        body: this.labels.unmute_request_sent,
                    },
                    duration: 5,
                    backgroundColor: get(this.$scope, 'eventTheme.toolbar.background_color'),
                    contentColor: get(this.$scope, 'eventTheme.toolbar.font_color'),
                    iconColor: get(this.$scope, 'eventTheme.toolbar.font_color')
                });
                this.uiService.inAppNotification(message);
            }
        }

        pinParticipant(pid) {
            this.$scope.$emit('moderator:pin', pid);
        }

        selectPax($event, pid) {
            if (this.selectedPax) {
                return this.selectedPax = null;
            }

            const buttonElementPos = $event.target.getBoundingClientRect();
            const wrapperElementPos = this.$element[0].getBoundingClientRect();
            const menuElement = this.$element.find('.action-list').get(0);

            menuElement.style.top = buttonElementPos.bottom - wrapperElementPos.top + 4 + 'px';
            menuElement.style.right = wrapperElementPos.right - buttonElementPos.right - 4 + 'px';

            this.selectedPax = pid;
        }

        hasAudio(participant) {
            return get(participant, 'subscriber.otSubscriberObject.stream.hasAudio', false);
        }

        isPinned(participant) {
            return this.pinnedParticipants.includes(participant.id);
        }

        getOpenProfileTooltip(participant) {
            return this.$i18n('video_calls.labels.open_profile').replace('{{fname}}', participant.fname);
        }
    }
};
