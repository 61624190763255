import { compact, get, map } from 'lodash';

import { CommonFeedBlock } from '../common-feed-block';

export const FeedCommentBlockComponent = {
    template: require('./feed-comment-block.jade'),
    bindings: {
        config: '<',
        onUpdate: '&'
    },
    controller: class FeedCommentBlockComponent extends CommonFeedBlock {
        /* @ngInject */ constructor(
            NAV_TEMPLATE_API_PREFIX,
            $timeout,
            databaseService,
            navService,
            $filter,
            $sheet,
            $i18n,
            EventEmitter,
            THEME
        ) {
            super(NAV_TEMPLATE_API_PREFIX, $filter, $sheet, $i18n, THEME);

            this.$timeout = $timeout;
            this.databaseService = databaseService;
            this.navService = navService;
            this.runScript = navService.runScript.bind(navService);
            this.EventEmitter = EventEmitter;
        }

        $onInit() {
            super.$onInit();

            const menu = get(this, 'config.data.actions.contextual_menu');
            const content = get(this, 'config.data.content');

            if (menu) {
                // Old pkg-feed do not provide contextual menu
                this.showContextualMenu = this.navService.showNavToolbarMenu(
                    map(menu.actions, action => {
                        action.action = () => {
                            this.runScript(action);
                        };
                        return action;
                    })
                );
            }

            if (!content) {
                return;
            }

            this.authorAction = () => this.runScript(this.actions.profile);

            if (content.feed) {
                this.titleAction = this.authorAction;
                this.authorAction = null;

                this.feedAction = () => this.runScript(this.actions.feed);
                this.feedMeta = content.feed;
            }
        }

        $onChanges(changes) {
            super.$onChanges(changes);

            if (!changes.config) return;

            const data = this.content;
            this.author = {
                photo: data.avatar_url,
                title: data.title,
                subtitle: compact([ data.company, data.position ]).join(' • ')
            };

            // In case of contextual menu presence we use the updated UI
            // where the author title is no more.
            if (get(this, 'config.data.actions.contextual_menu')) {
                this.author.subtitle =
                    get(this, 'config.data.content.time') ||
                    this.author.subtitle;
            }
        }

        /**
         * Trigger action of a button
         * @param {Object} button
         */
        triggerAction(button) {
            if (!button.disabled && button.action.target) {
                this.runScript(button.action).then(({ data }) => {
                    if (data.status !== 'ok') return;
                    const modPaths = this.config.data.actions.moderator_actions.map(a => a.action.path);
                    if (modPaths.some(path => path === button.action.path)) {
                        this.config.data.actions.moderator_actions = data.response;
                    }
                });
            }
        }
    }
};
