/**
 * This method checks which is the first scollable parent
 *
 * @param {HTMLElement} node the node to find the scrollable parent for
 *
 * @returns {HTMLElement} the first scrollable parent
 */
function getScrollParent(node) {
    if (node === null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode);
    }
}

angular.module('maestro.directives').directive('scrollHere', [
    '$timeout',
    $timeout => ({
        restrict: 'A',
        link: function($scope, $element, attrs) {
            if (attrs.scrollHere === 'true') {
                $timeout(() => {
                    const el = $element[0];
                    const scrollable = getScrollParent(el);

                    el.scrollIntoView(true);
                    if (scrollable) {
                        scrollable.scrollTop -= 60;
                    }
                });
            }
        }
    })
]);
