import { v4 as uuid } from 'uuid';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

const _ = require('lodash');
const moment = require('moment-timezone');

const READ_MESSAGE_IDS = {};

export const ConversationNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./conversation-nav.jade'),
    controller: class ConversationNavComponent {
        /* @ngInject */
        constructor(
            NAV_TEMPLATES,
            PID,
            THEME,
            $timeout,
            navService,
            databaseService,
            videoCallService,
            progressBarService,
            signalService,
            $http,
            $scope,
            $i18n,
            $element,
            $filter,
            $eventBus,
            dataSourceEvaluatorService
        ) {
            this.navTemplates = NAV_TEMPLATES;
            this.pid = PID;
            this.theme = THEME;
            this.$timeout = $timeout;
            this.navService = navService;
            this.databaseService = databaseService;
            this.videoCallService = videoCallService;
            this.progressBarService = progressBarService;
            this.signalService = signalService;
            this.$http = $http;

            this.loading = true;

            this.messageIds = {};

            this.updateInProgress = false;
            this.lastKey = '';
            this.lastDocId = '';

            this.sending = false;
            // used by send button animation
            this.sendJustFinished = false;
            this.$scope = $scope;
            this.$i18n = $i18n;
            this.linky = $filter('linky');
            this.$eventBus = $eventBus;
            this.textarea = $element.find('textarea.form-control');
            this.container = $element.find('.nav-content');
            this.glued = false;
            this.ds = dataSourceEvaluatorService;
            this.inCall = false;

            this.joinCallLabel = i18nWithFallback($i18n, 'video_calls.actions.join', 'Join call');
        }

        $onInit() {
            const config = this.config;

            if (!config.participants) {
                return;
            }

            console.debug('[ConversationNavComponent] init', config);

            config.ds.elements = [];
            this.isGroupChat = _.size(config.participants) > 2;

            if (!this.config.chat_name) {
                let p = _.clone(config.participants);
                delete p[this.pid];

                const paxes = Object.keys(p).map(k => {
                    return p[k];
                });

                this.config.chat_name = paxes.join(', ');
            }

            this.showDetails =
                config.actions &&
                config.actions.participant_interactions &&
                config.actions.rename_chatroom;

            this.removeUpdateListener = this.$eventBus.on('messages:update', () => this.updateNav());

            this.signalService.addSignalListener(`pushNotification/${this.pid}`, ({ notificationPayload }) => {
                // check if the notification is for a new message and for the same conversation
                const conversation_id = _.get(notificationPayload, 'action.params.conversation_id');
                if (conversation_id === this.config.conversation_id) {
                    this.evalDs().then(() => this.updateNav());
                }
            }).then(remover => this.removePushNotificationListener = remover);

            // hide notification for new message in current conversation
            this.$eventBus.emit('notifications:hide', `chat-message-${this.config.conversation_id}`);

            this.unsubFromNotifications = this.navService.subscribeToEvents(
                this.navService.flattenNotificationMap(config.ds.reload_on),
                (event, data) => {
                    switch (event.name) {
                        case 'activatedperson_updated':
                            this.$scope.$broadcast(
                                'conversation:load_details',
                                data
                            );
                            break;
                    }
                }
            );

            this.showVideoCall = !this.videoCallService.isDifferentLocation();
            this.$eventBus.on('video-pip:close', () => {
                this.showVideoCall = !this.videoCallService.isDifferentLocation();
            });
            this.$eventBus.on('video-pip:started', () => {
                this.showVideoCall = !this.videoCallService.isDifferentLocation();
            });

            if (this.config.enable_video_calls) {
                this.disableJoinButton = true;
                this.showVideoButtons = true;

                this.$scope.$watch('$ctrl.conversation', (newValue) => {
                    if (newValue) {
                        this.disableJoinButton = this.inCall || this.callEnded;
                        this.showVideoButtons = !!newValue.is_live;
                    }
                });
            }

            if (this.config.details && this.config.details.ds) {
                this.evalDs().then(() => this.updateNav());
            } else {
                this.updateNav();
            }
        }

        evalDs() {
            return this.ds
                .eval(this.config.details.ds, { conversation_id: this.config.conversation_id })
                .then(conversation => {
                    console.info('[ConversationNavComponent] Details loaded:', conversation);
                    this.conversation = conversation;
                    this.showVideoButtons = !!conversation.is_live;
                    return conversation;
                })
                .then(conversation => {
                    // check if the user is kicked out from the conversation
                    if (!_.find(this.conversation.participants, p => p.id === this.pid)) {
                        this.config.ds.elements = [];
                        this.blockedConversation = true;
                        return this.$scope.$applyAsync();
                    }

                    return this.config.enable_video_calls && !conversation.session_id ?
                        this.videoCallService.createSession() : null;
                })
                .then(sessionId => {
                    if (!sessionId) return;
                    this.conversation.session_id = sessionId;
                    return this.videoCallService.addSessionIdToConversation(this.conversation.id, sessionId);
                })
                .catch(err => console.error('[ConversationNavComponent] Error:', err));
        }

        $onDestroy() {
            if (_.isFunction(this.unsubFromNotifications)) {
                this.unsubFromNotifications();
            }

            if (_.isFunction(this.removePushNotificationListener)) {
                this.removePushNotificationListener();
            }

            if (_.isFunction(this.removeUpdateListener)) {
                this.removeUpdateListener();
            }

            if (_.isFunction(this.callStopUnregister)) {
                this.callStopUnregister();
            }

            if (_.isFunction(this.callUpdateUnregister)) {
                this.callUpdateUnregister();
            }

            this.$eventBus.emit('notifications:show', `chat-message-${this.config.conversation_id}`);
        }

        filterAndAppend(messages) {
            messages = _.filter(messages, ({ _id }) =>
                this.messageIds[_id] ? false : (this.messageIds[_id] = true)
            );

            messages = this.remapMessages(messages);

            Array.prototype.push.apply(this.config.ds.elements, messages);
            this.regroupMessages();
        }

        remapMessages(messages) {
            let rows = [];
            const onlyVideoMessages = _.every(messages, message => {
                const event_type = _.get(message, 'render.params.mapping.event_type') || '';
                return event_type.includes('call');
            });

            for (const m of messages) {
                try {
                    const mapping = m.render.params.mapping;

                    let row = {
                        _id: m._id,
                        body: this.linky(mapping.body, '_blank'),
                        category: mapping.category,
                        cssClasses: [],
                        // ...yes... it's a string -.-"
                        isOwn: mapping.is_own === 'true' || mapping.origin === this.pid,
                        pid: mapping.pid,
                        showName: true,
                        title: mapping.title,
                        ts: mapping.ts,
                        timestamp: mapping.timestamp,
                        row_type: m.render.row_type
                    };

                    // Only version >= 3.0.0 have the category field
                    if (row.category) {
                        if (row.category === 'chat_notifications') {
                            row.body = mapping.system_notification;
                            const eventType = mapping.event_type;
                            row.cssClasses = row.cssClasses.concat([ 'event', eventType ]);
                            row.type = eventType || row.type;

                            if (!(eventType || '').startsWith('call')) {
                                row.cssClasses.push('notification');
                                row.themeSelector = 'toolbar';
                                delete row.ts;
                            } else {
                                if (row.isOwn) {
                                    row.body = row.body.replace(this.config.participants[this.pid].trim(), this.$i18n('conversation_nav.details.you'));
                                }

                                row.category = 'mail';

                                // if there are only video-call related messages, then they are new
                                // for the conversation, and if thre's a "callstart" in them, this means
                                // someone started a call, so we refresh the conversation
                                if (onlyVideoMessages && eventType === 'callstart') {
                                    this.evalDs();
                                    this.callEnded = false;
                                }

                                // if there are only video-call related messages, then they are new
                                // for the conversation, and if thre's a "callstop" in them, this means
                                // the call has been ended
                                if (onlyVideoMessages && eventType === 'callstop') {
                                    this.disableJoinButton = true;
                                    this.callEnded = true;
                                }

                                if (eventType === 'callpart' && row.isOwn) {
                                    row.rejoin = true;
                                }
                            }
                        }

                        if (row.category === 'chat_dates') {
                            row.body = mapping.date_notification;
                            row.cssClasses = row.cssClasses.concat([
                                'notification',
                                'date'
                            ]);
                            delete row.ts;
                        }

                        // trigger check if the act. user is kicked out from the conversation
                        if (row.type === 'quit' && mapping.origin === this.pid) {
                            this.evalDs();
                        }

                        if (row.isOwn && row.category === 'mail') {
                            row.cssClasses.push('own');
                        } else if (row.category === 'mail') {
                            row.cssClasses.push('their');
                        }

                        row.showName = !row.isOwn && row.category === 'mail';
                    } else {
                        if (row.isOwn) {
                            row.cssClasses.push('own');
                            row.showName = false;
                        }
                        row.ts = row.timestamp;
                    }

                    rows.push(row);
                } catch (error) {
                    console.error('[ConversationNavComponent] Error parsing message', m);
                }
            }

            return this.config.nav_version >= 3 ? rows.reverse() : rows;
        }

        regroupMessages() {
            const latestCallRow = _.reduce(this.config.ds.elements, (memo, row) => {
                if ((row.type === 'callstart' || (row.type === 'callpart' && row.isOwn)) &&
                    row.timestamp > memo.timestamp) {
                    memo = row;
                }
                return memo;
            });

            /* jshint plusplus:false */
            let n = this.config.ds.elements.length;
            while (n--) {
                const row = this.config.ds.elements[n];
                const prev = this.config.ds.elements[n - 1];
                if (!prev) continue;

                row.showName = !row.isOwn && row.category === 'mail';

                const sameCat = row.category === prev.category;

                if (row.title === prev.title && sameCat) {
                    prev.cssClasses.push('group-with-next');
                    row.cssClasses.push('group-with-prev');
                }
                if ([ 'callstart', 'callpart' ].includes(row.type)) {
                    row.latest = row._id === latestCallRow._id;
                }
            }

            // video call join button may be missing if the call hasn't been ended properly
            if (this.conversation.is_live && !_.find(this.config.ds.elements, el => el.latest)) {
                this.config.ds.elements.push({
                    body: this.$i18n('conversation_nav.notifications.video_call_title'),
                    category: 'mail',
                    cssClasses: [ 'event', 'callstart', 'own' ],
                    isOwn: true,
                    row_type: 'conversation_row',
                    type: 'callstart',
                    latest: true
                });
            }
        }

        updateNav(refocus = false) {
            if (this.updateInProgress || this.blockedConversation) return;

            if (this.updateTimeout) this.$timeout.cancel(this.updateTimeout);
            if (!this.config.conversation_id) return;

            this.updateInProgress = true;
            this.glued = true;
            this.progressBarService.startTask();

            return this.navService
                .inflateNav(this.config, {
                    skip: this.lastKey ? 1 : 0,
                    updates_only: this.lastKey ? true : false,
                    startkey: this.lastKey,
                    startkey_docid: this.lastDocId,
                    conversation_id: this.config.conversation_id,
                    version: 4,
                    cache: false
                })
                .then(({ elements }) => {
                    if (!elements || !elements.length) {
                        return;
                    }

                    this.lastKey = elements[0].key;
                    this.lastDocId = elements[0]._id;

                    elements.reverse();

                    this.filterAndAppend(elements);
                })
                .finally(() => {
                    this.markLastMessageAsRead();

                    if (refocus) {
                        this.textarea.focus();
                    }

                    this.$timeout(() => {
                        const container = this.container[0];

                        if (container) {
                            container.scrollTo(0, container.getBoundingClientRect().height);
                        }

                        this.updateInProgress = false;
                        this.progressBarService.finishTask();

                    }, 250);
                });
        }

        sendMessage($event) {
            if ($event) $event.stopPropagation();

            if (this.sending) return;
            if (!this.messageToSend || this.messageToSend.length < 1) return;
            this.sending = true;

            this.databaseService
                .runAppScript(this.config.actions.send_button.path, {
                    message: {
                        _id: uuid(),
                        body: this.messageToSend,
                        conversation_id: this.config.conversation_id || uuid(),
                        participants: this.config.participants
                    }
                })
                .then(({ data: { response } }) => {
                    // console.log('Service response: ', response);
                    if (response.conversation_id)
                        this.config.conversation_id = response.conversation_id;
                })
                .finally(() => {
                    this.sending = false;
                    this.sendJustFinished = true;
                    this.messageToSend = '';
                })
                .finally(() => {
                    if (this.config.nav_version < 4) {
                        this.navService.emit('messages:update');
                    }
                    this.updateNav(true);
                });
        }

        openUserProfile(pid) {
            if (this.config.participants) {
                const lastNav = this.navService.activeNav.nav;
                this.navService.openDocument(
                    { id: pid },
                    { _parent_nav_id: lastNav.nuid }
                );
            }
        }

        markLastMessageAsRead() {
            if (READ_MESSAGE_IDS[this.lastDocId]) return;

            READ_MESSAGE_IDS[this.lastDocId] = true;

            // TODO check if the last message was written by the owner,
            // in which case we don't have to set the status again

            return this.databaseService
                .saveDoc({
                    fp_type: 'mail_status',
                    fp_owner: this.pid,
                    status: 'read',
                    conversation_id: this.config.conversation_id,
                    parent_mail: this.lastDocId,
                    timestamp: moment().unix()
                })
                .finally(() => this.navService.emit('messages:update'));
        }

        openDetails() {
            this.config.detailsIn = true;
            this.$scope.$broadcast('conversation:load_details');
        }

        startVideoCall() {
            this.callStopUnregister = this.$eventBus.on(`chat:stopcall:${this.conversation.id}`, () => {
                this.showVideoCall = false;
                this.videoCallService.stop();
                this.inCall = false;
                this.disableJoinButton = true;
                this.callStopUnregister();
                this.callUpdateUnregister();
                this.evalDs().then(() => this.updateNav());
            });

            this.callUpdateUnregister = this.$eventBus.on(`chat:updatecall:${this.conversation.id}`, (event, inCall) => {
                this.navService.emit('messages:update');

                if (_.isBoolean(inCall)) {
                    this.inCall = inCall;
                    this.disableJoinButton = inCall || this.callEnded;
                }
            });

            this.videoCallService.start({
                docType: this.conversation.type,
                sessionId: this.conversation.session_id,
                conversationId: this.conversation.id,
                actions: this.config.actions
            });
            this.showVideoCall = true;
            this.callEnded = false;
        }
    }
};
