/**
 * Generates the values for the different 'bestFit' layout variants,
 * the format is [width, height, positions] where positions is the [top, left] coordinates of each stream
 * unit is %
 * @param {Number} width width of the videos
 * @param {Number} height height of the videos
 * @param {Object} videoMargins margins between the broadcast border and the first video
 * @param {Array} videosPerRow the number of video elements per row
 * @return {(*|*[])[]}
 */
function getPositions(width, height, videoMargins, videosPerRow) {
    if (!height) {
        height = width;
    }
    const pos = [];
    let row = 0;
    const maxPerRow = Math.max(...videosPerRow);
    const numberOfRows = videosPerRow.length;
    const screenMargins = {};
    screenMargins.x = (100 - videoMargins.x * (maxPerRow - 1) - maxPerRow * width) / 2;
    screenMargins.y = (100 - videoMargins.y * (numberOfRows - 1) - numberOfRows * height) / 2;
    screenMargins.x = screenMargins.x || 0;
    screenMargins.y = screenMargins.y || 0;
    if (screenMargins.x < 0 || screenMargins.y < 0) {
        throw new Error('Overconstrained error', screenMargins);
    }
    for (const numberThisRow of videosPerRow) {
        const offsetX = (maxPerRow - numberThisRow) * ((width + videoMargins.x) / 2);
        for (let col = 0; col < numberThisRow; col++) {
            const top = screenMargins.y + row * videoMargins.y + row * height;
            const left = offsetX + screenMargins.x + col * videoMargins.x + col * width;
            pos.push([ top, left ]);
        }
        row++;
    }
    return [ width, height, pos ];
}

/**
 * Generates the values for a screen share layout. This will positions videos from left to right, centered above a screenshare
 * the format is [width, height, positions] where positions is the [top, left] coordinates of each stream
 * unit is %
 * @param {Number} width width of the videos
 * @param {Number} height height of the videos
 * @param {Number} videoMarginX margins between the videos
 * @param {Object} screenMargins margins between edge of broadcast and first video
 * @param {Number} number the number of video elements
 * @return {(*|*[])[]}
 */
function getPositionsForScreenShare(width, height, videoMarginX, screenMargins, number) {
    if (!height) {
        height = width;
    }
    const pos = [];
    const offsetX = (100 - width * number - (number - 1) * videoMarginX - 2 * screenMargins.x) / 2;
    for (let col = 0; col < number; col++) {
        const top = screenMargins.y;
        const left = offsetX + screenMargins.x + col * videoMarginX + col * width;
        pos.push([ top, left ]);
    }
    return [ width, height, pos ];
}

function bestFitGenerator(values, n = 0) {
    if (n < 1) {
        n = 1;
    }
    n = Math.min(n, values.length);
    const [ width, height, positions ] = values[n - 1];
    return positions.map(([ top, left ], i) => `
  .stream_${i} {
      z-index: 1;
      width: ${width}%;
      height: ${height}%;
      top: ${top}%;
      left: ${left}%;
  }
  .stream_${i} video {
      object-fit: cover;
  }`).join('');
}

export {
    bestFitGenerator,
    getPositions,
    getPositionsForScreenShare
};
