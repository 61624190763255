// Utils
import { isFunction } from 'lodash';
import { MODERATOR_ACTIONS } from '../../../services/videoCallModeratorService';

/**
 * This component is used to handle video calls
 */
export class DeviceManager {
    /* @ngInject */
    constructor(mediaDevicesService, $eventBus, $scope) {
        this.mediaDevicesService = mediaDevicesService;
        this.$eventBus = $eventBus;

        this.streams = [];
        this.userPermissions = null;
        this.userPermissionAsked = false;
        this.$scope = $scope;

        this.videoOn = true;
        this.audioOn = true;

        this.settings = {};

        const hideInputDevices = () => {
            if (this.showAudioInputs || this.showVideoInputs) {
                this.hideInputDevicesTimeout = setTimeout(() => {
                    this.showAudioInputs = false;
                    this.showVideoInputs = false;
                    this.$scope.$applyAsync();
                });
            }
        };

        this.hideInputDevices = hideInputDevices.bind(this);

        this.$eventBus.on(`moderatorRequest:${MODERATOR_ACTIONS.FORCE_MUTE}`, () => { this.toggleAudio(false); });
        this.$eventBus.on(`moderatorRequest:${MODERATOR_ACTIONS.UNMUTE}`, () => {
            if (!this.audioOn) {
                this.showAskToUnmute = true;
                this.showList = false;
            }
        });
    }

    init() {
        this.subscribeToDeviceChange();
        document.addEventListener('mouseup', this.hideInputDevices);
    }

    subscribeToDeviceChange() {
        this.deviceChangeListener = this.mediaDevicesService.onDevicesChange(this.deviceUpdatedHandler.bind(this));
    }

    unsubscribeFromDeviceChange() {
        if (isFunction(this.deviceChangeListener)) {
            this.deviceChangeListener();
        }
    }

    toggleCamera() {
        const videoOn = !this.videoOn;
        if (videoOn && !this.mediaDevicesService.videoDeviceSelected) {
            this.mediaDevicesService.selectDefaultVideo();
        }
        this.setVideoOn(videoOn);
    }

    toggleAudio(audioOn = !this.audioOn) {
        if (audioOn && !this.mediaDevicesService.audioDeviceSelected) {
            this.mediaDevicesService.selectDefaultAudio();
        }
        this.setAudioOn(audioOn);
    }

    showInputDevices(type) {
        this.showAudioInputs = type === 'audio' && !this.showAudioInputs;
        this.showVideoInputs = type === 'video' && !this.showVideoInputs;
    }

    changeAudioSource(device) {
        this.showAudioInputs = false;
        this.mediaDevicesService.selectDevice('audio', device);
    }

    changeVideoSource(device) {
        this.showVideoInputs = false;
        this.mediaDevicesService.selectDevice('video', device);
    }

    $onDestroy() {
        this.unsubscribeFromDeviceChange();
        document.removeEventListener('mouseup', this.hideInputDevices);
        clearTimeout(this.hideInputDevicesTimeout);
    }
}
