import { last } from 'lodash';

const TEMPLATE_API_PREFIX = '/api/v1/nav-template';

const NAVS = {
    './navs/agenda.jade': require('./navs/agenda.jade'),
    './navs/appscript_launcher.jade': require('./navs/appscript_launcher.jade'),
    './navs/blocks.jade': require('./navs/blocks.jade'),
    './navs/blocks_list.jade': require('./navs/blocks_list.jade'),
    './navs/calendar_services_sheet.jade': require('./navs/calendar_services_sheet.jade'),
    './navs/conversation.jade': require('./navs/conversation.jade'),
    './navs/document.jade': require('./navs/document.jade'),
    './navs/document_note.jade': require('./navs/document_note.jade'),
    './navs/fstg.jade': require('./navs/fstg.jade'),
    './navs/home.jade': require('./navs/home.jade'),
    './navs/list.jade': require('./navs/list.jade'),
    './navs/media.jade': require('./navs/media.jade'),
    './navs/new_meeting.jade': require('./navs/new_meeting.jade'),
    './navs/new_message.pug': require('./navs/new_message.pug'),
    './navs/note.jade': require('./navs/note.jade'),
    './navs/qr_sheet.jade': require('./navs/qr_sheet.jade'),
    './navs/scan.jade': require('./navs/scan.jade'),
    './navs/sheet.jade': require('./navs/sheet.jade'),
    './navs/spotman.jade': require('./navs/spotman.jade')
};


const ROWS = {
    './rows/agenda_master_row.jade': require('./rows/agenda_master_row.jade'),
    './rows/agenda_regular_row.jade': require('./rows/agenda_regular_row.jade'),
    './rows/conversation_row.jade': require('./rows/conversation_row.jade'),
    './rows/conversationmessage_row.jade': require('./rows/conversationmessage_row.jade'),
    './rows/list_row.jade': require('./rows/list_row.jade'),
    './rows/person_row.pug': require('./rows/person_row.pug'),
    './rows/profile_row.jade': require('./rows/profile_row.jade'),
    './rows/separator_row.jade': require('./rows/separator_row.jade'),
    './rows/spotman_row.jade': require('./rows/spotman_row.jade'),
    './rows/text_row.jade': require('./rows/text_row.jade'),
    './rows/universal_search_row.jade': require('./rows/universal_search_row.jade'),
};

const BLOCKS = {
    './blocks/block_action_btn.jade': require('./blocks/block_action_btn.jade'),
    './blocks/block_banner.jade': require('./blocks/block_banner.jade'),
    './blocks/block_card.jade': require('./blocks/block_card.jade'),
    './blocks/block_common_attach_image_preview.jade': require('./blocks/block_common_attach_image_preview.jade'),
    './blocks/block_common_edit_select.pug': require('./blocks/block_common_edit_select.pug'),
    './blocks/block_common_edit_text.jade': require('./blocks/block_common_edit_text.jade'),
    './blocks/block_empty_state.jade': require('./blocks/block_empty_state.jade'),
    './blocks/block_feed_comment.jade': require('./blocks/block_feed_comment.jade'),
    './blocks/block_feed_topic.jade': require('./blocks/block_feed_topic.jade'),
    './blocks/block_feed_new_post.jade': require('./blocks/block_feed_new_post.jade'),
    './blocks/block_image.jade': require('./blocks/block_image.jade'),
    './blocks/block_list.jade': require('./blocks/block_list.jade'),
    './blocks/block_list_header.jade': require('./blocks/block_list_header.jade'),
    './blocks/block_meeting.pug': require('./blocks/block_meeting.pug'),
    './blocks/block_slider.jade': require('./blocks/block_slider.jade'),
    './blocks/block_text.jade': require('./blocks/block_text.jade'),
    './blocks/block_toggle.jade': require('./blocks/block_toggle.jade'),
    './blocks/image_block.jade': require('./blocks/image_block.jade'),
    './blocks/list_block.jade': require('./blocks/list_block.jade'),
    './blocks/text_block.jade': require('./blocks/text_block.jade'),
    './blocks/zoom.pug': require('./blocks/zoom.pug'),
};

const TEMPLATES = Object.assign({}, NAVS, ROWS, BLOCKS);
const KNOWN_NAVS = Object.keys(TEMPLATES).map(path => last(path.split('/')).split('.')[0]);

function injectTemplates($templateCache) {
    console.groupCollapsed('[maestro] injecting cached templates', KNOWN_NAVS.length);

    for (const path of Object.keys(TEMPLATES)) {
        const url = `${TEMPLATE_API_PREFIX}/${ last(path.split('/')).replace(/\.(pug|jade)/, '.html') }`;
        console.log(url);
        $templateCache.put(url, TEMPLATES[path]);
    }

    console.groupEnd();
}

angular
    .module('maestro.navs.types', [])
    .constant('NAV_TEMPLATE_API_PREFIX', TEMPLATE_API_PREFIX)
    .constant('NAV_TYPES', KNOWN_NAVS);


const NAV_TEMPLATES = {};
for (const nav of KNOWN_NAVS) {
    NAV_TEMPLATES[nav] = `${TEMPLATE_API_PREFIX}/${nav}.html`;
}

angular.module('maestro.navs.templates', []).constant('NAV_TEMPLATES', NAV_TEMPLATES);

angular
    .module('maestro.cache.templates', [])
    .run([ '$templateCache', injectTemplates ]);
