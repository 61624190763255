// used by FSTG blocks to apply different styling (in FSTG since 2018)
angular
    .module('maestro.directives')
    .directive('decorateWithWebappUiVersion', () => {
        return {
            restrict: 'AC',
            link(_scope, el) {
                el.addClass('webapp-ui-v2');
            }
        };
    });
