import i18nWithFallback from '../../../utils/i18n-with-fallback';

export const UpdateBrowserBoxComponent = {
    template: require('./update-browser-box.jade'),
    controller: class UpdateBrowserBoxComponent {
        /* @ngInject */
        constructor($i18n, $element) {
            this.$element = $element;
            this.$i18n = $i18n;

            this.labels = {
                title: i18nWithFallback(
                    $i18n,
                    'update-browser.title',
                    'You should update your browser'
                ),
                text: i18nWithFallback(
                    $i18n,
                    'update-browser.title',
                    'Unfortunately the browser you are using will not allow some of the app  features to work. Please download and use one of the following browsers to access the app.'
                )
            };

            const browsers = [
                {
                    name: 'firefox',
                    owner: 'Mozilla',
                    link: 'https://www.mozilla.org/firefox/download'
                }, {
                    name: 'chrome',
                    owner: 'Google',
                    link: 'https://www.google.com/chrome'
                }, {
                    name: 'edge (v80+)',
                    owner: 'Microsoft',
                    link: 'https://www.microsoft.com/en-us/edge'
                }, {
                    name: 'opera',
                    owner: 'Opera',
                    link: 'https://www.opera.com/download'
                }
            ];
            this.browsers = this.shuffleArray(browsers);
        }

        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        }

        downloadBrowser(browser) {
            window.open(browser.link, '_blank');
        }
    }
};
