import { each } from 'lodash';
import { CarouselController } from '../carousel/carousel-controller';

export const TagListComponent = {
    template: require('./tag-list.jade'),
    bindings: {
        row: '<'
    },
    controller: class TagListComponent extends CarouselController {
        $onInit() {
            super.$onInit();

            this.config.elements = this.config.items;
            this.setStyles();
        }

        setStyles() {
            each(this.config.elements, item => {
                item.style = this.parser.parseTextsStyles(item);
                if (item.selected || item.text_color) {
                    item.style['background-color'] = this.parser.parseColor(
                        item.color
                    );
                }

                if (item.text_color) {
                    item.style['color'] = this.parser.parseColor(
                        item.selected
                            ? item.text_color.selected
                            : item.text_color.default
                    );

                    if (!item.selected) {
                        item.style['background-color'] = 'transparent';
                        item.style['border-color'] = this.parser.parseColor(
                            item.color
                        );
                    }
                }
            });
        }

        performLabelAction($event, row) {
            super.performLabelAction($event, row.action);

            if (this.config.selectable !== false) {
                each(this.config.elements, item => {
                    item.style = this.parser.parseTextsStyles(item);
                    item.selected = false;
                });

                row.selected = true;
                this.setStyles();
            }
        }
    }
};
