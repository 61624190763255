// used to prevent user doing stupid stuf with the uploaded images
function PreventContextMenuDirective() {
    return {
        restrict: 'A',
        link: function(scope, elem) {
            elem.on('contextmenu', e => e.preventDefault());
        }
    };
}

angular
    .module('maestro.directives')
    .directive('preventContextMenu', [ PreventContextMenuDirective ]);
