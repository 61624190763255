import { each, reduce, map, extend } from 'lodash';
import mixin from '../utils/mixin';
import localCache from '../utils/localCache';

const bind = Function.prototype.bind;

// globify expands require() to:
// [{name: './navs/x.js', module: require('./navs/x.js')}]
// and es6 modules, when required via require() return {__es6_module: true, default: class X}
const mixins = map(
    [
        require('./navs/actions'),
        require('./navs/activate-event'),
        require('./navs/appscript'),
        require('./navs/events'),
        require('./navs/externals'),
        require('./navs/inflator'),
        require('./navs/integrations'),
        require('./navs/misc'),
        require('./navs/open-doc'),
        require('./navs/ops'),
        require('./navs/ui')
    ],
    r => r.default
);

class NavService {
    constructor() {
        const deps = {};
        const instance = this;
        each(NavService.$inject, (dep, i) => (deps[dep] = arguments[i]));
        each(mixins, NavPartial => {
            const $inject = (NavPartial.$inject || []).map(dep => deps[dep]);
            try {
                const partial = new (bind.apply(
                    NavPartial,
                    [ null ].concat($inject)
                ))();
                extend(instance, partial);
            } catch (e) {
                console.warn(`Failed to initialize ${NavPartial.name}`, e);
            }
        });
    }
}

reduce(mixins, mixin, NavService);

/* @ngInject */
function applyActivationConfig(
    EVENT_NAVS,
    EID,
    PID,
    navService,
    databaseService
) {
    // to mimic the app, do this only once on activation per pax.
    // Changing the pax on the same browser will trigger activation again.
    const cache = localCache('applyActivationConfig');
    if (cache.get('activated') === `${EID}:${PID}`) {
        return;
    }
    cache.put('activated', `${EID}:${PID}`);

    const activationConfig = EVENT_NAVS['actstoryboard_config'];
    if (activationConfig && activationConfig.document) {
        return navService.openDocument(activationConfig.document);
    } else if (activationConfig && activationConfig.edit_profile) {
        return databaseService.runAppScript('actions/edit-profile');
    }
}

angular
    .module('maestro.navs', [ 'maestro.navs.templates', 'maestro.navs.router' ])
    .service('navService', NavService)
    .run(applyActivationConfig);
