// Provides a promise-based interface to sheets/modals/alerts/etc.
import { filter, each, uniqueId } from 'lodash';

function SheetService() {
    let openSheets = [];
    this.$get = /* @ngInject */ function(THEME, $document, $rootScope, $q, $compile, $window) {
        let $body = $($document[0].body);
        let service = config => {
            const id = uniqueId('sheet_');

            const deferred = $q.defer();
            const promise = deferred.promise;

            const sheetScope = $rootScope.$new(/* isolate */ true);
            openSheets.push(sheetScope);

            sheetScope.$$sheetId = promise.$$sheetId = id;

            // deferred is wrapped so that angular won't digest it too much
            sheetScope.$$deferred = () => deferred;
            sheetScope.relayout = () => deferred.notify(1);
            sheetScope.accept = reason => deferred.resolve(reason);
            sheetScope.reject = reason => deferred.reject(reason);

            // used for layouting in a couple of places
            sheetScope.device = $rootScope.device;
            sheetScope.eventTheme = THEME;

            if (config.menu) {
                config.pin = 'bottom';
                config.prompt = true;
                config.dismissOnOutsideClick = true;
                // close on window resize
                angular.element($window).one('resize', () => deferred.reject());
            }

            angular.extend(sheetScope, config);

            // close eyes this is where magic happens
            $body.append($compile('<sheet></sheet>')(sheetScope));
            $body.addClass('modal-open');
            // open eyes

            promise
                .then(
                    // on success, destroy scope and pass the reason
                    reason => {
                        sheetScope.$destroy();
                        return reason;
                    },
                    // on failure, destroy scope and pass error handling to next-in-chain
                    reason => {
                        console.log('[$sheet] rejected', id, reason);
                        sheetScope.$destroy();
                        return $q.reject(reason);
                    }
                )
                .finally(() => {
                    console.log('[$sheet] cleaning up', id);

                    openSheets = filter(
                        openSheets,
                        sheet => sheet.$$sheetId !== id
                    );

                    if (openSheets.length === 0) {
                        $body.removeClass('modal-open');
                    }
                });

            // close on navigation if not handled by navigation, and not prompting
            if (!config.nav && !config.prompt) {
                const off = $rootScope.$on('navigate', () => {
                    deferred.reject();
                    // remove listener
                    off();
                });
            }

            return promise;
        };

        // API

        service.hasOpenSheets = () =>
            openSheets.filter(sheet => !sheet.menu && !sheet.alert).length > 0;
        service.dismissAllSheets = reason =>
            each(openSheets, sheet => sheet.reject(reason));
        service.dismissMenus = reason =>
            openSheets
                .filter(sheet => sheet.menu)
                .forEach(sheet => sheet.reject(reason));
        service.getSheetIds = () => openSheets.map(sheet => sheet.$$sheetId);
        service.getNavSheetIds = () =>
            openSheets
                .filter(sheet => !!sheet.nav)
                .map(sheet => sheet.$$sheetId);
        service.dismissSheet = (id, reason) =>
            openSheets
                .filter(sheet => sheet.$$sheetId === id)
                .forEach(sheet => sheet.reject(reason));

        return service;
    };
}

angular.module('maestro.services').provider('$sheet', SheetService);
