import { get } from 'lodash';

export const MeetingBlockComponent = {
    template: require('./meeting-block.pug'),
    bindings: {
        config: '<'
    },
    controller: class MeetingBlockComponent {
        /* @ngInject */ constructor(databaseService) {
            this.databaseService = databaseService;
        }

        $onInit() {
            const config = this.config;
            console.info('[MeetingBlockComponent] init', config);
            this.content = config.data.content;
            this.attend = config.data.content.status || 'maybe';
            const externalAvatars = (this.content.externals || []).map(external => { return { initials: external.substring(0, 2) }; });
            this.avatars = [ ...(this.content.participants || []), ...externalAvatars ];

            // We limit avatar list to 4 elements and add a bullet reporting
            // how many more participants are invited to the meeting.
            if (
                this.avatars.length > 4
            ) {
                const len = this.avatars.length;
                this.avatars = this.avatars.slice(0, 3);
                this.avatars.push({
                    initials: `+${len - 3}`
                });
            }

            // Location of the meeting
            if (this.content.meeting_location) {
                const location = this.content.location || '';
                this.content.location = this.content.meeting_location.title;

                if (location.length) {
                    this.content.location += ` - ${location}`;
                }
            }
        }

        $onChanges(changes) {
            const config = changes.config.currentValue;
            this.attend = config.data.content.status;
        }

        /**
         * Opens the meeting details.
         */
        openDetails() {
            const action = get(this, 'config.data.actions.block');

            if (action) {
                this.performAction(action);
            }
        }

        /**
         * Changes meeting attendance in three steps: yes, no, maybe.
         */
        toggleAttendance() {
            const data = this.config.data;
            const action = data.actions.attendance;

            switch (data.content.status) {
                case 'yes':
                    action.params.attend = 'no';
                    break;
                case 'no':
                    action.params.attend = 'maybe';
                    break;
                default:
                    action.params.attend = 'yes';
                    break;
            }

            this.attend = data.content.status = action.params.attend;
            this.performAction(action);
        }

        /**
         * Executes the action and sends data to the server.
         * @param  {Object} action the action to execute.
         */
        performAction(action) {
            this.databaseService.runAppScript(action.path, action.params);
        }
    }
};
