/**
 * Carousel is an element that may contain several card block as childs
 * and display them in an horizontal slider.
 *
 * His main purpose is to provide a compact way to display several card
 * blocks with the same contents.
 *
 * The properties that can be set on a Carousel element are the following
 *
 * ---------------------------------------------------------------------------------------------
 * Parameter     Type       Default    Min      Max    Encoding               Mandatory    Notes
 * ---------------------------------------------------------------------------------------------
 * title         String     -          -        -      -                      no
 * title_color   String     808080     -        -      #RRGGBB or             no
 *                                                     #AARRGGBB
 * title_size    int        18pt       >= 1     -      -                      no
 * title_style   String     bold       -        -      normal, bold,          no
 *                                                     italic, bold-italic
 * card_weight   float      0.5        0.1      1      -                      no           (1)
 * card_height   int        200        1        -      -                      yes          (2)
 * margin        JSON                                                         no           (3)
 * `- left       int        8          >= 0     -      points/dp              no           (7)
 * `- top        int        8          >= 0     -      points/dp              no           (7)
 * `- right      int        8          >= 0     -      points/dp              no           (7)
 * `- bottom     int        8          >= 0     -      points/dp              no           (7)
 * detail        JSON                                                         no           (4)
 * label         String     -          -        -      -                      no
 * label_color   String     808080     -        -      #RRGGBB or             no
 *                                                     #AARRGGBB
 * label_size    int        14pt       >= 1     -      -                      no
 * label_style   String     normal     -        -      normal, bold,          no
 *                                                     italic, bold-italic
 * action        JSON                                                         no           (5)
 * path          String     -          -        -      -                      no
 * params        JSON                                                         no
 * []            JSON       array                                             no           (6)
 * ---------------------------------------------------------------------------------------------
 *
 * (1) Weight express how much of the current screen width is used by one
 *     card block childs. The max value is 1 (card width corrispond to current screen width ),
 *     the min value is 0.1 (card width is 1/10 of the current screen width), when the default
 *     value is used (0.5) two cards are displayed.
 *
 * (2) Height express the height for every card block child, if not present a default value of
 *     200 pixel is applied but this does not mean that the content of the childs will be
 *     completely visible so is necessary pre-evaluate the content before choose the right value
 *     and verify on a real device that is fully visible.
 *
 * (3) Contains margin properties (left,top, right and bottom), a margin value missing will be
 *     set to a default value.
 *
 * (4) JSON that contains label, label_color and action attributes.
 *
 * (5) JSON that contains path and params attributes.
 *
 * (6) JSON array that will contain all the card block childs the carousel is going to contain
 *
 * (7) This margin value is fixed on iOS and for now cannot be dynamically changed
 *
 * Notice
 *   - is possible to define heterogeneous card block childs but is not advisable so don't do it! ;)
 *   - is a good practice try to keep the number and the layout of the carousel childs reasonable
 *     without too many nested card block elements, from a pure performance point of view the Carousel
 *     is a heavy card block element to render and on some old device this could be a limit.
 */

import { CarouselController } from './carousel-controller';

export const CarouselComponent = {
    bindings: {
        row: '<'
    },
    replace: true,
    template: require('./carousel.jade'),
    controller: CarouselController
};
