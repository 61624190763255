import { each } from 'lodash';
import i18nWithFallback from '../../../utils/i18n-with-fallback';

const MAX_AUTO_RECONNECT_ATTEMPTS = 5;

export const OfflineNotificationComponent = {
    template: require('./offline-notification.jade'),
    controller: class OfflineNotificationComponent {
        /* @ngInject */
        constructor(offlineService, signalService, $i18n, $scope) {
            this.offlineService = offlineService;
            this.signalService = signalService;
            this.$scope = $scope;
            this.offline = false;
            this.pendingCall = false;
            this.signalConnected = true;
            this.signalReconnectAttempts = 0;
            this.permanentSignalDisconnected = false;

            this.labels = {
                offlineTitle: i18nWithFallback($i18n, 'webapp_offline.offline'),
                offlineSubtitle: i18nWithFallback($i18n, 'webapp_offline.pending_offline'),
                noSignalTitle: i18nWithFallback($i18n, 'webapp_offline.no_signal_title', 'Something preventing the system from working.'),
                noSignalSubtitle: i18nWithFallback($i18n, 'webapp_offline.no_signal_subtitle', 'Please refer to our troubleshooting article for guidance.'),
                reconnectButton: i18nWithFallback($i18n, 'webapp_offline.reconnect', 'Try to refresh')
            };
        }

        $onInit() {
            const onSignalStateChange = () => this.$scope.$emit('error-banner:display', this.showSignalReconnectMessage);
            this.eventListeners = [
                this.offlineService.on('offline', () => this.onOffline()),
                this.offlineService.on('online', () => this.onOnline()),
                this.offlineService.on('offline:pending', () => this.onPendingRequest()),
                this.signalService.addEventListener('end', () => this.onSignalDisconnection()),
                this.signalService.addEventListener('open', () => this.onSignalProofOfConnection()),
                this.signalService.addEventListener('data', () => this.onSignalProofOfConnection()),
                this.$scope.$watch('$ctrl.showSignalReconnectMessage', onSignalStateChange, true)
            ];
            onSignalStateChange();
        }

        get showSignalReconnectMessage() {
            return !this.pendingCall && this.permanentSignalDisconnected;
        }

        onSignalDisconnection() {
            this.loading = false;
            this.signalConnected = false;
            if (this.signalReconnectAttempts < MAX_AUTO_RECONNECT_ATTEMPTS) {
                console.warn('[OfflineNotification] Signal disconnected, reconnecting...');
                this.reconnectSignal();
            } else {
                console.error('[OfflineNotification] Signal permanently disconnected, prompting user to reconnect');
                this.permanentSignalDisconnected = true;
            }
        }

        onSignalProofOfConnection() {
            this.loading = false;
            this.signalConnected = true;
            this.signalReconnectAttempts = 0;
            this.offline = false;
            this.pendingCall = false;
            this.permanentSignalDisconnected = false;
        }

        onOffline() {
            this.offline = true;
        }

        onOnline() {
            this.offline = false;
            this.pendingCall = false;
            this.reconnectSignal({ delay: 0, jitter: 0 });
        }

        onPendingRequest() {
            this.pendingCall = true;
        }

        reconnectSignal({ delay, jitter } = {}) {
            if (this.signalConnected) {
                this.loading = false;
                return;
            }
            this.signalReconnectAttempts++;
            this.loading = true;
            this.signalService.restart({ delay, jitter });
        }

        $onDestroy() {
            each(this.eventListeners, unsub => unsub());
        }
    }
};
