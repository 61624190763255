import { each, filter, get, uniq } from 'lodash';

export class CommonFeedBlockNotificationHandlers {
    // expectations:
    // this.config, this.onUpdate, this.EventEmitter

    /* @ngInject */
    constructor(THEME) {
        this.THEME = THEME;
    }

    $onInit() {
        const config = this.config;
        const notificationsToSubscribeTo =
            config.reload_on && config.reload_on.notifications;
        if (!notificationsToSubscribeTo || !notificationsToSubscribeTo.length) {
            return;
        }

        this.notificationSubscriptions = [];
        const specialHandlers = {
            'blocks:insert': this.onBlocksInsertNotification.bind(this),
            'blocks:remove': this.onBlocksRemoveNotification.bind(this),
            'blocks:update': this.onBlocksUpdateNotification.bind(this)
        };
        each(specialHandlers, (handler, name) => {
            if (notificationsToSubscribeTo.indexOf(name) !== -1) {
                this.notificationSubscriptions.push(
                    this.navService.on(name, handler)
                );
            }
        });

        if (get(this, 'config.data.theme') && this.THEME.version !== 3) {
            this.topic_theme = {
                background_color: this.config.data.theme.background_color_topic,
                text_color: this.config.data.theme.text_color_topic
            };
        }
    }

    $onDestroy() {
        const subs = this.notificationSubscriptions;
        if (subs && subs.length) {
            each(subs, unsub => unsub());
        }
    }

    onBlocksInsertNotification({ target, block }) {
        if (this.config.id !== target || !this.inlineBlocks) return;

        console.log(
            '[CommonFeedBlockNotificationHandlers] insert',
            target,
            block
        );

        this.onUpdate(
            this.EventEmitter({
                id: this.config.id,
                updates: {
                    content: {
                        inline_blocks: uniq(
                            this.inlineBlocks.concat([ block ]),
                            ({ id }) => id
                        )
                    }
                }
            })
        );
    }

    // unlike other notifications this one is handled in parent block
    // instead of checking if this block's id === target, we simply remove all children with target id
    onBlocksRemoveNotification({ target }) {
        const n = this.inlineBlocks.length;
        const filtered = filter(this.inlineBlocks, ({ id }) => id !== target);
        if (n !== filtered.length) {
            console.log('[CommonFeedBlockNotificationHandlers] remove', target);
            this.onUpdate(
                this.EventEmitter({
                    id: this.config.id,
                    updates: {
                        content: {
                            inline_blocks: filtered
                        }
                    }
                })
            );
        }
    }

    onBlocksUpdateNotification({ target, updates }) {
        if (this.config.id !== target) return;

        console.log(
            '[CommonFeedBlockNotificationHandlers] update',
            target,
            updates
        );
        this.onUpdate(this.EventEmitter({ id: target, updates }));
    }
}
