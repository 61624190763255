import { sortBy } from 'lodash';
import moment from 'moment-timezone';

/**
 * @param {object[]} sessionsToday
 * @param {number} nowSeconds
 * @returns {object | undefined}
 */
export function selectNextSession(sessionsToday, nowSeconds) {
    let candidate;

    for (const session of sortBy(sessionsToday, s => s.start_ts)) {
        const hasStarted = session.start_ts <= nowSeconds;
        if (hasStarted || !candidate) {
            candidate = session;
        }

        const hasEnded = session.end_ts < nowSeconds;
        if (hasEnded) {
            candidate = undefined;
        }
    }

    return candidate;
}

/**
 * Checks if a session is currently live.
 *
 * @param {Object} session - The session object to check.
 *
 * @returns {boolean} - Returns true if the session is live, false otherwise.
 */
export function isSessionLive(session) {
    if (!session) {
        return false;
    }

    let now = moment();
    let start = moment(session.start_ts * 1000);
    let end = moment(session.end_ts * 1000);

    return now.isBetween(start, end);
}

/**
 * Checks if a session is in the past.
 *
 * @param {Object} session - The session object to check.
 *
 * @returns {boolean} - Returns true if the session is in the past, false otherwise.
 */
export function isSessionPast(session) {
    if (!session) {
        return false;
    }

    let now = moment();
    let end = moment(session.end_ts * 1000);

    return now.isAfter(end);
}
