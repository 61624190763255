export default class NavActivateEvent {
    /* @ngInject */
    constructor($window, BRANDING) {
        this.$windows = $window;
        this.BRANDING = BRANDING;
    }

    activateEvent({ eid, pid, token, appScript }) {
        const redirect = appScript
            ? `&redirect=/_as/${btoa(JSON.stringify(appScript))}`
            : '';
        this.$window.location.href =
            `${"https://webapp.spotme-dev.com"}/api/v1/request-webapp-link?` +
            'format=redirect' +
            `&eid=${eid}&pid=${pid}` +
            `&invitationToken=${token}` +
            `&branding=${this.BRANDING}` +
            redirect;
    }
}
