import { Tab } from './Tab';

export class Translations extends Tab {

    get parameters() {
        const isDarkMode = this.services.THEME.get('appearance') === 'dark';
        return `${isDarkMode ? 'fgcolor=E4E6EB&bgcolor=18191A' : 'fgcolor=18191A&bgcolor=FFFFFF'}&lang=en&enableTTS=true`;
    }

    get sessionId() {
        return this.livestream.multicast_session_id;
    }

    get url() {
        return `https://attend.wordly.ai/frame/${this.sessionId}?${this.parameters}`;
    }

    get config() {
        if (!this.sessionId) {
            return {};
        }

        return {
            id: 'translations',
            src: this.url
        };
    }
}
