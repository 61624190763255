import { cloneDeep, isEqual } from 'lodash';
import { Tab } from './Tab';

export class Viewers extends Tab {

    async init(livestreamId) {
        this.listeners.push(
            await this.services.signalService.addSignalListener(
                `liveStream/${livestreamId}/interactions`,
                (signal) => this.updateViewers(signal)
            )
        );
    }

    updateViewers(data) {
        this.loaded = true;

        if (!data || !data.viewers) {
            return;
        }

        const oldViewers = cloneDeep(this.viewers);

        const { viewers } = data;
        this.viewers = (viewers.participants || []).map(viewer => ({
            ...viewer,
        }));

        if (!isEqual(oldViewers, this.viewers)) {
            this.updatePlayerHandler();
        }
    }

    onSideBarEvent({ pid }) {
        if (this.services.EVENT.is_webinar) return;
        this.services.navService.openDocument({
            _id: pid
        }, {
            navParams: {
                modal: true,
                fullscreen: true
            }
        });
    }

    get config() {
        return {
            id: 'viewers',
            viewers: this.viewers,
            hidden: !this.loaded
        };
    }
}
