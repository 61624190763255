// Utils
import { chunk, each, get, indexBy, isFunction, omit, pick, throttle, uniq } from 'lodash';
import { DeviceManager } from './DeviceManager';
import i18nWithFallback from '../../../utils/i18n-with-fallback';
import { bestFit, share, BEST_FIT_VALUES, SHARE_VALUES } from './BroadcastLayout';
import { MODERATOR_ACTIONS } from '../../../services/videoCallModeratorService';
import { Subscriber } from './Subscriber';
import { videoElementSanityCheck } from './sanity-checks';
import EventEmitter from '../../../utils/event-emitter';

const ROOM_CAPACITY = 50;
let USERS_PER_PAGE = 12;
let USERS_PER_SHARE_SCREEN_PAGE = 6;

const LAYOUT_CHANGE_THROTTLES = 500;

let MAX_SCREENSHARE_RESOLUTION = { width: 1280, height: 720 };
let SCREENSHARE_CONTENT_HINT = '';

/**
 * This component is used to handle video calls
 */
export const VideoCallComponent = {
    bindings: {
        actions: '=',
        conversationId: '<',
        sessionId: '<',
        docType: '<',
        parentDocumentId: '<'
    },
    template: require('./video-call.pug'),
    controller: class VideoCallComponent extends DeviceManager {
        /* @ngInject */
        constructor(
            ACTIVATED_PERSON,
            crashReportingService,
            $scope,
            videoCallService,
            videoCallModeratorService,
            videoBreakoutService,
            databaseService,
            metricsService,
            libLoaderService,
            $i18n,
            $http,
            $element,
            $eventBus,
            $sce,
            $timeout,
            $interval,
            $filter,
            mediaDevicesService,
            signalService,
            triggerService,
            uiService
        ) {
            super(mediaDevicesService, $eventBus, $scope);
            this.ACTIVATED_PERSON = ACTIVATED_PERSON;
            this._videoCallService = videoCallService;
            this._videoBreakoutService = videoBreakoutService;
            this.videoCallModeratorService = videoCallModeratorService;
            this.crashReportingService = crashReportingService;
            this.triggerService = triggerService;
            this.uiService = uiService;
            this.libLoaderService = libLoaderService;
            this.dbService = databaseService;
            this.$http = $http;
            this.$i18n = $i18n;
            this.$sce = $sce;
            this.$element = $element;
            this.$timeout = $timeout;
            this.$interval = $interval;
            this.$filter = $filter;
            this.publisherTestOptions = {};

            this.publisher = null;
            this.participants = [];
            this.subscribers = [];
            this.pinnedParticipants = [];
            this.talkingOrder = [];
            this.showAudioInputs = false;
            this.showVideoInputs = false;
            this.showAskToUnmute = false;
            this.layoutData = {};
            this.errorMessage = null;
            this.showReconnectionMessage = false;
            this.metricsService = metricsService;
            this.signalService = signalService;
            this.publisherPublished = false;
            this.eventEmitter = new EventEmitter($timeout);

            this.showCall = false;
            this.showSpinner = true;
            this.numberOfAutoReconnect = 0 ;
            this.listeners = [];
            this.sessionListeners = [
                { event: 'streamCreated', handler: this.onStreamCreated.bind(this) },
                { event: 'streamDestroyed', handler: this.onStreamDestroyed.bind(this) },
                { event: 'streamPropertyChanged', handler: this.onStreamPropertyChanged.bind(this) },
                { event: 'connectionDestroyed', handler: this.removeParticipant.bind(this) },
                { event: 'sessionDisconnected', handler: this.onSessionDisconnected.bind(this) }
            ];

            this.labels = {
                you_were_disconnected: i18nWithFallback($i18n, 'video_calls.labels.you_were_disconnected', 'You were disconnected from the call'),
                auto_reconnect_in: i18nWithFallback($i18n, 'video_calls.labels.auto_reconnect_in', 'You will be automatically reconnected in'),
                reconnect_now: i18nWithFallback($i18n, 'video_calls.actions.reconnect_now', 'Reconnect now'),
                unmute_modal_title: i18nWithFallback($i18n, 'video_calls.unmute_modal.title', 'Unmute microphone'),
                unmute_modal_hint: i18nWithFallback($i18n, 'video_calls.unmute_modal.hint', 'The moderator is asking you to unmute yourself.'),
                unmute: i18nWithFallback($i18n, 'video_calls.unmute', 'Unmute'),
                cancel: i18nWithFallback($i18n, 'video_calls.cancel', 'Cancel'),
                screen_error_modal_title: i18nWithFallback($i18n, 'video_calls.screen_error_modal_title', 'Screen sharing not available'),
                screen_error_modal_hint: i18nWithFallback($i18n, 'video_calls.screen_error_modal_hint', 'Please grant screen share access in the privacy settings.'),
                more_info: i18nWithFallback($i18n, 'video_calls.more_info', 'More info'),
                kickout_participant: i18nWithFallback($i18n, 'video_calls.kickout_participant', 'Kickout Participant'),
                confirm_kickout_participant: i18nWithFallback($i18n, 'video_calls.confirm_kickout_participant', 'Are you sure you want to kickout this participant'),
                kickout: i18nWithFallback($i18n, 'video_calls.kickout', 'Kick out'),
                moderator: i18nWithFallback($i18n, 'video_calls.moderator', 'Moderator'),
                moderator_removed_you: i18nWithFallback($i18n, 'video_calls.moderator_removed_you', 'The moderator removed you from the meeting.')
            };

            this.askToUnmuteModal = {
                title: this.labels.unmute_modal_title,
                hint: this.labels.unmute_modal_hint,
                buttonValidate: {
                    label: this.labels.unmute,
                    action: () => {
                        this.toggleAudio(true);
                        this.showAskToUnmute = false;
                    }
                },
                buttonCancel: {
                    label: this.labels.cancel,
                    action: () => {
                        this.showAskToUnmute = false;
                    }
                }
            };

            this.screenShareErrorModal = {
                title: this.labels.screen_error_modal_title,
                hint: `<span> ${this.labels.screen_error_modal_hint} </span>
                        <a target="_blank" href="https://support.spotme.com/hc/en-us/articles/360062337193-How-to-allow-screen-sharing-for-your-browser-on-Mac">
                        ${this.labels.more_info}</a>`,
                buttonCancel: {
                    label: this.labels.cancel,
                    action: () => {
                        this.showScreenShareErrorModal = false;
                    }
                }
            };

            this.keyDownEscapeHandler = (e) => {
                if (!e || e.key !== 'Escape') {
                    return;
                }
                this.toggleFullScreen(false);
            };

            const setPages = () => {
                const usersPerPage = this.getNumberOfUserPerPage();

                this.subscribers = this.subscribers.filter(subscriber => {
                    return !this.hasDeadStream(subscriber);
                });


                for (const i in this.subscribers) {
                    let talkingPosition = this.talkingOrder.indexOf(this.subscribers[i].pid);
                    talkingPosition = talkingPosition < 0 ? ROOM_CAPACITY : talkingPosition;
                    this.subscribers[i].setPinned(this.pinnedParticipants.includes(this.subscribers[i].pid));
                    this.subscribers[i].setTalkingPosition(talkingPosition);
                }

                this.subscribers = this.subscribers.sort((a, b) => {
                    if (a.isPinned && !b.isPinned) {
                        return -1;
                    } else if (!a.isPinned && b.isPinned) {
                        return 1;
                    } else if (a.isPinned && b.isPinned) {
                        return 0;
                    } else if (this.subscribers.length > usersPerPage - 1) {
                        // we reorder the speaking participants only if there's more than 1 page
                        if (a.talkingPosition < b.talkingPosition) {
                            return -1;
                        } else if (b.talkingPosition < a.talkingPosition) {
                            return 1;
                        }
                    }
                    return 0;
                });

                for (let i = 0;i < this.subscribers.length; i++) {
                    this.subscribers[i].index = (i % (usersPerPage - 1)) + 1;
                }
                this.pages = chunk(this.subscribers, usersPerPage - 1);
                const pageIndex = this.currentPage ? this.currentPage.index : 0;
                this.goToPage(pageIndex);
                each(this.subscribers, subscriber => {
                    subscriber.show = !!this.currentPage.subscribers[subscriber.stream.streamId];
                });
                this.setLayout();
            };

            const setLayout = () => {
                const style = this.$element.find('style')[0];
                if (!style) {
                    return;
                }
                let count = get(this, 'currentPage.size', 0) + 1;
                this.layout = count
                    ? this.isSomeContentShared()
                        ? share(count)
                        : bestFit(count)
                    : bestFit(1);
                style.textContent = this.layout;

                count--;
                this.layoutData.fitValues = count
                    ? this.isSomeContentShared()
                        ? SHARE_VALUES[count]
                        : BEST_FIT_VALUES[count]
                    : BEST_FIT_VALUES[0];
                this.setSubscribersVisibility();
                this.adaptSubscriberResolution();
                this.$scope.$applyAsync();
            };

            this.setLayout = throttle(() => { setLayout(); }, LAYOUT_CHANGE_THROTTLES);
            this.setPages = throttle(() => { setPages(); }, LAYOUT_CHANGE_THROTTLES);

            this.listeners.push(
                this.triggerService.addTrigger('destroySession', () => {
                    this.session.disconnect();
                }),
                this.triggerService.addTrigger('setScreenShareResolution', (width, height) => {
                    MAX_SCREENSHARE_RESOLUTION = { width, height };
                }),
                this.triggerService.addTrigger('setScreenShareContentHint', (newVal) => {
                    SCREENSHARE_CONTENT_HINT = newVal;
                }),
                this.triggerService.addTrigger('reducePublisherResolutionByFactor', (f = 1) => {
                    this.publisherTestOptions.reducePublisherResolutionByFactor = f;
                }),
                this.triggerService.addTrigger('setNumberOfUserPerPage', (newVal = 12, newScreenShareVal = 6) => {
                    USERS_PER_PAGE = Math.min(newVal, 12);
                    USERS_PER_SHARE_SCREEN_PAGE = Math.min(newScreenShareVal, 6);
                    this.setPages();
                }),
                this.triggerService.addTrigger('testVideoCall', async () => {
                    const sleep = async function sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms)); };
                    window.triggers.destroySession(); await sleep(20 * 1000);
                    window.triggers.destroyPublisher(); await sleep(20 * 1000);
                    window.triggers.destroyPublisherStream(); await sleep(6 * 1000);
                    window.triggers.destroyPublisherVideoMediaTrack(); await sleep(6 * 1000);
                    window.triggers.destroyPublisherAudioMediaTrack(); await sleep(6 * 1000);
                    window.triggers.destroySubscriber(); await sleep(6 * 1000);
                    window.triggers.stopSubscriberMediaTracks(); await sleep(6 * 1000);
                    window.triggers.stopSubscriberAudioMediaStreamTrack(); await sleep(6 * 1000);
                    window.triggers.stopSubscriberVideoMediaStreamTrack(); await sleep(6 * 1000);
                    console.debug('[VideoCalls] all error triggers were called, hopefully video and audio was restored between each interruption!');
                }),
                this.triggerService.addTrigger('enableScreenShareSimulcast', (val) => {
                    this.enableScreenShareSimulcast = val;
                }),
                this.triggerService.addTrigger('enableVideoFilter', (filter) => {
                    this.publisherTestOptions.videoFilter = filter
                        || {
                            type: 'backgroundBlur',
                            blurStrength: 'high'
                        };
                }),
            );
        }

        async $onInit() {
            this.videoCallService = this.conversationId ? this._videoCallService : this._videoBreakoutService;
            this.inCall = false;
            this.videoCallId = this.sessionId;

            this.OTexceptionHandler = async (err) => {
                console.error('[video call component] OT exception', err);
                const errorMessage = pick(err, [ 'code', 'message', 'title', 'type' ]);
                try {
                    const videostream = await this.mediaDevicesService.getUserMedia({ video: true, audio: false });
                    errorMessage.videoStream = { active: videostream.active, track: pick(videostream.getVideoTracks()[0], [ 'enabled', 'kind', 'label', 'muted', 'readyState' ]) };
                } catch (e) {
                    errorMessage.videoStreamError = pick(e, [ 'message', 'name' ]);
                }
                try {
                    const audiostream = await this.mediaDevicesService.getUserMedia({ audio: true, video: false });
                    errorMessage.audioStream = { active: audiostream.active, track: pick(audiostream.getAudioTracks()[0], [ 'enabled', 'kind', 'label', 'muted', 'readyState' ]) };
                } catch (e) {
                    errorMessage.audioStreamError = pick(e, [ 'message', 'name' ]);
                }
                this.mediaDevicesService.stopAllStreams();
                this.crashReportingService.reportError('Video call component', 'OT exception handler', errorMessage);
            };

            try {
                await this.libLoaderService.load(this.$filter('appResource'), 'opentok');
                this.$scope.$applyAsync(() => this.init());
                OT.on('exception', this.OTexceptionHandler);
            } catch (e) {
                this.handleError(null, 'unable_to_connect');
            }

            const onPublisherPublished = () => {
                this.publisherPublished = true;
                this.showCall = true;
                this.showState('call');
                this.adaptSubscriberResolution();
                if (this.conversationId) {
                    // The send action have no impact on the video call, their failure should not impact the call.
                    if (this.isFirstInCall) {
                        this.sendStartVideoCallAction().catch(() => {});
                    } else if (!this.subscribers.length) {
                        // here we joined the call, no one is there, but we thought we were not the first joiner
                        // meaning the call was previously badly closed, leaving it in a is_live = true state
                        // => we send the notification to the other users that the call has started again
                        this.sendStartVideoCallAction().catch(() => {});
                    } else {
                        this.sendAction('join_video_call').catch(() => {});
                    }
                }
                this.$interval.cancel(this.sanityCheckInterval);
                this.sanityCheckInterval = this.$interval(async () => {
                    for (const subscriber of this.subscribers) {
                        if (!videoElementSanityCheck(subscriber.videoElement, subscriber.hasAudio, subscriber.hasVideo)) {
                            console.log(`[VideoCall] sanity check failed, resubscribing to ${subscriber.name}'s subscriber`);
                            await subscriber.subscribeToStream();
                        }
                    }
                }, 5 * 1000);

            };

            window.onbeforeunload = this.partVideoCall.bind(this);

            if (this.docType === 'session') {
                this.videoType = 'session-breakout';
            } else if (this.docType === 'sponsor') {
                this.videoType = 'sponsor-booth';
            } else if (this.docType === 'chat') {
                this.videoType = 'chat';
            }

            const pinParticipant = (pid) => {
                if (!this.pinnedParticipants.includes(pid)) {
                    if (this.pinnedParticipants.length >= 5) {
                        return;
                    }
                    this.pinnedParticipants.push(pid);
                } else {
                    this.pinnedParticipants = this.pinnedParticipants.filter(id => id !== pid);
                }
                this.videoCallService.savePinnedParticipants(this.videoCallDoc._id, this.pinnedParticipants);
                this.videoCallModeratorService.sendAction(MODERATOR_ACTIONS.PIN, { data: { pinnedParticipants: this.pinnedParticipants } });
                this.setPages();
            };

            const isTalking = (pid) => {
                if (this.talkingOrder.includes(pid)) {
                    // Minus 1 for the publisher, and minus 1 for the index starting at 0
                    if (this.talkingOrder.indexOf(pid) > this.getNumberOfUserPerPage() - this.pinnedParticipants.length - 2) {
                        // The speaker is not on the first page, we move it up the list
                        this.talkingOrder = this.talkingOrder.sort(function(x, y) { return x === pid ? -1 : y === pid ? 1 : 0; });
                    }
                } else {
                    this.talkingOrder.unshift(pid);
                }
                this.setPages();
            };

            const updateModerators = async (moderators = []) => {
                if (!moderators || !moderators.length) {
                    if (this.parentDocumentId) {
                        moderators = await this.videoCallService.getModerators(this.parentDocumentId);
                    }
                }
                this.isModerator = moderators.includes(this.ACTIVATED_PERSON._id) || moderators.includes(this.ACTIVATED_PERSON.fp_ext_id);
            };

            const showKickoutModal = (event, options) => {
                this.kickoutModal = {
                    title: this.labels.kickout_participant,
                    hint: this.labels.confirm_kickout_participant,
                    buttonValidate: {
                        label: this.labels.kickout,
                        action: () => {
                            this.videoCallModeratorService.sendAction(MODERATOR_ACTIONS.KICKOUT, options);
                            this.kickoutModal = null;
                        }
                    },
                    buttonCancel: {
                        label: this.labels.cancel,
                        action: () => {
                            this.kickoutModal = null;
                        }
                    }
                };
            };

            const notifyOfBeingKickedOut = () => {
                const message = this.uiService.buildNotificationMessage({
                    title: this.labels.moderator,
                    message: {
                        body: this.labels.moderator_removed_you,
                    },
                    duration: 5,
                    backgroundColor: get(this.$scope, 'eventTheme.toolbar.background_color'),
                    contentColor: get(this.$scope, 'eventTheme.toolbar.font_color'),
                    iconColor: get(this.$scope, 'eventTheme.toolbar.font_color')
                });
                this.uiService.inAppNotification(message);
            };

            this.listeners.push(
                this.$scope.$on('joinCall', (event, { videoOn, audioOn }) => this.joinVideoCall({ videoOn, audioOn })),
                this.$scope.$on('hideSpinner', () => this.showState('onboarding')),
                this.$scope.$on('publisher:error', () => this.destroySession()),
                this.$scope.$on('publisher:published', () => onPublisherPublished()),
                this.$scope.$on('publisher:deviceChangeError', (err) => this.handleError(err)),
                this.$scope.$on('pip:close', () => this.partVideoCall()),
                this.$scope.$on('publisher:accessDenied', () => {
                    this.destroySession();
                    this.showState('error');
                    this.callJoined = false;
                    this.$scope.$applyAsync();
                }),
                this.$scope.$on('publisher:created', (event, publisher) => {
                    this.publisher = publisher;
                    this.$scope.$applyAsync();
                }),
                this.$eventBus.on('$navChange', () => {
                    this.toggleFullScreen(false);
                    this.showList = false;
                }),
                this.$eventBus.on(`moderatorRequest:${MODERATOR_ACTIONS.KICKOUT}`, () => {
                    notifyOfBeingKickedOut();
                    this.partVideoCall();
                }),
                this.$eventBus.on(`moderatorRequest:${MODERATOR_ACTIONS.PIN}`, (data) => {
                    this.pinnedParticipants = get(data, 'pinnedParticipants', []);
                    this.setPages();
                }),
                this.$scope.$on('moderator:pin', (event, pid) => { pinParticipant(pid); }),
                this.$scope.$on('talkingIndicator:isTalking', (event, pid) => { isTalking(pid); }),
                await this.signalService.addSignalListener(`videoCalls/new-moderators/${this.parentDocumentId}`,
                    async (signal) => {
                        await updateModerators(signal.moderators);
                        this.$scope.$applyAsync();
                    }),
                this.$scope.$on('subscriber:initiated', (event, subscriber) => {
                    this.participants = this.participants.map((p) => {
                        if (p.id === subscriber.pid) {
                            p.subscriber = subscriber;
                        }
                        return p;
                    });
                }),
                this.$scope.$on('moderator:showKickoutModal', showKickoutModal)
            );

            await updateModerators();
        }

        deviceUpdatedHandler({ error }) {
            if (error) this.handleError(error);
            if (!this.mediaDevicesService.audioDevices.length || !this.mediaDevicesService.audioDeviceSelected) {
                this.setAudioOn(false);
            }

            if (!this.mediaDevicesService.videoDevices.length || !this.mediaDevicesService.videoDeviceSelected) {
                this.setVideoOn(false);
            }
            this.$scope.$applyAsync();
        }

        /**
         * Initialize the component.
         *
         * NOTE: we assume that at the time we call this method,
         *       OpenTok runtime has been succesfully loaded.
         */
        init() {
            console.info('[VideoCall] Init', this.conversationId, this.actions);
            this.showState('loading');
            this.showOnboarding = true;
            this.getSession();
        }

        /**
         * Opens a room and starts the video call
         */
        sendStartVideoCallAction() {
            return this.sendAction('start_video_call', {
                session_id: this.videoCallId,
                conversation_id: this.conversationId
            });
        }

        getSession() {
            this.errorMessage = null;
            if (this.session) this.session.disconnect();

            if (this.videoCallId && !this.conversationId) {
                this.publisherName = this.ACTIVATED_PERSON.fname + ' ' + this.ACTIVATED_PERSON.lname;
                return;
            }

            this.videoCallService.getConversation(this.conversationId).then(conversation => {
                this.videoCallId = conversation.session_id;
                this.isFirstInCall = !conversation.is_live;
                this.publisherName = conversation.all_pax[this.ACTIVATED_PERSON._id];
            }).catch(err => this.handleError(err, 'unable_to_connect'));
        }

        getTokenAndApiKey() {
            return this.videoCallService.getTokenAndApiKey(this.videoCallId, this.parentDocumentId).then(({ data }) => {
                this.videoCallDoc = data;
                const { videoSessionConfig, token, embedded_url_enabled, embedded_url, pinnedParticipants } = this.videoCallDoc;
                this.vonageSessionId = (videoSessionConfig && videoSessionConfig.sessionId) ? videoSessionConfig.sessionId : this.videoCallId;
                this.apiKey = videoSessionConfig.apiKey;
                this.token = token;
                this.embeddedUrl = embedded_url_enabled ? this.$sce.trustAsResourceUrl(embedded_url) : false;
                this.pinnedParticipants = pinnedParticipants || [];
            }).catch(err => this.handleError(err, 'unable_to_connect'));
        }

        isSomeContentShared() {
            return !!(this.embeddedUrl || this.screenShareActive);
        }

        /**
         * Joins an ongoing video call
         */
        joinVideoCall(options = {}) {
            super.init();

            this.inCall = true;
            this.showState('loading');

            if (options.hasOwnProperty('videoOn')) {
                this.videoOn = options.videoOn;
            }
            if (options.hasOwnProperty('audioOn')) {
                this.audioOn = options.audioOn;
            }

            this.getTokenAndApiKey().then(() => {
                this.setupSession();
                this.$eventBus.emit('video-call:joined');
            });
        }

        async setupSession() {
            this.showReconnectionMessage = false;
            this.callJoined = false;
            this.showState('loading');

            const session = OT.initSession(this.apiKey, this.vonageSessionId);
            this.session = this.angularizeSessionListeners(session);

            this.session.connect(this.token, err => {
                if (err) return this.handleError(err, 'unable_to_connect');

                this.addParticipant({ id: this.ACTIVATED_PERSON._id });

                this.videoCallModeratorService.init(this.session);

                this.createSessionListeners();
                // wait for the subscribers' streams to be created
                // to make sure there's room to join
                this.$timeout(() => {
                    this.setPages();
                    this.callJoined = true;
                }, 1000);
            });
        }

        onStreamCreated(event) {
            if (event.stream.videoType === 'screen') {
                this.subscriberSharingStream = event.stream;
                this.screenShareActive = true;
            } else {
                if (this.hasDeadStream(event)) {
                    return;
                }

                this.subscribers.push(
                    new Subscriber({ triggerService: this.triggerService }, {
                        session: this.session,
                        name: event.stream.name,
                        pid: this.getStreamOwner(event.stream).id,
                        stream: event.stream,
                    })
                );

                if ((this.subscribers.length + 1) > ROOM_CAPACITY && !this.callJoined) {
                    this.destroySession();
                    this.handleError(null, 'limit_reached');
                    return this.$scope.$applyAsync();
                }

                if (this.callJoined) this.setPages();

                this.addParticipant(event);
            }

            if (this.callJoined) this.emitUpdate();
        }

        onStreamPropertyChanged(event) {
            if (event.stream.connection.connectionId === this.session.connection.connectionId) return;
            if (event.changedProperty === 'hasVideo') {
                this.toggleSubscriberAudioVideo(event.stream, 'hasVideo', event.newValue);
            }
            if (event.changedProperty === 'hasAudio') {
                this.toggleSubscriberAudioVideo(event.stream, 'hasAudio', event.newValue);
            }
            this.$scope.$applyAsync();
        }

        hasDeadStream(obj) {
            const connectionDestroyed = get(obj, 'stream.connection.destroyed', () => {})();
            const streamDestroyed = get(obj, 'stream.destroyed');
            return streamDestroyed || connectionDestroyed;
        }

        adaptSubscriberResolution() {
            this.$scope.$broadcast('layout:changed', this.layoutData);
        }

        switchPage(increment) {
            const newIndex = this.currentPage.index + increment;
            this.goToPage(newIndex);
            this.setLayout();
        }

        goToPage(index) {
            this.currentPage = {
                index,
                subscribers: indexBy(this.pages[index], sub => sub.stream.streamId),
                size: this.pages[index] ? this.pages[index].length : 0
            };
            if (this.currentPage.size === 0 && index > 0) {
                this.goToPage(index - 1);
            }
        }

        setSubscribersVisibility() {
            if (!this.currentPage) {
                return;
            }
            each(this.subscribers, subscriber => {
                if (this.currentPage.subscribers[subscriber.stream.streamId]) {
                    this.setSingleSubscriberVisibility(subscriber, true);
                } else {
                    this.setSingleSubscriberVisibility(subscriber, false);
                }
            });
        }

        setSingleSubscriberVisibility(subscriber, val) {
            subscriber.show = val;
            if (subscriber.otSubscriberObject && isFunction(subscriber.otSubscriberObject.restrictFrameRate)) {
                subscriber.otSubscriberObject.restrictFrameRate(!val);
            }
        }

        onStreamDestroyed(event) {
            console.log('[VideoCall] onStreamDestroyed', event);
            if (this.subscriberSharingStream && this.subscriberSharingStream.streamId === event.stream.streamId) {
                this.subscriberSharingStream = null;
                this.screenShareActive = false;
            } else {
                this.subscribers = this.subscribers.filter(sub => {
                    return sub.stream.streamId !== event.stream.streamId;
                });
            }
            this.setPages();
            this.emitUpdate();
        }

        /**
         * Get the owner of a stream, returns their pid
         *
         * @param {object} stream the stream
         */
        getStreamOwner(stream) {
            const jsonData = get(stream, 'connection.data');
            let participant;
            if (jsonData) {
                participant = JSON.parse(jsonData);
            } else {
                participant = stream;
            }
            return participant;
        }

        /**
         * Adds a participant to the participants list
         *
         * @param {Object} event the event passed on connection created
         */
        addParticipant(event) {
            const stream = get(event, 'stream', event);
            const participant = this.getStreamOwner(stream);

            if (participant.id === this.ACTIVATED_PERSON._id) {
                participant.name = this.$i18n('conversation_nav.details.you');
                participant.isActPerson = true;
                this.participants.unshift(participant);
            }

            if (!this.participants.find(p => p.id === participant.id)) {
                participant.fname = participant.name.split(' ')[0];
                participant.openProfileTooltip = this.$i18n('video_calls.labels.open_profile')
                    .replace('{{fname}}', participant.fname);
                this.participants.push(participant);
            }

            this.participants = uniq(this.participants, p => p.id);
        }

        /**
         * Removes a participant from the participants list
         *
         * @param {object} event the event passed on connection destroyed
         */
        removeParticipant(event) {
            const jsonData = get(event, 'connection.data');
            const participant = JSON.parse(jsonData);

            this.participants = this.participants.filter(p => p.id !== participant.id);
        }

        toggleSubscriberAudioVideo(stream, type, value) {
            this.subscribers = this.subscribers.map(sub => {
                if (sub.stream.streamId === stream.streamId) {
                    sub[type] = value;
                }
                return sub;
            });
        }

        toggleScreenSharing() {
            if (this.subscriberSharingStream) {
                this.showScreenshareBusyError = true;
                this.$timeout(() => {
                    this.showScreenshareBusyError = false;
                    this.$scope.$applyAsync();
                }, 5000);
                return;
            }
            if (!this.screenShareActive && !this.screenSharingCapabilityChecked) {
                OT.checkScreenSharingCapability(response => {
                    if (!response.supported || response.extensionRegistered === false) {
                        this.handleError(new Error('This browser does not support screen sharing.'));
                    } else {
                        this.screenShareActive = true;
                    }
                    this.screenSharingCapabilityChecked = true;
                });
            } else {
                this.screenShareActive = !this.screenShareActive;
                if (!this.screenShareActive) this.stopScreenShare();
            }
        }

        async startScreenShare() {
            this.setPages();
            const screenPreviewEl = this.$element.find('.screen-preview').get(0);

            if (this.subscriberSharingStream) {
                if (this.screenShareSubscriber) {
                    await this.screenShareSubscriber.destroy();
                    this.screenShareSubscriber = null;
                }
                this.screenShareSubscriber = new Subscriber({ triggerService: this.triggerService },
                    { session: this.session, stream: this.subscriberSharingStream });
                return await this.screenShareSubscriber.init(screenPreviewEl, this.eventEmitter);
            }

            const screenShareOptions = {
                videoSource: 'screen',
                videoContentHint: SCREENSHARE_CONTENT_HINT,
                insertMode: 'append',
                width: '100%',
                height: '100%',
                showControls: false,
                publishAudio: true,
                disableAudioProcessing: true,
                name: this.publisherName
            };

            if (this.enableScreenShareSimulcast) {
                screenShareOptions.scalableScreenshare = true;
            } else {
                screenShareOptions.maxResolution = MAX_SCREENSHARE_RESOLUTION;
            }

            this.screenSharePublisher = OT.initPublisher(screenPreviewEl, screenShareOptions, err => {
                if (err) {
                    const deniedBySystem = (err.originalMessage || '').toLowerCase().includes('by system')
                        || (err.message || '').toLowerCase().includes('by system');

                    if (deniedBySystem) {
                        this.showScreenShareErrorModal = true;
                    }
                    this.stopScreenShare();
                    return this.handleError(err);
                }
                this.screenSharePublisher.on('mediaStopped', this.stopScreenShare.bind(this));
                this.session.publish(this.screenSharePublisher, error => error && this.handleError(error));
                this.adaptSubscriberResolution();
            });
        }

        stopScreenShare() {
            this.screenShareActive = false;
            if (this.screenSharePublisher) {
                this.session.unpublish(this.screenSharePublisher);
                this.screenSharePublisher.destroy();
            }
            this.screenSharePublisher = null;
            this.setPages();
        }

        setVideoOn(value) {
            if (this.showOnboarding) {
                return;
            }

            const selectedVideoSourceId = get(this, 'mediaDevicesService.videoDeviceSelected.deviceId');

            this.videoOn = selectedVideoSourceId ? value : false;

            if (!this.publisher) {
                return this.$scope.$applyAsync();
            }

            this.publisher.publishVideo(this.videoOn);
            if (!this.videoOn) {
                return this.$scope.$applyAsync();
            }

            let effectiveVideoSourceId;
            try {
                effectiveVideoSourceId = this.publisher.getVideoSource().deviceId;
            } catch (e) {
                effectiveVideoSourceId = null;
            }
            const shouldSetVideoSource = effectiveVideoSourceId !== selectedVideoSourceId;

            if (shouldSetVideoSource) {
                this.$scope.$broadcast('publisher:updateVideoSource');
            }
        }

        setAudioOn(value) {
            if (this.showOnboarding) {
                return;
            }

            const selectedAudioSourceId = get(this, 'mediaDevicesService.audioDeviceSelected.deviceId');

            this.audioOn = selectedAudioSourceId ? value : false;

            if (!this.publisher) {
                return this.$scope.$applyAsync();
            }

            this.publisher.publishAudio(this.audioOn);
            if (!this.audioOn) {
                return this.$scope.$applyAsync();
            }

            let effectiveAudioSourceId;
            try {
                effectiveAudioSourceId = this.publisher.getAudioSource().getSettings().deviceId;
            } catch (e) {
                effectiveAudioSourceId = null;
            }
            const shouldSetAudioSource = effectiveAudioSourceId !== selectedAudioSourceId;

            if (shouldSetAudioSource) {
                this.$scope.$broadcast('publisher:updateAudioSource');
            }
        }

        changeAudioSource(device) {
            super.changeAudioSource(device);
            this.$scope.$applyAsync();
            this.$scope.$broadcast('publisher:updateAudioSource');
        }

        changeVideoSource(device) {
            super.changeVideoSource(device);
            this.$scope.$applyAsync();
            this.$scope.$broadcast('publisher:updateVideoSource');
        }

        toggleFullScreen(val = !this.fullScreen) {
            this.fullScreen = val;
            this.$element.removeAttr('style');
            if (this.fullScreen) {
                this.$element.css({
                    'z-index': 20 // To position it above the navbar
                });
                this.$element.addClass('full-screen');
                document.addEventListener('keydown', this.keyDownEscapeHandler);
            } else {
                this.$element.css({ zIndex: '' });
                this.$element.removeClass('full-screen');
                document.removeEventListener('keydown', this.keyDownEscapeHandler);
            }
        }

        reJoinVideoCall() {
            this.$eventBus.emit('restartCall');
        }

        createSessionListeners() {
            for (const listener of this.sessionListeners) {
                this.session.on(listener.event, listener.handler);
            }
        }

        removeSessionListeners() {
            this.session.off();
        }

        destroySession() {
            if (!this.session) return;
            this.removeSessionListeners();
            this.inCall = false;
            if (this.screenSharePublisher) this.session.unpublish(this.screenSharePublisher);
            if (this.publisher) {
                this.publisher.off();
                this.session.unpublish(this.publisher);
                this.publisher.destroy();
            }
            this.session.disconnect();
            this.callJoined = false;
            this.callEnded = true;
            this.lastInCall = this.session.streams.length() <= 1;
            this.metricsService.endTrack(this.videoCallDurationTrakingId, {});

            if (this.conversationId) {
                if (this.publisherPublished && !this.subscribers.length && this.lastInCall) {
                    this.sendAction('stop_video_call');
                } else {
                    this.sendBeacon('part_video_call');
                }
            }
            this.publisherPublished = false;

            // on some unknown conditions, the above session.disconnect() does not trigger this handler
            this.onSessionDisconnected();
        }

        async onSessionDisconnected(event) {
            this.subscribers = [];
            this.participants = [];
            this.pinnedParticipants = [];

            if (this.publisher) {
                this.publisher.off();
            }
            for (const subscriber of this.subscribers) {
                if (subscriber && subscriber.otSubscriberObject) {
                    await this.session.unsubscribe(subscriber.otSubscriberObject);
                }
            }
            this.removeSessionListeners();

            this.emitUpdate();

            if (event) {
                this.crashReportingService.reportError('Video call component', 'onSessionDisconnected', omit(event, [ 'target' ]));
            }

            this.showState('error');
            this.showReconnectionMessage = true;
            this.reconnectionCountdown = 10;
            this.addReconnectionCountdown = false;
            if (this.numberOfAutoReconnect < 5) {
                this.addReconnectionCountdown = true;
                this.$interval.cancel(this.reconnectionInterval);
                this.reconnectionInterval = this.$interval(() => {
                    this.reconnectionCountdown = this.reconnectionCountdown - 1;
                    if (this.reconnectionCountdown <= 0) {
                        this.numberOfAutoReconnect++;
                        this.reconnectNow();
                    }
                }, 1000);
            }
        }

        async reconnectNow() {
            this.$interval.cancel(this.reconnectionInterval);
            this.joinVideoCall();
        }

        /**
         * Handler used for handling user leaving the call
         */
        partVideoCall() {
            this.$eventBus.emit(`chat:stopcall:${this.conversationId}`);
            this.$eventBus.emit('video-pip:close');
            this.$eventBus.emit('join-call-button:show');
        }

        toggleParticipantsList() {
            this.showList = !this.showList;
        }

        /**
         * Performs the requested action and evaluate the response.
         *
         * @param {string} action the action to perform
         * @param {object} [extraParams] eventual extra params to send with the request
         *
         * @returns {Promise<Object>} the server response
         */
        sendAction(action, extraParams = {}) {
            if (!this.conversationId || !this.actions) return;
            const params = Object.assign({ conversation_id: this.conversationId }, extraParams);

            return this.dbService
                .runAppScript(this.actions[action].path, params)
                .then(({ data }) => {
                    if (data.error) {
                        console.error('[VideoCall] Cannot perform requested action', data.error);
                        return;
                    }

                    console.log('[VideoCall] Service response: ', data.response);

                    return data.response;
                })
                .finally(() => this.emitUpdate());
        }

        /**
         * Sends a request to the server and forget about the response.
         *
         * @param {string} action the action to perform on the server
         */
        sendBeacon(action) {
            if (!this.conversationId || !this.actions) return;
            const params = `params=${JSON.stringify({ conversation_id: this.conversationId })}`;
            navigator.sendBeacon(`/api/v1/eid/${this.eid}/appscripts/${this.actions[action].path}?${params}`);
            this.emitUpdate();
        }

        handleError(error, name) {
            if (!error && !name) {
                return;
            }
            console.error('[videoCall component error]', error, name);
            this.setLayout();
            if (name) {
                this.errorMessage = `video_calls.errors.${name}`;
                this.showState('error');
            }
            this.$scope.$applyAsync();
        }

        unsubscribeFromEvents() {
            for (const unsub of this.listeners) {
                if (isFunction(unsub)) {
                    unsub();
                }
            }
        }

        $onDestroy() {
            super.$onDestroy();
            OT.off('exception', this.OTexceptionHandler);
            if (this.publisher) {
                this.publisher.off();
            }
            this.destroySession();

            this.partVideoCall();
            this.unsubscribeFromEvents();
            this.videoCallModeratorService.unregisterListeners();
            document.removeEventListener('keydown', this.keyDownEscapeHandler);
            window.onbeforeunload = () => {};

            this.$interval.cancel(this.sanityCheckInterval);
            this.$interval.cancel(this.reconnectionInterval);
        }

        emitUpdate() {
            this.$scope.$applyAsync();
            if (this.conversationId) this.$eventBus.emit(`chat:updatecall:${this.conversationId}`, this.inCall);
        }

        showState(state) {
            if (state === 'onboarding') {
                this.showSpinner = false;
                this.showCall = false;
                this.fixPipSize(true);
            } else if (state === 'call') {
                this.showOnboarding = false;
                this.showCall = true;
                this.showSpinner = false;
                this.fixPipSize(false);
            } else if (state === 'error') {
                this.showOnboarding = false;
                this.showSpinner = false;
                this.showCall = false;
                this.fixPipSize(true);
            } else {
                this.showSpinner = true;
                this.showCall = false;
                this.showOnboarding = false;
                this.fixPipSize(true);
            }
        }

        fixPipSize(val) {
            if (val) {
                this.videoCallService.addPipClass('fixed-size');
            } else {
                this.videoCallService.removePipClass('fixed-size');
            }
        }

        showScreenPreview() {
            return this.session && this.session.connection && !this.embeddedUrl && this.screenShareActive;
        }

        angularizeSessionListeners(session) {
            const on = session.on;
            session.on = (type, handler, context) => {
                on(type, async (...args) => {
                    await handler(...args);
                    this.$scope.$applyAsync();
                }, context);
            };
            return session;
        }

        getNumberOfUserPerPage() {
            let usersPerPage = USERS_PER_PAGE;
            if (this.screenShareActive) {
                usersPerPage = USERS_PER_SHARE_SCREEN_PAGE;
            }
            return usersPerPage;
        }
    }
};
