import { isFunction, isObject } from 'lodash';
import { BaseService } from './baseService';

const FRAME_MARGINS = 15;

export default class VideoPipService extends BaseService {
    /* @ngInject */
    constructor($document, $eventBus, $location, $rootScope, $http, navService) {
        super($http);

        this.$eventBus = $eventBus;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.navService = navService;
        this.$document = $document;

        this.pipListeners = [];

        this.videoElement = null;

        this.windowResizeListener = () => {
            if (!this.videoElement) {
                return;
            }
            const x = parseInt(this.videoElement.css('left'), 10) || window.innerWidth;
            const y = parseInt(this.videoElement.css('top'), 10) || window.innerHeight;
            this.setPosition(x, y);
            this.snapToSide();
        };

        this.reset();
    }

    init(videoElement, { scope, width, height } = {}) {
        this.$eventBus.emit('video-pip:close');
        // TODO: access to the pipWrapper component should maybe be extracted to yet another service, or made static + static methods when we drop IE support
        // at the time of this writing, this service in inherited by 3 other services, videoCall, videoBreakout(through videoCall), and pictureInPicture
        this.pipWrapper = this.$document.find('#pip-wrapper');
        this.show();

        this.originalNav = this.navService.getActiveNav();

        this.originalLocation = this.$location.url();

        this.videoElement = videoElement;
        this.videoElementScope = scope;

        this.pipWrapper.append(videoElement);
        window.scrollTo(0, 0);

        width = Number.parseInt(width) || 320;
        height = Number.parseInt(height) || 180;
        this.pipSize = { width, height };

        const navChange = this.$eventBus.on('$navChange', nav => {
            if (this.shouldFloat(nav)) {
                this.float();
            }
        });

        const locationChange = this.$rootScope.$on('$locationChangeSuccess', () => {
            if (this.$location.url() === this.originalLocation) {
                this.embed();
            }
        });

        const videoReopen = this.$eventBus.on('video-pip:reopen-nav', (id) => {
            if (isObject(this.originalNav)) {
                this.navService.openNav(this.originalNav);
            } else if (this.originalLocation && (!id || this.originalLocation.includes(id))) {
                this.$location.url(this.originalLocation);
            } else {
                this.embed();
            }
        });

        const videoClose = this.$eventBus.on('video-pip:close', () => this.stop());

        this.pipListeners.push(
            videoClose,
            navChange,
            videoReopen,
            locationChange
        );

        this.videoElement.off('mousedown');
        this.videoElement.on('mousedown', e => {
            if (!this.videoElement.is('.pinned') || this.videoElement.is('.maximized')) return;
            if (
                e.target.tagName === 'INPUT' ||
                e.target.tagName === 'BUTTON'
            )
                return;
            e.preventDefault();
            this.startX = e.clientX - this.videoElement[0].offsetLeft;
            this.startY = e.clientY - this.videoElement[0].offsetTop;
            this.$document.on('mousemove.pip', ev => this.onMouseMove(ev));
            this.$document.on('mouseup.pip', ev => this.onMouseUp(ev));
        });

        this.started = true;
        this.isFloating = false;
        this.$eventBus.emit('video-pip:started');
    }

    reset() {
        this.originalNav = null;
        this.originalLocation = null;
        if (this.videoElement) {
            this.onMouseUp();
            this.videoElement.remove();
        }
        this.videoElement = null;
        this.isFloating = false;
        for (const unsub of this.pipListeners) {
            if (isFunction(unsub)) unsub();
        }
        this.pipListeners = [];
        window.removeEventListener('resize', this.windowResizeListener);
        if (this.pipWrapper) {
            this.pipWrapper.innerHTML = '';
            this.pipWrapper.removeClass();
        }
        this.pipWrapper = null;
    }

    stop() {
        this.embed();
        this.reset();
    }

    isDifferentLocation() {
        return this.$location.url() !== this.originalLocation;
    }

    shouldFloat(nav = this.navService.getActiveNav()) {
        if (!this.videoElement) {
            return true;
        }

        const isNotModal = !nav.modal && !nav.fullscreen;

        const isDifferentLocation = this.isDifferentLocation();

        const shouldFloat = isNotModal && isDifferentLocation;

        console.log('[videoPipService] shouldFloat ?', shouldFloat, { isNotModal, isDifferentLocation });
        return shouldFloat;
    }

    embed() {
        this.isFloating = false;
        if (this.videoElementScope) {
            this.videoElementScope.$broadcast('video-pip:embed');
        }
        if (this.pipWrapper) {
            this.pipWrapper.removeClass('detached');
        }
        if (this.videoElement) {
            this.videoElement.removeClass('pinned');
            this.videoElement.removeAttr('style');
        }
        window.removeEventListener('resize', this.windowResizeListener);
    }

    float() {
        if (!this.pipWrapper) {
            return;
        }

        this.isFloating = true;
        if (this.videoElementScope) {
            this.videoElementScope.$broadcast('video-pip:float');
        }
        this.pipWrapper.addClass('detached');
        this.videoElement.addClass('pinned');

        this.setPosition(window.outerWidth, window.outerHeight);
        this.snapToSide();
        window.addEventListener('resize', this.windowResizeListener);
    }

    hide() {
        this.pipWrapper.addClass('hidden');
    }

    show() {
        this.pipWrapper.removeClass('hidden');
    }

    addPipClass(c) {
        if (this.pipWrapper) {
            this.pipWrapper.addClass(c);
        }
    }

    removePipClass(c) {
        if (this.pipWrapper) {
            this.pipWrapper.removeClass(c);
        }
    }

    onMouseMove(e) {
        const x = e.clientX - this.startX;
        const y = e.clientY - this.startY;
        this.videoElement.addClass('dragging');
        this.setPosition(x, y);
    }

    onMouseUp() {
        this.$document.off('mousemove.pip');
        this.$document.off('mouseup.pip');
        this.videoElement.removeClass('dragging');
        this.snapToSide();
    }

    setPosition(x, y) {
        if (!this.videoElement || !this.isFloating) {
            return;
        }

        let width = this.pipSize.width;
        let height = this.pipSize.height;

        if (!this.controlsHeight) {
            this.controlsHeight = this.videoElement.find('.controls').height() || 0;
        }

        height = height + this.controlsHeight;

        requestAnimationFrame(() => {
            if (!this.videoElement) {
                return;
            }

            if (width && height) {
                this.videoElement.css({ width, height });
            }

            // places element within bounds
            x = Math.max(FRAME_MARGINS, Math.min(window.innerWidth - FRAME_MARGINS - width, x));
            y = Math.max(FRAME_MARGINS + this.controlsHeight, Math.min(window.innerHeight - FRAME_MARGINS - height, y));

            this.videoElement.css({
                top: y + 'px',
                left: x + 'px'
            });
        });
    }

    snapToSide() {
        requestAnimationFrame(() => {
            if (!this.videoElement || !this.isFloating) {
                return;
            }

            const x = this.videoElement[0].offsetLeft + this.pipSize.width / 2;
            const w = window.innerWidth;
            const side = x > w / 2 ? 'right' : 'left';
            let newX = side === 'right' ? w - FRAME_MARGINS - this.pipSize.width : FRAME_MARGINS;

            if (newX < FRAME_MARGINS) {
                newX = FRAME_MARGINS;
            }

            this.videoElement.css({ left: newX });
        });
    }
}
