export const NavHeaderComponent = {
    template: require('./nav-header.jade'),
    bindings: {
        parentNavId: '<',
        label: '<',
        actions: '<',
        toolbar: '<',
        banner: '<'
    },
    transclude: true
};
