// Special thanks to Backstage for inspiration
class HandlerService {
    /* @ngInject */ constructor(
        EID,
        BACKEND_VERSION,
        $http,
        $httpParamSerializerJQLike,
        progressBarService
    ) {
        this.EID = EID;
        this.$http = $http;
        this.progressBarService = progressBarService;

        this.generateHandlerUrl = (handler, params) => {
            if (
                handler === 'webapp/doc' &&
                params &&
                params['id'] &&
                params['attachment']
            ) {
                return `/api/v1/eid/${EID}/webapp/${params['id']}/${params['attachment']}`;
            }
            params = params ? '?' + $httpParamSerializerJQLike(params) : '';
            return `/api/v1/eid/${EID}/nodehandlers/${handler}${params}`;
        };

        // this generates direct backend API endpoints URL for those handlers that have been ported to the backend
        this.generateBackendUrl = (handler, params) => {
            params = params
                ? '?' + $httpParamSerializerJQLike(params.params || params)
                : '';
            return `/api/v1/eid/${EID}/${handler}${params}`;
        };
    }

    callHandlerRaw(method, handler, config = {}, isHandler = true) {
        // deprecate jsonp
        if (method.toLowerCase().trim() === 'jsonp') {
            method = 'GET';
            console.warn(
                '[HandlerService/callHandlerRaw] JSONP is deprecated, use GET instead.'
            );
            if (!config.headers) config.headers = {};
            config.headers['json'] = true;
            // handler will still check something for the callback argument, so provide a fake one
            if (!config.params) config.params = {};
            config.params.callback = 'cb';
        }
        config.method = method;
        config.url = isHandler
            ? this.generateHandlerUrl(handler)
            : this.generateBackendUrl(handler);

        if (config.params && config.params._cache === false) {
            config.cache = false;
            delete config.params._cache;
        }

        return this.$http(config);
    }

    callHandler(handler, params = {}, silent = false) {
        const { progressBarService } = this;

        if (!silent) progressBarService.startTask();

        return this.callHandlerRaw('GET', handler, { params })
            .then(
                ({ data }) => data,
                reason => {
                    console.error(
                        `[HandlerService] Could not call handle ${handler}`,
                        reason
                    );
                    // still failing
                    throw reason;
                }
            )
            .finally(() => silent || progressBarService.finishTask());
    }

    callBackend(endpoint, params = {}, silent = false) {
        const { progressBarService } = this;

        if (!silent) progressBarService.startTask();

        return this.callHandlerRaw('GET', endpoint, { params }, false)
            .then(
                ({ data }) => data,
                reason => {
                    console.error(
                        `[HandlerService] Could not call backend ${endpoint}`,
                        reason
                    );
                    // still failing
                    throw reason;
                }
            )
            .finally(() => silent || progressBarService.finishTask());
    }
}

angular.module('maestro.services').service('handlerService', HandlerService);
