import { isSessionPast, isSessionLive } from '../../../../../utils/agenda-sessions';

export const RegularRowComponent = {
    bindings: {
        row: '<'
    },
    template: require('./regular-row.jade'),
    controller: class RegularRowComponent {
        /* @ngInject */
        constructor(navService) {
            this.navService = navService;
        }

        $onInit() {
            this.isLive = isSessionLive(this.row);
            this.isPast = isSessionPast(this.row);
        }

        performActions() {
            this.navService.performActions(this.row.actions);
        }
    }
};
