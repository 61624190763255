import { extend } from 'lodash';

class DsService {
    /* @ngInject */
    constructor($q, progressBarService, databaseService) {
        this.$q = $q;
        this.progressBarService = progressBarService;
        this.databaseService = databaseService;
    }

    eval(ds, paramOverrides) {
        if (!ds.type) return this.$q.reject('missing ds.type');

        const evaluatorKey = `eval_${ds.type}`;
        if (typeof this[evaluatorKey] !== 'function')
            return this.$q.reject(`unknown ds.type: ${ds.type}`);

        console.log(
            '[dataSourceEvaluatorService] evaluating',
            ds.type,
            ds.source
        );

        return this[evaluatorKey](ds, paramOverrides);
    }

    eval_javascript({ source: { path, params = {} } }, paramOverrides) {
        if (paramOverrides) {
            params = extend({}, params, paramOverrides);
        }

        this.progressBarService.startTask();

        return this.databaseService
            .runAppScript(path, params)
            .then(({ data }) => {
                if (data.status !== 'ok') return this.$q.reject(data.response);
                return data.response;
            })
            .finally(() => this.progressBarService.finishTask());
    }
}

angular
    .module('maestro.services')
    .service('dataSourceEvaluatorService', DsService);
