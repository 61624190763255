module.exports = '<style>.bg-wrapper {\n' +
'  background-color: {{ ::$ctrl.THEME.spotman.view.background_color }};\n' +
'  color: {{ ::$ctrl.THEME.spotman.view.font_color }};\n' +
'}\n' +
'\n' +
'.bg-wrapper .banner,\n' +
'.bg-wrapper .user-info .empty-avatar,\n' +
'.bg-wrapper .user-info .avatar > img {\n' +
'  background-color: {{ ::$ctrl.THEME.primary_color }};\n' +
'}\n' +
'\n' +
'.bg-wrapper .user-info .nav-list-item-title {\n' +
'  color: {{ ::$ctrl.THEME.get(\'navs.nav_spotman.profilerow_nav.title.font_color\') }};\n' +
'}\n' +
'\n' +
'.bg-wrapper .user-info .nav-list-item-subtitle {\n' +
'  color: {{ ::$ctrl.THEME.get(\'navs.nav_spotman.profilerow_nav.subtitle.font_color\') }};\n' +
'}\n' +
'</style><div class="bg-wrapper no-click-effect" ng-click="$ctrl.performActions()" ng-class="{&quot;with-bg&quot;: $ctrl.bannerUrl, &quot;no-actions&quot;: !$ctrl.config.actions.length}"><img class="banner" ng-if="$ctrl.bannerUrl" ng-src="{{$ctrl.bannerUrl | assetUrl}}"/><div class="user-info"><avatar ng-if="$ctrl.showPhoto" object="$ctrl.person" mode="important" watch="true"></avatar><div class="nav-list-item-title">{{$ctrl.person.full_name}}</div><div class="nav-list-item-subtitle">{{$ctrl.subtitle}}</div></div></div>';