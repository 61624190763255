const deprecate = (a, b, c) =>
    console.warn(
        '[LayoutManager] This module is deprecated, please advise.',
        a,
        b,
        c
    );

function VerticalLayoutDirective() {
    let link = deprecate;

    return {
        restrict: 'AC',
        priority: 0,
        link
    };
}

angular.module('maestro.layoutmanager', []).service('$layout', () => {
    return {
        update: deprecate
    };
});
angular
    .module('maestro.directives')
    .directive('vLayout', VerticalLayoutDirective);
