
export class BaseService {
    constructor($http) {
        this.$http = $http;

        this.GETrequestCache = {};
    }

    getCachedOrMakeRequest(url, expires) {
        if (this.GETrequestCache[url]) {
            return this.GETrequestCache[url];
        }
        const promise = this.$http.get(url);
        this.GETrequestCache[url] = promise;
        setTimeout(() => {
            delete this.GETrequestCache[url];
        }, expires);
        return promise;
    }

    /**
     * Async HTTP post request with retries
     *
     * @param {string} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Number} retries the number of retries before giving up
     * @param {Number} delay the delay in ms between each retries
     *
     * @returns {Promise<Object>} the server response
     */
    async postWithRetries(url, data, retries = 3, delay = 1000) {
        const makeRequest = (retries) => this.$http.post(url, data)
            .catch(err => {
                if (retries < 1) {
                    throw err;
                }
                if (err && err.status === 409) {
                    console.info('Post request failed because of a conflict, retrying');
                } else {
                    console.error('Post request failed because of an unknown error, retrying', err);
                }
                return this.timeout(() => makeRequest(retries - 1), delay);
            });
        return makeRequest(retries);
    }

    /**
     * timeout function that returns the result of the callback function
     * @param {Function} callback
     * @param {Number} delay
     * @return {Promise<unknown>}
     */
    async timeout(callback, delay) {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const res = await callback().catch((e) => {
                    return reject(e);
                });
                resolve(res);
            }, delay);
        });
    }
}
