import { isSessionLive } from '../../../../../utils/agenda-sessions';

export const BannerRowComponent = {
    bindings: {
        row: '<'
    },
    template: require('./banner-row.jade'),
    controller: /* @ngInject */ function(navService) {
        this.$onInit = () => {
            this.isLive = isSessionLive(this.row);
        };
        this.$onChanges = changes => {
            if (changes.row && changes.row.currentValue) {
                this.row = angular.copy(this.row);
            }
        };
        this.performRowActions = () => {
            navService.performActions(this.row.actions);
        };
    }
};
