import { isFunction } from 'lodash';

class TriggerService {
    /* @ngInject */
    constructor() {
        window.triggers = {};
        this.handlers = {};
    }

    /**
     * add a global access to the passed error triggering function
     * @param name
     * @param fun
     */
    addTrigger(name, fun) {
        if (!isFunction(fun)) {
            return;
        }
        if (!this.handlers[name]) {
            this.handlers[name] = [];
        }
        this.handlers[name].push(fun);

        window.triggers[name] = (...arg) => {
            for (const handler of this.handlers[name]) {
                if (isFunction(handler)) {
                    setTimeout(() => handler(...arg));
                }
            }
            console.log(`successfully triggered ${name}`);
        };

        return () => { this.removeTrigger(name, fun); };
    }

    /**
     * remove the global access of an error triggering function
     * @param name
     * @param fun
     */
    removeTrigger(name, fun) {
        this.handlers[name] = this.handlers[name].filter((o) => {
            return o !== fun;
        });
    }
}

angular
    .module('maestro.services')
    .service('triggerService', TriggerService);
