// removes all inherited properties and properties that start with $/$$ (angular stuffs)
export default function(obj) {
    let noMagic = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && key.indexOf('$') !== 0) {
            noMagic[key] = obj[key];
        }
    }
    return noMagic;
}
