/**
 * Utils to add an event listener to a DOM element, and return a function to remove it.
 * @param {Element | Document | Window} element
 * @param {String} event
 * @param {Function} handler
 * @returns {Function}
 */
export function addEventListener(element, event, handler) {
    element.addEventListener(event, handler);
    return () => element.removeEventListener(event, handler);
}


/**
 * replaces the meta referrer attribute
 * @param newReferrer
 * @return {*}
 */
export function replaceReferrer(newReferrer) {
    console.log('[DOMUtils] replacing meta referrer content with ', newReferrer);
    const ref = document.querySelector('meta[name="referrer"]');
    const prevReferrerValue = ref.attributes.content.value;
    ref.attributes.content.value = newReferrer;
    return prevReferrerValue;
}

/**
 * exits a potentially started native fullscreen
 */
export function exitNativeFullscreen() {
    if (!document.fullscreenElement) {
        return;
    }

    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
    }
}
