export const ImageMediumComponent = {
    bindings: {
        media: '<',
        load: '<',
        zoomed: '<'
    },
    template: require('./image.jade'),
    controller: class ImageMedium {
        /* @ngInject */
        constructor(navService, loadAssetImg, $element, $scope) {
            this.navService = navService;
            this.loadAssetImg = loadAssetImg;
            this.element = $element;
            this.$scope = $scope;
            this.loading = true;
        }

        $onInit() {
            this.image = this.element.find('img');
            if (this.zoomed) {
                this.load = this.media.url;
            }
            this.loadImage(this.load || this.media.thumbnail);
        }

        zoom() {
            if (this.zoomed) {
                return;
            }

            this.loadImage(this.media.url, () => {
                this.$scope.$emit('startZoom', {
                    element: this.element,
                    media: this.media
                });
            });
        }

        loadImage(url, callback) {
            this.loading = true;

            this.loadAssetImg(this.image, url, () => {
                this.loading = false;
                this.$scope.$apply();

                if (callback && typeof callback === 'function') {
                    callback();
                }
            });
        }

        performActions() {
            console.info('[ImageMedium] performActions');
            this.navService.performActions(this.media.actions);
        }
    }
};
