import { toQueryString } from '../utils/url';
import Primus from 'primus';
import EventEmitter from '../utils/event-emitter';
import { isEmpty, isFunction, isString } from 'lodash';
import { BaseService } from './baseService';

const MAX_RETRIES = 6; // number of times Primus will try to reconnect after an issue
const TIMEOUT = 15000; // Timeout before Primus abandon a connection attempt
const MIN_DELAY = 500; // delay before Primus starts a new reconnection attempt, will be doubled on each retry (+ some randomness)
const approxMaxRetryDelay = MIN_DELAY * (Math.pow(2, MAX_RETRIES) - 1);
const DEFAULT_RESTART_JITTER = 5 * 1000;
const DEFAULT_RESTART_DELAY = 10 * 1000;

/**
 * Provides utils for managing signal messages.
 *
 * We have now 'timeout' in the reconnection strategy, without there were no retries if the initial connection failed.
 * Primus is discouraging this for ws with auth but we suspect this to have prevented some users to properly connect.
 *
 * With this in place, the following behavior is observed (not all events are reported, and onInitError is our own event emitted on Primus' 'error'):
 *
 * If BE refuses the Auth, or if the query is wrong (i.e. source: 'abc'):
 * 'onInitError' event -> x retries showing an error in the console without emitting events -> 'end' event
 *
 * If BE takes too long to authorize (can be tested by changing TIMEOUT to 1):
 * 'timeout' event -> x retries + 'reconnect timeout' event -> 'end' event -> 'reconnect fail' event -> 'onInitError' event
 *
 * If user goes offline:
 * 'offline' event -> 'close' event -> 'end' event -> no retries, waiting to be online again -> 'online' event -> 'reconnected' event -> 'open' event
 *
 * If connection is permanently broken after a successful connection (i.e. BE goes down):
 * 'close' event -> x retries showing an error in the console without emitting events -> 'end' event
 *
 * If BE takes inexplicably long to authorize but still does after a long time, this one is a bit weird but Primus recovers after emitting 'end', even though the docs
 * stipulate 'When this event is emitted you should consider your connection to be fully dead with no way of reconnecting'
 * This makes the UI a bit inconsistent and may display the signal error, but then make it disappear (can be tested with a delay on BE auth):
 * 'close' event -> x retries showing an error in the console without emitting events -> 'end' event -> 'open' event
 *
 * If connection is temporarily broken (can be tested by restarting BE):
 * 'close' event -> x retries showing an error in the console without emitting events -> 'reconnected' event -> 'open' event
 *
 * @example
 * import SignalService from 'libs/services/signal';
 * ...
 * const signal = new SignalService();
 */
export default class SignalService extends BaseService {

    /* @ngInject */
    constructor($http, $timeout, databaseService) {
        super($http);

        this.databaseService = databaseService;

        this.appScriptListeners = {};
        this.isConnected = false;

        this.signalEmitter = new EventEmitter($timeout);
        this.eventEmitter = new EventEmitter($timeout);
    }

    /**
     * Initialize the service for the given event
     *
     * @param {String} eid the event id to connect the socket to
     */
    init(eid) {
        console.log('[SignalService] init', eid);
        this.eid = eid;

        if (this.primus) {
            try {
                this.primus.destroy();
            } catch (e) {
                console.log('[SignalService] could not destroy previous instance', e);
            }
            this.primus = null;
        }

        clearTimeout(this.restartPrimusTimeout);
        this.restartPrimusTimeout = null;

        const query = {
            source: 'webApp',
            eid
        };

        this.primus = new Primus(`${window.location.origin}?${toQueryString(query)}`, {
            // Initial connection timeout
            timeout: TIMEOUT,
            // This looks like the default but it's not, since we have BE ws auth, the 'timeout' isn't part of the default strategy
            // 'timeout' will most importantly induce a reconnect if the initial connection throws an error, or times out
            strategy: 'disconnect,online,timeout',
            reconnect: {
                'reconnect timeout': TIMEOUT,
                retries: MAX_RETRIES,
                min: MIN_DELAY
            }
        });

        this.primus.on('open', () => this.onInitOpen());
        this.primus.on('error', (err) => this.onInitError(err));
        this.primus.on('timeout', (err) => this.onInitError(err));
        this.primus.on('data', (data) => this.onData(data));

        const genericEventTypes = [ 'close', 'disconnection', 'open', 'reconnect', 'reconnected', 'reconnect failed', 'timeout', 'end', 'data' ];
        for (const type of genericEventTypes) {
            this.primus.on(type, (err) => this.onPrimusGenericEvent(type, err));
        }
    }

    /**
     * Adds the specified listener to the given signal type pool
     *
     * @param {String} signalType the type of the signal to add the listener to
     * @param {Function} listener the listener to add
     */
    async addSignalListener(signalType, listener) {
        if (!isFunction(listener)) {
            return console.error('[SignalService] listener must be a function');
        }
        try {
            await this.primus.write({
                type: 'registerToSignal',
                signalType
            });

            this.signalEmitter.addEventListener(signalType, listener);

            return () => this.removeSignalListener(signalType, listener);
        } catch (error) {
            console.error('[SignalService] An error occurred while adding listener', error);
        }
    }

    /**
     * Removes the specified listener from the given signal type pool
     *
     * @param {String} signalType the type of the signal to remove the listener from
     * @param {Function} listener the listener to remove
     */
    async removeSignalListener(signalType, listener) {

        this.signalEmitter.removeEventListener(signalType, listener);

        if (!this.signalEmitter.hasListeners(signalType)) {
            try {
                await this.primus.write({
                    type: 'unregisterFromSignal',
                    signalType
                });
            } catch (error) {
                console.error('[SignalService] An error occurred while removing listener', error);
            }
        }
    }

    /**
     * Sends a signal through the bus.
     *
     * @param {Object} signal the signal payload to send
     */
    async sendSignal(signal) {
        console.log('[SignalService] Sending signal', signal);
        try {
            await this.primus.write({ type: 'sendSignal', signal });
        } catch (error) {
            console.error('[SignalService] An error occurred while sending signal', error);
        }
    }

    /**
     * Adds event listener
     *
     * @param {string} eventType type of an event (for now only 'reconnected' and 'close' events are supported)
     * @param {Function} listener function to be called
     */
    addEventListener(eventType, listener) {
        return this.eventEmitter.addEventListener(eventType, listener);
    }

    /**
     * Remove event listener
     *
     * @param {string} eventType type of event (for now only 'reconnected' and 'close' events are supported)
     * @param {Function} listener
     */
    removeEventListener(eventType, listener) {
        return this.eventEmitter.removeEventListener(eventType, listener);
    }

    /**
     * Calls event listeners for a specific event.type
     *
     * @param {object} event
     */
    triggerEvent(event) {
        this.eventEmitter.emit(event.type, event);
    }

    /* =============== */
    /* Events handlers */
    /* =============== */

    /**
     * Removes handlers from the socket
     *
     * @private
     */
    offInitListeners() {
        this.primus.off('open', this.onInitOpen);
        this.primus.off('error', this.onInitError);
        this.primus.off('timeout', this.onInitError);
    }

    /**
     * Handler called when the initialization succeeds
     *
     * @private
     */
    onInitOpen() {
        console.info('[SignalService] connected');
        this.offInitListeners();
        this.primus.on('error', (err) => this.onPrimusGenericEvent('error', err));
        this.primus.on('timeout', (err) => this.onPrimusGenericEvent('timeout', err));
    }

    /**
     * Handler called when socket initialization fails
     *
     * @param {Error} error the error to rise
     *
     * @private
     */
    onInitError(error) {
        console.error(`[SignalService] signal initialization error, we will retry ${MAX_RETRIES} times for approx. ${approxMaxRetryDelay / 1000}s`, error);
        this.isConnected = false;
        this.triggerEvent({ type: 'onInitError', error });
    }

    /**
     * Handler called when data is received through the socket
     *
     * @param {Object} data received data
     *
     * @private
     */
    onData(data) {
        const { type, signal } = data;

        if (type === 'signal' && signal && isString(signal.type) && !isEmpty(signal.type)) {
            this.signalEmitter.emit(signal.type, signal);
        }
    }

    /**
     * if the ws is disconnected, we have to re register to all the signals on open
     */
    addBackAllExistingSignals() {
        Object.keys(this.signalEmitter.getListeners()).forEach(signalType => {
            this.primus.write({
                type: 'registerToSignal',
                signalType
            });
        });
    }

    /**
     * Handler called when primus emits an event
     *
     * @param {String} type the event type
     * @param {Error} error the error to rise
     *
     * @private
     */
    onPrimusGenericEvent(type, error) {
        console.debug(`[SignalService] ${type} event`, error);
        if (type === 'open') {
            clearTimeout(this.restartPrimusTimeout);
            this.restartPrimusTimeout = null;
            this.addBackAllExistingSignals();
            this.isConnected = true;
        } else if ([ 'close', 'timeout', 'end' ].includes(type)) {
            this.isConnected = false;
        }

        this.triggerEvent({ type, error });
    }

    /**
     * restart primus
     * @param {Number} delay delay before restarting
     * @param {Number} jitter additional random delay before restarting
     */
    restart({ delay = DEFAULT_RESTART_DELAY, jitter = DEFAULT_RESTART_JITTER } = {}) {
        if (this.restartPrimusTimeout) {
            return;
        }

        this.restartPrimusTimeout = setTimeout(() => {
            this.restartPrimusTimeout = null;
            console.error('[SignalService] connection is dead, initiating it again');
            this.init(this.eid);
        }, delay + Math.floor(Math.random() * jitter));
    }

    /**
     * /!\ this method is never called from the webapp directly, but from packages
     * /!\ search for addAppscriptSignalListener in BE
     * @param args
     * @return {Promise<Function>}
     */
    async addAppscriptSignalListener(args) {
        const [ signalType, appscriptPath ] = args;
        const key = `${signalType}-${appscriptPath}`;
        const listener = signal =>
            this.databaseService.runAppScript(appscriptPath, signal);

        this.appScriptListeners[key] = listener;

        return await this.addSignalListener(signalType, listener);
    }

    async removeAppscriptSignalListener(args) {
        const [ signalType, appscriptPath ] = args;
        const key = `${signalType}-${appscriptPath}`;
        const listener = this.appScriptListeners[key];

        if (!listener) {
            return;
        }

        delete this.appScriptListeners[key];

        return await this.removeSignalListener(signalType, listener);
    }
}
