import { bestFitGenerator, getPositions, getPositionsForScreenShare } from './BroadcastLayoutGenerator.js';

/**
 * @constant BEST_FIT_VALUES contains the values for the different 'bestFit' layout variants,
 * the format is [width, height, positions] where positions is the [top, left] coord of each stream
 */
const BEST_FIT_VALUES = [
    getPositions(77.50, null, { x: 1, y: 1.777 }, [ 1 ]),
    getPositions(43.75, null, { x: 1, y: 1.777 }, [ 2 ]),
    getPositions(43.75, null, { x: 1, y: 1.777 }, [ 2, 1 ]),
    getPositions(43.75, null, { x: 1, y: 1.777 }, [ 2, 2 ]),
    getPositions(31.25, null, { x: 1, y: 1.777 }, [ 3, 2 ]),
    getPositions(31.25, null, { x: 1, y: 1.777 }, [ 3, 3 ]),
    getPositions(31.25, null, { x: 1, y: 1.777 }, [ 3, 3, 1 ]),
    getPositions(31.25, null, { x: 1, y: 1.777 }, [ 3, 3, 2 ]),
    getPositions(31.25, null, { x: 1, y: 1.777 }, [ 3, 3, 3 ]),
    getPositions(23.00, null, { x: 1, y: 1.777 }, [ 4, 4, 2 ]),
    getPositions(23.00, null, { x: 1, y: 1.777 }, [ 4, 4, 3 ]),
    getPositions(23.00, null, { x: 1, y: 1.777 }, [ 4, 4, 4 ])
];

/**
 * @constant SHARE_VALUES contains the values for the different 'share' layout variants,
 * the format is [width, height, positions] where positions is the [top, left] coord of each *non-screen* stream
 */
const SHARE_VALUES = [
    getPositionsForScreenShare(15, null, 0.8, { x: 10, y: 1 }, 1),
    getPositionsForScreenShare(15, null, 0.8, { x: 10, y: 1 }, 2),
    getPositionsForScreenShare(15, null, 0.8, { x: 10, y: 1 }, 3),
    getPositionsForScreenShare(15, null, 0.8, { x: 10, y: 1 }, 4),
    getPositionsForScreenShare(15, null, 0.8, { x: 10, y: 1 }, 5),
    getPositionsForScreenShare(15, null, 0.8, { x: 10, y: 1 }, 6),
];

function bestFit(n) {
    return bestFitGenerator(BEST_FIT_VALUES, n);
}

function share(n) {
    return bestFitGenerator(SHARE_VALUES, n);
}

export {
    bestFit,
    share,
    BEST_FIT_VALUES,
    SHARE_VALUES
};
