import { throttle, isFunction } from 'lodash';
const SCROLL_THROTTLE_TIMEOUT = 120;
const BOTTOM_TRESHOLD = 600;
const DEFAULT_ITEM_HEIGHT = 70;

const OnBottomReachedDirective = /* @ngInject */ function($timeout, $window) {
    const $$window = $($window);
    let link = function(scope, elem) {
        const scrollTarget = $$window;
        let itemHeight = 0;

        const checkScroll = function() {
            const scrollPosition = window.innerHeight + window.pageYOffset;
            if (scrollPosition >= document.body.offsetHeight - BOTTOM_TRESHOLD) {
                if (!itemHeight) {
                    itemHeight = elem.find('div:eq(0)').height() || 0;
                }

                let height = elem.height();

                $timeout(() =>
                    scope.onBottomReached({
                        optimalCount: Math.round(height / (itemHeight || DEFAULT_ITEM_HEIGHT)) * 2
                    })
                );
            }
        };

        const onScroll = throttle(checkScroll, SCROLL_THROTTLE_TIMEOUT);

        scrollTarget.on('scroll', ev => {
            if (!elem.is(':visible')) return;

            onScroll(ev);

            // `scrolled` binding is a function that returns the bound function
            const scrolledCallback = scope.scrolled && scope.scrolled();

            if (isFunction(scrolledCallback)) {
                // We call back the scrolled function to inform the wrapping
                // controller that the element has scrolled and it might not be
                // at the top anymore.
                // NOTE: since we are in an isolated scope, we have to pass through the
                // original context by using `call(...)`.
                scrolledCallback.call(
                    scope.$parent.$ctrl,
                    window.pageYOffset === 0
                );
            }
        });

        scope.$on('$destroy', () => scrollTarget.off('scroll', onScroll));
    };

    return {
        restrict: 'AC',
        scope: {
            onBottomReached: '&',
            scrolled: '&',
            scrollsInBody: '<'
        },
        link
    };
};

angular
    .module('maestro.directives')
    .directive('onBottomReached', OnBottomReachedDirective);
