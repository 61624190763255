const { replaceHostIfHasWhiteLabelDomain } = require('../utils/url');

const SESSION_ENDPOINT = '/api/v1/webapp/session?eid={eid}';

const getUserSession = (options, $http) => {
    // format of window.location.pathname:
    //  - '{eid}/home', '/{eid}/sessions/{sessionId}', ... -> new urls with webapp sessions
    //  - '/{somePrefix}/{eid}/home', '/{somePrefix}/{eid}/sessions/{sessionId}', ... -> urls when used on local nodes
    // the 3rd ones are in fact very similar to the first, so the session handler
    // will have to try to get the event with eid (first 32char block found) to define if this is
    // case 1 or case 2
    const pathname = window.location.pathname;
    const { prefix, eid } = pathname.split('/').reduce(
        (previous, current) => {
            // pathname start with /, so this handles the first /
            if (!current) return previous;
            if (previous.eid) return previous;
            if (/^[\w]{32}$/.test(current)) {
                previous.eid = current;
            } else {
                previous.prefix += `/${current}`;
            }
            return previous;
        },
        { prefix: '' }
    );

    if (!eid) {
        console.error('Could not find eid in path ', pathname);
    }

    return $http
        .get(SESSION_ENDPOINT.replace('{eid}', eid), options)
        .then(data => {
            if (data) data.prefix = prefix;
            return data;
        });
};

class AuthService {
    /* @ngInject */
    constructor(ACTIVATED_PERSON, BRANDING, EID, WHITE_LABEL_DOMAIN, $window) {
        this.ACTIVATED_PERSON = ACTIVATED_PERSON;
        this.BRANDING = BRANDING;
        this.EID = EID;
        this.WHITE_LABEL_DOMAIN = WHITE_LABEL_DOMAIN;
        this.$window = $window;
    }

    logOut(options = {}) {
        let redirectTo = options.redirectTo || '/';
        let reason = options.reason || 'logout';
        let branding = options.branding || null;
        let goToWelcomePage = options.goToWelcomePage || false;

        try {
            this.$window.localStorage.clear();
        } catch (e) {
            // do nothing
        }

        // :(
        // we can't do $http from here because it would introduce a circular dependency
        // ($http -> httpAuthInterceptor -> authService -> $http)
        let loginUrlWithPrefilledData =
            `${"https://webapp.spotme-dev.com"}/login?` +
            `branding=${branding || this.BRANDING || 'eventspace'}` +
            (reason ? `&reason=${reason}` : '') +
            `&redirect=${redirectTo}` +
            `&email=${this.ACTIVATED_PERSON.email}` +
            `&install_instructions=${goToWelcomePage}`;

        loginUrlWithPrefilledData = replaceHostIfHasWhiteLabelDomain(loginUrlWithPrefilledData, this.WHITE_LABEL_DOMAIN);

        $.ajax(SESSION_ENDPOINT.replace('{eid}', this.EID), {
            method: 'DELETE',
            complete: () =>
                (this.$window.location.href = loginUrlWithPrefilledData)
        });
    }

    getSession() {
        // manual injection is required to avoid dependency loop
        return getUserSession({}, angular.injector([ 'ng' ]).get('$http'));
    }
}

// factories can't be classes
/* @ngInject */
function HttpAuthInterceptor($q, authService, $injector) {
    return {
        responseError(response) {
            if (response.status === 401) {
                // first check if session is still valid
                authService.getSession().catch(() => {
                    console.log(
                        '[HttpAuthInterceptor] 401 response detected, kicking user out'
                    );
                    const BRANDING = $injector.get('BRANDING');
                    return authService.logOut(undefined, undefined, BRANDING || (response.data || {}).branding);
                });
            }

            return $q.reject(response);
        }
    };
}

angular
    .module('maestro.auth', [ 'maestro.config' ])
    .service('authService', AuthService)
    .factory('httpAuthInterceptor', HttpAuthInterceptor)
    .config([ '$httpProvider', $httpProvider => $httpProvider.interceptors.push('httpAuthInterceptor') ]);

// exposed for boot
module.exports.getUserSession = getUserSession;
