import { get, map } from 'lodash';

export const SelectBlockComponent = {
    template: require('./select-block.pug'),
    bindings: {
        parentNavId: '<',
        config: '<'
    },
    controller: class SelectBlockComponent {
        /* @ngInject */ constructor(navService, navFormService, $sheet, $eventBus) {
            this.navService = navService;
            this.navFormService = navFormService;
            this.$sheet = $sheet;
            this.$eventBus = $eventBus;
        }

        $onInit() {
            console.log('[SelectBlockComponent] init', this.config);
            this.pristine = get(this.config, 'data.theme.pristine') === false ? false : true;
            this.showRequiredText = this.config.required;
            this.selectorText = this.config.data.content.placeholder;
            this.requiredText = get(this.config, 'data.content.required_text') || 'This field is required';
            this.items = get(this, 'config.data.content.items') || [];
            this.visible = this.items.length > 1;
            this.actions = map(this.items, i => {
                return {
                    id: i.id,
                    label: i.title,
                    params: {
                        id: i.id
                    },
                    action: ({ action }) => this.selectItem(action)
                };
            });

            // Preselect first value in case of single choice
            if (this.items.length === 1) {
                this.selectItem(this.items[0]);
            }

            if (this.config.required) {
                this.navFormService.setRequiredField(this.parentNavId, this.config.input_key, true);
            }
        }

        selectItem(item) {
            console.info('[SelectBlockComponent] Element selected', item);
            this.pristine = false;
            this.selectorText = item.label;
            this.showRequiredText = false;

            this.navFormService.set(
                this.parentNavId,
                this.config.input_key,
                item.id
            );
        }

        showFeedsList($event) {
            this.$sheet({
                menu: true,
                positionMenuAround: $event.currentTarget,
                title: this.config.data.content.placeholder,
                actions: this.actions,
                anchor: 'bottom left',
                vOffset: 6
            });
        }
    }
};
