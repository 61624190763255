import { isFunction } from 'lodash';

export class Tab {
    constructor(services, context, getters, updatePlayerHandler) {
        this.services = services;
        this.context = context;
        this.getters = getters;
        this.updatePlayerHandler = updatePlayerHandler;

        this.listeners = [];
    }

    init() {
        // overrides
    }

    // eslint-disable-next-line no-unused-vars
    onContextUpdated(_oldContext) {
        // overrides
    }

    destroy() {
        for (const unsub of this.listeners) {
            if (isFunction(unsub)) {
                unsub();
            }
        }
    }

    showNotification(payload) {
        this.playerApi.clientAction(payload, 'sideBarNotification');
    }

    get useMetadata() {
        return this.getters.useMetadata();
    }

    get playerApi() {
        return this.getters.playerApi();
    }

    get isIframeContent() {
        return this.getters.isIframeContent();
    }

    get isSmallScreen() {
        return this.getters.isSmallScreen();
    }

    get isStreamless() {
        return this.getters.isStreamless();
    }

    get isInPerson() {
        return this.getters.isInPerson();
    }

    get isVod() {
        return this.getters.isVod();
    }

    get streamEnded() {
        return this.getters.streamEnded();
    }

    get livestream() {
        return this.context.livestream;
    }
}
