import { nowUnixTimestamp } from '../utils/time';

const GET_COOKIES_ENDPOINT = '/api/v1/eid/{{eid}}/signed-assets/{{docId}}';

export default class CdnCookiesProvider {

    /* @ngInject */
    constructor($http, EID) {
        this.$http = $http;
        this.EID = EID;
        this.cache = new Map();
    }

    /**
     * Get the CDN cookies for the given document's attached files
     * Can be called as often as needed, the cache will prevent multiple requests
     * @param {String} docId
     * @return {Promise<String>} the base CDN directory URL
     */
    async getCookiesForDirectory(docId) {
        const cacheKey = docId;
        const cachedData = this.cache.get(cacheKey);

        // Check if there is cached data and if the expiration time is not in the next 30 seconds
        if (cachedData && nowUnixTimestamp() < cachedData.expires - 30) {
            return cachedData.baseCdnDirectoryUrl;
        }

        const getCookiesUrl = GET_COOKIES_ENDPOINT.replace('{{eid}}', this.EID).replace('{{docId}}', docId);

        // The response's headers will contain all the necessary cookies to access any file in the directory
        const response = await this.$http.get(getCookiesUrl, { withCredentials: true, cache: false }).catch((err) => {
            console.error('[CdnCookiesProvider] Failed to get CDN cookies for directory', { err, docId });
            throw err;
        });
        const { url: baseCdnDirectoryUrl, expires } = response.data;

        if (expires) {
            this.cache.set(cacheKey, {
                baseCdnDirectoryUrl,
                expires
            });
        }

        return baseCdnDirectoryUrl;
    }
}
