import { compact, get } from 'lodash';

export const ProfileRowComponent = {
    template: require('./profile-row.jade'),
    bindings: {
        config: '<'
    },
    controller: class ProfileRowComponent {
        /* @ngInject */
        constructor(ACTIVATED_PERSON, EVENT, THEME, navService, $scope, $i18n) {
            this.person = ACTIVATED_PERSON;
            this.photoRev = 1;
            this.paxFullName = ACTIVATED_PERSON._representation || `${ACTIVATED_PERSON.fname} ${ACTIVATED_PERSON.lname}`;

            navService.on('activated-person:photo', () => {
                this.photoRev++;
                this.refreshImage();
            });

            this.performActions = () => navService.performActions(this.config.actions, this.config.nuid);

            this.THEME = THEME;

            $scope.$watch('$ctrl.config', () => {
                this.bannerUrl = get(this, 'config.ds.source.params.banner', null);
                this.showPhoto = !get(this, 'config.ds.source.params.disable_user_photo');
                this.refreshImage();
                this.person.full_name = ACTIVATED_PERSON._representation || compact([ ACTIVATED_PERSON.fname, ACTIVATED_PERSON.lname ]).join(' ');

                const subtitle = get(this, 'config.ds.source.params.subtitle') || 'eventName';
                switch (subtitle) {
                    case 'nothing':
                        this.subtitle = '';
                        break;
                    case 'labelMyProfile':
                        this.subtitle = $i18n('section_title.my_profile');
                        break;
                    case 'eventName':
                        this.subtitle = EVENT.description.title.en;
                        break;
                    default:
                        this.subtitle = ACTIVATED_PERSON[subtitle] || EVENT.description.title.en;

                }
            }, true);
        }

        refreshImage() {
            this.person.photo = `${this.person._id}-photo/photo?_=${this.photoRev}`;
            if (this.person.hasOwnProperty('_representation_image')) {
                this.person._representation_image = this.person.photo;
            }
        }
    }
};
