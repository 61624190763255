function EnterKey() {
    const linker = ($scope, element) => {
        console.info('[EnterKey] init', element);
        element
            .on('keydown', e => {
                if (e.key.toLowerCase() === $scope.submitKey && e.shiftKey) {
                    element.val(element.val().trim());
                }
            })
            .on('keypress', e => {
                if (e.key.toLowerCase() === $scope.submitKey && !e.shiftKey) {
                    $scope.onSubmit(e);
                    $scope.$apply();

                    element.focus();
                    return false;
                }
            });
    };

    return {
        restrict: 'A',
        scope: {
            submitKey: '@enterKey',
            onSubmit: '&'
        },
        link: linker
    };
}

angular.module('maestro.directives').directive('enterKey', EnterKey);
